import { configs } from "../../configs";
import { TRANSLATIONS_SUPPORTED_LANGUAGES } from "@trinity/constants";
import { isEmptyObject, isJson } from "../common/common";
import { FETCH, fetchCreator } from "./fetch";

export const TRANSLATE = "utils/translate";

const TRANSLATE_API_MAX_NUMBER_OF_ARRAY_ELEMENTS = 100;
const TRANSLATE_API_MAX_REQUEST_SIZE_OF_CHARACTERS = 10000;

export interface ITranslateAction {
  meta: {
    translate: {
      /**
       * Enable or disable the translation
       */
      isEnabled: boolean;

      /**
       * Fields with respect to properties thats needs to be translated
       */
      objectToTranslate: {
        keyToIndexMap: Record<string, string>[];
        indexToTranslatedKeyMap: Record<string, string>[];
      };

      /**
       * Current language
       */
      from: string;

      /**
       * Language to be translated
       */
      to: string;

      /**
       * unique cache key where the translations are saved
       */
      translationKey: string;

      /**
       * Translate on demand If data already exits
       */
      data?: any;

      /**
       * Will ignore the properties for translation
       */
      propertiesToIgnore?: string[];

      languageToItemsMap?: (result: any) => Record<string, any>;
    };
  };
}

let translateMetaData, storeInstance;

export const translateMiddleware = (store) => (next) => async (action) => {
  // If there is any other action apart from fetch/translate go to next.
  if (action.type !== FETCH && action.type !== TRANSLATE) return next(action);

  // If meta translate information is not there go to next.
  if (!action.meta || !action.meta.translate) return next(action);

  // if translation is not enabled go next
  if (!action.meta.translate.isEnabled) return next(action);

  translateMetaData = action.meta.translate;
  storeInstance = store;

  // If the data is given on demand, only translate the given data
  if (translateMetaData.data) {
    await new Translate(translateMetaData).translate();
    return new Promise((resolve) => resolve(new Response())).catch((error) => {
      throw new Error(error);
    });
  }

  // If it has to explicity call an API and then translate it
  return new Promise((resolve, reject) => {
    next(action)
      .then(async (response: Response) => {
        if (response.ok) {
          // NOTE: response.json() can be read only once.
          // Therefore, it has to be resolved and given back as a new response.
          const result = await response.json();

          const languageToItemsMap = translateMetaData.languageToItemsMap(
            result.value
          );

          const translatePromises: Promise<void>[] = [];

          for (const [fromLanguage, items] of Object.entries(
            languageToItemsMap
          )) {
            translatePromises.push(
              new Translate({
                ...translateMetaData,
                from: fromLanguage || translateMetaData.from,
                data: items,
              }).translate()
            );
          }

          await Promise.all(translatePromises);

          resolve(new Response(JSON.stringify(result)));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

class Translate {
  from: string;
  to: string;
  data: any;
  translationKey: string;
  objectToTranslate: (result: any) => {
    keyToIndexMap: Record<string, string>[];
    indexToTranslatedKeyMap: Record<string, string>[];
  };
  constructor(translateMetaData: {
    from: string;
    to: string;
    data: any;
    translationKey: string;
    objectToTranslate: (result: any) => {
      keyToIndexMap: Record<string, string>[];
      indexToTranslatedKeyMap: Record<string, string>[];
    };
  }) {
    this.from = translateMetaData.from;
    this.to = translateMetaData.to;
    this.data = translateMetaData.data;
    this.translationKey = translateMetaData.translationKey;
    this.objectToTranslate = translateMetaData.objectToTranslate;
  }

  /**
   * Main function which starts translate operation
   * @param result data that is to be translated
   */
  async translate() {
    const { keyToIndexMap, indexToTranslatedKeyMap } = this.objectToTranslate(
      this.data
    );

    const translations = await this.getTranslations(indexToTranslatedKeyMap);

    const finalTranslationObject = this.mapTranslations(
      keyToIndexMap,
      translations
    );

    this.upsertTranslationCache(finalTranslationObject);
  }

  /**
   * translated API function which sends the data in batches with respect to MAX_NUMBER_OF_ARRAY_ELEMENTS & MAX_REQUEST_SIZE_OF_CHARACTERS that API supports and is received in the same sequence as the way it was sent
   * @param data indexToTranslatedKeyMap - key value pair object for translation api request
   * @returns response - which gives the translated data
   */
  async getTranslations(data: Record<string, string>[]) {
    let response = [];

    try {
      let requestBody: any[] = [];
      let counter = 0;
      let elementIndex = 1;

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        if (
          counter > TRANSLATE_API_MAX_REQUEST_SIZE_OF_CHARACTERS ||
          elementIndex > TRANSLATE_API_MAX_NUMBER_OF_ARRAY_ELEMENTS
        ) {
          counter = 0;
          elementIndex = 1;
          index--;
          response = response.concat(await this.fetchTranslation(requestBody));
          requestBody = [];
        } else {
          counter += element.text.length;
          elementIndex++;
          requestBody.push(element);
        }
      }

      if (requestBody.length > 0) {
        response = response.concat(await this.fetchTranslation(requestBody));
      }

      return response;
    } catch (error: any) {
      throw new Error(error as any);
    }
  }

  /**
   * Maps back the translated value to that of the key which is to be translated
   * @param objectToTranslateData keyToIndexMap - key value pair object which is indexed such that it knows where its needed to be mapped back when the response is received from the translation API
   * @param translatedData response of the translated API received in the same sequence that it was sent
   * @returns Combines the translatedData according to the index positions of the keyToIndexMap object
   */
  mapTranslations(objectToTranslateData: any, translatedData: any[]): any {
    if (translatedData.length > 0) {
      return Object.entries(objectToTranslateData).reduce(
        (acc, [key, value]: any) => {
          acc[key] = translatedData[value]
            ? translatedData[value].translations[0].text
            : decodeURI(key);

          return acc;
        },
        {}
      );
    }

    return {};
  }

  /**
   * Keeps updating the cache of the translated key value pair
   * @param data mapTranslations data to update the cache
   */
  upsertTranslationCache(data: any): void {
    const existingData = getTranslationsCache(this.translationKey);
    const result = { ...existingData, ...data };

    localStorage.setItem(this.translationKey, JSON.stringify(result));
  }

  /**
   * Helper function to send the request
   * @param requestBody data that is to be sent
   * @returns the translated response of the given data
   */
  async fetchTranslation(requestBody) {
    const request = await storeInstance.dispatch(
      fetchCreator(
        `${
          configs.client.endpoint.appServiceEndpoint
        }/translate?from=${getLanguageCode(this.from)}&to=${this.to}`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
        }
      )
    );

    return await request.json();
  }
}

/**
 * Helper function which gets the language code
 * @param value current language value
 * @returns the supported language code by the translation API, if not present returns the given value
 */
function getLanguageCode(value: string) {
  const result = TRANSLATIONS_SUPPORTED_LANGUAGES[value];

  return result ? result.supportedCode : value;
}

/**
 * Helper function which gets the value of the translated content from the local storage
 * @returns cache object if present else empty
 */
function getTranslationsCache(translationKey: string): any {
  const result = localStorage.getItem(translationKey);

  if (result && !isEmptyObject(result)) {
    return JSON.parse(result);
  }

  return {};
}
