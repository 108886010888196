import { Reducer } from "redux";
import {
  DefaultSearchRule,
  DefaultUpdateRule,
  ISearchRule,
  IUpdateRule,
  actionTypes,
} from "./RuleState";

export const SearchRuleReducer: Reducer<ISearchRule> = (
  state: ISearchRule = DefaultSearchRule,
  action: any
) => {
  const fetchedData = action?.payload?.data || {};
  switch (action.type) {
    case actionTypes.SEARCH_DEFAULT_TERM_RULE:
      return {
        ...state,
        defaultTermRules: fetchedData.defaultTermRules,
      };
    case actionTypes.SEARCH_TERM_OVERRIDE_RULE:
      return {
        ...state,
        termOverrideRule: fetchedData.termOverrideRules,
      };
    case actionTypes.SEARCH_FLAG_OVERRIDE_RULE:
      return {
        ...state,
        flagOverrideRule: fetchedData.flagOverrideRules,
      };
    case actionTypes.SEARCH_URL:
      return {
        ...state,
        urlRule: fetchedData.urlRules,
      };
    default:
      return state || {};
  }
};

export const UpdateRuleReducer: Reducer<IUpdateRule> = (
  state: IUpdateRule = DefaultUpdateRule,
  action: any
) => {
  const fetchedData = action?.payload?.data || {};
  switch (action.type) {
    case actionTypes.UPDATE_DEFAULT_TERM_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    case actionTypes.UPDATE_TERM_OVERRIDE_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    case actionTypes.UPDATE_FLAG_OVERRIDE_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    default:
      return state || {};
  }
};
