import { IDropdownOption } from "@fluentui/react";

/**
 * {@docCategory Pagination}
 */
export interface IPaginationProps {
  /**
   * Optional callback for the change of dropdown option
   */
  onDropDownValueChange?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  /**
   * Optional callback for the next page
   */
  onNextClick?: (currentDropDownValue: number, currentPage: number) => void;
  /**
   * Optional callback for the pervious page
   */
  onPreviousClick?: (currentDropDownValue: number, currentPage: number) => void;
  /**
   * Class name for adding styles
   */
  className?: string;
  /**
   * Pagination Shortcuts
   */
  shortcuts?: PaginationShortcuts;
}

export enum PaginationShortcuts {
  PAGINATION_NEXT_PAGE = "PAGINATION_NEXT_PAGE",
  PAGINATION_PREVIOUS_PAGE = "PAGINATION_PREVIOUS_PAGE",
}
