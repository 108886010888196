import { IFetchAction } from "./fetch";

export interface ITimeoutAction extends IFetchAction {
  meta: {
    timeout: {
      /**
       * Number of milliseconds to wait on fetch call before throwing an error.
       */
      ms: number;
    };
  };
}

export const timeoutMiddleware = (store) => (next) => (action) => {
  if (action.meta && action.meta.timeout) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error("Error: Request Timed Out"));
      }, action.meta.timeout.ms);
      next(action).then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  } else {
    return next(action);
  }
};
