import { LoadState, QueueTypes } from "@trinity/enums";

export interface IReviewerReviewPackageState {
  isViewOnly: boolean;
  currentPackage: ICurrentPackage;
}

export interface ICurrentPackage {
  id: string;
  queueId: number;
  queueName: string;
  queueIsTranslationEnabled: boolean;
  queueLanguage: number;
  queueType: QueueTypes;
  workflowStatusId: number;
  loadState: LoadState;
  assignedUserID: number | null;
}
export interface IPackageDetails {
  dSeqId: number;
  sequenceId: number;
  packageGuid: string;
  capabilityID: number;
  metaInfoJson: string;
  rowsCount: number;
  workflowStatusId: number;
  createdAt: Date;
  modifiedAt: Date | null;
  assignedAt: Date | null;
  completedAt: Date | null;
  assignedUserID: number | null;
  modifiedBy: string | null;
  capabilityName: string;
  reviewType: number;
  rowDateCreated: Date;
  queueType: number;
  entityType: number;
  segment: number;
  language: number;
  region: number;
  isSystemDefined: boolean;
  priority: number;
  packageCheckoutDuration: number;
  isTranslationEnabled: boolean;
}

export enum actionTypes {
  GET_PACKAGE_DETAILS = "[REVIEWER-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS",
  GET_PACKAGE_DETAILS_FAILED = "[REVIEWER-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS_FAILED",
  GET_PACKAGE_DETAILS_COMPLETE = "[REVIEWER-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS_COMPLETE",
  GET_CURRENT_PACKAGE = "[REVIEWER-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE",
  GET_CURRENT_PACKAGE_COMPLETE = "[REVIEWER-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE_COMPLETE",
  GET_CURRENT_PACKAGE_FAILED = "[REVIEWER-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE_FAILED",

  GET_REVIEWER_REVIEWING_PACKAGE = "[REVIEWER-REVIEW-PACKAGE] - GET_REVIEWER_REVIEWING_PACKAGE",
  GET_REVIEWER_REVIEWING_PACKAGE_COMPLETE = "[REVIEWER-REVIEW-PACKAGE] - GET_REVIEWER_REVIEWING_PACKAGE_COMPLETE",
  GET_REVIEWER_REVIEWING_PACKAGE_FAILED = "[REVIEWER-REVIEW-PACKAGE] - GET_REVIEWER_REVIEWING_PACKAGE_FAILED",
  ASSIGN_AND_REVIEW_PACKAGE = "[REVIEWER-REVIEW-PACKAGE] - ASSIGN_AND_REVIEW_PACKAGE",
  ASSIGN_AND_REVIEW_PACKAGE_FAILED = "[REVIEWER-REVIEW-PACKAGE] - ASSIGN_AND_REVIEW_PACKAGE_FAILED",
  RESET_CURRENT_PACKAGE = "[REVIEWER-REVIEW-PACKAGE] - RESET_CURRENT_PACKAGE",
}

export const DefaultCurrentPackage: ICurrentPackage = {
  id: "",
  queueId: 0,
  queueName: "",
  queueIsTranslationEnabled: false,
  queueLanguage: -1,
  queueType: QueueTypes.None,
  workflowStatusId: -1,
  loadState: LoadState.NotLoaded,
  assignedUserID: null,
};

export const DefaultReviewerReviewPackageState: IReviewerReviewPackageState = {
  isViewOnly: false,
  currentPackage: DefaultCurrentPackage,
};
