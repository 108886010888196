import {
  AdType,
  EntityType,
  LoadState,
  ReviewPackageWorkFlow,
} from "@trinity/enums";
import { UserPreferencesModule } from "../user-preference/UserPreferenceState";

export enum QueueTypeLabel {
  Appeal,
  PMA,
  PA_Appeal,
  PA_PMA,
}

export enum ReviewPackagePanelType {
  None,
  Flags,
  Submit,
  SupportingInfo,
  OverrideFlagTerm,
  OverrideFlagArea,
  Comments,
  OverrideFlagAreaAppealV2,
  OverrideFlagTermAppealV2,
  Appeal_Review_V2,
  Appeal_Review_V2_Auditor,
  Appeal_Review_V2_Reviewer,
}

export const CONTENT_FIELDS_TO_TRANSLATE = ["FlagTerm"];

export const ENTITY_AND_SAMPLES_FIELDS_TO_TRANSLATE = [
  "Description",
  "ShortHeadline",
  "LongHeadline",
  "BusinessName",
  "AdTitle",
  "AdDescription",
  "KeywordText",
  "Param1",
  "Param2",
  "Param3",
];

export enum DecisionSource {
  SINGLE = "single",
  MULTIPLE = "multiple",
  PACKAGE = "package",
}

export interface IReviewPackageItem {
  id: string;
  notes: string;
  decision: string;
  contentType: string;
  packageGuid: string;
  currentDecision: string;
  workflowStatusId: number;
  content: IReviewPackageContent;
  extraDecision: IExtraDecision;
}

export interface IExtraDecision {
  newFlags: INewFlag[];
  decisionSource: DecisionSource;
}

export interface INewFlag {
  flagTerm: string;
  flagAreaID: number;
  flagLevel: number;
  country: string;
  component: string;
}
export interface IReviewPackageContent {
  PID: string;
  RowID: number;
  Country: string;
  Abandon: string;
  Language: string;
  AppealID: string;
  FlagTerm: string;
  EntityId: string;
  UPMScore: string;
  RowNumber: string;
  FlagLevel: string;
  CustomerId: string;
  AccountIDs: string;
  EntityType: string;
  FlagAreaID: string;
  TrustRating: string;
  FraudStatus: string;
  CustomerName: string;
  ModifiedDate: string;
  ComponentMask: number;
  AggEntityText: string;
  SubEntityType: string;
  AccountNumber: string;
  ModifiedByUser: string;
  KeywordDetails: string;
  FlagAreaIDText: string;
  SampleAdCopies: string;
  IsAdultCustomer: string;
  CountOfEntities: string;
  EditorialStatus: string;
  LanguageCodeText: string;
  VNextSamplesJSON: string;
  SubEntityTypeText: string;
  ModifiedByService: string;
  UsedInEntityTypes?: string;
  VNextEntityTextJSON: string;
  EditorialCategoryInfo: string;
  CapabilityName: number;
  Comments: string;
  ParentPackageGuid: string;
  ParentPackageUserID: number;
  ParentPackageUserAlias: string;
  ParentPackageRowID: string;
  ParentPackageFinalDecision: string;
  ParentPackageExtraDecision: IExtraDecision;
  ParentPackageFinalDecisionDate: string;
  ReviewedPackageGuid: string;
  ReviewerUserId: number;
  ReviewerAlias: string;
  ReviewedDate: string;
  ReviewerDecision: string;
  ReviewerExtraDecision: IExtraDecision;
  SubmitterEmail: string;
  IsYahooManaged: string;
  SubCategoryName: string;
  ListName: string;
  OfferOrigin: string;
  Flags: string;
}

export interface IReviewPackageSummary {
  id: string;
  upheld: number;
  completed: number;
  elapsedTime: Date;
  startTime: string;
  overturned: number;
  reviewTime: number;
  publishedAt: string;
  loadState: LoadState;
  rowsToReview: number;
  hasAllRowsDecisionTaken: boolean;
  flagAreas: IReviewPackageFlagAreaSummary[];
  aggregatedColumns: AggregateColumns;
}

export interface AggregateColumns {
  customerIds: Array<string>;
  entityTypes: Array<string>;
  entityIds: Array<string>;
}

export interface IReviewPackageFlagAreaSummary {
  id: number;
  total: number;
  upheld: number;
  overturned: number;
  incomplete: number;
}

export interface IReviewPackageState {
  deleteAllFlags: boolean;
  isTranslationEnabled: boolean;
  panelType: ReviewPackagePanelType;
  summary: IReviewPackageSummary;
  packageItems: IReviewPackageItems;
  overrideFlagTermResponse: any;
  supportingInfo: IReviewPackageSupportingInfo;
  selectedItems: Array<IReviewPackageItem>;
  areAllItemsSelected: boolean;
  reviewPackageDetails: IReviewPackageDetails;
  isPackageDecisionsSyncInProgress: boolean;
  isPackageDecisionsSyncFailed: boolean;
  csvData: any[] | null;
  isFiltered: boolean;
}

export interface IReviewPackageSupportingInfo {
  items: ISupportingInfo;
  loadState: LoadState;
  translatedImagesText: IImageTranslationSummary[];
}

export interface ISupportingInfo {
  type: EntityType;
  content: any;
  highlightTerm: string;
  subEntityType: AdType;
  entityText: any;
  ignoreVNextSamples: boolean;
}

export interface IReviewPackageItems {
  items: Array<IReviewPackageItem>;
  count: number;
  translatedItems: Record<string, string>;
  loadState: LoadState;
}

export interface IReviewPackageDetails {
  queueId: number;
  queueName: string;
  packageGuid: string;
  queueLanguage: number;
  queueIsTranslationEnabled: boolean;
  workFlowType: ReviewPackageWorkFlow;
  redirectUrl: string;
  addFlagForceDecisionOverturned: boolean;
  userPreferenceModule: UserPreferencesModule;
  isViewOnly: boolean;
  disabledFlagAreas: string[];
  workflowStatusId: number;
  assignedToUserEmail: string;
}

export interface IImageTranslationSummary {
  id: number;
  originalText: string;
  translatedText: string;
}

export interface IFlag {
  FA: number;
  TFA: string;
  FL: number;
  FT: string;
  ComponentMask: number;
  LanguageID: number;
  LanguageCodeText: string;
  CC: string;
  PreviousModifiedByUserID: number;
  PreviousModifiedServiceID: string;
  PreviousRowDateModified: string;
  OfferOrigin: string;
  FFL: string;
}

export enum ContentType {
  APPEAL_V2 = "appealV2",
  MisMatch = "MisMatch",
}

export const DefaultReviewPackageItems: IReviewPackageItems = {
  count: 0,
  items: [],
  translatedItems: {},
  loadState: LoadState.NotLoaded,
};

export const DefaultReviewPackageSummary: IReviewPackageSummary = {
  id: "",
  upheld: 0,
  completed: 0,
  reviewTime: 0,
  overturned: 0,
  flagAreas: [],
  startTime: "",
  rowsToReview: 0,
  publishedAt: "",
  elapsedTime: new Date(),
  loadState: LoadState.NotLoaded,
  hasAllRowsDecisionTaken: false,
  aggregatedColumns: {
    customerIds: [],
    entityIds: [],
    entityTypes: [],
  },
};

export const DefaultReviewSupportingInfo: IReviewPackageSupportingInfo = {
  translatedImagesText: [],
  loadState: LoadState.NotLoaded,
  items: {
    type: EntityType.Ad,
    content: {},
    highlightTerm: "",
    subEntityType: AdType.Text,
    entityText: {},
    ignoreVNextSamples: false,
  },
};

export const DefaultReviewPackageDetails: IReviewPackageDetails = {
  packageGuid: "",
  isViewOnly: false,
  queueId: -1,
  queueIsTranslationEnabled: false,
  queueLanguage: -1,
  addFlagForceDecisionOverturned: false,
  queueName: "",
  redirectUrl: "",
  userPreferenceModule: UserPreferencesModule.NONE,
  workFlowType: ReviewPackageWorkFlow.NONE,
  disabledFlagAreas: [],
  workflowStatusId: -1,
  assignedToUserEmail: "",
};

export const DefaultReviewPackageState: IReviewPackageState = {
  selectedItems: [],
  deleteAllFlags: false,
  isTranslationEnabled: false,
  panelType: ReviewPackagePanelType.None,
  summary: DefaultReviewPackageSummary,
  packageItems: DefaultReviewPackageItems,
  overrideFlagTermResponse: {},
  supportingInfo: DefaultReviewSupportingInfo,
  areAllItemsSelected: false,
  reviewPackageDetails: DefaultReviewPackageDetails,
  isPackageDecisionsSyncInProgress: false,
  isPackageDecisionsSyncFailed: false,
  csvData: null,
  isFiltered: false,
};

export enum actionTypes {
  GET_PACKAGE_SUMMARY = "[REVIEW-PACKAGE] - GET_PACKAGE_SUMMARY",
  PACKAGE_SUMMARY_RECEIVED = "[REVIEW-PACKAGE] - PACKAGE_SUMMARY_RECEIVED",
  PACKAGE_SUMMARY_FETCH_FAILED = "[REVIEW-PACKAGE] - PACKAGE_SUMMARY_FETCH_FAILED",
  GET_PACKAGE_ITEMS = "[REVIEW-PACKAGE] - GET_PACKAGE_ITEMS",
  PACKAGE_ITEMS_RECEIVED = "[REVIEW-PACKAGE] - PACKAGE_ITEMS_RECEIVED",
  PACKAGE_ITEMS_FETCH_FAILED = "[REVIEW-PACKAGE] - PACKAGE_ITEMS_FETCH_FAILED",
  SUBMIT_PACKAGE = "[REVIEW-PACKAGE] - SUBMIT_PACKAGE",
  SUBMIT_PACKAGE_FAILED = "[REVIEW-PACKAGE] - SUBMIT_PACKAGE_FAILED",
  REDIRECT_TO_OVERVIEW_SCREEN = "[REVIEW-PACKAGE] - REDIRECT_TO_OVERVIEW_SCREEN",
  SUBMIT_PACKAGE_TO_NEW_QUEUE = "[REVIEW-PACKAGE] - SUBMIT_PACKAGE_TO_NEW_QUEUE",
  SUBMIT_PACKAGE_TO_NEW_QUEUE_FAILED = "[REVIEW-PACKAGE] - SUBMIT_PACKAGE_TO_NEW_QUEUE_FAILED",
  SUBMIT_FLAG_TERM_OVERRIDE = "[REVIEW-PACKAGE] - SUBMIT_FLAG_TERM_OVERRIDE",
  SUBMIT_FLAG_TERM_OVERRIDE_FAILED = "[REVIEW-PACKAGE] - SUBMIT_FLAG_TERM_OVERRIDE_FAILED",
  SUBMIT_FLAG_AREA_OVERRIDE = "[REVIEW-PACKAGE] - SUBMIT_FLAG_AREA_OVERRIDE",
  SUBMIT_FLAG_AREA_OVERRIDE_FAILED = "[REVIEW-PACKAGE] - SUBMIT_FLAG_AREA_OVERRIDE_FAILED",
  UPDATE_OVERTURN_REJECTION_DECISIONS = "[REVIEW-PACKAGE] - UPDATE_OVERTURN_REJECTION_DECISIONS",
  UPDATE_UPHOLD_REJECTION_DECISIONS = "[REVIEW-PACKAGE] - UPDATE_UPHOLD_REJECTION_DECISIONS",
  UPDATE_TO_REVIEW_DECISIONS = "[REVIEW-PACKAGE] - UPDATE_TO_REVIEW_DECISIONS",
  GET_SUPPORTING_INFO_START = "[REVIEW-PACKAGE] - GET_SUPPORTING_INFO_START",
  GET_SUPPORTING_INFO_COMPLETE = "[REVIEW-PACKAGE] - GET_SUPPORTING_INFO_COMPLETE",
  GET_SUPPORTING_INFO_FAILED = "[REVIEW-PACKAGE] - GET_SUPPORTING_INFO_FAILED",
  OPEN_PANEL = "[REVIEW-PACKAGE] - OPEN_PANEL",
  CLOSE_PANEL = "[REVIEW-PACKAGE] - CLOSE_PANEL",
  SET_SELECTED_ITEMS = "[REVIEW-PACKAGE] - SET_SELECTED_ITEMS",
  SET_IS_TRANSLATION_ENABLED = "[REVIEW-PACKAGE] - SET_IS_TRANSLATION_ENABLED",
  SUBMIT_ADD_FLAG = "[REVIEW-PACKAGE] - SUBMIT_ADD_FLAG",
  SUBMIT_ADD_FLAG_FAILED = "[REVIEW-PACKAGE] - SUBMIT_ADD_FLAG_FAILED",
  SUBMIT_ADD_FLAG_ALL = "[REVIEW-PACKAGE] - SUBMIT_ADD_FLAG_ALL",
  SUBMIT_ADD_FLAG_ALL_FAILED = "[REVIEW-PACKAGE] - SUBMIT_ADD_FLAG_ALL_FAILED",
  SET_ARE_ALL_ITEMS_SELECTED = "[REVIEW-PACKAGE] - SET_ARE_ALL_ITEMS_SELECTED",
  DELETE_FLAG_INFO = "[REVIEW-PACKAGE] - DELETE_FLAG_INFO",
  SYNC_ALL_PACKAGE_ITEMS_DECISIONS = "[REVIEW-PACKAGE] - SYNC_ALL_PACKAGE_ITEMS_DECISIONS",
  SYNC_ALL_PACKAGE_ITEMS_DECISIONS_FAILED = "[REVIEW-PACKAGE] - SYNC_ALL_PACKAGE_ITEMS_DECISIONS_FAILED",
  SYNC_ALL_PACKAGE_ITEMS_DECISIONS_COMPLETED = "[REVIEW-PACKAGE] - SYNC_ALL_PACKAGE_ITEMS_DECISIONS_COMPLETED",
  SET_REVIEW_PACKAGE_DETAILS = "[REVIEW-PACKAGE] - SET_REVIEW_PACKAGE_DETAILS",
  SUBMIT_PACKAGE_COMPLETE = "[REVIEW-PACKAGE] - SUBMIT_PACKAGE_COMPLETE",
  UPDATE_OVERTURN_REJECTION_DECISIONS_COMPLETED = "[REVIEW-PACKAGE] - UPDATE_OVERTURN_REJECTION_DECISIONS_COMPLETED",
  UPDATE_UPHOLD_REJECTION_DECISIONS_COMPLETED = "[REVIEW-PACKAGE] - UPDATE_UPHOLD_REJECTION_DECISIONS_COMPLETED",
  UPDATE_TO_REVIEW_DECISIONS_COMPLETED = "[REVIEW-PACKAGE] - UPDATE_TO_REVIEW_DECISIONS_COMPLETED",
  SYNC_PACKAGE_ITEMS = "[REVIEW-PACKAGE] - SYNC_PACKAGE_ITEMS",
  SYNC_PACKAGE_ITEMS_COMPLETE = "[REVIEW-PACKAGE] - SYNC_PACKAGE_ITEMS_COMPLETE",
  SYNC_PACKAGE_ITEMS_DECISIONS_FAILED = "[REVIEW-PACKAGE] - SYNC_PACKAGE_ITEMS_DECISIONS_FAILED",
  DOWNLOAD_PACKAGE_DETAILS = "[REVIEW-PACKAGE] - DOWNLOAD_PACKAGE_DETAILS",
  DOWNLOAD_PACKAGE_DETAILS_COMPLETED = "[REVIEW-PACKAGE] - DOWNLOAD_PACKAGE_DETAILS_COMPLETED",
  DOWNLOAD_PACKAGE_DETAILS_FAILED = "[REVIEW-PACKAGE] - DOWNLOAD_PACKAGE_DETAILS_FAILED",
  GET_IMAGE_TEXT_TRANSLATION = "[REVIEW-PACKAGE] - GET_IMAGE_TEXT_TRANSLATION",
  GET_IMAGE_TEXT_TRANSLATION_FAILED = "[REVIEW-PACKAGE] - GET_IMAGE_TEXT_TRANSLATION FAILED",
  SAVE_COMMENTS = "[REVIEW-PACKAGE] - SAVE_COMMENTS",
  GET_PACKAGE_SUMMARY_TO_VALIDATE = "GET_PACKAGE_SUMMARY_TO_VALIDATE",
  PACKAGE_SUMMARY_TO_VALIDATE_RECEIVED = "PACKAGE_SUMMARY_TO_VALIDATE_RECEIVED",
  PACKAGE_SUMMARY_TO_VALIDATE_FETCH_FAILED = "PACKAGE_SUMMARY_TO_VALIDATE_FETCH_FAILED",
  UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2 = "UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2",
  UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2_COMPLETED = "UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2_COMPLETED",
  UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2 = "UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2",
  UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2_COMPLETED = "UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2_COMPLETED",
  APPEAL_V2_SUBMIT_COMPLETED = "APPEAL_V2_SUBMIT_COMPLETED",
  APPEAL_V2_SUBMIT = "APPEAL_V2_SUBMIT",
  UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2 = "UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2",
  UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2_COMPLETED = "UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2_COMPLETED",
}
