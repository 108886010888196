import { LoadState } from "@trinity/enums";

export interface IAdminQueueManagementState {
  allQueues: IQueueItem[];
  isLoaded: LoadState;
}

export interface IQueueItem {
  id: number;
  name: string;
  status: number;
  priority: number;
  usersCount: number;
  isTranslationEnabled: boolean;
  language: number;
}

export const INITIAL_STATE: IAdminQueueManagementState = {
  allQueues: [],
  isLoaded: LoadState.NotLoaded,
};
