export interface IListManagerNormalizedTokenCheckState {
  loading: boolean;
  normalizedOutput: string;
}

export enum actionTypes {
  GET_NORMALIZED_OUTPUT = "[LISTMANAGERNORMALIZEDTOKENCHECK] - GET_NORMALIZED_OUTPUT",
  GET_NORMALIZED_OUTPUT_SUCCESS = "[LISTMANAGERNORMALIZEDTOKENCHECK] - GET_NORMALIZED_OUTPUT_SUCCESS",
  GET_NORMALIZED_OUTPUT_FAILED = "[LISTMANAGERNORMALIZEDTOKENCHECK] - GET_NORMALIZED_OUTPUT_FAILED",
  CLEAR_NORMALIZED_OUTPUT = "[LISTMANAGERNORMALIZEDTOKENCHECK] - CLEAR_NORMALIZED_OUTPUT",
}

export const INITIAL_STATE: IListManagerNormalizedTokenCheckState = {
  loading: false,
  normalizedOutput: "",
};
