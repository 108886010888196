import { IFooterStyles, IFooterStyleProps } from "./Footer.types";
import { IStyleFunction } from "@fluentui/react";
import { Sizes } from "@trinity/common";

export const getStyles: IStyleFunction<IFooterStyleProps, IFooterStyles> = (
  props
) => {
  const { className } = props;
  return {
    root: [
      "trinity-Footer",
      {
        color: "#666",
        background: "#e5e5e5",
        font: "normal 12px arial",
        lineHeight: "12px",
        height: Sizes.footerHeight,
        bottom: "0",
        display: "flex" as const,
        alignItems: "center" as const,
        zIndex: 1,
      },
      className,
    ],
    item: [
      "trinity-Footer-item",
      {
        paddingLeft: Sizes.paddingMedium,
      },
    ],
    itemLink: [
      "trinity-Footer-itemLink",
      {
        color: "#666",
        textDecoration: "none",

        selectors: {
          "&.is-disabled:hover": {
            color: "#333",
            textDecoration: "none",
          },
          "&:hover": {
            color: "#333",
            textDecoration: "underline",
          },
          "&:focus": {
            color: "#333",
          },
          "&:active:hover": {
            color: "#333",
          },
        },
      },
    ],
  };
};
