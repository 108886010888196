import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { ReactNode, Suspense } from "react";

interface Props {
  children: ReactNode;
}

const spinnerStyles: any = {
  root: [
    {
      height: "100vh",
    },
  ],
};

const Fallback = () => {
  return (
    <div className="vnext-Layout">
      <Stack>
        <Spinner
          label="Setting things up..."
          styles={spinnerStyles}
          size={SpinnerSize.large}
        />
      </Stack>
    </div>
  );
};

const LazyComponentWrapper = ({ children }: Props) => (
  <Suspense fallback={<Fallback />}>{children}</Suspense>
);

export default LazyComponentWrapper;
