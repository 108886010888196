import { MessageBarType } from "@fluentui/react";
import { v4 as uuidv4 } from "uuid";
import { IApplicationState } from "..";
import { Conditions } from "../../components/common/table/table-command-bar/plugins/filters/Filters.types";
import { IModuleFilters } from "../../components/common/table/Table.types";
import { configs } from "../../configs";
import {
  ApiRequestMethods,
  BroadcastChannelName,
  UserActionTypes,
} from "@trinity/enums";
import {
  PackageUnAssign,
  RequestKeys,
} from "../../modules/admin-package-management/AdminPackageManagement.types";
import * as preferences from "../../utils/common/preferences";
import { fetchCreator } from "../../utils/middleware/fetch";
import { actionCreators as commonActions } from "../common/CommonActions";
import { IUserActionPayload } from "../common/CommonState";
import { DecisionSource } from "../review-package/ReviewPackageState";
import { actionCreators as reviewerReviewPackageActions } from "../reviewer-review-page/ReviewerReviewPackageActions";
import { actionCreators as userPackageActions } from "../user-package/UserPackageActions";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import {
  IUserModulePreferences,
  UserPreferencesModule,
} from "../user-preference/UserPreferenceState";
import { IUserState } from "../user/UserState";
import {
  IEditPackageItemBody,
  IPackageItem,
} from "./AdminPackageManagementState";

const PACKAGE_MANAGEMENT_QUEUE_ID_KEY = "package-management-queue-id";

const inProgressMessages = {
  [RequestKeys.SequenceId]: "Updating package sequence",
  [RequestKeys.ApplyDecision]: "Apply decision to package",
  [RequestKeys.AssignToQueue]: "Assigning package to queue",
  [RequestKeys.AssignUnAssign]: "Unassigning package",
};

export enum actionTypes {
  GET_PACKAGE_MANAGEMENT_PACKAGES = "GET_PACKAGE_MANAGEMENT_PACKAGES",
  GET_PACKAGE_MANAGEMENT_PACKAGES_FAILED = "GET_PACKAGE_MANAGEMENT_PACKAGES_FAILED",
  UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA = "UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA",
  UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA_FAILED = "UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA_FAILED",
  UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE = "UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE",
  UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE_FAILED = "UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE_FAILED",
  UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE = "UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE",
  UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE_FAILED = "UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE_FAILED",
  PACKAGE_MANAGEMENT_SELECTED_PACKAGE = "PACKAGE_MANAGEMENT_SELECTED_PACKAGE",
  GET_QUEUE_SUMMARY = "GET_QUEUE_SUMMARY",
  GET_QUEUE_SUMMARY_FAILED = "GET_QUEUE_SUMMARY_FAILED",
  LOAD_QUEUE_SUMMARY = "LOAD_QUEUE_SUMMARY",
  LOAD_PACKAGE_MANAGEMENT_PACKAGES = "LOAD_PACKAGE_MANAGEMENT_PACKAGES",
  RESET_TABLE_SELECTION = "RESET_TABLE_SELECTION",
  RESET_TABLE_SELECTION_COMPLETE = "RESET_TABLE_SELECTION_COMPLETE",
  FILTER_BY_FLAG_AREA_ON_SUMMARY = "FILTER_BY_FLAG_AREA_ON_SUMMARY",
  SET_IS_SUBMITTED_PACKAGES_ONLY = "SET_IS_SUBMITTED_PACKAGES_ONLY",
}

export const actionCreators = {
  getQueueSummary:
    (queueId) => async (dispatch: any, getState: () => IApplicationState) => {
      try {
        const currentQueueId = getState().userPackage.queueId;

        dispatch({ type: actionTypes.LOAD_QUEUE_SUMMARY });

        const response = await dispatch(
          fetchCreator(
            configs.client.endpoint.appServiceEndpoint + `queues/${queueId}`
          )
        );
        const data = await response.json();
        dispatch({
          type: actionTypes.GET_QUEUE_SUMMARY,
          payload: { data, currentQueueId },
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
        dispatch({
          type: actionTypes.GET_QUEUE_SUMMARY_FAILED,
        });
      }
    },

  getPackages:
    (queueId: number, debounceRequest = true) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        await dispatch(actionCreators.resetPackageManagementFilters(queueId));

        dispatch({ type: actionTypes.LOAD_PACKAGE_MANAGEMENT_PACKAGES });

        dispatch(
          commonActions.showMessage({
            message: "Loading...",
            type: MessageBarType.info,
          })
        );
        const { isSubmittedOnly } = getState().adminPackageManagement;
        const modules = getState().userPreference.modules;

        const moduleName = isSubmittedOnly
          ? UserPreferencesModule.ADMIN_PACKAGE_MANAGEMENT_SUBMITTED_ONLY
          : UserPreferencesModule.ADMIN_PACKAGE_MANAGEMENT;

        const queryString = preferences.getParamsFromPreferences(
          modules,
          moduleName
        );

        const fetchAction = fetchCreator(
          `${configs.client.endpoint.appServiceEndpoint}packages?queueId=${queueId}&${queryString}`
        );

        const debounceAction = { ...fetchAction };

        if (debounceRequest) {
          debounceAction["meta"] = {
            debounce: {
              name: "updatePackageItem",
              time: 1000,
            },
          };
        }
        const response = await dispatch(debounceAction);
        const data = await response.json();

        dispatch(commonActions.hideMessage());
        dispatch({
          type: actionTypes.GET_PACKAGE_MANAGEMENT_PACKAGES,
          payload: data,
        });
      } catch (error: any) {
        dispatch({
          type: actionTypes.GET_PACKAGE_MANAGEMENT_PACKAGES_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }

      // set cache for the current package being reviewed
      localStorage.setItem(PACKAGE_MANAGEMENT_QUEUE_ID_KEY, queueId.toString());
    },

  reviewPackage:
    (queueId: number, packageGuid: string, errorMessage: string) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        // Check or get the user reviewing the latest package.
        await dispatch(userPackageActions.getUserPackage());

        const { userPackage } = getState();

        // Check if the user is currently reviewing any package.
        if (userPackage.id) {
          // If its the same package, open in new window.
          if (packageGuid === userPackage.id) {
            window.open("/reviewer/review-package", "_blank");
          } else {
            // Else display an error message that the user is reviewing some other package.
            dispatch(
              commonActions.showMessage({
                message: errorMessage,
                type: MessageBarType.error,
              })
            );
          }
        } else {
          // If the user is not reviewing any package and is a new package.
          await dispatch(actionCreators.assignPackage(packageGuid));
          window.open("/reviewer/review-package", "_blank");
          // To keep everything in sync incase if the user comes back from the previous window and starts assigning to a different queue.
          dispatch(userPackageActions.getUserPackage());
          dispatch(actionCreators.getPackages(queueId, false));
        }
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  updateAssignToPackageInline:
    (item: IPackageItem) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        const { userId } = getState().user;

        dispatch(
          commonActions.showMessage({
            message: "Unassigning package...",
            type: MessageBarType.info,
          })
        );

        const body = [
          {
            id: item.PackageGuid,
            assignedTo: PackageUnAssign.value,
          },
        ];

        const { endPoint, options } = packagesPatchReq(JSON.stringify(body));
        const fetchAction = fetchCreator(endPoint, options);

        const debounceAction = {
          ...fetchAction,
          meta: {
            debounce: {
              name: "updatePackageItem",
              time: 1000,
              merge: true,
            },
          },
        };
        const response = await dispatch(debounceAction);
        const data = await response;

        // if package is assigned to the current user then empty the current package details
        if (item.AssignedToUserId === userId) {
          dispatch(userPackageActions.resetUserPackage());
        }

        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE,
          payload: item,
        });

        dispatch(reviewerReviewPackageActions.resetCurrentPackage());
        dispatch(commonActions.hideMessage());
      } catch (error: any) {
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  updatePackageInlineSequence:
    (item: IPackageItem) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        dispatch(
          commonActions.showMessage({
            message: "Updating sequence for package(s)...",
            type: MessageBarType.info,
          })
        );

        const body = [
          {
            id: item.PackageGuid,
            sequence: item.SequenceId,
          },
        ];

        const { endPoint, options } = packagesPatchReq(JSON.stringify(body));
        const fetchAction = fetchCreator(endPoint, options);

        const debounceAction = {
          ...fetchAction,
          meta: {
            debounce: {
              name: "updatePackageItem",
              time: 1000,
              merge: true,
            },
          },
        };
        const response = await dispatch(debounceAction);
        const data = await response;

        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE,
          payload: item,
        });

        dispatch(commonActions.hideMessage());
      } catch (error: any) {
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  selectedPackage: (selectedPackage) => async (dispatch: any) => {
    dispatch({
      type: actionTypes.PACKAGE_MANAGEMENT_SELECTED_PACKAGE,
      payload: selectedPackage,
    });
  },

  updateDecision:
    (selectedPackages, selectedDecision, queueId) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      if (!selectedPackages || selectedPackages.length === 0) {
        dispatch({ type: actionTypes.RESET_TABLE_SELECTION });
        return;
      }

      dispatch({ type: actionTypes.RESET_TABLE_SELECTION });

      const {
        user: { userId },
        userPackage,
      } = getState();

      const findAssignedPackage = selectedPackages.find(
        (packageItem: IPackageItem) => packageItem.AssignedToUserId === userId
      );

      const dispAssignedToPackageMessage = () => {
        if (!findAssignedPackage && userPackage.id) {
          return true;
        }
        return false;
      };

      try {
        //re arrange array to bring assigned package first
        selectedPackages = selectedPackages.reduce((acc, element) => {
          if (element.AssignedToUserId === userId) {
            return [element, ...acc];
          }
          return [...acc, element];
        }, []);

        if (dispAssignedToPackageMessage()) {
          dispatch(
            commonActions.showMessage({
              type: MessageBarType.error,
              message:
                "You are already assigned to a package, apply decision or unassign the package first.",
            })
          );
          dispatch({ type: actionTypes.RESET_TABLE_SELECTION_COMPLETE });
        } else {
          dispatch(
            commonActions.showMessage({
              type: MessageBarType.info,
              message: `Submitting package decisions...`,
            })
          );

          const body = selectedPackages.map((pkg) => ({
            id: pkg.PackageGuid,
            [RequestKeys.ApplyDecision]: `${selectedDecision}`,
          }));

          const { endPoint, options } = packagesPutReq(JSON.stringify(body));

          await dispatch(fetchCreator(endPoint, options));
          dispatch(
            commonActions.showMessage({
              type: MessageBarType.info,
              message: `Packages submitted, decisions are getting processed, reloading packages...`,
            })
          );

          //post user action when a package decision is taken.
          dispatch(
            actionCreators.postPackageDecision(
              selectedPackages,
              selectedDecision
            )
          );
        }
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: getError("UPDATE_PACKAGE", error),
          })
        );
      } finally {
        if (!dispAssignedToPackageMessage()) {
          setTimeout(() => {
            dispatch(actionCreators.getPackages(queueId, false));
            dispatch(actionCreators.getQueueSummary(queueId));
            dispatch(userPackageActions.getUserPackage());
          }, 3000);
        }
      }
    },

  submitPackage: (packageGuid) => async (dispatch: any) => {
    try {
      dispatch(
        commonActions.showMessage({
          type: MessageBarType.info,
          message: `Submitting package ${packageGuid}`,
        })
      );

      // Create the fetch action and wait for response.
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.vNextServiceEndpoint +
            `packages/${packageGuid}`,
          {
            method: "PUT",
          }
        )
      );
      dispatch(
        commonActions.showMessage({
          type: MessageBarType.success,
          message: `Package ${packageGuid} submitted`,
        })
      );

      return true;
    } catch (error: any) {
      dispatch(
        commonActions.showMessage({
          type: MessageBarType.error,
          message: `Submitting package ${packageGuid} failed, reloading packages.`,
        })
      );

      return false;
    }
  },

  assignPackage:
    (packageGuid) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        const { userId } = getState().user;
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.info,
            message: `Assigning package ${packageGuid}`,
          })
        );

        const assignKey = RequestKeys.AssignUnAssign;
        const body = [
          {
            id: packageGuid,
            [assignKey]: userId,
          },
        ];

        const { endPoint, options } = packagesPatchReq(JSON.stringify(body));
        const response = await dispatch(fetchCreator(endPoint, options));
        return true;
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: `Assigning package ${packageGuid} failed, reloading packages.`,
          })
        );

        return false;
      }
    },

  packageAssignToQueue:
    (selectedPackages, selectedQueueId, queueId) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        dispatch({ type: actionTypes.RESET_TABLE_SELECTION });

        dispatch(
          commonActions.showMessage({
            message: packageManagementInProgressMessages(
              RequestKeys.AssignToQueue
            ),
            type: MessageBarType.info,
          })
        );

        const body: IEditPackageItemBody[] = selectedPackages.map((item) => ({
          id: item.PackageGuid,
          [RequestKeys.AssignToQueue]: Number(selectedQueueId),
        }));

        const { endPoint, options } = packagesPatchReq(JSON.stringify(body));
        const response = await dispatch(fetchCreator(endPoint, options));
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA,
        });
        dispatch(commonActions.hideMessage());

        dispatch(actionCreators.getQueueSummary(queueId));
        dispatch(actionCreators.getPackages(queueId));

        //Post user action when package reassigned to another Queue.
        dispatch(
          actionCreators.postPackageReaasignedToAnotherQ(
            selectedPackages,
            Number(queueId),
            Number(selectedQueueId)
          )
        );
      } catch (error: any) {
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  updatePresetsPackageManagement:
    (
      presets: IUserModulePreferences,
      hasAllItemsOnFirstPage?: boolean,
      reset?: boolean,
      isResizingColumn?: boolean
    ) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const currentQueueId = getState().adminPackageManagement.queueSummary
        .id as number;
      const isSubmittedOnly = getState().adminPackageManagement.isSubmittedOnly;

      if (isSubmittedOnly) {
        dispatch(
          userPreferenceActions.updatePackageManagementSubmittedOnlyPreferences(
            presets
          )
        );
      } else {
        dispatch(
          userPreferenceActions.updatePackageManagementPreferences(presets)
        );
      }

      if (!hasAllItemsOnFirstPage && !isResizingColumn) {
        dispatch(actionCreators.getPackages(currentQueueId));
      }
    },

  resetPackageManagementFilters:
    (queueId: number) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const previousQueueId = Number(
        localStorage.getItem(PACKAGE_MANAGEMENT_QUEUE_ID_KEY)
      );

      const isSubmittedOnly = getState().adminPackageManagement.isSubmittedOnly;
      const moduleName = isSubmittedOnly
        ? UserPreferencesModule.ADMIN_PACKAGE_MANAGEMENT_SUBMITTED_ONLY
        : UserPreferencesModule.ADMIN_PACKAGE_MANAGEMENT;

      const module = getState().userPreference.modules[moduleName];

      // if the user is reviewing the same package, do not reset filters else reset
      // Cannot use Current Package Details store for getting the queueId because it gives the details which are currently being reviewed
      if (previousQueueId !== queueId && module && module.tablePresets) {
        if (module.tablePresets.filters) {
          module.tablePresets.filters.applied = [];
        }

        if (module.tablePresets.paging) {
          module.tablePresets.paging.currentPage = 1;
          module.tablePresets.paging.rowsPerPage = 200;
        }
      }

      if (isSubmittedOnly) {
        dispatch(
          userPreferenceActions.updatePackageManagementSubmittedOnlyPreferences(
            module
          )
        );
      } else {
        dispatch(
          userPreferenceActions.updatePackageManagementPreferences(module)
        );
      }
    },

  filterByFlagAreaOnSummary:
    (key: number, value: string) =>
    (dispatch, getState: () => IApplicationState) => {
      dispatch({ type: actionTypes.FILTER_BY_FLAG_AREA_ON_SUMMARY });

      let currentPreferences: IUserModulePreferences =
        getState().userPreference.modules["ADMIN-PACKAGE-MANAGEMENT"];

      const moduleFilters: IModuleFilters = {
        applied: [
          {
            condition: Conditions.EQUALS,
            field: "FlagArea",
            key: [key],
            name: "Flag Area",
            value: value,
            type: "combobox",
          },
        ],
        recent: [],
        saved: [],
      };

      if (
        currentPreferences.tablePresets &&
        currentPreferences.tablePresets.filters
      ) {
        currentPreferences.tablePresets.filters = {
          ...currentPreferences.tablePresets.filters,
          applied: moduleFilters.applied,
        };
      } else {
        currentPreferences = { tablePresets: { filters: moduleFilters } };
      }

      dispatch(
        actionCreators.updatePresetsPackageManagement(currentPreferences)
      );
    },

  onPackageManagementMessage:
    () => (dispatch: any, getState: () => IApplicationState) => {
      const onMessage = () => {
        dispatch(
          actionCreators.getPackages(
            getState().adminPackageManagement.queueSummary.id
          )
        );
      };

      dispatch(
        commonActions.initBroadcastChannel(
          BroadcastChannelName.PACKAGE_MANAGEMENT,
          onMessage
        )
      );
    },

  // The following user actions are captured in Admin Package Management.
  // Prepares the body here and sends it to postUserAction to make the api call.
  postPackageDecision:
    (selectedPackages: Array<IPackageItem>, selectedDecision) =>
    (dispatch, getState: () => IApplicationState) => {
      const {
        user: { userId },
        userPackage,
      } = getState();

      // create body from all selected packages
      const body = selectedPackages.map((item: IPackageItem) => {
        // extract required properties from package item
        const {
          AccountId,
          CustomerId,
          CapabilityId,
          FlagArea: FlagAreaID,
          PackageGuid,
          RowsCount,
          UsedInEntityTypes,
        } = item;
        // add required properties to extraInfo
        const payload: IUserActionPayload = {
          id: uuidv4(),
          userId,
          type: UserActionTypes.PackageDecision,
          dateTime: new Date().toISOString(),
          extraInfo: JSON.stringify({
            AccountId,
            CustomerId,
            CapabilityId,
            FlagAreaID,
            PackageGuid,
            RowsCount,
            FinalDecision: selectedDecision,
            DecisionSource: DecisionSource.PACKAGE,
            UsedInEntityTypes,
          }),
        };
        return payload;
      });
      dispatch(commonActions.postUserAction(body));
    },

  postPackageReaasignedToAnotherQ:
    (
      selectedPackages: Array<IPackageItem>,
      sourceQueue: number,
      destinationQueue: number
    ) =>
    (dispatch, getState: () => IApplicationState) => {
      const { userId } = getState().user as IUserState;
      // create body from all selected packages
      const body = selectedPackages.map(({ PackageGuid, CapabilityId }) => {
        //add required properties to extraInfo
        const payload: IUserActionPayload = {
          id: uuidv4(),
          userId,
          type: UserActionTypes.PackageReassignedToAnotherQ,
          dateTime: new Date().toISOString(),
          extraInfo: JSON.stringify({
            CapabilityId,
            PackageGuid,
            sourceQueue,
            destinationQueue,
          }),
        };
        return payload;
      });
      dispatch(commonActions.postUserAction(body));
    },

  setIsSubmittedPackagesOnly: (isSubmittedOnly: boolean) => (dispatch) => {
    dispatch({
      type: actionTypes.SET_IS_SUBMITTED_PACKAGES_ONLY,
      payload: isSubmittedOnly,
    });
  },
};

function packagesPatchReq(body: string) {
  return {
    endPoint: configs.client.endpoint.vNextServiceEndpoint + "packages",
    options: {
      method: ApiRequestMethods.Patch,
      body,
    },
  };
}

function packagesPutReq(body: string) {
  return {
    endPoint: configs.client.endpoint.vNextServiceEndpoint + "packages",
    options: {
      method: ApiRequestMethods.Put,
      body,
    },
  };
}

function packageManagementInProgressMessages(key) {
  switch (key) {
    case RequestKeys.AssignUnAssign:
      return inProgressMessages.assignedTo;
    case RequestKeys.ApplyDecision:
      return inProgressMessages.decision;
    case RequestKeys.AssignToQueue:
      return inProgressMessages.queueId;
    default:
      return inProgressMessages.sequence;
  }
}

function getError(functionType, error) {
  switch (functionType) {
    case "UPDATE_PACKAGE":
      if (error && error.response && error.response.status) {
        switch (error.response.status) {
          case 409:
            return "Some of the packages were already submitted by another admin, reloading the packages...";

          default:
            return error;
        }
      }
      break;

    default:
      return error;
  }
}
