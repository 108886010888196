export { ASSET } from "./src/asset";
export { ADD_FLAG_ENABLED_QUEUES } from "./src/add-flag-enabled-queues";
export { LISTMANAGER_LANGUAGE_OPTIONS } from "./src/list-manager-language-options";
export { WORD_BREAKER_OPTIONS } from "./src/list-manager-language-options";
export { ESCALATION_EDITORIAL_COMPONENTS } from "./src/escalation-editorial-components";
export { TRANSLATIONS_SUPPORTED_LANGUAGES } from "./src/translation-supported-languages";
export { MCA_TYPES } from "./src/mca-types";
export { IMPORT_TEMPLATE_LINKS } from "./src/importTemplateLinks";

export {
  OVERRIDE_COMPONENTS_OPTIONS,
  OVERRIDE_COMPONENTSMASK,
  OVERRIDE_COMPONENTS_COMMA,
} from "./src/override-components-mask";

export const DISABLED_FLAG_AREAS = ["254"];
export const TRINITY_OVERVIEW_ORIGIN = "trinity-overview-origin";
export const PACKAGE_GUID_PATTERN = new RegExp(
  "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$",
  "i"
);

export const ENFORCEMENT_PARAMS = "enforcement-params";
