import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store";
import { RightPanel } from "@trinity/common";
import Help from "./help/Help";
import { MyAccount } from "./my-account/MyAccount";
import Notification from "./notification/Notification";
import { actionCreators as headerActions } from "../../store/header/HeaderActions";
import { IHeaderState } from "../../store/header/HeaderState";
import { HeaderItems } from "@trinity/enums";

function HeaderPanel() {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const headerProps: IHeaderState = useSelector(
    (state: IApplicationState) => state.header
  );

  return (
    <RightPanel
      data-test="rightPanel"
      closeButtonAriaLabel={t("panel.close-button-label", {
        panelTitle: headerProps.title,
      })}
      styles={{ commands: { background: "red" }, root: { background: "red" } }}
      onDismiss={() => dispatch(headerActions.closeHeaderPanel())}
      isOpen={!!headerProps.selectedItemKey}
      headerText={headerProps.title}
      makeFullWidth={true}
      showGreyBackground={true}
    >
      {getContent(headerProps.selectedItemKey)}
    </RightPanel>
  );
}

function getContent(key?: string) {
  if (key === HeaderItems.Help) {
    return <Help />;
  } else if (key === HeaderItems.Notification) {
    return <Notification />;
  } else if (key === HeaderItems.Persona) {
    return <MyAccount />;
  } else {
    return null;
  }
}

export default HeaderPanel;
