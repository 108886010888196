export interface IEscalationsState {
  loading: boolean;
  showPanel: boolean;
  panelType: EscalationsPanelType;
  jobStatus: IJobStatus;
}

export interface IJobStatus {
  items: Array<IJobStatusItems> | null;
  selectedItems: Array<IJobStatusItems> | null;
}

export interface IJobStatusItems {
  CreatedTime: string;
  childJobId: number;
  childJobName: string;
  childWorkflowStatus: number;
  packageGuid: string;
  parentJobId: number;
  parentJobName: string;
  parentJobStatus: number;
  rowsCount: number;
}

export enum EscalationsPanelType {
  NONE,
  FLAGS,
  SUBMIT,
  REASSIGN_JOB,
  SUPPORTING_INFO,
  OVERRIDE_FLAG_AREA,
  OVERRIDE_FLAG_TERM,
}

export enum actionTypes {
  UPDATE_PRESETS = "[ESCALATIONS] - UPDATE_PRESETS",
  CREATE_JOB = "[ESCALATIONS] - CREATE_JOB",
  CREATE_JOB_FAILED = "[ESCALATIONS] - CREATE_JOB_FAILED",
  GET_JOB_STATUS_FAILED = "[ESCALATIONS] - GET_JOB_STATUS_FAILED",
  GET_JOB_STATUS = "[ESCALATIONS] - GET_JOB_STATUS",
  DELETE_JOBS_START = "[ESCALATIONS] - DELETE_JOBS START",
  DELETE_JOBS_COMPLETED = "[ESCALATIONS] - DELETE_JOBS COMPLETED",
  DELETE_JOBS_FAILED = "[ESCALATIONS] - DELETE_JOBS_FAILED",
  REASSIGN_JOBS_START = "[ESCALATIONS] - REASSIGN_JOBS START",
  REASSIGN_JOBS_COMPLETED = "[ESCALATIONS] - REASSIGN_JOBS COMPLETED",
  REASSIGN_JOBS_FAILED = "[ESCALATIONS] - REASSIGN_JOBS_FAILED",
  OPEN_PANEL = "[ESCALATIONS] - OPEN_PANEL",
  CLOSE_PANEL = "[ESCALATIONS] - CLOSE_PANEL",
  SELECTED_ITEMS = "[ESCALATIONS] - SELECTED_ITEMS",
  DOWNLOAD_STATS = "[ESCALATIONS] - DOWNLOAD_STATS",
  DOWNLOAD_STATS_FAILED = "[ESCALATIONS] - DOWNLOAD_STATS_FAILED",
  RESET_CURRENT_PACKAGE = "[ESCALATIONS] - RESET_CURRENT_PACKAGE",
}

export const INITIAL_STATE: IEscalationsState = {
  loading: false,
  showPanel: false,
  panelType: EscalationsPanelType.NONE,
  jobStatus: {
    items: null,
    selectedItems: null,
  },
};
