import {
  AccountsActionCode,
  CustomerActionCode,
  DecisionSource,
  JoinCondition,
  LoadState,
  Operator,
  StoreActionCode,
  TNSEntityType,
  TNSTableView,
} from "@trinity/enums";
import { ISharedValue } from "../../modules/tns-explore/TNSExplore.types";

export interface ITNSExploreState {
  customers: ICustomer[];
  accounts: IAccount[];
  stores: IStore[];
  users: IUser[];
  domains: IDomain[];
  currentTableView: TNSTableView;
  tableLoadState: LoadState;
  insightsLoadState: LoadState;
  customerLoadState: LoadState;
  networkLoadState: LoadState;
  currentCustomer: ICustomerDetails;
  pivotResponse: IPivotResponse;
  networkResponse: INetworkResponse;
  networkNodesCount: number;
  allRows: IRowDefinition[];
  searchType: "sampleSearch" | "advanceSearch";
  rowId: number;
  searchedObject: any | null;
  savedSearches: {
    name: string;
    rows: IRowDefinition[];
  }[];
  enforceMessage: string;
  enforceLoadState: LoadState;
  enforcementResponse: IEnforcementResponse[];
}

export interface IEnforcementResponse {
  entityId: string;
  status: number;
}

export interface ICustomerAccount {
  accountNumber?: string;
  accountName?: string;
  advertiserCustomerId?: string;
  agencyCustomerId?: string;
  salesHouseCustomerId?: string;
  billToCustomerId?: string;
  preferredBillToPaymentInstrId?: string;
  countryCode?: string;
  preferredUserId?: string;
  preferredCurrencyId?: string;
  preferredLanguageId?: string;
  agencyContactName?: string;
  paymentOptionId?: string;
  spendLimitId?: string;
  createdDTim?: string;
  timeZoneId?: string;
  l4ServiceSegmentName?: string;
  l2ServiceSegmentName?: string;
  id?: string;
  partitionId?: string;
  vertexType?: string;
  l28dSpend?: string;
  l30dRevenue?: string;
  penaltyScore?: number;
  lifeCycleStatus?: string;
}

export interface ICustomerDomain {
  customerId?: string;
  accountId?: string;
  domain?: string;
  created?: string;
  registrarName?: string;
  registrarAbuseContactPhone?: string;
  registrarAbuseContactEmail?: string;
  adminContactName?: string;
  adminContactOrg?: string;
  adminContactStreet?: string;
  adminContactCity?: string;
  adminContactState?: string;
  adminContactPostal?: string;
  adminContactCountry?: string;
  adminContactPhone?: string;
  adminContactEmail?: string;
  billingContactName?: string;
  billingContactOrg?: string;
  billingContactStreet?: string;
  billingContactCity?: string;
  billingContactState?: string;
  billingContactPostal?: string;
  billingContactCountry?: string;
  billingContactPhone?: string;
  billingContactEmail?: string;
  registrantContactName?: string;
  registrantContactOrg?: string;
  registrantContactStreet?: string;
  registrantContactCity?: string;
  registrantContactState?: string;
  registrantContactPostal?: string;
  registrantContactCountry?: string;
  registrantContactPhone?: string;
  registrantContactEmail?: string;
  nameServer1?: string;
  id?: string;
  partitionId?: string;
  vertexType?: string;
  scores?: string;
  l28dSpend?: string;
  l30dRevenue?: string;
  domainFraudRate?: number;
  linkedCustomersCount?: number;
}

export interface ICustomerDetails {
  id: string;
  customer: ICustomer;
  accounts: IAccount[];
  domains: ICustomerDomain[];
  userWidget: IUserWidget[];
  paymentWidget: IPaymentWidget[];
  storeWidget: IStoreWidget[];
  riskInfo: IRiskInfo;
  relatedCustomerDetails: {
    agencyRelatedCustomers: ICustomer[];
    billToRelatedCustomers: ICustomer[];
  };
}

export interface ICustomer {
  id: string;
  customerId: string;
  customerNumber?: string;
  name?: string;
  age?: string;
  daysSpend28?: string;
  createdDTim?: string;
  fraudStatusId?: string;
  fraudReasonCodeId: string;
  fraudStatusChangeDateTime?: string;
  accountManager?: string;
  managedBy?: string;
  agency?: string;
  segment?: string;
  subSegment?: string;
  noOfAccounts?: number;
  noOfStores?: number;
  noOfUsers?: number;
  noOfDomain?: number;
  noOfFraudNotifs?: number;
  reputation?: string;
  deviceIds?: string[];
  paymentInstr?: string;
  paymentInstType?: string;
  pIID?: string;
  phoneNumber?: string;
  scores?;
  isManaged?: string;
  l28dSpend?: string;
  l30dRevenue?: string;
  lifetimeRevenue?: string;
  l2ServiceSegmentName?: string;
  l4ServiceSegmentName?: string;
  isPremiumCustomer: boolean;
  anBReasonCode: string;
  customerStatus: string;
  aivRiskType: string;
  lifeCycleStatus?: string;
}

export type TNSMCAType = 0 | 1 | 2;

export interface IAccount {
  accountId: string;
  age: string | number;
  id: string;
  customerId?: string;
  name?: string;
  fraudStatusId?: string;
  accountNumber: string;
  accountName?: string;
  daysSpend28?: string;
  currentAccountQBR?: string;
  l4ServiceSegmentName?: string;
  l2ServiceSegmentName?: string;
  acountManager?: string;
  agency?: string;
  noOfUsers?: string;
  noOfDomains?: string;
  noOfFraudNotifs?: string;
  iSActive?: string;
  countryCode?: string;
  timeZone?: string;
  currency?: string;
  businessName?: string;
  address?: string;
  city?: string;
  state?: string;
  phoneNumber?: string;
  scores?;
  l28dSpend?: string;
  l30dRevenue?: string;
  penaltyScore?: number;
}

export interface IStore {
  customerId?: string;
  customerName?: string;
  fraudStatusId?: string;
  sellerName?: string;
  storeId: string;
  storeName?: string;
  storeStatus?: string;
  storeRejectionReason?: string;
  domain?: string;
  age?: string;
  domainAge?: string;
  l28dSpend?: number;
  daysSpend28?: string;
  nOfFraudNotifs?: string;
  isPremiumCustomer: boolean;
}

export interface IUser {
  customerId?: string;
  userName?: string;
  fullName?: string;
  fullAddress?: string;
  email?: string;
  zipOrPostalCode?: string;
  phone1?: string;
}

export interface IDomain {
  age?: string;
  billingContactCity?: string;
  billingContactEmail?: string;
  billingContactName?: string;
  billingContactPhone?: string;
  customerId?: string;
  domain?: string;
  l28dSpend?: string;
  registrarAbuseContactEmail?: string;
  registrarAbuseContactPhone?: string;
  registrarName?: string;
  scores?: any;
  technicalContactEmail?: string;
  technicalContactPhone?: string;
  domainFraudRate?: number;
  linkedCustomersCount?: number;
}

export interface IUserWidget {
  addressId: string;
  age: string;
  city: string;
  countryCode: string;
  customerId: string;
  email: string;
  emailDomainFraudRate: number;
  emailDomainUserCount: number;
  fax: string;
  firstName: string;
  fullAddress: string;
  fullName: string;
  l28dSpend: number;
  lastName: string;
  linkedCustomerCount: number;
  mobile: string;
  phone: string;
  scores: any;
  stateOrProvince: string;
  userFraudRate: number;
  userId: string;
  userName: string;
  zipOrPostalCode: string;
}

export interface IPaymentWidget {
  age: string;
  billToCustomerId: string;
  city: string;
  cityCustomerCount: number;
  cityFraudRate: number;
  customerId: string;
  fullAddress: string;
  l28dSpend: number;
  paymentInstrCustomerCount: number;
  paymentInstrFraudRate: number;
  paymentInstrId: string;
  paymentInstrType: string;
  paymentInstrTypeCustomerCount: number;
  paymentInstrTypeFraudRate: number;
  scores: any;
}

export interface IStoreWidget {
  storeId: string;
  sellerName: string;
  storeStatus: string;
  domain: string;
  scores: any;
  l28dSpend: number;
  age: string;
  domainAge: string;
}

export interface IRiskRuleData {
  feature: string;
  ruleValue: string;
  fraudRate: number;
  totalCustomerCount: number;
  fraudCustomerCount: number;
  highRiskClosures: number;
  piFraudClosures: number;
  userSafetyClosures: number;
  otherClosures: number;
  sampledFraudCustomers: string[];
  sampledNonFraudCustomers: string[];
  modifiedDTim: string;
}
export interface IRiskInfo {
  score: number;
  scoreModifiedTime: string;
  ruleData: IRiskRuleData[];
}

export interface ICustomerEnforcePayload {
  entityType: TNSEntityType;
  entityIds: string[];
  reasonCode: string;
  riskResultCode: CustomerActionCode;
  notes: string;
  updateTime: string;
}

export interface IEnforcePayload {
  entityType: TNSEntityType;
  entityItems: any[];
  reasonCodes: string[];
  notes: string;
  decision: AccountsActionCode | StoreActionCode;
  source: DecisionSource;
}

export interface IRowDefinition {
  index: number;
  joinCondition: JoinCondition;
  field: string;
  value: string;
  operator: Operator;
  isBracketOpen: boolean;
  isBracketClose: boolean;
}

export interface INetworkResponse {
  phone: ISharedValue[];
  email: ISharedValue[];
  domain: ISharedValue[];
  registrantContactName: ISharedValue[];
  registrantContactEmail: ISharedValue[];
  registrantContactPhone: ISharedValue[];
  zipCode: ISharedValue[];
  name: ISharedValue[];
  agencyCustomerId: ISharedValue[];
  billToCustomerId: ISharedValue[];
  technicalContactPhone: ISharedValue[];
  technicalContactEmail: ISharedValue[];
  fullAddress: ISharedValue[];
  paymemtInstrId: ISharedValue[];
  greenId: ISharedValue[];
}

export interface IPivotResponse {
  customerName: ISharedValue[];
  email: ISharedValue[];
  phone: ISharedValue[];
  domain: ISharedValue[];
  paymentInstr: ISharedValue[];
}

export const DefaultTNSExploreState: ITNSExploreState = {
  customers: [],
  accounts: [],
  stores: [],
  users: [],
  domains: [],
  currentCustomer: {
    id: "",
    relatedCustomerDetails: {
      agencyRelatedCustomers: [],
      billToRelatedCustomers: [],
    },
    customer: {
      aivRiskType: "",
      customerId: "",
      id: "",
      fraudReasonCodeId: "",
      isPremiumCustomer: false,
      customerStatus: "",
      anBReasonCode: "",
    },
    accounts: [],
    domains: [],
    userWidget: [],
    paymentWidget: [],
    storeWidget: [],
    riskInfo: {
      score: 0,
      scoreModifiedTime: "",
      ruleData: [],
    },
  },
  tableLoadState: LoadState.NotLoaded,
  insightsLoadState: LoadState.NotLoaded,
  customerLoadState: LoadState.NotLoaded,
  networkLoadState: LoadState.NotLoaded,
  networkResponse: {
    phone: [],
    email: [],
    domain: [],
    registrantContactName: [],
    registrantContactEmail: [],
    registrantContactPhone: [],
    zipCode: [],
    name: [],
    agencyCustomerId: [],
    billToCustomerId: [],
    technicalContactPhone: [],
    technicalContactEmail: [],
    fullAddress: [],
    paymemtInstrId: [],
    greenId: [],
  },
  networkNodesCount: 0,
  pivotResponse: {
    customerName: [],
    email: [],
    phone: [],
    paymentInstr: [],
    domain: [],
  },
  rowId: 1,
  currentTableView: TNSTableView.CUSTOMERS,
  searchedObject: null,
  allRows: [
    {
      index: 0,
      joinCondition: JoinCondition.AND,
      field: "Customer.Id",
      value: "",
      operator: Operator.EQUALS,
      isBracketClose: false,
      isBracketOpen: false,
    },
  ],
  searchType: "sampleSearch",
  savedSearches: [],
  enforceMessage: "",
  enforceLoadState: LoadState.NotLoaded,
  enforcementResponse: [],
};

export interface INetworkResponse {
  phone: ISharedValue[];
  email: ISharedValue[];
  domain: ISharedValue[];
  registrantContactName: ISharedValue[];
  registrantContactEmail: ISharedValue[];
  registrantContactPhone: ISharedValue[];
  zipCode: ISharedValue[];
  name: ISharedValue[];
  agencyCustomerId: ISharedValue[];
  billToCustomerId: ISharedValue[];
  technicalContactPhone: ISharedValue[];
  technicalContactEmail: ISharedValue[];
  fullAddress: ISharedValue[];
  paymemtInstrId: ISharedValue[];
  greenId: ISharedValue[];
}
