import { MessageBarType } from "@fluentui/react";
import { IApplicationState } from "..";
import { configs } from "../../configs";
import { fetchCreator } from "../../utils/middleware/fetch";
import { actionCreators as commonActions } from "../common/CommonActions";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import {
  IUserModulePreferences,
  UserPreferencesModule,
} from "../user-preference/UserPreferenceState";
import * as preferences from "../../utils/common/preferences";
import { Conditions } from "../../components/common/table/table-command-bar/plugins/filters/Filters.types";
import { IFilter } from "../../components/common/table/Table.types";
import { removeDuplicatesFilter } from "../../utils/common/common";
import history from "../../utils/common/history";
import { WorkflowStatus } from "@trinity/enums";

export enum actionTypes {
  GET_SUBMITTED_PACKAGES = "GET_SUBMITTED_PACKAGES",
  GET_SUBMITTED_PACKAGES_COMPLETED = "GET_SUBMITTED_PACKAGES_COMPLETED",
  GET_SUBMITTED_PACKAGES_FAILED = "GET_SUBMITTED_PACKAGES_FAILED",
  GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY = "GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY",
  GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_COMPLETED = "GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_COMPLETED",
  GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_FAILED = "GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_FAILED",
}

export const actionCreators = {
  getSubmittedPackages:
    (queueId, debounceRequest = true) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        const userId = getState().user.userId;
        dispatch({ type: actionTypes.GET_SUBMITTED_PACKAGES });

        dispatch(
          commonActions.showMessage({
            message: "Loading...",
            type: MessageBarType.info,
          })
        );

        const modules = getState().userPreference.modules;
        const userPreferences =
          modules[UserPreferencesModule.REVIEWER_SUBMITTED_PACKAGES];

        const defaultAppliedFilters: IFilter[] = [
          {
            key: [WorkflowStatus.Submitted, WorkflowStatus.Synced],
            field: "WorkflowStatusId",
            condition: Conditions.EQUALS,
            value: "Submitted, Synced",
            name: "Status",
            type: "checkbox",
            customQueries: [],
            isHidden: true,
          },
          {
            key: "AssignedToUserId",
            field: "AssignedToUserId",
            condition: Conditions.EQUALS,
            value: String(userId),
            name: "Submitted By",
            type: "string",
            customQueries: [],
            isHidden: true,
          },
        ];

        userPreferences.tablePresets = {
          ...userPreferences.tablePresets,
          filters: {
            ...((userPreferences.tablePresets &&
              userPreferences.tablePresets.filters) || {
              recent: [],
              saved: [],
            }),
            applied:
              userPreferences.tablePresets &&
              userPreferences.tablePresets.filters &&
              userPreferences.tablePresets.filters.applied &&
              userPreferences.tablePresets.filters.applied.length > 0
                ? userPreferences.tablePresets.filters.applied
                    .concat(defaultAppliedFilters)
                    .filter(removeDuplicatesFilter("field"))
                : defaultAppliedFilters,
          },
        };

        modules[UserPreferencesModule.REVIEWER_SUBMITTED_PACKAGES] =
          userPreferences;

        const queryString = preferences.getParamsFromPreferences(
          modules,
          UserPreferencesModule.REVIEWER_SUBMITTED_PACKAGES
        );

        const fetchAction = fetchCreator(
          `${configs.client.endpoint.appServiceEndpoint}packages?queueId=${queueId}&${queryString}`
        );

        const debounceAction = { ...fetchAction };

        if (debounceRequest) {
          debounceAction["meta"] = {
            debounce: {
              name: "submittedPackages",
              time: 1000,
            },
          };
        }
        const response = await dispatch(debounceAction);
        const data = await response.json();

        dispatch(commonActions.hideMessage());
        dispatch({
          type: actionTypes.GET_SUBMITTED_PACKAGES_COMPLETED,
          payload: data,
        });
      } catch (error: any) {
        dispatch({
          type: actionTypes.GET_SUBMITTED_PACKAGES_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  getQueueSummary:
    (queueId) => async (dispatch: any, getState: () => IApplicationState) => {
      try {
        dispatch({ type: actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY });

        const response = await dispatch(
          fetchCreator(
            configs.client.endpoint.appServiceEndpoint + `queues/${queueId}`
          )
        );
        const data = await response.json();
        dispatch({
          type: actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_COMPLETED,
          payload: { data },
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
        dispatch({
          type: actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_FAILED,
        });
        history.push("/reviewer/overview");
      }
    },

  updatePresetsReviewerSubmittedPackages:
    (
      presets: IUserModulePreferences,
      hasAllItemsOnFirstPage?: boolean,
      reset?: boolean,
      isResizingColumn?: boolean
    ) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const currentQueueId = getState().reviewerSubmittedPackages.queueSummary
        .id as number;
      dispatch(
        userPreferenceActions.updateReviewerSubmittedPackagesPreferences(
          presets
        )
      );
      if (!hasAllItemsOnFirstPage && !isResizingColumn) {
        dispatch(actionCreators.getSubmittedPackages(currentQueueId));
      }
    },
};
