import { IApplicationState } from "..";
import { configs } from "../../configs";
import { fetchCreator } from "../../utils/middleware/fetch";
import { IUserModulePreferences } from "../user-preference/UserPreferenceState";
import { actionCreators as commonActions } from "../common/CommonActions";
import { MessageBarType } from "@fluentui/react";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import { AIVSource } from "@trinity/enums";
import { getRiskScope } from "../../utils/common/getRiskScope";
import { compare } from "../../utils/common/common";

export enum actionTypes {
  GET_AIV_DATA = "GET_AIV_DATA",
  GET_AIV_DATA_RECEIVED = "GET_AIV_DATA_RECEIVED",
  GET_AIV_DATA_FAILED = "GET_AIV_DATA_FAILED",
  CLEAR_AIV_DATA = "CLEAR_AIV_DATA",
}

export const actionCreators = {
  getAIVData:
    (
      payload: {
        entityId: string;
        entityType: string;
        maxAIVDataCount: number;
        source: AIVSource;
      },
      hideLoader?: boolean
    ) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const { entityId, entityType, maxAIVDataCount, source } = payload;
      try {
        const { userRoles } = getState().user;
        dispatch({ type: actionTypes.GET_AIV_DATA });
        if (!hideLoader) {
          dispatch(
            commonActions.showMessage({
              message: "loading...",
              type: MessageBarType.info,
            })
          );
        }
        const response = await dispatch(
          fetchCreator(`${configs.client.endpoint.tnsEndpoint}api/aiv/data`, {
            method: "POST",
            body: JSON.stringify({
              EntityId: entityId,
              EntityType: entityType,
              MaxAIVDataCount: maxAIVDataCount,
              Source: source,
            }),
            customOptions: { scope: getRiskScope(userRoles) },
          })
        );

        if (response.status === 204) {
          dispatch({
            type: actionTypes.GET_AIV_DATA_FAILED,
          });
          dispatch(
            commonActions.showMessage({
              message: "Incorrect details given.",
              type: MessageBarType.error,
            })
          );
          return;
        }

        const data = await response.json();

        dispatch(commonActions.hideMessage());
        dispatch({
          type: actionTypes.GET_AIV_DATA_RECEIVED,
          payload: data.sort(
            (a, b) =>
              compare(true, new Date(a.requestDate), new Date(b.requestDate))
            // compare(true, new Date(a.startDate), new Date(b.startDate)),
            // compare(true, new Date(a.finishDate), new Date(b.finishDate))
          ),
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
        dispatch({ type: actionTypes.GET_AIV_DATA_FAILED });
      }
    },

  updateAIVDataPreferences:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      // dispatch(
      //   userPreferenceActions.updateTNSAIVDataPreferences(presets)
      // );
    },

  clearAIVData: () => async (dispatch: any) => {
    dispatch({
      type: actionTypes.CLEAR_AIV_DATA,
    });
  },
};
