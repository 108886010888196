import { DecisionSource, LoadState, TNSEntityType } from "@trinity/enums";

export interface ITNSEnforcementState {
  validate: IEnforcementValidate;
  isConfirmed: LoadState;
  autoSuggest: IAutoSuggestion[];
}

export interface IAutoSuggestion {
  key: string;
  value: string;
  notificationTimeUtc: string;
}

export interface IEnforcementValidate {
  overallStatus: number;
  entityDecisions: {
    entityType: number;
    entityId: string | null;
    entity: string | null;
    status: number;
    comment: string[];
  }[];
}

export const DefaultTNSEnforcementState: ITNSEnforcementState = {
  validate: {
    entityDecisions: [],
    overallStatus: 1,
  },
  isConfirmed: LoadState.NotLoaded,
  autoSuggest: [],
};

export interface ITNSEnforcementPayload {
  entityType: TNSEntityType;
  entities: IEntity[];
  decision: string;
  reasonCodeId: number | "";
  notes: string;
  modifiedByUserId: number;
  modifiedByUserEmail: string;
  source: DecisionSource;
  jarvisTrackingId: string;
  policyBlurbIds: number[] | string[];
  templateId?: number | string;
  transactionTypeId: number;
  ucmTrackingId: string;
}
export interface IEntity {
  userId?: string;
  customerId?: string;
  accountId?: string;
  storeId?: string;
  campaignId?: string;
  adGroupId?: string;
}
