import {
  Customizer,
  Spinner,
  SpinnerSize,
  Stack,
  Customizations,
} from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadState } from "@trinity/enums";
import { IApplicationState } from "../../store";
import { Footer } from "../footer/Footer";
import Header from "../header/Header";
import LeftNav from "../navigation/LeftNav";
import WorkspaceSwitcher from "../navigation/WorkspaceSwitcher";
import HeaderPanel from "../header/HeaderPanel";
import ServerError from "../error/ServerError";
import initializeKeyboardShortcuts from "../../utils/common/keyboardShortcuts";

function Layout(props) {
  const dispatch = useDispatch<any>();

  initializeKeyboardShortcuts(dispatch);

  const { loadState } = useSelector((state: IApplicationState) => state.user);

  const contentStyles: any = {
    root: [
      {
        width: "100%",
      },
    ],
  };

  const spinnerStyles: any = {
    root: [
      {
        height: "100vh",
      },
    ],
  };

  const serverErrorStyles: any = {
    root: [
      {
        height: "100vh",
      },
    ],
  };

  return (
    <Customizer {...Customizations}>
      <div className="vnext-Layout">
        <Stack styles={loadState === LoadState.Failed ? serverErrorStyles : {}}>
          <Header />

          {loadState === LoadState.Loaded && (
            <Stack horizontal>
              <LeftNav />

              <Stack styles={contentStyles} className={"vnext-Layout-content"}>
                {props.children}

                <Footer />
              </Stack>
            </Stack>
          )}

          {loadState === LoadState.NotLoaded && (
            <Spinner
              label="Getting user details..."
              styles={spinnerStyles}
              size={SpinnerSize.large}
            />
          )}

          {loadState === LoadState.Failed && (
            <Stack horizontal>
              <Stack styles={contentStyles} className={"vnext-Layout-content"}>
                <ServerError />

                <Footer />
              </Stack>
            </Stack>
          )}
        </Stack>

        <WorkspaceSwitcher />

        <HeaderPanel />
      </div>
    </Customizer>
  );
}

export default Layout;
