import { IconButton, IIconProps, Stack } from "@fluentui/react";
import { INavLink, Nav } from "@fluentui/react/lib/Nav";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { configs } from "../../configs";
import { IApplicationState } from "../../store";
import { getStyles } from "./LeftNav.styles";

function LeftNav() {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { t } = useTranslation();
  const styles = getStyles();
  const icon: IIconProps = { iconName: "GlobalNavButton" };
  const navigate = useNavigate();
  const state = useSelector((state: IApplicationState) => ({
    ...state.header,
  }));
  const workspace = configs.workspace.find(
    (e) => e.key === state.selectedWorkspaceKey
  );

  const links = React.useMemo(() => {
    let items = [];
    if (workspace && workspace.items) {
      items = JSON.parse(JSON.stringify(workspace.items));

      items.forEach((menu: any) => {
        menu.name = t("leftnav.menuitem-" + menu.key + "-title");
        menu.ariaLabel = menu.name;

        if (menu.links) {
          if (!isExpanded) {
            delete menu.links;
          } else {
            menu.links.forEach((submenu) => {
              submenu.name = t(
                "leftnav.menuitem-" +
                  menu.key +
                  "-submenu-" +
                  submenu.key +
                  "-title"
              );
              submenu.ariaLabel = menu.name;
            });
          }
        }
      });
    }
    return items;
  }, [t, isExpanded, workspace]);

  const onLinkClick = React.useCallback(
    (item?: INavLink) => {
      if (item && state.selectedWorkspaceKey)
        navigate("/" + state.selectedWorkspaceKey + item.link);
    },
    [navigate, state]
  );

  const onLinkExpandClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>) => {
      if (ev && !isExpanded) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    [isExpanded]
  );

  const hasValueInSlug = (slug, slugKeys) => {
    return slugKeys.find((key) => slug[key] !== undefined);
  };

  const getSelectedKey = React.useCallback(() => {
    const locationParts = location.pathname.split("/");
    const slug = useParams();
    const slugKeys = Object.keys(slug);

    if (locationParts.length < 3) return "";

    if (!isExpanded) return locationParts[2];

    if (slugKeys.length > 0 && hasValueInSlug(slug, slugKeys)) {
      return locationParts[locationParts.length - (slugKeys.length + 1)];
    }

    return locationParts[locationParts.length - 1];
  }, [navigate, isExpanded]);

  return (
    <Stack styles={styles.leftnav} className={isExpanded ? "" : "collapsed"}>
      <div
        data-test="toggleMenu"
        className="toggle-menu"
        onClick={(ev) => setIsExpanded(!isExpanded)}>
        <IconButton
          data-test="iconButton"
          iconProps={icon}
          ariaLabel={t("leftnav.toggle-title")}
          title={t("leftnav.toggle-title")}
        />
      </div>
      <Nav
        styles={styles.nav}
        data-test="nav"
        className={isExpanded ? "" : "collapsed"}
        onLinkClick={(ev, item) => onLinkClick(item)}
        onLinkExpandClick={(ev, item) => onLinkExpandClick(ev)}
        selectedKey={getSelectedKey()}
        ariaLabel={t("leftnav.menu-title")}
        groups={[
          {
            links: links,
          },
        ]}
      />
    </Stack>
  );
}

export default LeftNav;
