import { Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import { actionTypes } from "./ReviewerSubmittedPackagesActions";
import {
  INITIAL_STATE,
  IReviewerSubmittedPackagesState,
} from "./ReviewerSubmittedPackagesState";

export const reviewerSubmittedPackagesReducer: Reducer<
  IReviewerSubmittedPackagesState
> = (state: IReviewerSubmittedPackagesState = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.GET_SUBMITTED_PACKAGES:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.InProgress,
        },
      };
    case actionTypes.GET_SUBMITTED_PACKAGES_COMPLETED:
      return {
        ...state,
        packages: action.payload.value,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.Loaded,
        },
      };
    case actionTypes.GET_SUBMITTED_PACKAGES_FAILED:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.Failed,
        },
      };
    case actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.InProgress,
        },
      };

    case actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_COMPLETED:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.Loaded,
        },
        queueSummary: {
          id: action.payload.data.id,
          name: action.payload.data.name,
          language: action.payload.data.language,
          queueType: action.payload.data.queueType,
        },
      };

    case actionTypes.GET_SUBMITTED_PACKAGES_QUEUE_SUMMARY_FAILED:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.Loaded,
        },
      };
    default:
      return state;
  }
};
