import { Reducer } from "redux";
import {
  Brackets,
  JoinCondition,
  LoadState,
  Operator,
  UserRole,
} from "@trinity/enums";
import {
  isPremimum,
  removeDuplicatesFilter,
} from "../../../src/utils/common/common";
import { actionTypes } from "./TNSExploreActions";
import {
  DefaultTNSExploreState,
  IAccount,
  ICustomer,
  ICustomerDetails,
  INetworkResponse,
  IStore,
  ITNSExploreState,
} from "./TNSExploreState";

export const tnsExploreReducer: Reducer<ITNSExploreState> = (
  state: ITNSExploreState = DefaultTNSExploreState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SAVE_SEARCH:
      return {
        ...state,
        savedSearches: [
          ...state.savedSearches,
          {
            name: action.payload.searchName,
            rows: state.allRows.map((row) => {
              return { ...row };
            }),
          },
        ],
      };

    case actionTypes.SEARCH_CUSTOMERS:
      return {
        ...state,
        tableLoadState: LoadState.InProgress,
      };

    case actionTypes.SEARCH_CUSTOMERS_SUCCESS:
      const userRoles = action.payload.userRoles;
      const isRiskPremiumReviewer = userRoles.includes(
        UserRole.RiskPremiumReviewer
      );

      return {
        ...state,
        customers: action.payload.result.customerTable.map((customer) => {
          customer.hideCheckBox = isPremimum(
            customer.isPremiumCustomer,
            userRoles
          );
          return customer;
        }),
        accounts: action.payload.result.accountTable.map((account) => {
          account.hideCheckBox = isPremimum(
            account.isPremiumAccount,
            userRoles
          );
          return account;
        }),
        stores: action.payload.result.storeTable.map((store) => {
          store.hideCheckBox = isPremimum(store.isPremiumCustomer, userRoles);
          return store;
        }),
        users: action.payload.result.userTable,
        domains: action.payload.result.whoisTable.filter(
          removeDuplicatesFilter("domain")
        ),
        searchedObject: action.payload.searchedObject,
        tableLoadState: LoadState.Loaded,
      };

    case actionTypes.SEARCH_CUSTOMERS_FAILED:
      return {
        ...state,
        tableLoadState: LoadState.Failed,
      };

    case actionTypes.SEARCH_PIVOT:
      return {
        ...state,
        insightsLoadState: LoadState.InProgress,
      };

    case actionTypes.SEARCH_PIVOT_SUCCESS:
      return {
        ...state,
        pivotResponse: { ...action.payload.tempPivotResponse },
        insightsLoadState: LoadState.Loaded,
      };

    case actionTypes.SEARCH_PIVOT_FAILED:
      return {
        ...state,
        insightsLoadState: LoadState.Failed,
      };

    case actionTypes.SEARCH_PIVOT_CLEAR:
      return {
        ...state,
        pivotResponse: {
          customerName: [],
          email: [],
          phone: [],
          domain: [],
          paymentInstr: [],
        },
      };

    case actionTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerLoadState: LoadState.InProgress,
      };

    case actionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
      const response = { ...action.payload.result };
      const riskInfo =
        response.riskInfo || DefaultTNSExploreState.currentCustomer.riskInfo;
      const tempDetails: ICustomerDetails = {
        id: response.customerTable.customerId,
        customer: {
          ...response.customerTable,
        },
        accounts: [...response.accountTable],
        domains: [...response.whoisTable],
        userWidget: [...response.userTable],
        paymentWidget: [...response.paymentTable],
        storeWidget: [...response.storeTable],
        relatedCustomerDetails: { ...response.relatedCustomerDetails },
        riskInfo: riskInfo,
      };
      return {
        ...state,
        currentCustomer: tempDetails,
        customerLoadState: LoadState.Loaded,
      };

    case actionTypes.GET_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        customerLoadState: LoadState.Failed,
      };

    case actionTypes.GET_CUSTOMER_NETWORK:
      return {
        ...state,
        networkLoadState: LoadState.InProgress,
      };

    case actionTypes.GET_CUSTOMER_NETWORK_SUCCESS:
      const { networkResponse, networkNodesCount } = getCalcNetworkResponse(
        action.payload.networkResponse
      );
      return {
        ...state,
        networkResponse: networkResponse,
        networkNodesCount: networkNodesCount,
        networkLoadState: LoadState.Loaded,
      };

    case actionTypes.GET_CUSTOMER_NETWORK_FAILED:
      return {
        ...state,
        networkLoadState: LoadState.Failed,
      };

    case actionTypes.ENFORCE_DECISION:
      return {
        ...state,
        enforceLoadState: LoadState.InProgress,
      };

    case actionTypes.ENFORCE_DECISION_SUCCESS:
      return {
        ...state,
        enforceLoadState: LoadState.Loaded,
      };

    case actionTypes.ENFORCE_DECISION_FAILED:
      return {
        ...state,
        enforceLoadState: LoadState.Failed,
        enforceMessage: "Oops, there was some error on the server side!!",
      };

    case actionTypes.SEARCHEDITOR_ADD:
      return {
        ...state,
        allRows: [
          ...state.allRows,
          {
            index: action.payload.index,
            joinCondition: JoinCondition.AND,
            field: action.payload.defaultField,
            value: "",
            operator: Operator.IN,
            isBracketClose: false,
            isBracketOpen: false,
          },
        ],
      };

    case actionTypes.SEARCHEDITOR_DELETE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows.splice(i, 1);
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_JOINCHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].joinCondition = action.payload.newValue;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_VALUECHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].value = action.payload.newValue;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_FIELDCHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].field = action.payload.newValue;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_OPENBRACKETCHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].isBracketOpen = action.payload.newValue == Brackets.OPEN;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_CLOSEBRACKETCHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].isBracketClose = action.payload.newValue == Brackets.CLOSE;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_OPERATORCHANGE: {
      const newRows = [...state.allRows];
      const i = newRows.findIndex((val) => {
        return action.payload.rowIndex == val.index;
      });
      newRows[i].operator = action.payload.newValue;
      return {
        ...state,
        allRows: newRows,
      };
    }

    case actionTypes.SEARCHEDITOR_CLEAR:
      return {
        ...state,
        rowId: 1,
        allRows: [
          {
            index: 0,
            joinCondition: JoinCondition.AND,
            field: "Customer.Id",
            value: "",
            operator: Operator.EQUALS,
            isBracketClose: false,
            isBracketOpen: false,
          },
        ],
      };

    case actionTypes.UPDATE_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload.searchType,
      };

    case actionTypes.LOAD_SAVED_SEARCH:
      if (action.payload.selectedSearch && action.payload.selectedSearch.rows) {
        return {
          ...state,
          allRows: [...action.payload.selectedSearch.rows],
        };
      } else {
        return state;
      }

    case actionTypes.INCREMENT_ROWID:
      const newRowId = state.rowId + 1;
      return {
        ...state,
        rowId: newRowId,
      };

    case actionTypes.DELETE_SEARCH:
      const indexToBeDeleted = state.savedSearches.findIndex((savedSearch) => {
        return savedSearch.name == action.payload.searchName;
      });
      if (indexToBeDeleted == -1) {
        return state;
      }

      const newSavedSearches = [...state.savedSearches];
      newSavedSearches.splice(indexToBeDeleted, 1);
      return {
        ...state,
        savedSearches: newSavedSearches,
      };

    case actionTypes.CHANGE_TABLE_VIEW:
      return {
        ...state,
        currentTableView: action.payload.newTableView,
      };

    case actionTypes.CLEAR_ENFORCE:
      return {
        ...state,
        enforceLoadState: LoadState.NotLoaded,
        enforceMessage: "",
      };
    case actionTypes.ENFORCEMENT_RESPONSE_LOAD:
      return {
        ...state,
        enforcementResponse: action.payload.enforcementResponse,
      };

    case actionTypes.CLEAR_ENFORCEMENT_RESPONSE:
      return {
        ...state,
        enforcementResponse: [],
      };

    default:
      return state || DefaultTNSExploreState;
  }
};

const getCalcNetworkResponse = (networkRAWResult: any[]) => {
  const networkResponse: INetworkResponse = {
    phone: [],
    email: [],
    domain: [],
    registrantContactName: [],
    registrantContactEmail: [],
    registrantContactPhone: [],
    zipCode: [],
    name: [],
    agencyCustomerId: [],
    billToCustomerId: [],
    technicalContactPhone: [],
    technicalContactEmail: [],
    fullAddress: [],
    paymemtInstrId: [],
    greenId: [],
  };
  let networkNodesCount = 0;
  networkRAWResult.forEach((result) => {
    switch (result.connectionEntityAttribute.toLowerCase()) {
      case "email":
        if (result.connectionEntityType == "User") {
          networkNodesCount++;
          networkResponse.email.push(result);
        }
        break;

      case "phone1":
        networkNodesCount++;
        networkResponse.phone.push(result);
        break;

      case "fulldomain":
        if (result.connectionEntityType == "Whois")
          networkResponse.domain.push(result);
        break;

      case "registrantcontactname":
        networkNodesCount++;
        networkResponse.registrantContactName.push(result);
        break;

      case "registrantcontactphone":
        networkNodesCount++;
        networkResponse.registrantContactPhone.push(result);
        break;

      case "registrantcontactemail":
        networkNodesCount++;
        networkResponse.registrantContactEmail.push(result);
        break;

      case "name":
        if (result.connectionEntityType == "Customer") {
          networkNodesCount++;
          networkResponse.name.push(result);
        }
        break;

      case "ziporpostalcode":
        if (result.connectionEntityType == "PaymentInstr") {
          networkNodesCount++;
          networkResponse.zipCode.push(result);
        }
        break;

      case "billtocustomerid":
        networkNodesCount++;
        networkResponse.billToCustomerId.push(result);
        break;

      case "agencycustomerid":
        networkNodesCount++;
        networkResponse.agencyCustomerId.push(result);
        break;

      case "technicalcontactphone":
        networkNodesCount++;
        networkResponse.technicalContactPhone.push(result);
        break;

      case "technicalcontactemail":
        networkNodesCount++;
        networkResponse.technicalContactEmail.push(result);
        break;

      case "fulladdress":
        if (result.connectionEntityType == "PaymentInstr") {
          networkNodesCount++;
          networkResponse.fullAddress.push(result);
        }
        break;

      case "id":
        if (result.connectionEntityType == "PaymentInstr") {
          networkNodesCount++;
          networkResponse.paymemtInstrId.push(result);
        }
        if (result.connectionEntityType == "Whois") {
          networkNodesCount++;
          networkResponse.domain.push(result);
        }
        break;

      case "deviceid":
        networkNodesCount++;
        networkResponse.greenId.push(result);
        break;
    }
  });
  return { networkResponse, networkNodesCount };
};
