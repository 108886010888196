import { actionCreators as headerActions } from "../header/HeaderActions";
import { actionCreators as userPackageActions } from "../user-package/UserPackageActions";
import { fetchCreator } from "../../utils/middleware/fetch";
import { configs } from "../../configs";
import { actionCreators as commonActions } from "../common/CommonActions";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import { actionCreators as adminPackageManagementActions } from "../admin-package-management/AdminPackageManagementActions";
import { MessageBarType } from "@fluentui/react";
import { IUserModulePreferences } from "../user-preference/UserPreferenceState";
import { BroadcastChannelName, WorkflowStatus } from "@trinity/enums";
import { IUserPackage } from "../user-package/UserPackageState";
import { IApplicationState } from "..";
import { actionTypes } from "./ReviewerReviewPackageState";
import { IFilterSet } from "../../components/common/table/Table.types";
import { getViewOnlyModeDetails } from "../../utils/common/common";
import history from "../../utils/common/history";

export const actionCreators = {
  getCurrentPackage: () => async (dispatch: any) => {
    try {
      dispatch({ type: actionTypes.GET_CURRENT_PACKAGE });
      dispatch(headerActions.setWorkspace("reviewer"));

      // If its view only then get the packageGuid from the url params else make a call to getUserPackage
      const { isViewOnly, packageGuid } = getViewOnlyModeDetails();

      if (isViewOnly) {
        await dispatch(actionCreators.getPackageDetails(packageGuid));
      } else {
        await dispatch(actionCreators.getReviewerReviewingPackage());
      }

      dispatch({ type: actionTypes.GET_CURRENT_PACKAGE_COMPLETE });
    } catch (error: any) {
      dispatch({ type: actionTypes.GET_CURRENT_PACKAGE_FAILED });
    }
  },

  getReviewerReviewingPackage:
    () => async (dispatch: any, getState: () => IApplicationState) => {
      try {
        dispatch({ type: actionTypes.GET_REVIEWER_REVIEWING_PACKAGE });

        dispatch(
          commonActions.showMessage({
            message: "Fetching current reviewing package details...",
            type: MessageBarType.info,
          })
        );

        await dispatch(userPackageActions.getUserPackage());

        dispatch({
          type: actionTypes.GET_REVIEWER_REVIEWING_PACKAGE_COMPLETE,
          payload: getState().userPackage,
        });

        dispatch(commonActions.hideMessage());
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );

        dispatch({ type: actionTypes.GET_REVIEWER_REVIEWING_PACKAGE_FAILED });
      }
    },

  getPackageDetails: (packageGuid: string) => async (dispatch: any) => {
    try {
      dispatch({ type: actionTypes.GET_PACKAGE_DETAILS });

      dispatch(
        commonActions.showMessage({
          message: "Fetching package details...",
          type: MessageBarType.info,
        })
      );

      const fetchAction = fetchCreator(
        `${configs.client.endpoint.appServiceEndpoint}packages/details/${packageGuid}`,
        {
          method: "GET",
        }
      );

      const response = await dispatch(fetchAction);
      const data = await response.json();

      dispatch({
        type: actionTypes.GET_PACKAGE_DETAILS_COMPLETE,
        payload: data,
      });

      dispatch(commonActions.hideMessage());
    } catch (error: any) {
      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );

      dispatch({ type: actionTypes.GET_PACKAGE_DETAILS_FAILED });
    }
  },

  updatePresets: (presets: IUserModulePreferences) => async (dispatch: any) => {
    dispatch(
      userPreferenceActions.updateReviewerReviewPackagePreferences(presets)
    );
  },

  assignAndReviewPackage:
    (packageGuid: string) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        dispatch(
          commonActions.showMessage({
            message: "Loading...",
            type: MessageBarType.info,
          })
        );

        dispatch({ type: actionTypes.ASSIGN_AND_REVIEW_PACKAGE });

        const userPackage: IUserPackage = await dispatch(
          userPackageActions.getUserPackage()
        );

        if (userPackage.id) {
          if (packageGuid !== userPackage.id) {
            dispatch(
              commonActions.showMessage({
                message: "You are already reviewing another package.",
                type: MessageBarType.error,
              })
            );
          } else {
            history.push("/reviewer/review-package");
          }
        } else {
          await dispatch(actionCreators.getPackageDetails(packageGuid));

          if (
            getState().reviewerReviewPackage.currentPackage.workflowStatusId ===
            WorkflowStatus.Published
          ) {
            await dispatch(
              adminPackageManagementActions.assignPackage(packageGuid)
            );
            dispatch(
              commonActions.broadcastMessageToChannel(
                BroadcastChannelName.PACKAGE_MANAGEMENT,
                true
              )
            );
            history.push("/reviewer/review-package");
            dispatch(actionCreators.getCurrentPackage());
          } else {
            dispatch(
              commonActions.showMessage({
                message:
                  "Package cannot be reviewed again as its already submitted.",
                type: MessageBarType.error,
              })
            );
          }
        }
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
        dispatch({
          type: actionTypes.ASSIGN_AND_REVIEW_PACKAGE_FAILED,
          message: error.message,
        });
      }
    },

  resetCurrentPackage:
    () => (dispatch: any, getState: () => IApplicationState) => {
      const reviewerReviewPackagePreferences =
        getState().userPreference.modules["REVIEWER-REVIEW-PACKAGE"]
          .tablePresets;

      let recentFilters: IFilterSet[] = [];
      let savedFilters: IFilterSet[] = [];
      let rowsPerPage = 200;

      if (
        reviewerReviewPackagePreferences &&
        reviewerReviewPackagePreferences.filters &&
        reviewerReviewPackagePreferences.filters.recent
      ) {
        recentFilters = reviewerReviewPackagePreferences.filters.recent;
      }

      if (
        reviewerReviewPackagePreferences &&
        reviewerReviewPackagePreferences.filters &&
        reviewerReviewPackagePreferences.filters.saved
      ) {
        savedFilters = reviewerReviewPackagePreferences.filters.saved;
      }

      if (
        reviewerReviewPackagePreferences &&
        reviewerReviewPackagePreferences.paging &&
        reviewerReviewPackagePreferences.paging.rowsPerPage
      ) {
        rowsPerPage = reviewerReviewPackagePreferences.paging.rowsPerPage;
      }

      dispatch({ type: actionTypes.RESET_CURRENT_PACKAGE });
      dispatch(
        userPreferenceActions.updateReviewerReviewPackagePreferences({
          tablePresets: {
            ...reviewerReviewPackagePreferences,
            paging: {
              currentPage: 1,
              rowsPerPage: rowsPerPage,
            },
            filters: {
              applied: [],
              recent: recentFilters || [],
              saved: savedFilters || [],
            },
          },
        })
      );
    },
};
