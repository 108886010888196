import { ITag } from "@fluentui/react/lib/components/pickers/TagPicker/TagPicker.types";
import { Reducer } from "redux";
import {
  actionTypes,
  DefaultSearchDsaBlockDetailsState,
  ISearchDsaBlockDetails,
} from "./DsaBlockState";

export const SearchDsaBlockReducer: Reducer<ISearchDsaBlockDetails> = (
  state: ISearchDsaBlockDetails = DefaultSearchDsaBlockDetailsState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SEARCH_DSA_BLOCK:
      const fetchedData = action.payload.data;
      return {
        ...state,
        searchResults: fetchedData,
      };
    case actionTypes.EXPORT_DSA_BLOCK:
      const exportData = action.payload.data;
      return {
        ...state,
        exportData: exportData,
      };
    case actionTypes.CLEAR_EXPORT_DATA:
      return {
        ...state,
        exportData: [],
      };
    case actionTypes.SELECTED_DSA_BLOCK: {
      return {
        ...state,
        selectedRule: action.payload,
      };
    }
    default:
      return state || {};
  }
};
