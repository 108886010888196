import { IDropdownOption } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../store";
import { StatusTypeOption } from "../PulisherRule.service";
import { IRendererProps } from "./Renderer.types";

export const DropdownRenderer = (props: IRendererProps) => {
  const { item, column } = props;
  if (!item || !column || !column.key) return null;

  if (!item[column.key] || item[column.key].length === 0) {
    return <></>;
  }
  const [dropdown, setDropdown] = React.useState<IDropdownOption[]>([]);
  const { categories } = useSelector((state: IApplicationState) => ({
    ...state.publisherCategories,
  }));
  React.useEffect(() => {
    handleDropDown();
  }, [column.key]);

  const handleDropDown = () => {
    switch (column.key) {
      case "category":
        setDropdown(categories);
        break;
      case "status":
        setDropdown(StatusTypeOption);
        break;
    }
  };
  const val = dropdown.find((x) => x.key == item[column.key]);

  return <>{val ? val.text : ""}</>;
};
