import { IPopupProps } from "@trinity/common";
import { IFilter, ITableColumn, IValidations } from "../../../Table.types";

/**
 * {@docCategory Filters}
 */
export interface IFiltersProps {
  onApply?: (name: string, filterValues) => void;
  onRemove?: (name: string) => void;
  onRecent?: (name: string) => void;
  onSaved?: (name: string) => void;
  isDisabled?: boolean;
  shortcuts?: FilterShortcuts;
}

export enum FilterShortcuts {
  FILTER_ADD = "FILTER_ADD",
  FILTER_RESET = "FILTER_RESET",
}

/**
 * {@docCategory Filters}
 */
export interface IShowPopup {
  popupProps: IPopupProps;
  column: ITableColumn;
  filterValue?: IFilter;
}

/**
 * {@docCategory Filters}
 */
export enum Conditions {
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does not equal",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does not contain",
  BEGINS_WITH = "begins with",
  ENDS_WITH = "ends with",
}

/**
 * {@docCategory Filters}
 */
export interface IPopupBody {
  onRenderedValue: (
    value: string,
    key: string[],
    hasKeyValues: boolean,
    isInvalid?: boolean
  ) => void;
  filterValue?: IFilter;
  data: IValidations;
}

/**
 * {@docCategory Filters}
 */
export interface IPopupDropdown {
  onDropDownClick: (value: Conditions) => void;
  filterValue?: IFilter;
  data: IValidations;
}
