export enum Risk {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export enum EnforcementStatus {
  Skipped = 0,
  Success = 1,
  Failed = 2,
}

export enum DecisionSource {
  FLA_Explore = 0,
  FLA_CustomerProfile = 1,
  FLA_Network = 2,
  FLA_Network_InfoPane = 3,
  FraudMT = 4,
  FLAEnforcementScreen = 5,
  FLADecisionHistoryScreen = 6,
  FLADetectionHistoryScreen = 15,
}
export enum DetectionSource {
  FLA_Explore = 0,
  FLA_CustomerProfile = 1,
  FLA_Network = 2,
  FLA_Network_InfoPane = 3,
  FraudMT = 4,
  FLAEnforcementScreen = 5,
  FLADetectionHistoryScreen = 15,
}

export enum AIVSource {
  FLA_Explore = 0,
  FLA_CustomerProfile = 1,
  FLA_Network = 2,
  FLA_Network_InfoPane = 3,
  FraudMT = 4,
  FLAEnforcementScreen = 5,
}

export enum Brackets {
  OPEN = "(",
  CLOSE = ")",
  UNDEFINED = "null",
}
