import { FontSizes, getTheme, Icon, Stack, Text, Link } from "@fluentui/react";
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../store";

function ServerError() {
  const theme = getTheme();
  const { t } = useTranslation();
  const { error } = useSelector((state: IApplicationState) => ({
    ...state.user,
  }));

  const contentStyles: any = {
    root: [
      {
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      },
    ],
  };
  const iconStyles: any = {
    root: [
      {
        fontSize: FontSizes.mega,
        color: theme.palette.neutralTertiaryAlt,
      },
    ],
  };
  const textStyles: any = {
    root: [
      {
        fontSize: FontSizes.xLarge,
        color: theme.palette.neutralSecondary,
      },
    ],
  };

  const Error = (): JSX.Element => {
    if (error) {
      return (
        <h3>
          <Text>{error}</Text>
        </h3>
      );
    }
    return <></>;
  };

  return (
    <Stack styles={contentStyles}>
      <Icon data-test="icon" iconName="Sad" styles={iconStyles}></Icon>
      <h1>
        <Text data-test="text" style={textStyles}>
          <Trans i18nKey="error.server-error">
            Something went wrong. Try again or contact Trinity Support
            <Link data-test="link" href="mailto:EVDsupport@microsoft.com">
              Support team
            </Link>
            for further assistance.
          </Trans>
        </Text>
      </h1>
      <Error />
    </Stack>
  );
}

export default ServerError;
