import { DecisionSource, LoadState, TNSEntityType } from "@trinity/enums";
import {
  IEntity,
  ITNSEnforcementPayload,
} from "../tns-enforcement/TNSEnforcementState";

export interface ITNSEnforcementHistoryState {
  loadState: LoadState;
  enforcementHistroyList: IDecisionData[];
}

interface IDecisionData {
  entityType: string;
  entityId: string;
  decision: string;
  enforcementActionType: string;
  reasonCode: string;
  notes: string;
  modifiedByUserId: string;
  modifiedByUserEmail: string;
  source: string;
  transactionType: string;
  trackingId: string;
  templateName: string;
  policyBlurbs: string[];
  decisionTime: string;
  frontendDecision: string;
}

export const DefaulEnforcementHistoryState: ITNSEnforcementHistoryState = {
  loadState: LoadState.NotLoaded,
  enforcementHistroyList: [],
};
