import { MessageBarType } from "@fluentui/react";
import { IApplicationState } from "..";
import { configs } from "../../configs";
import { ApiRequestMethods } from "@trinity/enums";
import { fetchCreator } from "../../utils/middleware/fetch";
import { actionCreators as commonActions } from "../common/CommonActions";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import { IUserModulePreferences } from "../user-preference/UserPreferenceState";

export enum actionTypes {
  GET_QUEUE_MANAGEMENT_QUEUES = "GET_QUEUE_MANAGEMENT_QUEUES",
  GET_QUEUE_MANAGEMENT_QUEUES_FAILED = "GET_QUEUE_MANAGEMENT_QUEUES_FAILED",
  UPDATE_TRANSLATION = "UPDATE_TRANSLATION",
}

export const actionCreators = {
  getAllQueues: () => async (dispatch: any, getState) => {
    try {
      dispatch(
        commonActions.showMessage({
          message: "Loading...",
          type: MessageBarType.info,
        })
      );
      const userId = (getState() as IApplicationState).user.userId;
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + `queues?userId=${userId}`
        )
      );

      const data = await response.json();
      dispatch(commonActions.hideMessage());

      dispatch({
        type: actionTypes.GET_QUEUE_MANAGEMENT_QUEUES,
        payload: {
          allQueues: data,
        },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_QUEUE_MANAGEMENT_QUEUES_FAILED,
      });
      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );
    }
  },

  updatePresetsQueueManagement:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateQueueManagementPreferences(presets));
    },

  updateTranslation:
    (checked: boolean | undefined, queueId: number) =>
    async (dispatch: any) => {
      try {
        const fetchAction = fetchCreator(
          `${configs.client.endpoint.appServiceEndpoint}queues/${queueId}/translation?enable=${checked}`,
          {
            method: ApiRequestMethods.Put,
          }
        );

        const response = await dispatch(fetchAction);
        const data = await response;

        dispatch({
          type: actionTypes.UPDATE_TRANSLATION,
          payload: { queueId, checked },
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },
};
