import { AnyAction, Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import { DefaultAIVDataState, ITNSAIVDataState } from "./TNSAIVDataState";
import { actionTypes } from "./TNSAIVDataActions";

export const tnsAIVDataReducer: Reducer<ITNSAIVDataState> = (
  state: ITNSAIVDataState = DefaultAIVDataState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.GET_AIV_DATA:
      return { ...state, loadState: LoadState.InProgress };

    case actionTypes.GET_AIV_DATA_RECEIVED:
      return {
        ...state,
        loadState: LoadState.Loaded,
        aivDataList: action.payload,
      };

    case actionTypes.GET_AIV_DATA_FAILED:
      return {
        ...state,
        loadState: LoadState.Failed,
      };

    case actionTypes.CLEAR_AIV_DATA:
      return DefaultAIVDataState;

    default:
      return state;
  }
};
