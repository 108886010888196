import { FETCH } from "./fetch";

export const CACHE = "utils/cache";

/**
 * Schema for cache value
 */
interface ICache {
  uniqueKey: string;
  value: any;
}

export interface ICachingAction {
  meta: {
    cache: {
      /**
       * The cache's root key
       * Responsible for fetching the cache's value
       */
      primaryKey: string;
      /**
       * The unique key with respect to the cache's schema.
       * Responsible for fetching the value in the array with respect to the given key.
       */
      uniqueKey: string;
      /**
       * The reference key with respect to the cache's schema.
       * Responsible keeping references in a separate cache.
       */
      referenceKey: string;
      /**
       * If true will remove the object from the cache
       * Depends on the primary key
       */
      invalidate: boolean;
      /**
       * Add editable fields
       * This will invalidate the cache unique key if editable field change values
       */
      editableFields: string[];
      /**
       * Updates the existing cache.
       * Depends on the Unique Key
       */
      update: {
        /**
         * All the data that is to be updated.
         */
        data: string;
        /**
         * fields that needs to be updated, if no fields then all the data will be updated.
         */
        fields: string[];
        /**
         * Calls next middleware
         */
        next: boolean;
      };

      /**
       * Callback function to modify the data as per the business need and then save it into the cache.
       */

      modifyResultAndSaveInCache: (result) => null;
    };
  };
}

export const cachingMiddleware = (store) => (next) => (action) => {
  // If there is any other action apart from fetch/cache go to next.
  if (action.type !== FETCH && action.type !== CACHE) return next(action);

  // If meta cache information is not there go to next.
  if (!action.meta || !action.meta.cache) return next(action);

  // If update property is set, just update the cache and return.
  if (action.meta.cache.update) {
    updateCache(
      action,
      action.meta.cache.update.data,
      action.meta.cache.update.fields
    );

    if (action.meta.cache.update.next) {
      return next(action);
    }

    return;
  }

  // Invalidate cache if invalidate property is set to true.
  if (action.meta.cache.invalidate)
    localStorage.removeItem(action.meta.cache.primaryKey);

  // Get the cache and return if its present.
  const result = getCache(action);
  if (result) return new Promise((resolve) => resolve(new Response(result)));

  // If cache is not present, get from the next action and set the cache and return.
  return new Promise((resolve, reject) => {
    next(action)
      .then(async (response: Response) => {
        if (response.ok) {
          // NOTE: response.json() can be read only once.
          // Therefore, it has to be resolved and given back as a new response.
          let result = await response.json();

          // Get the result implementation in the callback function where the cache is implemented and then set it into the local storage
          if (action.meta.cache.modifyResultAndSaveInCache) {
            result = action.meta.cache.modifyResultAndSaveInCache(result);
          }

          setCache(action, result);
          resolve(new Response(getCache(action)));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function getCache(action): string | undefined {
  const cache = getParseObjectFromCache(action);

  if (cache) {
    const result = cache.find(
      (x) => x.uniqueKey === action.meta.cache.uniqueKey
    );
    if (result) {
      if (
        action.meta.cache.referenceKey &&
        result.value &&
        result.value.value
      ) {
        const refCache = getParseObjectRefFromCache(action) || {};

        for (const key in result.value.value) {
          if (refCache[result.value.value[key]])
            result.value.value[key] = refCache[result.value.value[key]];
          else delete result.value.value[key];
        }
      }

      return JSON.stringify(result.value);
    }
  }

  return undefined;
}

function setCache(action, result: any): void {
  const existingCache = getParseObjectFromCache(action) || [];

  // We are expecting the result to contain a value array when referenceKey is passed
  if (
    action.meta.cache.referenceKey &&
    Array.isArray(result.value) &&
    result.value.length > 0
  ) {
    const refCache = getParseObjectRefFromCache(action) || {};
    const newResult = { value: [] as any };

    for (const key in result) {
      if (key !== "value") newResult[key] = result[key];
    }

    for (const key in result.value) {
      // if cache is not present then only add it.
      if (!refCache[result.value[key][action.meta.cache.referenceKey]])
        refCache[result.value[key][action.meta.cache.referenceKey]] =
          result.value[key];
      newResult.value[key] = result.value[key][action.meta.cache.referenceKey];
    }

    localStorage.setItem(getRefKey(action), JSON.stringify(refCache));

    // Reset the cache sync flag so that new updates will be synced
    localStorage.setItem("trinity-cache-synced", "0");

    result = newResult;
  }

  existingCache.push({
    uniqueKey: action.meta.cache.uniqueKey,
    value: result,
  });

  localStorage.setItem(
    action.meta.cache.primaryKey,
    JSON.stringify(existingCache)
  );
}

function updateCache<T>(action, data: any, fields: string[]): void {
  if (action.meta.cache.referenceKey) {
    const refCache = getParseObjectRefFromCache(action) || {};
    for (const key in data.value) {
      // if no editable fields are there update all the fields
      if (fields.length === 0) {
        refCache[data.value[key][action.meta.cache.referenceKey]] =
          data.value[key];
      } else {
        for (const field of fields) {
          if (field in data.value[key])
            refCache[data.value[key][action.meta.cache.referenceKey]][field] =
              data.value[key][field];
        }
      }
    }

    localStorage.setItem(getRefKey(action), JSON.stringify(refCache));

    // Reset the cache sync flag so that new updates will be synced
    localStorage.setItem("trinity-cache-synced", "0");

    // If any of the editable values exists in the url then invalidate that caches index
    upsertItemIndex(action);
  } else {
    const cache = getParseObjectFromCache(action);

    if (cache) {
      const index = cache.findIndex(
        (x) => x.uniqueKey === action.meta.cache.uniqueKey
      );

      if (index !== -1) {
        cache[index].value = data;
        localStorage.setItem(
          action.meta.cache.primaryKey,
          JSON.stringify(cache)
        );
      }
    }
  }
}

function getParseObjectFromCache(action): ICache[] | undefined {
  const result = localStorage.getItem(action.meta.cache.primaryKey);

  if (result) {
    return JSON.parse(result);
  }

  return undefined;
}

function getParseObjectRefFromCache(action): ICache[] | undefined {
  const result = localStorage.getItem(getRefKey(action));

  if (result) {
    return JSON.parse(result);
  }

  return undefined;
}

function getRefKey(action): string {
  return action.meta.cache.primaryKey + "-ref";
}

function upsertItemIndex(action): void {
  // Remove the matching indexes and update the same primary key value if the editable value changes
  // Therefore, next time since the value won't be present in the cache, it will make an API call.
  let cache = getParseObjectFromCache(action);
  if (cache && action.meta.cache.editableFields) {
    action.meta.cache.editableFields.map((x) => {
      cache = cache && cache.filter((y) => !y.uniqueKey.includes(x));
    });

    localStorage.setItem(action.meta.cache.primaryKey, JSON.stringify(cache));
  }
}
