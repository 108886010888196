import { Reducer } from "redux";
import { EscalationChildStatus, EscalationParentStatus } from "@trinity/enums";
import { DownloadFile } from "../../../src/utils/common/common";
import {
  actionTypes,
  EscalationsPanelType,
  IEscalationsState,
  IJobStatusItems,
  INITIAL_STATE,
} from "./EscalationsState";

export const escalationsReducer: Reducer<IEscalationsState> = (
  state: IEscalationsState = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.OPEN_PANEL:
      return {
        ...state,
        showPanel: true,
        panelType: action.payload,
      };

    case actionTypes.CLOSE_PANEL:
      return {
        ...state,
        showPanel: false,
        panelType: EscalationsPanelType.NONE,
      };

    case actionTypes.CREATE_JOB:
      return {
        ...state,
      };

    case actionTypes.CREATE_JOB_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_JOB_STATUS:
      return {
        ...state,
        loading: action.payload.loading,
        jobStatus: {
          ...state.jobStatus,
          items: getJobs(action.payload.data),
        },
      };

    case actionTypes.GET_JOB_STATUS_FAILED:
      return {
        ...state,
      };

    case actionTypes.REASSIGN_JOBS_START:
    case actionTypes.DELETE_JOBS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.REASSIGN_JOBS_COMPLETED:
    case actionTypes.DELETE_JOBS_COMPLETED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.REASSIGN_JOBS_FAILED:
    case actionTypes.DELETE_JOBS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SELECTED_ITEMS:
      return {
        ...state,
        jobStatus: {
          ...state.jobStatus,
          selectedItems: action.payload,
        },
      };

    case actionTypes.DOWNLOAD_STATS:
      DownloadFile(action.payload, "stats");

      return {
        ...state,
      };

    case actionTypes.DOWNLOAD_STATS_FAILED:
      return {
        ...state,
      };

    case actionTypes.RESET_CURRENT_PACKAGE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

function getJobs(items: IJobStatusItems[]) {
  let jobs: IJobStatusItems[] = items;

  if (jobs) {
    jobs = jobs.filter((x) => {
      const isChildJobDeleted =
        x.childWorkflowStatus &&
        x.childWorkflowStatus === EscalationChildStatus.Deleted;

      const isParentJobDeleted =
        x.parentJobStatus &&
        x.parentJobStatus === EscalationParentStatus.Deleted;

      if (isChildJobDeleted || isParentJobDeleted) {
        return false;
      }

      return true;
    });
  }

  return jobs;
}
