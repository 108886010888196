import {
  ActionButton,
  IconButton,
  IPanelProps,
  IRenderFunction,
  Stack,
} from "@fluentui/react";
import { Panel } from "@fluentui/react/lib/Panel";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { configs } from "../../configs";
import { IWorkspaceConfig } from "../../configs/WorkspaceConfig";
import { IApplicationState } from "../../store";
import { UserRole } from "@trinity/enums";
import { actionCreators as headerActions } from "../../store/header/HeaderActions";
import { getStyles } from "./WorkspaceSwitcher.styles";

function WorkspaceSwitcher() {
  const styles = getStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const state = useSelector((state: IApplicationState) => ({
    ...state.header,
    ...state.user,
  }));

  const title = t("panel.close-button-label", {
    panelTitle: t("header.workspace-launch-title"),
  });

  const onRenderNavigationContent = React.useCallback(
    (props?: IPanelProps, defaultRender?: IRenderFunction<IPanelProps>) => {
      const title = t("panel.close-button-label", {
        panelTitle: t("header.workspace-launch-title"),
      });

      return (
        <React.Fragment>
          <div id={"workspace-switcher-panel-icon-container"}>
            <IconButton
              className="iconSwitch"
              iconProps={{
                iconName: "Waffle",
              }}
              ariaLabel={title}
              title={title}
              onClick={(e) => dispatch(headerActions.closeWorkspaces())}
            />
          </div>
          {defaultRender && defaultRender(props)}
        </React.Fragment>
      );
    },
    [t, dispatch]
  );

  const onWorkspaceClick = React.useCallback(
    (item) => {
      navigate("/" + item.key + item.default);
      dispatch(headerActions.setWorkspace(item.key));
      dispatch(headerActions.closeWorkspaces());
    },
    [dispatch, navigate]
  );

  const workspaces = getUserWorkspaces(configs.workspace, state.userRoles);

  return (
    <Panel
      data-test="panel"
      styles={styles.panel}
      closeButtonAriaLabel={title}
      isBlocking={false}
      isLightDismiss={true}
      onDismiss={() => dispatch(headerActions.closeWorkspaces())}
      isOpen={state.showWorkspaces}
      headerText={t("workspaces.panel-title")}
      onRenderNavigationContent={onRenderNavigationContent}
    >
      <Stack>
        {workspaces.map((item) => (
          <ActionButton
            data-test="actionButton"
            className={
              state.selectedWorkspaceKey === item.key ? "selected" : ""
            }
            key={item.key}
            title={t("workspaces." + item.key + "-title")}
            iconProps={{ iconName: item.icon }}
            onClick={(ev) => onWorkspaceClick(item)}
          >
            {t("workspaces." + item.key + "-title")}
          </ActionButton>
        ))}
      </Stack>
    </Panel>
  );
}

function getUserWorkspaces(
  workspaces: IWorkspaceConfig[],
  userRoles: UserRole[]
): IWorkspaceConfig[] {
  const userWorkspaces: IWorkspaceConfig[] = [];
  if (userRoles && userRoles.length > 0) {
    if (
      userRoles.includes(UserRole.EditorialAdmin) &&
      userRoles.includes(UserRole.EditorialChineseAdmin) &&
      userRoles.includes(UserRole.RiskAdmin)
    )
      return workspaces;

    for (const idx in workspaces) {
      const item = workspaces[idx];
      switch (item.key) {
        case "admin":
          if (
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.RiskAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly)
          )
            userWorkspaces.push(item);
          continue;

        case "reviewer":
          if (
            userRoles.includes(UserRole.EditorialAdvancedReviewer) ||
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialManager) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin)
          )
            userWorkspaces.push(item);
          continue;

        case "escalations":
          if (
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly) ||
            userRoles.includes(UserRole.RiskAdmin) ||
            userRoles.includes(UserRole.RiskPremiumReviewer) ||
            userRoles.includes(UserRole.RiskReviewer)
          )
            userWorkspaces.push(item);
          continue;

        case "listmanager":
          if (
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly)
          )
            userWorkspaces.push(item);
          continue;
        case "tns":
        case "enforcement":
          if (
            userRoles.includes(UserRole.RiskAdmin) ||
            userRoles.includes(UserRole.RiskPremiumReviewer) ||
            userRoles.includes(UserRole.RiskReviewer) ||
            userRoles.includes(UserRole.RiskViewer)
          )
            userWorkspaces.push(item);
          continue;
        case "qre":
          if (
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly)
          )
            userWorkspaces.push(item);
          continue;
        case "rule":
          if (
            userRoles.includes(UserRole.RiskAdmin) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly) ||
            userRoles.includes(UserRole.EditorialReviewer)
          )
            userWorkspaces.push(item);
          continue;
        case "publisher":
          if (
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.EditorialReadOnly)
          )
            userWorkspaces.push(item);
          continue;

        case "editorial":
          if (
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialManager) ||
            userRoles.includes(UserRole.EditorialReviewer) ||
            userRoles.includes(UserRole.EditorialAdvancedReviewer) ||
            userRoles.includes(UserRole.EditorialReadOnly) ||
            userRoles.includes(UserRole.RiskViewer) ||
            userRoles.includes(UserRole.RiskReviewer) ||
            userRoles.includes(UserRole.RiskPremiumReviewer) ||
            userRoles.includes(UserRole.RiskAdmin) ||
            userRoles.includes(UserRole.EditorialChineseAdmin) ||
            userRoles.includes(UserRole.Developer)
          )
            userWorkspaces.push(item);
          continue;

        case "approval":
          if (
            userRoles.includes(UserRole.EditorialAdmin) ||
            userRoles.includes(UserRole.EditorialApprover)
          )
            userWorkspaces.push(item);
          continue;
        default:
          continue;
      }
    }
  }

  return userWorkspaces;
}

export default WorkspaceSwitcher;
