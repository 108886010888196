import {
  EntityType,
  TNSEntityType,
  TnsEntityTypeToEntity,
} from "@trinity/enums";
import { ITNSEnforcementFormState } from "../../modules/tns-enforcement/TnsEnforcement.types";
import { IEntity, ITNSEnforcementPayload } from "./TNSEnforcementState";
import { actionCreators as commonActions } from "../common/CommonActions";
import { MessageBarType } from "@fluentui/react";
import { fetchCreator } from "../../utils/middleware/fetch";
import { configs } from "../../configs";
import { getRiskScope } from "../../utils/common/getRiskScope";
import { ICommonState } from "../common/CommonState";

export enum actionTypes {
  ENFORCEMENT_VALIDATE_RESPONSE = "ENFORCEMENT_VALIDATE_RESPONSE",
  RESET_ENFORCEMENT_VALIDATE = "RESET_ENFORCEMENT_VALIDATE",
  ENFORCEMENT_CONFIRMED_PROGRESS = "ENFORCEMENT_CONFIRMED_PROGRESS",
  ENFORCEMENT_CONFIRMED = "ENFORCEMENT_CONFIRMED",
  ENFORCEMENT_CONFIRMED_FAILED = "ENFORCEMENT_CONFIRMED_FAILED",
  CLEAR_ENFORCEMENT = "CLEAR_ENFORCEMENT",
  GET_AUTO_SUGGESTION_VALUES = "GET_AUTO_SUGGESTION_VALUES",
  GET_AUTO_SUGGESTION_VALUES_RESPONSE = "GET_AUTO_SUGGESTION_VALUES_RESPONSE",
  CLEAR_AUTO_SUGGESTION_VALUES = "CLEAR_AUTO_SUGGESTION_VALUES",
}

export const actionCreators = {
  validateEntityEnforcement:
    (enforcement: ITNSEnforcementFormState) => async (dispatch, getState) => {
      try {
        dispatch({ type: actionTypes.CLEAR_ENFORCEMENT });
        dispatch(
          commonActions.showMessage({
            message: "Validation in progress. Please wait...",
            type: MessageBarType.info,
          })
        );
        dispatch(
          commonActions.showMessage({
            message: "Loading. Please wait...",
            type: MessageBarType.info,
          })
        );
        console.log("Loading message dispatched");
        const { email, userId, userRoles } = getState().user;
        const flaConstants = getState().common.flaConstants;
        const payload = generateEnforcementPayload(
          enforcement,
          userId,
          email,
          flaConstants
        );
        const enforcementResponse = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.tnsEndpoint}api/entityenforcement/validate`,
            {
              method: "POST",
              body: JSON.stringify(payload),
              customOptions: { scope: getRiskScope(userRoles) },
            }
          )
        );

        dispatch(commonActions.hideMessage());
        const response = await enforcementResponse.json();
        dispatch({
          type: actionTypes.ENFORCEMENT_VALIDATE_RESPONSE,
          payload: response,
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: error.message,
          })
        );
      }
      	finally {
        dispatch(commonActions.hideMessage());
      }
    },

  confirmEntityEnforcement:
    (enforcement: ITNSEnforcementFormState) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actionTypes.ENFORCEMENT_CONFIRMED_PROGRESS,
        });
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.info,
            message: "Applying the enforcement...",
          })
        );
        const { email, userId, userRoles } = getState().user;
        const flaConstants = getState().common.flaConstants;

        const payload = generateEnforcementPayload(
          enforcement,
          userId,
          email,
          flaConstants
        );
        const enforcementResponse = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.tnsEndpoint}api/entityenforcement/enforce`,
            {
              method: "POST",
              body: JSON.stringify(payload),
              customOptions: { scope: getRiskScope(userRoles) },
            }
          )
        );
        const response = await enforcementResponse.json();
        if (response.overallStatus === 0) {
          dispatch({
            type: actionTypes.ENFORCEMENT_CONFIRMED,
          });
          dispatch(
            commonActions.showMessage({
              type: MessageBarType.success,
              message: "Enforcement applied successfully!",
            })
          );
          setTimeout(() => {
            dispatch(commonActions.hideMessage());
          }, 3000);
        } else {
          dispatch({
            type: actionTypes.ENFORCEMENT_VALIDATE_RESPONSE,
            payload: response,
          });
        }
      } catch (error: any) {
        dispatch({
          type: actionTypes.ENFORCEMENT_CONFIRMED_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: error.message,
          })
        );
      }
    },

  clearEnforcement: () => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.CLEAR_ENFORCEMENT,
    });
  },

  getAutoSuggestionValues:
    (values: ITNSEnforcementFormState) => async (dispatch, getState) => {
      try {
        dispatch({ type: actionTypes.GET_AUTO_SUGGESTION_VALUES });
        dispatch(
          commonActions.showMessage({
            message: "Fetching auto suggestions...",
            type: MessageBarType.info,
          })
        );

        const { userRoles } = getState().user;

        const fetchAction = fetchCreator(
          `${configs.client.endpoint.tnsEndpoint}api/notification/suggest?api-version=1.0`,
          {
            method: "POST",
            body: JSON.stringify({
              EntityId: values.entities[values.entity],
              EntityType: values.entity,
            }),
            customOptions: { scope: getRiskScope(userRoles) },
          }
        );

        const response = await dispatch(fetchAction);
        const data = await response.json();
        dispatch(commonActions.hideMessage());
        dispatch({
          type: actionTypes.GET_AUTO_SUGGESTION_VALUES_RESPONSE,
          payload: data,
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: error.message,
          })
        );
      }
    },

  clearAutoSuggestionValues: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CLEAR_AUTO_SUGGESTION_VALUES });
  },
};

const generateEnforcementPayload = (
  enforcement: ITNSEnforcementFormState,
  userId: number,
  email: string,
  flaConstants: ICommonState["flaConstants"]
) => {
  const { reasonCodeDetails } = flaConstants;
  const entityType = TNSEntityType[enforcement.entity];
  const entitiesObj = {};
  Object.entries(enforcement.entities).map(([key, values]) => {
    entitiesObj[key] = values.split(",").filter(Boolean);
  });
  let finalEntities = [];
  finalEntities = entitiesObj[enforcement.entity].map((id, index) => {
    if (entityType === TNSEntityType.Customer) {
      return {
        customerId: id,
      };
    } else if (entitiesObj["Customer"]) {
      return {
        [TnsEntityTypeToEntity[entityType]]: id,
        customerId: entitiesObj["Customer"][index],
      };
    }
    return {
      [TnsEntityTypeToEntity[entityType]]: id,
    };
  });

  const policyBlurbsSet = new Set(enforcement.policyBlurb);
  const optionalPayload: { templateId?: string | number } = {};
  if (reasonCodeDetails[enforcement.reasonCode]) {
    const reasonCodeData = reasonCodeDetails[enforcement.reasonCode];
    optionalPayload.templateId = reasonCodeData.templateId;
    if (reasonCodeData.policyBlurbId) {
      policyBlurbsSet.add(
        reasonCodeDetails[enforcement.reasonCode].policyBlurbId
      );
    }
  }
  const finalPayload: ITNSEnforcementPayload = {
    entityType: entityType,
    entities: finalEntities,
    decision: enforcement.decisionType,
    reasonCodeId: enforcement.reasonCode,
    notes: enforcement.notes,
    modifiedByUserId: userId,
    modifiedByUserEmail: email,
    source: enforcement.source,
    jarvisTrackingId: enforcement.jarvisTrackingGuid,
    policyBlurbIds: [...policyBlurbsSet],
    transactionTypeId: enforcement.transactionTypeId,
    ucmTrackingId: enforcement.ucmTrackingId,
    ...optionalPayload,
  };
  return finalPayload;
};
