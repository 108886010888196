import { IDropdownOption } from "@fluentui/react";
import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { Operator } from "@trinity/enums";

export enum actionTypes {
  INSERT_NEW_DSA_BLOCK = "INSERT_NEW_DSA_BLOCK",
  INSERT_NEW_DSA_BLOCK_FAILED = "INSERT_NEW_DSA_BLOCK_FAILED",
  IMPORT_DSA_BLOCK = "IMPORT_DSA_BLOCK",
  IMPORT_DSA_BLOCK_FAILED = "IMPORT_DSA_BLOCK_FAILED",
  SEARCH_DSA_BLOCK = "SEARCH_DSA_BLOCK",
  SEARCH_DSA_BLOCK_FAILED = "SEARCH_DSA_BLOCK_FAILED",
  EXPORT_DSA_BLOCK = "EXPORT_DSA_BLOCK",
  EXPORT_DSA_BLOCK_FAILED = "EXPORT_DSA_BLOCK_FAILED",
  CLEAR_EXPORT_DATA = "CLEAR_EXPORT_DATA",
  SELECTED_DSA_BLOCK = "SELECTED_DSA_BLOCK",
  SELECTED_DSA_BLOCK_FAILED = "SELECTED_DSA_BLOCK_FAILED",
  DELETE_DSA_BLOCK = "DELETE_DSA_BLOCK",
  DELETE_DSA_BLOCK_FAILED = "DELETE_DSA_BLOCK_FAILED",
  BULK_DELETE_DSA_BLOCK = "BULK_DELETE_DSA_BLOCK",
  BULK_DELETE_DSA_BLOCK_FAILED = "BULK_DELETE_DSA_BLOCK_FAILED",
  ON_LOAD_DSA_BLOCK_DATA = "ON_LOAD_DSA_BLOCK_DATA",
}

export interface ISearchRequest {
  fieldName: string;
  operator: string;
  value: string;
}

export interface ISearchDsaBlockItem extends ITableRowItemProps {
  DSAListItemID: number;
  Uid: number;
  Reason: string;
  FlagAreaId: number | IDropdownOption;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
  ErrorMsg: string;
}

export interface ISearchDsaBlockDetails {
  exportData: ISearchDsaBlockDetail[];
  searchResults: ISearchDsaBlockDetail[];
}

export interface ISearchDsaBlockDetail {
  DSAListItemID: number;
  Uid: number;
  Reason: string;
  FlagAreaId: number | IDropdownOption;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export interface ImportDataDetails {
  Action: string;
  DSAListItemID: number;
  Uid: number;
  Reason: string;
  FlagAreaId: number | IDropdownOption;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export interface CodeNameMapping {
  code: string;
  name: string;
  mappings: IMappings[];
}

export interface IMappings {
  code: string;
  name: string;
  market: string;
}

export interface IDeleteDsaBlockState {
  DSAListItemID: number;
  Uid: number;
  Reason: string;
  FlagAreaId: number | IDropdownOption;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export const DefaultImportDataDetails: ImportDataDetails = {
  Action: "",
  DSAListItemID: 0,
  Uid: 0,
  Reason: "",
  FlagAreaId: 0,
  rowDateCreated: new Date(),
  rowDateModified: new Date(),
  ModifiedByUserId: -1,
};

export const DefaultSearchDsaBlockDetailsState: ISearchDsaBlockDetails = {
  exportData: [],
  searchResults: [],
};

export const DefaultSearchDsaBlockState: ISearchDsaBlockDetail = {
  DSAListItemID: 0,
  Uid: 0,
  Reason: "",
  FlagAreaId: 0,
  rowDateCreated: new Date(),
  rowDateModified: new Date(),
  ModifiedByUserId: -1,
};

export const OperatorMap = {
  checkbox: Operator.EQUALS,
  contains: Operator.CONTAINS,
};
