import {
  classNamesFunction,
  Link,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../store";
import { actionCreators as userActions } from "../../../store/user/UserActions";
import { IUserState } from "../../../store/user/UserState";
import { getPersonaStyles } from "./MyAccount.styles";
import {
  IMyAccountProps,
  IMyAccountStyleProps,
  IMyAccountStyles,
} from "./MyAccount.types";

function MyAccountBase(props: IMyAccountProps) {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const user: IUserState = useSelector(
    (state: IApplicationState) => state.user
  );
  const { styles, className, theme } = props;
  const getClassNames = classNamesFunction<
    IMyAccountStyleProps,
    IMyAccountStyles
  >();

  const classNames = getClassNames(styles, {
    className,
    theme: theme,
  });

  const onRenderTertiaryText = React.useCallback(() => {
    return (
      <Link
        onClick={() => dispatch(userActions.logout())}
        aria-label={t("myaccount.signout-text")}
        title={t("myaccount.signout-text")}
        target="_self">
        {t("myaccount.signout-text")}
      </Link>
    );
  }, [t, dispatch]);

  return (
    <div className={classNames.root}>
      <div className={classNames.personawrapper}>
        <Persona
          data-test="persona"
          initialsColor={PersonaInitialsColor.blue}
          styles={getPersonaStyles()}
          size={PersonaSize.size72}
          showInitialsUntilImageLoads={true}
          imageInitials={user.imageInitials}
          imageUrl={user.imageUrl}
          text={user.displayName}
          secondaryText={user.alias}
          onRenderTertiaryText={onRenderTertiaryText}
        />
      </div>
    </div>
  );
}

export default MyAccountBase;
