import { ITag, IDropdownOption } from "@fluentui/react";
import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { JoinCondition, LoadState, Operator } from "@trinity/enums";

export enum actionTypes {
  ON_FIELD_CHANGE = "ON_FIELD_CHANGE",
  ON_OPERATOR_CHANGE = "ON_OPERATOR_CHANGE",
  ON_VALUE_CHANGE = "ON_VALUE_CHANGE",
  ON_INCREAMENT = "ON_INCREAMENT",
  INSERT_NEW_QRE = "INSERT_NEW_QRE",
  INSERT_NEW_QRE_FAILED = "INSERT_NEW_QRE_FAILED",
  SEARCH_QRE = "SEARCH_QRE",
  SEARCH_QRE_FAILED = "SEARCH_QRE_FAILED",
  UPDATE_QRE = "UPDATE_QRE",
  UPDATE_QRE_FAILED = "UPDATE_QRE_FAILED",
  DELETE_QRE = "DELETE_QRE",
  DELETE_QRE_FAILED = "DELETE_QRE_FAILED",
  SELECTED_QRE = "SELECTED_QRE",
  SELECTED_QRE_FAILED = "SELECTED_QRE_FAILED",
  ON_LOAD_QRE_DATA = "ON_LOAD_QRE_DATA",
  ON_LOAD_QRE_DATA_FAILED = "ON_LOAD_QRE_DATA_FAILED",
  FETCH_QRE_CAPABILITY_DETAIL = "FETCH_QRE_CAPABILITY_DETAIL",
  FETCH_QRE_CAPABILITY_DETAIL_FAILED = "FETCH_QRE_CAPABILITY_DETAIL_FAILED",
  FETCH_QRE_CAPABILITY_DETAIL_FROM_LOCAL_STORAGE = "FETCH_QRE_CAPABILITY_DETAIL_FROM_LOCAL_STORAGE",
  FETCH_QRE_CAPABILITY_DETAIL_FROM_LOCAL_STORAGE_FAILED = "FETCH_QRE_CAPABILITY_DETAIL_FROM_LOCAL_STORAGE_FAILED",
}

export interface ISearchQreRequest {
  fieldName: string;
  operator: string;
  value: string;
}

export interface ISearchQreItem extends ITableRowItemProps {
  listId: number;
  listName: string;
  marketId: string;
  subCategoryId: string;
  region: string;
  capabilityId: string;
  status: string;
  isActive: string;
  scheduledEndDate: Date;
  scheduledStartDate: Date;
  modifiedBy: string;
}

export interface ISearchQreDetails {
  searchResults: ISearchQreDetail[];
}

export interface ISearchQreDetail {
  listId: number;
  listName: string;
  marketId: IDropdownOption;
  subCategoryId: IDropdownOption;
  region: IDropdownOption;
  capabilityId: IDropdownOption;
  status: IDropdownOption;
  isActive: string;
  scheduledEndDate: Date | undefined;
  scheduledStartDate: Date | undefined;
  keywords: string[];
  modifiedBy: string;
}

export const DefaultSearchQreDetail: ISearchQreDetail = {
  listId: 0,
  listName: "",
  marketId: { key: "", text: "" },
  subCategoryId: { key: "", text: "" },
  region: { key: "", text: "" },
  capabilityId: { key: "", text: "" },
  status: { key: "", text: "" },
  isActive: "",
  scheduledEndDate: undefined,
  scheduledStartDate: undefined,
  modifiedBy: "",
  keywords: [],
};

export const DefaultSearchQreState: ISearchQreDetails = {
  searchResults: [],
};

export interface IUpdateQreState {
  listId: number;
  listName: string;
  marketId: string | IDropdownOption;
  subCategoryId: string | IDropdownOption;
  region: string | IDropdownOption;
  capabilityId: string | IDropdownOption;
  status: string | IDropdownOption;
  isActive: string;
  scheduledEndDate: Date;
  scheduledStartDate: Date;
  modifiedBy: string;
  keywords: string[];
}

export interface IDeleteQreState {
  listId: number;
  listName: string;
  marketId: string | IDropdownOption;
  subCategoryId: string | IDropdownOption;
  region: string | IDropdownOption;
  capabilityId: string | IDropdownOption;
  status: string | IDropdownOption;
  isActive: string;
  scheduledEndDate: Date;
  scheduledStartDate: Date;
  modifiedBy: string;
}

export const DefaultUpdateQreState: IUpdateQreState = {
  listId: 0,
  listName: "",
  marketId: "",
  subCategoryId: "",
  region: "",
  capabilityId: "",
  status: "",
  isActive: "",
  scheduledEndDate: new Date("2099-01-01"),
  scheduledStartDate: new Date("2099-01-01"),
  modifiedBy: "",
  keywords: [],
};

export const OperatorMap = {
  checkbox: Operator.EQUALS,
  contains: Operator.CONTAINS,
};

export interface ISearchQreRequest {
  fieldName: string;
  operator: string;
  value: string;
}

export interface IQreCapabilityDetail {
  regions: IDropdownOption[];
  capabilities: Record<string, IDropdownOption[]>;
  allCapabilities: IDropdownOption[];
  markets: Record<string, IDropdownOption[]>;
  allMarkets: IDropdownOption[];
  subCategories: Record<string, IDropdownOption[]>;
  allSubCategories: IDropdownOption[];
}

export interface IQreCapabilityDetailResponse {
  marketGroup: string;
  market: string;
  subCategory: string;
  region: string;
  subCategoryId: string;
  marketId: string;
  capabilityName: string;
  capabilityId: string;
}

export const defaultQreCapabilityDetail: IQreCapabilityDetail = {
  regions: [],
  capabilities: {},
  allCapabilities: [],
  markets: {},
  allMarkets: [],
  subCategories: {},
  allSubCategories: [],
};

export interface IQreKeyword {
  keywordId: number;
  keywordName: string;
  createdDate: Date;
  modifiedDate: Date;
  modifiedBy: string;
}

export const defaultQreKeyword: IQreKeyword = {
  keywordId: 0,
  keywordName: "",
  createdDate: new Date("2099-01-01"),
  modifiedDate: new Date("2099-01-01"),
  modifiedBy: "0",
};
