import { IDropdownOption } from "@fluentui/react";
import { ITableRowItemProps } from "../../components/common/table/Table.types";

export enum actionTypes {
  INSERT_PUBLISHER_RULE = "INSERT_PUBLISHER_RULE",
  INSERT_PUBLISHER_RULE_FAILED = "INSERT_PUBLISHER_RULE_FAILED",
  SEARCH_PUBLISHER_RULE = "SEARCH_PUBLISHER_RULE",
  SEARCH_PUBLISHER_RULE_FAILED = "SEARCH_PUBLISHER_RULE_FAILED",
  UPDATE_PUBLISHER_RULE = "UPDATE_PUBLISHER_RULE",
  UPDATE_PUBLISHER_RULE_FAILED = "UPDATE_PUBLISHER_RULE_FAILED",
  DELETE_PUBLISHER_RULE = "DELETE_PUBLISHER_RULE",
  DELETE_PUBLISHER_RULE_FAILED = "DELETE_PUBLISHER_RULE_FAILED",
  SELECTED_PUBLISHER_RULE = "SELECTED_PUBLISHER_RULE",
  SELECTED_PUBLISHER_RULE_FAILED = "SELECTED_PUBLISHER_RULE_FAILED",
  ON_LOAD_PUBLISHER_RULE_DATA = "ON_LOAD_PUBLISHER_RULE_DATA",
  ON_LOAD_PUBLISHER_RULE_DATA_FAILED = "ON_LOAD_PUBLISHER_RULE_DATA_FAILED",
  FETCH_CATEGORY_DETAILS = "FETCH_CATEGORY_DETAILS",
  FETCH_CATEGORY_DETAILS_FAILED = "FETCH_CATEGORY_DETAILS_FAILED",
  FETCH_PUBLISHER_CATEGORY_FROM_LOCAL_STORAGE = "FETCH_PUBLISHER_CATEGORY_FROM_LOCAL_STORAGE",
  FETCH_PUBLISHER_CATEGORY_FROM_LOCAL_STORAGE_FAILED = "FETCH_PUBLISHER_CATEGORY_FROM_LOCAL_STORAGE_FAILED",
}

export interface ISearchPublisherRuleRequest {
  fieldName: string;
  operator: string;
  value: string;
}
export interface IPublisherRuleDetail {
  category: string;
  status: number;
  tokens: string[];
  modifiedBy: string;
}

export interface ISearchPublisherRuleItem extends ITableRowItemProps {
  cty: string;
  sts: number;
  tkn: string;
}

export const defaultPublisherRuleDetail: IPublisherRuleDetail = {
  category: "",
  status: -1,
  tokens: [],
  modifiedBy: "",
};

export interface ISearchPublisherRuleDetails {
  searchResults: ISearchPublisherRuleDetail[];
}

export interface ISearchPublisherRuleDetail {
  id: string;
  category: string;
  status: number;
  tokens: string[];
  modifiedBy: string;
  rowDateModified: Date;
}

export const DefaultSearchPublisherRuleDetail: ISearchPublisherRuleDetail = {
  id: "",
  category: "",
  status: -1,
  tokens: [],
  modifiedBy: "",
  rowDateModified: new Date(),
};

export const DefaultSearchPublisherRuleDetails: ISearchPublisherRuleDetails = {
  searchResults: [],
};

export interface IUpdatePublisherRuleState {
  category: string | IDropdownOption;
  status: number | IDropdownOption;
  modifiedBy: string;
  tokens: string[];
}

export const DefaultUpdatePublisherRuleState: IUpdatePublisherRuleState = {
  category: "",
  status: -1,
  modifiedBy: "",
  tokens: [],
};

export interface IPublisherCategory {
  id: string;
  val: string;
  type: number;
}

export interface IPublisherCommonData {
  categories: IDropdownOption[];
}

export const defaultPublisherCommonData: IPublisherCommonData = {
  categories: [],
};
