export enum FlagAreaType {
  REJECT_IN_ALL_COUNTRIES = 0,
  REJECT_IN_SOME_COUNTRIES = 1,
  CATEGORY_ONLY = 2,
  ABANDONED = 3,
  DEPRECATED = 4,
}

export enum FlagLevels {
  Confirm = 1, // Reject
  Probable = 2,
  Possible = 3,
  Monitor = 99,
  Override = 4,
  Clear = 5,
}
