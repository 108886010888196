import {
  Customizer,
  Spinner,
  SpinnerSize,
  Customizations,
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../store";
import Header from "../header/Header";
import WorkspaceSwitcher from "../navigation/WorkspaceSwitcher";
import HeaderPanel from "../header/HeaderPanel";
import { LoadState } from "@trinity/enums";
import ServerError from "../error/ServerError";

function EmptyLayout(props) {
  const { loadState } = useSelector((state: IApplicationState) => state.user);

  const spinnerStyles: any = {
    root: [
      {
        height: "100vh",
      },
    ],
  };

  return (
    <Customizer data-test="customizer" {...Customizations}>
      <div className="vnext-Layout">
        <Header data-test="header" />

        {loadState === LoadState.Loaded && <>{props.children}</>}

        {loadState === LoadState.NotLoaded && (
          <Spinner
            label="Getting user details..."
            data-test="spinner"
            styles={spinnerStyles}
            size={SpinnerSize.large}
          />
        )}

        {loadState === LoadState.Failed && (
          <ServerError data-test="serverError" />
        )}
      </div>

      <WorkspaceSwitcher data-test="workspaceSwitcher" />

      <HeaderPanel data-test="headerPanel" />
    </Customizer>
  );
}

export default EmptyLayout;
