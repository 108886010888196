import { v4 as uuidv4 } from "uuid";
import { LoggerService } from "../../services/LoggerService";
import { IFetchOptions } from "./fetch";

const FETCH = "utils/fetch";

interface IFetchAction {
  type: string;
  payload: {
    input: RequestInfo;
    init: IFetchOptions;
  };
}

export const logMiddleware = (store) => (next) => (action) => {
  if (action.type === FETCH) {
    const fetchAction = action as IFetchAction;
    const trackingGuid = uuidv4();
    const userAlias = store.getState().user.alias;

    fetchAction.payload.init.headers = {
      ...fetchAction.payload.init.headers,
      "Tracking-Id": trackingGuid,
      "User-Alias": userAlias,
    };

    return new Promise((resolve, reject) => {
      next(action)
        .then((response: Response) => {
          if (response.ok) resolve(response);
          else throw new Error(response.statusText);
        })
        .catch((error) => {
          const feature = "API Request";
          const requestUrl =
            typeof action.payload.input === "string"
              ? action.payload.input
              : action.payload.input.url;
          const message = `vNext : Request URL - ${requestUrl} : Error - ${error.message}`;
          error.message = `Error occurred, reload the page and try again. Contact support with Tracking Id - ${trackingGuid}`;

          LoggerService.logError(trackingGuid, feature, message, userAlias);
          reject(error);
        });
    });
  } else {
    return next(action);
  }
};
