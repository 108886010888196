import { configs } from "../../configs";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import { DefaultAdminOverviewState } from "./AdminOverviewState";
import { IApplicationState } from "..";
import { actionCreators as userPackageActions } from "../user-package/UserPackageActions";
import { actionCreators as reviewerReviewPackageActions } from "../reviewer-review-page/ReviewerReviewPackageActions";
import { IUserPackage } from "../user-package/UserPackageState";
import { TRINITY_OVERVIEW_ORIGIN } from "@trinity/constants";
import history from "../../utils/common/history";

export enum actionTypes {
  GET_ADMIN_QUEUES = "GET_ADMIN_QUEUES",
  GET_PACKAGE_DETAILS = "GET_PACKAGE_DETAILS",
  GET_PACKAGE_DETAILS_FAILED = "GET_PACKAGE_DETAILS_FAILED",
  ADMINQUEUES_RECEIVED = "ADMINQUEUES_RECEIVED",
  ADMINQUEUES_FETCH_FAILED = "ADMINQUEUES_FETCH_FAILED",
  GET_ADMINQUEUES_SUMMARY = "GET_ADMINQUEUES_SUMMARY",
  GET_ADMINQUEUES_SUMMARY_FAILED = "GET_ADMINQUEUES_SUMMARY_FAILED",
}

export const actionCreators = {
  getAdminQueues: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.GET_ADMIN_QUEUES,
      });

      const userId = (getState() as IApplicationState).user.userId;
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + `queues?userId=${userId}`
        )
      );
      const data = await response.json();

      dispatch({
        type: actionTypes.ADMINQUEUES_RECEIVED,
        payload: { queues: data },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.ADMINQUEUES_FETCH_FAILED,
        message: error.message,
      });
    }
  },

  getAdminQueuesSummary: () => async (dispatch) => {
    try {
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + "queues/summary?type=all"
        )
      );
      const data = await response.json();
      const adminSummary = DefaultAdminOverviewState.adminSummary;
      adminSummary.forEach((item) => (item.count = data[item.key]));

      dispatch({
        type: actionTypes.GET_ADMINQUEUES_SUMMARY,
        payload: { adminSummary: adminSummary },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_ADMINQUEUES_SUMMARY_FAILED,
        message: error.message,
      });
    }
  },
  getNextPackage:
    (queueId, queueName, packageGuid?: string) =>
    async (dispatch: any, getState: any) => {
      try {
        let queryParams = "";

        localStorage.setItem(TRINITY_OVERVIEW_ORIGIN, "/admin/overview");

        /* If an existing packageGuid is there redirect to review package */
        if (packageGuid) {
          history.push("/reviewer/review-package");
          return;
        }

        dispatch(reviewerReviewPackageActions.resetCurrentPackage());

        if (queueId) {
          queryParams += `?queueId=${queueId}`;
        }

        const response = await dispatch(
          fetchCreator(
            configs.client.endpoint.appServiceEndpoint +
              "queues/nextpackage" +
              queryParams
          )
        );
        const data = await response.json();
        dispatch({
          type: actionTypes.GET_PACKAGE_DETAILS,
          payload: { packageGuid: data.packageGuid },
        });

        dispatch(
          userPackageActions.updateUserPackage({
            id: data.packageGuid,
            queueId: queueId,
            queueName: queueName,
          } as IUserPackage)
        );

        history.push("/reviewer/review-package");
        dispatch(reviewerReviewPackageActions.getCurrentPackage());
        return;
      } catch (error: any) {
        dispatch({
          type: actionTypes.GET_PACKAGE_DETAILS_FAILED,
          message: error.message,
        });
      }
    },
};
