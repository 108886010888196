import { IDropdownOption } from "@fluentui/react";
import { Reducer } from "redux";
import {
  actionTypes,
  defaultQreCapabilityDetail,
  DefaultSearchQreState,
  DefaultUpdateQreState,
  IQreCapabilityDetail,
  IQreCapabilityDetailResponse,
  ISearchQreDetails,
  IUpdateQreState,
} from "./QreState";
import { QREConstants } from "../../modules/qre/Qre.service";

export const SearchQreReducer: Reducer<ISearchQreDetails> = (
  state: ISearchQreDetails = DefaultSearchQreState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SEARCH_QRE:
      const fetchedData = action.payload.data;
      return {
        ...state,
        searchResults: fetchedData,
      };
    case actionTypes.SELECTED_QRE: {
      return {
        ...state,
        selectedRule: action.payload,
      };
    }
    default:
      return state || {};
  }
};

export const QreCapabilityReducer: Reducer<IQreCapabilityDetail> = (
  state: IQreCapabilityDetail = defaultQreCapabilityDetail,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_QRE_CAPABILITY_DETAIL:
      const fetchedData = action.payload.data;
      //const QRE_CAPABILITY_DETAIL = "qreCapabilityDetail";
      const regions: IDropdownOption[] = [];
      const capabilities: Record<string, IDropdownOption[]> = {};
      const allCapabilities: IDropdownOption[] = [];
      const markets: Record<string, IDropdownOption[]> = {};
      const allMarkets: IDropdownOption[] = [];
      const subCategories: Record<string, IDropdownOption[]> = {};
      const allSubCategories: IDropdownOption[] = [];
      if (fetchedData && fetchedData.length > 0) {
        fetchedData.forEach((element: IQreCapabilityDetailResponse) => {
          if (!regions.some((o) => o.text == element.region))
            regions.push({ key: element.region, text: element.region });
          const capbilityDropdown = capabilities[element.region] || [];
          if (
            !capbilityDropdown.some(
              (o) => o.key == element.capabilityId.toString()
            )
          )
            capbilityDropdown.push({
              key: "" + element.capabilityId,
              text: element.capabilityName,
            });
          if (
            !allCapabilities.some(
              (o) => o.key == element.capabilityId.toString()
            )
          )
            allCapabilities.push({
              key: "" + element.capabilityId,
              text: element.capabilityName,
            });
          capabilities[element.region] = capbilityDropdown;
          const regionCapability = element.region + "-" + element.capabilityId;
          const marketDropdown = markets[regionCapability] || [];
          if (!marketDropdown.some((o) => o.key == element.marketId.toString()))
            marketDropdown.push({
              key: "" + element.marketId,
              text: element.market,
            });
          if (!allMarkets.some((o) => o.key == element.marketId.toString()))
            allMarkets.push({
              key: "" + element.marketId,
              text: element.market,
            });
          markets[regionCapability] = marketDropdown;
          const regionCapabilityMarket =
            element.region +
            "-" +
            element.capabilityId +
            "-" +
            element.marketId;
          const subCatgoryDropdown =
            subCategories[regionCapabilityMarket] || [];
          if (
            !subCatgoryDropdown.some(
              (o) => o.key == element.subCategoryId.toString()
            )
          )
            subCatgoryDropdown.push({
              key: "" + element.subCategoryId,
              text: element.subCategory,
            });
          if (
            !allSubCategories.some(
              (o) => o.key == element.subCategoryId.toString()
            )
          )
            allSubCategories.push({
              key: "" + element.subCategoryId,
              text: element.subCategory,
            });
          subCategories[regionCapabilityMarket] = subCatgoryDropdown;
        });
      }
      const qreCapabilityDetails: IQreCapabilityDetail = {
        regions: regions,
        capabilities: capabilities,
        allCapabilities: allCapabilities,
        markets: markets,
        allMarkets: allMarkets,
        subCategories: subCategories,
        allSubCategories: allSubCategories,
      };
      localStorage.setItem(
        QREConstants.QRE_CAPABILITY_DETAIL,
        JSON.stringify(qreCapabilityDetails)
      );
      return {
        ...state,
        regions: regions,
        capabilities: capabilities,
        allCapabilities: allCapabilities,
        markets: markets,
        allMarkets: allMarkets,
        subCategories: subCategories,
        allSubCategories: allSubCategories,
      };
    //break;
    case actionTypes.FETCH_QRE_CAPABILITY_DETAIL_FROM_LOCAL_STORAGE:
      const capabilityDetails: IQreCapabilityDetail = action.payload;
      return {
        ...state,
        regions: capabilityDetails.regions,
        capabilities: capabilityDetails.capabilities,
        allCapabilities: capabilityDetails.allCapabilities,
        markets: capabilityDetails.markets,
        allMarkets: capabilityDetails.allMarkets,
        subCategories: capabilityDetails.subCategories,
        allSubCategories: capabilityDetails.allSubCategories,
      };

    default:
      return state || {};
  }
};

export const UpdateQreReducer: Reducer<IUpdateQreState> = (
  state: IUpdateQreState = DefaultUpdateQreState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.ON_LOAD_QRE_DATA:
      const data: IUpdateQreState = action.payload.data;
      const region = action.payload.region;
      const capabilityId = action.payload.capabilityId;
      const UPDATE_QRE_LOCAL_STORAGE = "qreUpdateData";
      const keywords = data
        ? action.payload.data.keywords
          ? action.payload.data.keywords.map((k) => k.keywordName)
          : []
        : [];
      data.keywords = [...keywords];
      data.region = region;
      data.capabilityId = capabilityId;
      //localStorage.setItem(UPDATE_QRE_LOCAL_STORAGE, JSON.stringify(data));
      return {
        ...state,
        region: data.region,
        capabilityId: data.capabilityId,
        listName: data.listName,
        marketId: data.marketId,
        subCategoryId: data.subCategoryId,
        scheduledStartDate: data.scheduledStartDate,
        scheduledEndDate: data.scheduledEndDate,
        listId: data.listId,
        keywords: keywords,
        status: data.status,
        modifiedBy: data.modifiedBy,
      };
    default:
      return state || {};
  }
};
