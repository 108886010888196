import { MessageBarType } from "@fluentui/react";
import { PaginationShortcuts } from "../../components/common/table/pagination/Pagination.types";
import { FilterShortcuts } from "../../components/common/table/table-command-bar/plugins/filters/Filters.types";
import { ReviewPackageShortcuts } from "../../components/review-package/ReviewPackageTable.types";
import { FlagAreaType, LoadState } from "@trinity/enums";

// Keep adding shortcuts here separated by pipe symbol
export type ShortcutTypes =
  | PaginationShortcuts
  | FilterShortcuts
  | ReviewPackageShortcuts;

export interface ICommonState {
  loadState: LoadState;
  messageBox: IMessageBoxState;
  constants: IConstantsState;
  flaConstantsLoadState: LoadState;
  flaConstants: IFLAConstantsState;
  shortcutType: ShortcutTypes | undefined;
}
// export interface IAlgoOption {
//   key: string;
//   text: string;
// }
export interface IMessageBoxState {
  message?: string | undefined;
  type?: MessageBarType | undefined;
  actions?: JSX.Element;
  children?: JSX.Element | undefined;
}

export interface IConstantsState {
  country: Record<number, Country>;
  distributionChannel: Record<string, DistributionChannel>;
  language: Record<number, Language>;
  flagAreaCategories: any;
  flagAreaDictionary: Record<string, Flag>;
  languageCountryMappings: LanguageCountryMappings;
  globalizationEnabledCountries: Record<number, Country>;
  LMCountryList: Record<number, Country>;
  LMLanguageList: Record<number, Language>;
  flag: Record<string, LMFlag>;
  MatchTypeAndComponentMask: Record<number, MatchTypeAndComponentMask>;
}

export interface IPolicyBlurbDetails {
  policyBlurb: string;
  policyText: string;
  policyLink: string;
}

export interface IReasonCodeDetails {
  reasonCode: string;
  templateId: string;
  policyBlurbId: number;
  noteForReviewer: string;
}

export interface ITempplateDetails {
  name: string;
}

export interface IFLAConstantsState {
  reasonCodeFilter: {
    [entityType: string]: {
      [option: string]: {
        [decision: string]: {
          [impactType: string]: {
            [policyCategory: string]: {
              [policy: string]: string[]; //Reason codes list
            };
          };
        };
      };
    };
  };
  policyBlurbFilter: {
    [entityType: string]: {
      [option: string]: {
        [decision: string]: {
          [impactType: string]: {
            [policyCategory: string]: {
              [policy: string]: string[]; //Policy blurb ids
            };
          };
        };
      };
    };
  };
  policyBlurbDetails: {
    [policyBlurbId: string]: IPolicyBlurbDetails;
  };
  reasonCodeDetails: {
    [reasonCodeId: string]: IReasonCodeDetails;
  };
  templateDetails: {
    [templateId: string]: ITempplateDetails;
  };
  transactionTypesFilter: {
    [entityType: string]: {
      [option: string]: [
        {
          [transactionType: string]: {
            isTrackingGuidMandatory: boolean;
          };
        }
      ];
    };
  };
  algoData: Record<number, string>;
}

export interface TransactionTypes {
  Customer: TransactionTypeDetails[];
  Account: TransactionTypeDetails[];
  Store: TransactionTypeDetails[];
}

export interface TransactionTypeDetails {
  transactionTypeId: number;
  entity: number;
  transactionType: string;
  transactionIdType: string;
  isTransactionIdMandatory: boolean;
  fraudDetectionMode: number;
  isActive: boolean;
}

export interface Language {
  value: number;
  code: string;
  name: string;
}

export interface Flag {
  constraintComponentMaskList: string;
  constraintComponentMask: string;
  type: FlagAreaType;
  id: number;
  link: string;
  name: string;
}

export interface LMFlag {
  area: string;
  components: string;
  flagAreaId: number;
  flagLevel: number;
  id: number;
  name: string;
}

export interface Country {
  value: number;
  code: string;
  name: string;
}

export interface DistributionChannel {
  languageCode: string;
  countryCode: string;
  distChanId: string;
  distChanCode: string;
  lcid: string;
}

export interface LanguageCountryMappings {
  countryMappingList: Array<CountryMappingList>;
  languageMappingList: Array<LanguageMappingList>;
}

export interface CountryMappingList {
  key: string;
  value: string;
}

export interface LanguageMappingList {
  key: string;
  value: string;
}

export const INITIAL_STATE: ICommonState = {
  loadState: LoadState.NotLoaded,
  messageBox: {} as IMessageBoxState,
  constants: {} as IConstantsState,
  flaConstantsLoadState: LoadState.NotLoaded,
  flaConstants: {
    reasonCodeFilter: {},
    policyBlurbFilter: {},
    transactionTypesFilter: {},
    policyBlurbDetails: {},
    reasonCodeDetails: {},
    templateDetails: {},
    algoData: [],
  } as IFLAConstantsState,
  shortcutType: undefined,
};

export interface IUserActionPayload {
  /** Unique id for each user action*/
  id: string;
  /** User id of the user who caused the action*/
  userId: number | null;
  /** Type of user action*/
  type: string;
  /** Extra information related to the type of user action*/
  extraInfo: any;
  /** Timestamp at which the user did the action*/
  dateTime: string;
}

export interface MatchTypeAndComponentMask {
  components: string;
  constraintComponentMask: number;
  matchType: string;
  matchTypeId: number;
}
