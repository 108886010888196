import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store/user/UserActions";
import { ISpinnerStyles, Spinner, SpinnerSize } from "@fluentui/react";
import AuthenticationError from "./AuthenticationError";
import { authInstance } from "./AuthModule";
import { IUserActionPayload } from "../../store/common/CommonState";
import { actionCreators as commonActions } from "../../store/common/CommonActions";

import { v4 as uuidv4 } from "uuid";
import { UserActionTypes } from "@trinity/enums";

function AuthenticationWrapper(props) {
  const dispatch = useDispatch<any>();

  const payload: IUserActionPayload = {
    id: uuidv4(),
    userId: null,
    type: UserActionTypes.AuthStarted,
    dateTime: new Date().toISOString(),
    extraInfo: "",
  };

  dispatch(commonActions.postUserAction([payload]));

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // Once user is authenticated, get user deatils for authorization.
    if (isAuthenticated) {
      // gets account details and stores it in authModule instance, later used for acquiring tokens silently
      authInstance.getAccount();
      dispatch(actionCreators.getUserDetails());
    }

    return;
  }, [isAuthenticated]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authInstance.loginRedirectRequest}
      errorComponent={AuthenticationError}
      loadingComponent={LoadingComponent}
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
}

export default AuthenticationWrapper;

function LoadingComponent() {
  const spinnerStyles: ISpinnerStyles = {
    root: [
      {
        height: "100vh",
      },
    ],
  };
  return (
    <Spinner
      label="Authenticating..."
      styles={spinnerStyles}
      size={SpinnerSize.large}
    />
  );
}
