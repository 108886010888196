import { INavLink } from "@fluentui/react";

export interface IWorkspaceConfig {
  name: string;
  key: string;
  icon: string;
  default: string;
  items: INavLink[];
  isSelected?: false;
}

export const workspaceConfig: Array<IWorkspaceConfig> = [
  {
    name: "Reviewer Workspace",
    key: "reviewer",
    icon: "DefaultApp",
    default: "/overview",
    items: [
      {
        name: "Overview",
        key: "overview",
        url: "",
        link: "/overview",
        icon: "ViewDashboard",
        ariaLabel: "Overview",
      },
      {
        name: "Review Package",
        key: "review-package",
        url: "",
        link: "/review-package",
        icon: "BulletedList2",
        ariaLabel: "Review Package",
      },
    ],
  },
  {
    name: "Escalations",
    key: "escalations",
    default: "",
    icon: "UpgradeAnalysis",
    items: [
      {
        name: "Escalations",
        key: "",
        url: "",
        link: "",
        icon: "FileSymlink",
        ariaLabel: "Escalations",
      },
      {
        name: "Job Status",
        key: "job-status",
        url: "",
        link: "/job-status",
        icon: "BulletedList",
        ariaLabel: "Job Status",
      },
    ],
  },
  {
    name: "List Management Workspace",
    key: "listmanager",
    icon: "BuildQueue",
    default: "/seed-token-discovery",
    items: [
      {
        name: "Seed Token Discovery",
        key: "seed-token-discovery",
        url: "",
        link: "/seed-token-discovery",
        icon: "SmartRule",
        ariaLabel: "Seed Token Discovery",
      },
      {
        name: "Job Status",
        key: "seed-token-job-status",
        url: "",
        link: "/seed-token-job-status",
        icon: "BulletedList2",
        ariaLabel: "Job Status",
      },
      {
        name: "Normalized Token Check",
        key: "normalized-token-check",
        url: "",
        link: "/normalized-token-check",
        icon: "NormalisedTokenCheck",
        ariaLabel: "Normalized Token Check",
      },
      {
        name: "Word Breaker",
        key: "word-breaker",
        url: "",
        link: "/word-breaker",
        icon: "NormalisedTokenCheck",
        ariaLabel: "Word Breaker",
      },
      {
        name: "Add New Trademark",
        key: "createTrademark",
        url: "",
        link: "/createTrademark",
        icon: "Add",
        ariaLabel: "Create Trademark",
      },
      {
        name: "Search Trademark",
        key: "searchTrademark",
        url: "",
        link: "/searchTrademark",
        icon: "Search",
        ariaLabel: "Search Trademark",
      },
      {
        name: "Add New DSA Block",
        key: "createDSABlock",
        url: "",
        link: "/createDsaBlock",
        icon: "NewAnalyticsQuery",
        ariaLabel: "Create DSA Block",
      },
      {
        name: "Search DSA Block",
        key: "searchDSABlock",
        url: "",
        link: "/searchDsaBlock",
        icon: "SearchData",
        ariaLabel: "Search DSA Block",
      },
      {
        name: "Add New PLA Block",
        key: "createPLABlock",
        url: "",
        link: "/createPLABlock",
        icon: "AddPLABlock",
        ariaLabel: "Create PLA Block",
      },
      {
        name: "Search PLA Block",
        key: "searchPLABlock",
        url: "",
        link: "/searchPlaBlock",
        icon: "SearchPLA",
        ariaLabel: "Search PLA Block",
      },
      {
        name: "Add New Entity List",
        key: "createEntityList",
        url: "",
        link: "/createEntityList",
        icon: "AddMedium",
        ariaLabel: "Create Entity List",
      },
      {
        name: "Search Entity List",
        key: "searchEntityList",
        url: "",
        link: "/searchEntityList",
        icon: "DocumentSearch",
        ariaLabel: "Search Entity List",
      },
    ],
  },
  {
    name: "Risk Trust and Safety Workspace",
    key: "tns",
    icon: "FLALink",
    default: "/explore",
    items: [
      {
        name: "Explore",
        key: "explore",
        url: "",
        icon: "FLAExplore",
        link: "/explore",
        ariaLabel: "Overview",
      },
    ],
  },
  {
    name: "Risk Enforcement Workspace",
    key: "enforcement",
    icon: "Shield",
    default: "/entity-enforcement",
    items: [
      {
        name: "Enforcement",
        key: "enforcement",
        url: "",
        link: "/entity-enforcement",
        icon: "SecurityGroup",
        ariaLabel: "Enforcement",
      },
      {
        name: "Enforcement History",
        key: "enforcementHistory",
        url: "",
        link: "/entity-enforcement-history",
        icon: "History",
        ariaLabel: "Enforcement History",
      },
      // {
      //   name: "Detection History",
      //   key: "detectionHistory",
      //   url: "",
      //   link: "/entity-detection-history",
      //   icon: "ExploreData",
      //   ariaLabel: "Detection History",
      // },
    ],
  },
  {
    name: "Administrator Workspace",
    key: "admin",
    icon: "LocalAdmin",
    default: "/overview",
    items: [
      {
        name: "Overview",
        key: "overview",
        url: "",
        icon: "ViewDashboard",
        link: "/overview",
        ariaLabel: "Overview",
      },
      {
        name: "Queue Management",
        key: "queue-management",
        url: "",
        link: "/queue-management",
        icon: "Queues",
        ariaLabel: "Queue Management",
      },
      {
        name: "Mismatch Report",
        key: "mismatch",
        url: "",
        link: "/mismatch",
        icon: "ReportAlert",
        ariaLabel: "Mismatch Report",
      },
      {
        name: "Rejection Report",
        key: "rejection",
        url: "",
        link: "/rejection",
        icon: "ReportWarning",
        ariaLabel: "Rejection Report",
      },
      {
        name: "QRE Reviews Report",
        key: "qre-reviews",
        url: "",
        link: "/qre-reviews",
        icon: "ReportInfo",
        ariaLabel: "QRE Reviews Report",
      },
      {
        name: "User Management",
        key: "user-management",
        url: "",
        link: "/user-management",
        icon: "PlayerSettings",
        ariaLabel: "User Management",
      },
    ],
  },
  {
    name: "QRE Workspace",
    key: "qre",
    icon: "forward",
    default: "/createQre",
    items: [
      {
        name: "Add New Qre",
        key: "createQre",
        url: "",
        link: "/createQre",
        icon: "Add",
        ariaLabel: "Create Qre",
      },
      {
        name: "Search Qre",
        key: "searchQre",
        url: "",
        link: "/searchQre",
        icon: "Search",
        ariaLabel: "Search Qre",
      },
    ],
  },
  {
    name: "Publisher Workspace",
    key: "publisher",
    icon: "Publisher",
    default: "/createRule",
    items: [
      {
        name: "Add New Publisher Rule",
        key: "createRule",
        url: "",
        link: "/createRule",
        icon: "Add",
        ariaLabel: "Create Publisher Rule",
      },
      {
        name: "Search Publisher Rule",
        key: "searchRule",
        url: "",
        link: "/searchRule",
        icon: "Search",
        ariaLabel: "Search Publisher Rule",
      },
    ],
  },
  {
    name: "Rule Management Workspace",
    key: "rule",
    icon: "CreateMainRule",
    default: "/createRule",
    items: [
      {
        name: "Add New Rule",
        key: "createRule",
        url: "",
        link: "/createRule",
        icon: "Add",
        ariaLabel: "Create  Rule",
      },
      {
        name: "Search Rule",
        key: "searchRule",
        url: "",
        link: "/searchRule",
        icon: "Search",
        ariaLabel: "Search Rule",
      },
      {
        name: "Add New URL",
        key: "createUrl",
        url: "",
        link: "/createUrl",
        icon: "AddLink",
        ariaLabel: "Create  URL",
      },
      {
        name: "Search URL",
        key: "searchUrl",
        url: "",
        link: "/searchUrl",
        icon: "Link12",
        ariaLabel: "Search Url",
      },
    ],
  },
  {
    name: "Exploratory Workspace",
    key: "editorial",
    icon: "forward",
    default: "/entityTracker",
    items: [
      {
        name: "Entity Tracker",
        key: "entitytracker",
        url: "",
        link: "/entityTracker",
        icon: "Search",
        ariaLabel: "Entity Tracker",
      },
    ],
  },
  {
    name: "Approval Workspace",
    key: "approval",
    icon: "NormalisedTokenCheck",
    default: "",
    items: [
      {
        name: "Approval Workspace",
        key: "approval",
        url: "",
        link: "",
        icon: "NormalisedTokenCheck",
        ariaLabel: "Approval Workspace",
      },
    ],
  },
];
