import {
  getTheme,
  IButtonStyles,
  ICommandBarStyles,
  IPersonaStyles,
  IStyle,
  selectProperties,
} from "@fluentui/react";
import { Sizes } from "@trinity/common";

export const getStyles = () => {
  const theme = getTheme();

  const commandBarStyles: ICommandBarStyles = {
    root: [
      "trinity-Header",
      {
        padding: 0,
        height: Sizes.headerHeight,
        backgroundColor: theme.palette.neutralPrimary,

        selectors: {
          ".app-name": {
            fontSize: theme.fonts.xLarge.fontSize,
            fontWeight: theme.fonts.mediumPlus.fontWeight,
            backgroundColor: theme.palette.neutralPrimary,
            color: theme.palette.white,

            selectors: {
              ":hover, :active": {
                backgroundColor: theme.palette.neutralPrimary,
                color: theme.palette.white,
              },
            },
          },
          ".workspace-name": {
            backgroundColor: theme.palette.neutralPrimary,
            color: theme.palette.white,
            borderLeft: "1px solid grey",
            paddingLeft: Sizes.paddingDefault,
            margin: Sizes.marginSmall,
            lineHeight: 28,
          },
          ".workspace-switcher": {
            backgroundColor: theme.palette.neutralPrimary,
            color: theme.palette.white,
            width: 48,

            selectors: {
              ":hover": {
                backgroundColor: theme.palette.blue,
              },
              ":active": {
                backgroundColor: theme.palette.neutralLighterAlt,
              },
              i: {
                color: theme.palette.white,
                fontSize: 24,
              },
              ":hover i": {
                color: theme.palette.white,
              },
              ":active i": {
                color: theme.palette.neutralPrimary,
              },
            },
          },
        },
      },
    ],
  };

  const commonHeaderItemStyles: IStyle = {
    height: "100%",
    border: "none",
    borderRadius: 0,
    minWidth: 48,
  };

  const headerItemStyles: IButtonStyles = {
    root: [
      "trinity-Header-Item",
      {
        ...commonHeaderItemStyles,
        backgroundColor: theme.palette.neutralPrimary,
        color: theme.palette.white,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.neutralPrimary,
          },
        },
      },
    ],
    rootHovered: [
      {
        backgroundColor: theme.palette.blue,
        color: theme.palette.white,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.blue,
          },
        },
      },
    ],
    rootPressed: [
      {
        backgroundColor: theme.palette.blue,
        color: theme.palette.white,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.blue,
          },
        },
      },
    ],
  };

  const headerItemSelectedStyles: IButtonStyles = {
    root: [
      "trinity-Header-Item",
      "selected",
      {
        ...commonHeaderItemStyles,
        backgroundColor: theme.palette.neutralLighterAlt,
        color: theme.palette.neutralPrimary,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.neutralLighterAlt,
          },
        },
      },
    ],
    rootHovered: [
      {
        backgroundColor: theme.palette.neutralLighterAlt,
        color: theme.palette.neutralPrimary,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.neutralLighterAlt,
          },
        },
      },
    ],
    rootPressed: [
      {
        backgroundColor: theme.palette.neutralLighterAlt,
        color: theme.palette.neutralPrimary,

        selectors: {
          ".ms-Persona-presence": {
            borderColor: theme.palette.neutralLighterAlt,
          },
        },
      },
    ],
  };

  const personaSmallStyles: Partial<IPersonaStyles> = {
    details: [
      {
        display: "none",
      },
    ],
  };

  return {
    commandBar: commandBarStyles,
    headerItem: headerItemStyles,
    headerItemSelected: headerItemSelectedStyles,
    personaSmall: personaSmallStyles,
  };
};
