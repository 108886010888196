import { IReviewPackageItem } from "../../store/review-package/ReviewPackageState";
import { ITableColumn, ITableProps } from "../common/table/Table.types";
import { RendererType } from "./renderers/Renderer.types";
import {
  IAlternateLabels,
  ISubmitPanelDetails,
  ITableDetails,
} from "./ReviewPackage.types";

export interface IReviewPackageTableProps extends ITableProps {
  columns: IReviewPackageColumn[];
}

export interface IReviewPackageColumn extends ITableColumn {
  rendererType?: RendererType;
  additionalInfo?: Record<string, any>;
  onOpenIconClick?: (item: IReviewPackageItem, name?: string) => void;
}

export enum CustomFilterQueryTypes {
  DECISION_UPHOLD_REJECTION,
  DECISION_OVERTURN_REJECTION,
}

export enum Decision {
  TOREVIEW = 0,
  UPHOLDREJECTION = 1,
  OVERTURNREJECTION = 5,
  APPEAL_V2 = -1,
}

export enum ReviewPackageShortcuts {
  OVERTURN_REJECTION = "OVERTURN_REJECTION",
  UPHOLD_REJECTION = "UPHOLD_REJECTION",
  SUBMIT = "SUBMIT",
}

export interface IReviewPackageTable {
  tableDetails: ITableDetails;
  alternateLabels: IAlternateLabels;
  submitPanelDetails: ISubmitPanelDetails;
}
