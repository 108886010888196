import { HeaderItems } from "@trinity/enums";

export enum actionTypes {
  HEADER_ITEM_OPENED = "HEADER_ITEM_OPENED",
  HEADER_ITEM_CLOSED = "HEADER_ITEM_CLOSED",
  OPEN_WORKSPACES = "OPEN_WORKSPACES",
  CLOSE_WORKSPACES = "CLOSE_WORKSPACES",
  SET_WORKSPACE = "SET_WORKSPACE",
}

export const actionCreators = {
  openHeaderItem: (key: HeaderItems, title?: string) => (dispatch: any) => {
    return dispatch({
      type: actionTypes.HEADER_ITEM_OPENED,
      payload: { selectedItemKey: key, title: title },
    });
  },
  closeHeaderPanel: () => (dispatch: any) => {
    return dispatch({
      type: actionTypes.HEADER_ITEM_CLOSED,
      payload: "",
    });
  },
  openWorkspaces: () => (dispatch: any) => {
    return dispatch({
      type: actionTypes.OPEN_WORKSPACES,
      payload: "",
    });
  },
  closeWorkspaces: () => (dispatch: any) => {
    return dispatch({
      type: actionTypes.CLOSE_WORKSPACES,
      payload: "",
    });
  },
  setWorkspace: (workspace) => (dispatch: any) => {
    return dispatch({
      type: actionTypes.SET_WORKSPACE,
      payload: workspace,
    });
  },
};
