import useKeyboardShortcut from "./hooks/useKeyboardShortcut";
import { actionCreators as commonActions } from "../../../src/store/common/CommonActions";
import { FilterShortcuts } from "../../../src/components/common/table/table-command-bar/plugins/filters/Filters.types";
import { PaginationShortcuts } from "../../../src/components/common/table/pagination/Pagination.types";
import { ReviewPackageShortcuts } from "../../../src/components/review-package/ReviewPackageTable.types";

export default function initializeKeyboardShortcuts(dispatch: any) {
  useKeyboardShortcut(["p", "n"], () => {
    dispatch(
      commonActions.keyboardShortcut(PaginationShortcuts.PAGINATION_NEXT_PAGE)
    );
  });

  useKeyboardShortcut(["p", "l"], () => {
    dispatch(
      commonActions.keyboardShortcut(
        PaginationShortcuts.PAGINATION_PREVIOUS_PAGE
      )
    );
  });

  useKeyboardShortcut(["s", "u"], () => {
    dispatch(commonActions.keyboardShortcut(ReviewPackageShortcuts.SUBMIT));
  });

  useKeyboardShortcut(["f", "l"], () => {
    dispatch(commonActions.keyboardShortcut(FilterShortcuts.FILTER_ADD));
  });

  useKeyboardShortcut(["f", "x"], () => {
    dispatch(commonActions.keyboardShortcut(FilterShortcuts.FILTER_RESET));
  });

  useKeyboardShortcut(["o", "f"], () => {
    dispatch(
      commonActions.keyboardShortcut(ReviewPackageShortcuts.OVERTURN_REJECTION)
    );
  });

  useKeyboardShortcut(["u", "r"], () => {
    dispatch(
      commonActions.keyboardShortcut(ReviewPackageShortcuts.UPHOLD_REJECTION)
    );
  });
}
