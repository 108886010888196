import { registerIcons } from "@fluentui/react";

registerIcons({
  fontFace: {
    fontFamily: "MsAdsMDL2",
    src: `url('/fonts/MsAdsMDL2.eot?#iefix') format('embedded-opentype'),
    url("/fonts/MsAdsMDL2.woff")`,
  },
  icons: {
    Waffle: "\uED89",
    Cancel: "\uE711",
    GlobalNavButton: "\uE700",
    DefaultApp: "\uE42A",
    BuildQueue: "\uF24F",
    LocalAdmin: "\uF1FB",
    Flag: "\uE7C1",
    SkipTab: "\uED1B",
    DecisionSolid: "\uF350",
    Assign: "\uE9D3",
    SwitcherStartEnd: "\uE810",
    Add: "\uE710",
    Edit: "\uE70F",
    Repair: "\uE90F",
    Ringer: "\uEA8F",
    Articles: "\uEAC1",
    HourGlass: "\uEA03",
    LocationDot: "\uE827",
    ViewDashboard: "\uF246",
    BulletedList2: "\uF2C7",
    Queues: "\uEFC0",
    PlayerSettings: "\uEF58",
    Settings: "\uE713",
    Sad: "\uE757",
    Checkbox: "\uE739",
    CheckMark: "\uE73E",
    ChevronUpMed: "\uE971",
    ChevronDownMed: "\uE972",
    IncidentTriangle: "\uE814",
    Unknown: "\uE9CE",
    ChevronLeft: "\uE76B",
    ChevronLeftSmall: "\uE96F",
    ChevronRight: "\uE76C",
    ChevronRightSmall: "\uE970",
    Search: "\uE721",
    ChevronDown: "\uE70D",
    SortUp: "\uEE68",
    SortDown: "\uEE69",
    Remove: "\uE738",
    Clear: "\uE711",
    Forward: "\uE72A",
    OpenInNewWindow: "\uE8A7",
    ErrorBadge: "\uEA39",
    Info: "\uE946",
    Completed: "\uE930",
    ChevronRightMed: "\uE974",
    Sort: "\uE8CB",
    Priority: "\uE8D0",
    TripleColumn: "\uF1D5",
    Save: "\uE74E",
    Refresh: "\uE72C",
    FilterPipe: "\uE71C",
    Accept: "\uE8FB",
    Translation: "\uE7B2",
    Play: "\uE768",
    Pause: "\uE769",
    FLALink: "\uE7EF",
    FLAExplore: "\uE721",
    FLAInsights: "\uE9EC",
    FLAReview: "\uE773",
    FLARuleManager: "\uE7B6",
    Close: "\uE8BB",
    PieSingle: "\uEB05",
    GraphSymbol: "\uE35D",
    SearchEditor: "\uF297",
    BulletedList: "\uE8FD",
    User: "\uE77B",
    Delete: "\uE74D",
    Load: "\uE896",
    Mail: "\uE715",
    NumberSymbol: "\uF7AC",
    Domain: "\uE3FE",
    PaymentCard: "\uE8C7",
    More: "\uE712",
    Tag: "\uE8EC",
    Copy: "\uE8C8",
    UserWindow: "\uE4ED",
    History: "\uE81C",
    Back: "\uE72B",
    OpenPane: "\uE8A0",
    TripleColumnEdit: "\uF323",
    Shield: "\uEA18",
    ShieldAlert: "\uF7D7",
    Clock: "\uE917",
    Eye: "\uE890",
    UpgradeAnalysis: "\uEA0B",
    FileSymlink: "\uF312",
    DownloadDocument: "\uF549",
    Upload: "\uE898",
    Leave: "\uF627",
    Download: "\uE896",
    SmartRule: "\uE369",
    DoubleChevronRight8: "\uF36E",
    NormalisedTokenCheck: "\uE310",
    ReportAlert: "\uF722",
    ReportWarning: "\uF569",
    ReportInfo: "\uE8FD",
    Message: "\uE8BD",
    Up: "\uE74A",
    Down: "\uE74B",
    SearchData: "\uF3F1",
    NewAnalyticsQuery: "\uF1E0",
    SearchPLA: "\uEEF5",
    AddPLABlock: "\uE4C7",
    Publisher: "\uF3A0",
    AddMedium: "\uECA1",
    DocumentSearch: "\uEF6C",
    AddLink: "\uE35E",
    Link12: "\uF6E3",
    CreateMainRule: "\uE4C2",
    BuildQueueAdd: "\uF250",
    SecurityGroup: "\uED85",
    Calendar: "\uE787",
    Contact: "\uE77B",
    CaretDownSolid8: "\uEDDC",
    CaretUpSolid8: "\uEDDB",
    ExploreData: "\uF5B6",
    AccountManagement: "\uF55C",
  },
});
