import { getTheme, INavStyles } from "@fluentui/react";

export const getStyles = () => {
  const theme = getTheme();

  const leftnavStyles = {
    root: [
      {
        backgroundColor: theme.palette.neutralLighter,
        borderRight: "1px solid " + theme.palette.neutralTertiaryAlt,
        minHeight: "100vh",

        selectors: {
          "&.collapsed": {
            width: 48,
          },
          ".toggle-menu": {
            borderBottom: "1px solid " + theme.palette.neutralTertiaryAlt,

            selectors: {
              button: {
                width: 47,
                height: 44,

                selectors: {
                  i: {
                    color: theme.palette.neutralPrimary,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  const navStyles: Partial<INavStyles> = {
    root: [
      {
        width: 200,
        overflow: "hidden",

        selectors: {
          "&.collapsed": {
            width: 47,
          },
          ".ms-Nav-compositeLink:hover .ms-Button, .ms-Nav-compositeLink:hover .ms-Nav-chevronButton": {
            backgroundColor: theme.palette.neutralLight,
          },
        },
      },
    ],
    navItems: [
      {
        margin: 0,
      },
    ],
    compositeLink: [
      {
        selectors: {
          "&.is-selected .ms-Button, &:hover .ms-Button": {
            backgroundColor: theme.palette.white,
            color: theme.palette.neutralPrimary,
          },
        },
      },
    ],
    link: [
      {
        backgroundColor: theme.palette.neutralLighter,
        height: 45,
        fontWeight: 600,
        selectors: {
          ".ms-Button-icon, &:hover .ms-Button-icon, &:active .ms-Button-icon, &:hover:active .ms-Button-icon": {
            color: theme.palette.neutralPrimary,
          },
          i: {
            marginLeft: 10,
          },
          "::after": {
            margin: "5px 0",
          },
        },
      },
    ],
    chevronButton: {
      left: 170,
    },
  };

  return {
    nav: navStyles,
    leftnav: leftnavStyles,
  };
};
