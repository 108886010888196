import { IDropdownOption } from "@fluentui/react";
import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { Operator } from "@trinity/enums";

export enum actionTypes {
  INSERT_NEW_PLA_BLOCK = "INSERT_NEW_PLA_BLOCK",
  INSERT_NEW_PLA_BLOCK_FAILED = "INSERT_NEW_PLA_BLOCK_FAILED",
  IMPORT_PLA_BLOCK = "IMPORT_PLA_BLOCK",
  IMPORT_PLA_BLOCK_FAILED = "IMPORT_PLA_BLOCK_FAILED",
  SEARCH_PLA_BLOCK = "SEARCH_PLA_BLOCK",
  SEARCH_PLA_BLOCK_FAILED = "SEARCH_PLA_BLOCK_FAILED",
  EXPORT_PLA_BLOCK = "EXPORT_PLA_BLOCK",
  EXPORT_PLA_BLOCK_FAILED = "EXPORT_PLA_BLOCK_FAILED",
  CLEAR_EXPORT_DATA = "CLEAR_EXPORT_DATA",
  SELECTED_PLA_BLOCK = "SELECTED_PLA_BLOCK",
  SELECTED_PLA_BLOCK_FAILED = "SELECTED_PLA_BLOCK_FAILED",
  DELETE_PLA_BLOCK = "DELETE_PLA_BLOCK",
  DELETE_PLA_BLOCK_FAILED = "DELETE_PLA_BLOCK_FAILED",
  BULK_DELETE_PLA_BLOCK = "BULK_DELETE_PLA_BLOCK",
  BULK_DELETE_PLA_BLOCK_FAILED = "BULK_DELETE_PLA_BLOCK_FAILED",
  ON_LOAD_PLA_BLOCK_DATA = "ON_LOAD_PLA_BLOCK_DATA",
}

export interface ISearchRequest {
  fieldName: string;
  operator: string;
  value: string;
}

export interface ISearchPlaBlockItem extends ITableRowItemProps {
  PLAListItemID: number;
  OfferTitle: string;
  ImageUrl: string;
  SellerName: string;
  rowType: number;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export interface ISearchPlaBlockDetails {
  exportData: ISearchPlaBlockDetail[];
  searchResults: ISearchPlaBlockDetail[];
}

export interface ISearchPlaBlockDetail {
  PLAListItemID: number;
  OfferTitle: string;
  ImageUrl: string;
  SellerName: string;
  rowType: number;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export interface ImportDataDetails {
  Action: string;
  PLAListItemID: number;
  OfferTitle: string;
  ImageUrl: string;
  SellerName: string;
  rowType: number;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export interface IDeletePlaBlockState {
  PLAListItemID: number;
  OfferTitle: string;
  ImageUrl: string;
  SellerName: string;
  rowType: number;
  rowDateCreated: Date;
  rowDateModified: Date;
  ModifiedByUserId: number;
}

export const DefaultImportDataDetails: ImportDataDetails = {
  Action: "",
  PLAListItemID: 0,
  OfferTitle: "",
  ImageUrl: "",
  SellerName: "",
  rowType: -1,
  rowDateCreated: new Date(),
  rowDateModified: new Date(),
  ModifiedByUserId: -1,
};

export const DefaultSearchPlaBlockDetailsState: ISearchPlaBlockDetails = {
  exportData: [],
  searchResults: [],
};

export const DefaultSearchPlaBlockState: ISearchPlaBlockDetail = {
  PLAListItemID: 0,
  OfferTitle: "",
  ImageUrl: "",
  SellerName: "",
  rowType: -1,
  rowDateCreated: new Date(),
  rowDateModified: new Date(),
  ModifiedByUserId: -1,
};

export const OperatorMap = {
  checkbox: Operator.EQUALS,
  contains: Operator.CONTAINS,
};
