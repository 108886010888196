import React from "react";
import { useDispatch } from "react-redux";
import { actionCreators as commonActions } from "../../../../src/store/common/CommonActions";

const blacklistedTargets = ["INPUT", "TEXTAREA"];

export enum actionTypes {
  SET_KEY_DOWN = "SET_KEY_DOWN",
  SET_KEY_UP = "SET_KEY_UP",
  RESET_KEYS = "RESET_KEYS",
}

const keysReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_KEY_DOWN:
      return { ...state, [action.key]: true };

    case actionTypes.SET_KEY_UP:
      return { ...state, [action.key]: false };

    case actionTypes.RESET_KEYS:
      return { ...action.data };

    default:
      return state;
  }
};

const useKeyboardShortcut = (shortcutKeys, callback) => {
  if (!Array.isArray(shortcutKeys)) return;
  if (!shortcutKeys.length) return;
  if (!callback || typeof callback !== "function") return;

  const initialKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
    currentKeys[key.toLowerCase()] = false;
    return currentKeys;
  }, {});

  const dispatch = useDispatch<any>();
  const [keys, setKeys] = React.useReducer(keysReducer, initialKeyMapping);

  const keydownListener = React.useCallback(
    (assignedKey) => (keydownEvent) => {
      const loweredKey = assignedKey.toLowerCase();

      if (keydownEvent.repeat) return;
      if (blacklistedTargets.includes(keydownEvent.target.tagName)) return;
      if (loweredKey !== keydownEvent.key.toLowerCase()) return;
      if (keys[loweredKey] === undefined) return;

      setKeys({ type: actionTypes.SET_KEY_DOWN, key: loweredKey });
      return false;
    },
    [keys]
  );

  const keyupListener = React.useCallback(
    (assignedKey) => (keyupEvent) => {
      const raisedKey = assignedKey.toLowerCase();

      if (blacklistedTargets.includes(keyupEvent.target.tagName)) return;
      if (keyupEvent.key.toLowerCase() !== raisedKey) return;
      if (keys[raisedKey] === undefined) return;

      setKeys({ type: actionTypes.SET_KEY_UP, key: raisedKey });
      return false;
    },
    [keys]
  );

  React.useEffect(() => {
    if (!Object.values(keys).filter((value) => !value).length) {
      callback(keys);
      setKeys({ type: actionTypes.RESET_KEYS, data: initialKeyMapping });
    } else {
      setKeys({ type: null });
    }

    return () => {
      dispatch(commonActions.resetKeyboardShortcuts());
    };
  }, [callback, keys]);

  React.useEffect(() => {
    const KEYDOWN = "keydown";
    const KEYUP = "keyup";

    shortcutKeys.forEach((k) =>
      window.addEventListener(KEYDOWN, keydownListener(k))
    );
    shortcutKeys.forEach((k) =>
      window.addEventListener(KEYUP, keyupListener(k))
    );

    return () => {
      shortcutKeys.forEach((k) =>
        window.removeEventListener(KEYDOWN, keydownListener(k))
      );
      shortcutKeys.forEach((k) =>
        window.removeEventListener(KEYUP, keyupListener(k))
      );
    };
  }, []);
};

export default useKeyboardShortcut;
