export enum AdType {
  Text = 1,
  Mobile = 2,
  BannerImage = 4,
  RichMedia = 16,
  ThirdPartyServedAsset = 32,
  RichSearchAd = 64,
  Product = 128,
  CPDAd = 8,
  AppInstall = 256,
  DynamicSearch = 512,
  ExpandedText = 1024,
  ResponsiveAd = 4096,
  ResponsiveSearch = 8192,
}

export enum AdExtensionProperty {
  VideoName = 324,
  VideoDisplayText = 325,
  VideoAltText = 326,
  ActionText = 327,
  VideoUrl = 328,
  VideoId = 329,
  ThumbnailUrl = 331,
  VideoStatus = 330,
  MediaAssetID = 48,
  MediaUrl = 49,
  FinalURL1 = 137,
  MobileUrl1 = 147,
  TrackingTemplate = 125,
  CustomParameterJSON = 294,
  FinalUrlSuffix = 293,
  FlyerName = 321,
  FlyerDescription = 322,
  DisplayText = 1,
  DestURL = 2,
  Country = 3,
  CompanyName = 4,
  BusinessPhone = 5,
  AddressLine1 = 6,
  AddressLine2 = 7,
  PhoneNumber = 16,
  PhoneCountry = 17,
  MapIconAssetId = 19,
  BusinessImageAssetId = 20,
  SocialDestinationURL = 44,
  Description1 = 46,
  Description2 = 47,
  ImageDestinationUrl = 50,
  AltText = 53,
  MaturityRating = 88,
  Text = 97,
  Source = 98,
  Url = 99,
  CallOutText = 100,
  MediaAssetId2 = 113,
  MediaAssetId3 = 114,
  MediaAssetId4 = 115,
  FinalUrl2 = 138,
  FinalUrl3 = 139,
  FinalUrl4 = 140,
  FinalUrl5 = 141,
  FinalUrl6 = 142,
  FinalUrl7 = 143,
  FinalUrl8 = 144,
  FinalUrl9 = 145,
  FinalUrl10 = 146,
  MobileFinalUrl2 = 148,
  MobileFinalUrl3 = 149,
  MobileFinalUrl4 = 150,
  MobileFinalUrl5 = 151,
  MobileFinalUrl6 = 152,
  MobileFinalUrl7 = 153,
  MobileFinalUrl8 = 154,
  MobileFinalUrl9 = 155,
  MobileFinalUrl10 = 156,
  StructuredSnippetCategory = 195,
  StructuredSnippetText1 = 196,
  StructuredSnippetText2 = 197,
  StructuredSnippetText3 = 198,
  StructuredSnippetText4 = 199,
  StructuredSnippetText5 = 200,
  StructuredSnippetText6 = 201,
  StructuredSnippetText7 = 202,
  StructuredSnippetText8 = 203,
  StructuredSnippetText9 = 204,
  StructuredSnippetText10 = 205,
  Header = 211,
  Language = 216,
  CurrencyCode = 266,
  PriceDescription = 281,
  ImageId = 297,
  DisclaimerPopUpText = 302,
  DisclaimerLineText = 303,
  PromotionItem = 305,
  DiscountModifier = 306,
  MoneyAmountOff = 308,
  PromotionCode = 309,
  Occasion = 311,
  PromotionStartDate = 312,
  PromotionEndDate = 313,
  City = 8,
  ProvinceName = 9,
  Zip = 10,
  Latitude = 13,
  Longitude = 14,
  IsExact = 96,
  OrderIds = "OrderIds",
  Description = "Description",
  FinalUrls = "FinalUrls",
  Category = "Category",
  Texts = "Texts",
  MediaURL0 = "MediaURL0",
  MediaURL1 = "MediaURL1",
  MediaURL2 = "MediaURL2",
  MediaURL3 = "MediaURL3",
  MediaURL4 = "MediaURL4",
  MediaURL5 = "MediaURL5",
  MediaURL6 = "MediaURL6",
  MediaURL7 = "MediaURL7",
  MediaURL8 = "MediaURL8",
  ActionType = "ActionType",
  ImageMediaUrls = "ImageMediaUrls",
  Title = "Title",
  PopupText = "PopupText",
  DisclaimerLayout = "DisclaimerLayout",
  BusinessLogo = 352,
  BusinessNameExt = 353,
  DomainName = 354,
  BusinessLogoUrl = "BusinessLogoUrl",
  BusinessNameLogo = "BusinessName",
  TextJson = 317,
  FinalUrlJSON = 318,
}

export enum AdExtensionType {
  AdLabsExtension = -1,
  Unknown = 0,
  SiteLinkExtension = 10,
  LocationExtension = 11,
  CallExtension = 12,
  SocialAdExtension = 34,
  ImageExtension = 35,
  AppExtension = 38,
  ReviewExtension = 39,
  ActionExtension = 40,
  CalloutExtension = 42,
  VideoAdExtension = 46,
  StructuredSnippetExtension = 51,
  FilterLinkExtension = 53,
  FlyerExtension = 54,
  PriceExtension = 55,
  PromotionExtension = 57,
  DisclaimersAdExtension = 60,
  GenericAdExtension = 1000,
  LogoExtension = 63,
}

export enum EntityAttributType {
  Video,
  Image,
  Text,
}

export enum EntityType {
  None = 0,
  Ad = 1,
  AdExtension = 2,
  Destination = 3,
  OrderItem = 4,
  Image = 5,
  Rais = 6,
  Site = 7,
  PA = 8,
  Listing = 9,
  AdLabExtension = 10,
  AIMAd = 11,
  AIMAdImage = 12,
  AIMImageAsset = 13,
  FeedItem = 15,
  SmartListing = 16,
  ImageAsset = 17,
  VideoAsset = 18,
  TextAsset = 19,
  AssetGroupAsset = 20,
  QREPA = 21,
  AssetGroup = 22,
}

export enum MatchTypes {
  ControlBiddingMatch = "Bidding",
  ClassifierOverrideMatch = "ClassifierOverrideMatch",
  Exact = "Exact",
  MLMatch = "ML Match",
  Phrase = "Phrase",
  Sensitive = "Sensitive",
  SpecialCharacter = "Special Character",
}

export enum MatchTypeOptions {
  ControlBiddingMatch = 21,
  ClassifierOverrideMatch = 28,
  Exact = 1,
  MLMatch = 26,
  Phrase = 2,
  Sensitive = 22,
  Smart = 3,
  SpecialCharacter = 23,
}

export enum SubEntityTypeText {
  ImageAsset = "ImageAsset",
  VideoAsset = "VideoAsset",
  Image = "Image",
  ImageSPOTLIGHT = "ImageSPOTLIGHT",
  TransparentLogo = "TransparentLogo",
  ImagePRONG = "ImagePRONG",
  SubTitle = "SubTitle",
}

export enum SubEntityType {
  Headline = 0,
  LongHeadline = 1,
  Description = 2,
  Image = 3,
  Video = 4,
  BusinessName = 5,
  FinalUrl = 6,
  MobileFinalUrl = 7,
  Path1 = 8,
  Path2 = 9,
  SubTitle = 10,
  ImagePRONG = 11,
  ImageSPOTLIGHT = 12,
  TransparentLogo = 13,
}
