import { classNamesFunction, Link } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IFooterProps, IFooterStyleProps, IFooterStyles } from "./Footer.types";

function FooterBase(props: IFooterProps) {
  const { t } = useTranslation();
  const { styles } = props;
  const getClassNames = classNamesFunction<IFooterStyleProps, IFooterStyles>();
  const classNames = getClassNames(styles);
  const items = React.useMemo(
    () => [
      {
        key: "Copyright",
        name: t("footer.copyright-text"),
        disabled: true,
      },
      {
        key: "Legal",
        name: t("footer.legal-text"),
        link: "https://ads.microsoft.com/tc.aspx",
      },
      {
        key: "Data Protection Notice",
        name: t("footer.dataprotection-text"),
        link: "https://go.microsoft.com/fwlink/?LinkId=518021",
      },
      {
        key: "Advertise",
        name: t("footer.advertise-text"),
        link: "http://go.microsoft.com/fwlink/?LinkId=258668",
      },
      {
        key: "Developers",
        name: t("footer.developers-text"),
        link: "http://go.microsoft.com/fwlink/?LinkID=268769",
      },
      {
        key: "Support",
        name: t("footer.support-text"),
        link: "http://go.microsoft.com/fwlink/?LinkId=258669",
      },
    ],
    [t]
  );

  return (
    <div className={classNames.root}>
      {items.map((item) => {
        return (
          <div key={item.key} className={classNames.item}>
            {item.disabled && <span>{item.name}</span>}
            {!item.disabled && (
              <Link
                data-test="link"
                className={classNames.itemLink}
                href={item.link}>
                {item.name}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default FooterBase;
