import { Reducer } from "redux";
import {
  actionTypes,
  IListManagerSeedTokenState,
  INITIAL_STATE,
} from "./ListManagerSeedTokenState";

export const listManagerSeedTokenReducer: Reducer<IListManagerSeedTokenState> = (
  state: IListManagerSeedTokenState = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.CREATE_JOB:
      return {
        ...state,
      };

    case actionTypes.CREATE_JOB_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_JOB_STATUS:
      return {
        ...state,
        loading: action.payload.loading,
        jobStatus: {
          ...state.jobStatus,
          items: action.payload.data || null,
        },
      };

    case actionTypes.GET_JOB_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.DELETE_JOBS:
      return {
        ...state,
        loading: action.payload.loading,
        jobStatus: {
          ...state.jobStatus,
        },
      };

    case actionTypes.DELETE_JOBS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SELECTED_ITEMS:
      return {
        ...state,
        jobStatus: {
          ...state.jobStatus,
          selectedItems: action.payload,
        },
      };

    default:
      return state;
  }
};
