import { configs } from "../../configs";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import { actionCreators as commonActions } from "../common/CommonActions";
import { authInstance } from "../../components/auth/AuthModule";
import { UserActionTypes } from "@trinity/enums";
import { IUserActionPayload } from "../common/CommonState";
import { v4 as uuidv4 } from "uuid";

// #region ACTION TYPES - https://redux.js.org/docs/basics/Actions.html#actions
export const ACTION_NAMESPACE = "user/";
export const USER_DETAILS_RECEIVED = ACTION_NAMESPACE + "USER_DETAILS_RECEIVED";
export const USER_DETAILS_FAILED = ACTION_NAMESPACE + "USER_DETAILS_FAILED";
export const USER_IMAGE_RECEIVED = ACTION_NAMESPACE + "USER_IMAGE_RECEIVED";
export const USER_IMAGE_FAILED = ACTION_NAMESPACE + "USER_IMAGE_FAILED";
export const USER_ROLE_RECEIVED = ACTION_NAMESPACE + "USER_ROLE_RECEIVED";
export const USER_ROLE_FAILED = ACTION_NAMESPACE + "USER_ROLE_FAILED";
export const UPDATE_USER_ROLE = ACTION_NAMESPACE + "UPDATE_USER_ROLE";
export const GET_USER_DETAILS_REQUEST_COMPLETE =
  ACTION_NAMESPACE + "GET_USER_DETAILS_REQUEST_COMPLETE";

export const actionCreators = {
  getUserDetails: () => async (dispatch: any) => {
    try {
      const response = await dispatch(
        fetchCreator(configs.client.endpoint.graphEndpoint + "me", {
          customOptions: { scope: "profile" },
        })
      );
      const data = await response.json();
      const user = {
        displayName: data.givenName || data.displayName,
        email: data.mail || data.userPrincipalName,
        alias: data.userPrincipalName,
        imageInitials: data.displayName
          .split(" ")
          .map((n) => n[0])
          .join(""),
      };

      dispatch({ type: USER_DETAILS_RECEIVED, payload: user });
      dispatch(commonActions.cacheBust());
      dispatch(commonActions.getConstants());
      await dispatch(actionCreators.getUserRole());
      dispatch(userPreferenceActions.getUserPreference());
      dispatch(actionCreators.getUserImage());
      dispatch(commonActions.getFLAConstants());
    } catch (error: any) {
      dispatch({ type: USER_DETAILS_FAILED, payload: error.message });
      const payloadFail: IUserActionPayload = {
        id: uuidv4(),
        userId: null,
        type: UserActionTypes.AuthFail,
        dateTime: new Date().toISOString(),
        extraInfo: "",
      };

      dispatch(commonActions.postUserAction([payloadFail]));
    }
  },

  getUserImage: () => async (dispatch: any) => {
    try {
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.graphEndpoint + "me/photos/96x96/$value",
          { customOptions: { scope: "profile" } }
        )
      );
      const imageData = await response.blob();
      const imageUrl = URL.createObjectURL(imageData);

      dispatch({ type: USER_IMAGE_RECEIVED, payload: { imageUrl: imageUrl } });
    } catch (error: any) {
      dispatch({ type: USER_IMAGE_FAILED });
    }
  },

  getUserRole: () => async (dispatch: any) => {
    try {
      const response = await dispatch(
        fetchCreator(configs.client.endpoint.appServiceEndpoint + "me")
      );
      const data = await response.json();
      const user = {
        userRoles: data.roles,
        userId: data.id,
      };

      const payloadSuccess: IUserActionPayload = {
        id: uuidv4(),
        userId: data.id,
        type: UserActionTypes.AuthSuccess,
        dateTime: new Date().toISOString(),
        extraInfo: "",
      };

      dispatch(commonActions.postUserAction([payloadSuccess]));

      dispatch({ type: USER_ROLE_RECEIVED, payload: user });
    } catch (error: any) {
      const payloadFail: IUserActionPayload = {
        id: uuidv4(),
        userId: null,
        type: UserActionTypes.AuthFail,
        dateTime: new Date().toISOString(),
        extraInfo: "",
      };

      dispatch(commonActions.postUserAction([payloadFail]));
      dispatch({ type: USER_ROLE_FAILED });
    }
  },

  logout: () => () => authInstance.logout(),
};
