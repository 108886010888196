import {
  FontSizes,
  getTheme,
  Icon,
  IIconStyles,
  IStackStyles,
  Stack,
  Text,
} from "@fluentui/react";

function AuthenticationError({ error }) {
  const theme = getTheme();
  const iconStyles: IIconStyles = {
    root: [
      {
        fontSize: FontSizes.mega,
        color: theme.palette.neutralTertiaryAlt,
      },
    ],
  };
  const stackStyles: IStackStyles = {
    root: [
      {
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        gridGap: "20px",
      },
    ],
  };
  return (
    <Stack styles={stackStyles}>
      <Icon data-test="icon" iconName="Sad" styles={iconStyles}></Icon>
      <Text variant="large" as="h1" block>
        Authentication Failed
      </Text>
      <Text variant="medium" as="p" block>
        An Error Occurred: {error}
      </Text>
    </Stack>
  );
}

export default AuthenticationError;
