//For tracking user actions.
export enum UserActionTypes {
  PackageDecision = "PackageDecision",
  SelectAllPackageDecision = "SelectAllPackageDecision",
  PackageBulkDecision = "PackageBulkDecision",
  FlagAreaOverride = "FlagAreaOverride",
  FlagTermOverride = "FlagTermOverride",
  SupportingInfoClick = "SupportingInfoClick",
  SummaryFlagAreaFilter = "SummaryFlagAreaFilter",
  PackageReassignedToAnotherQ = "PackageReassignedToAnotherQ",
  ImageTranslate = "ImageTranslate",
  UserRoleAssignment = "UserRoleAssignment",
  ProfileNetworkTabClick = "ProfileNetworkTabClick",
  CustomerProfileClick = "CustomerProfileClick",
  CustomerProfileLoaded = "CustomerProfileLoaded",
  FLAReqSent = "FLAReqSent",
  FLAReqSuccess = "FLAReqSuccess",
  FLAReqFailed = "FLAReqFailed",
  AuthStarted = "AuthStarted",
  AuthSuccess = "AuthSuccess",
  AuthFail = "AuthFail",
}
