export enum FeatureType {
  All,
  DefaultTerm,
  TermOverride,
  FlagOverride,
}

export enum OperationType {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export enum ApprovalStatus {
  Pending,
  Approved,
  Rejected,
  Cancelled,
}
