import { UserRole } from "@trinity/enums";

export const reviewer_auth = [
  UserRole.EditorialAdmin,
  UserRole.EditorialChineseAdmin,
  UserRole.EditorialAdvancedReviewer,
  UserRole.EditorialReviewer,
  UserRole.EditorialManager,
];

export const admin_auth = [
  UserRole.EditorialAdmin,
  UserRole.EditorialChineseAdmin,
];

export const enforcement_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.RiskPremiumReviewer,
];

export const token_auth = [
  UserRole.EditorialAdmin,
  UserRole.EditorialChineseAdmin,
  UserRole.EditorialReviewer,
  UserRole.EditorialReadOnly,
];

export const trademark_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.EditorialAdmin,
  UserRole.EditorialChineseAdmin,
];

export const qre_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.EditorialAdmin,
];

export const dsa_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.EditorialAdmin,
];

export const entityList_auth = [UserRole.RiskAdmin, UserRole.EditorialAdmin];

export const plaBlock_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.EditorialAdmin,
];

export const rule_create_auth = [UserRole.RiskAdmin, UserRole.EditorialAdmin];

export const rule_search_auth = [
  UserRole.RiskAdmin,
  UserRole.EditorialAdmin,
  UserRole.EditorialReadOnly,
  UserRole.EditorialReviewer,
];

export const publisherRule_auth = [
  UserRole.RiskAdmin,
  UserRole.RiskViewer,
  UserRole.RiskReviewer,
  UserRole.EditorialAdmin,
];
