import { ISearchPublisherRuleItem } from "../../../store/publisher-rule/PublisherRuleState";
import { ISearchPublisherRuleColumn } from "../PublisherRule.types";

import { DropdownRenderer } from "./DropdownRenderer";
import { RendererType } from "./Renderer.types";
import { TextRenderer } from "./TextRenderer";
export const Renderer = (
  item?: ISearchPublisherRuleItem,
  index?: number,
  column?: ISearchPublisherRuleColumn
) => {
  if (!item || !column || column.isHidden) return null;

  const props = {
    item: item,
    index: index,
    column: column,
  };

  switch (column.rendererType) {
    case RendererType.CATEGORY:
      return <DropdownRenderer {...props} />;
    case RendererType.STATUS:
      return <DropdownRenderer {...props} />;
    default:
      return <TextRenderer {...props} />;
  }
};
