export class ResponseError {
  status: number;
  message: string;
  response: any;

  constructor(status: number, message: string, response: any) {
    this.status = status;
    this.message = message;
    this.response = response;

    return this;
  }
}

export interface IQueryParams {
  top: number;
  skip: number;
  search: string;
  orderBy: string;
  count: boolean;
  filter: string;
}

export interface IApplicationConfig {
  appVersion: number;
}

export interface IQueue {
  id: number;
  name: string;
}

export interface IQueueTypeLabels {
  upheld: string;
  overturned: string;
  upholdRejection: string;
  overturnRejection: string;
}

export interface IObjectWithKeyText {
  key: number | string;
  text: string;
}

// Useful for type checking partial application state during tests
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
