import { DetectionSource, LoadState, TNSEntityType } from "@trinity/enums";
import {
  IEntity,
  ITNSEnforcementPayload,
} from "../tns-enforcement/TNSEnforcementState";

export interface ITNSDetectionHistoryState {
  loadState: LoadState;
  detectionHistoryListHistoryList: IDetectionData[];
}

interface IDetectionData {
  customerId: string;
  custFraudStatusId: string;
  accountId: string;
  trackingGuid: string;
  algoSource: string;
  eventSource: string;
  riskLevel: string;
  score: string;
  notificationDateTimeUtc: string;
  modifiedBy: string;
  reason: string;
  campaignIdList: string[];
  adGroupIdList: string[];
  failedAdGroupFraudRule: string;
  generateNotification: string;
  originalTrackingGuid: string;
  algoDetails: string;
  algoName: string;
}

export const DefaultDetectionHistoryState: ITNSDetectionHistoryState = {
  loadState: LoadState.NotLoaded,
  detectionHistoryListHistoryList: [],
};
