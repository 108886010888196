import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { LoadState, QueueTypes } from "@trinity/enums";
import { RequestKeys } from "../../modules/admin-package-management/AdminPackageManagement.types";
import { IQueue } from "../../utils/common/types";

export interface IAdminPackageManagementState {
  packages: IPackageItem[];
  tempPackages: string;
  packageItemsCount: number;
  selectedPackage: IPackageItem[];
  packageLoadState: IPackageManagementLoadState;
  queueSummary: IQueueSummary;
  resetTableSelection: boolean;
  isSubmittedOnly: boolean;
}

export interface IPackageManagementLoadState {
  packages: LoadState;
  queueSummary: LoadState;
}

export interface IPackageFlagAreaSummary {
  id: number;
  total: number;
  upheld: number;
  overturned: number;
  incomplete: number;
}
export interface IQueueSummaryDetail {
  flagArea: IPackageFlagAreaSummary[];
  maxAge: string;
  minAge: string;
  packageCount: number;
}

export interface IPackageItem extends ITableRowItemProps {
  AccountId: string;
  Age: number;
  AssignedToUserId: number;
  AssignedToUserName: string;
  CapabilityId: number;
  CustomerId: string;
  CustomerName: string;
  FlagArea: string;
  PackageGuid: string;
  RowsCount: number;
  SequenceId: number;
  WorkflowStatusId: number;
  StoreId: string;
  UsedInEntityTypes?: string;
}

export interface IQueueSummary {
  id: number;
  name: string;
  language: string;
  queueType: QueueTypes;
  isTranslationEnabled: boolean;
  relatedQueues: IQueue[];
  summary: IQueueSummaryDetail;
}

export const DefaultQueueSummary: IQueueSummary = {
  id: 0,
  language: "",
  name: "",
  queueType: QueueTypes.None,
  isTranslationEnabled: false,
  relatedQueues: [],
  summary: {
    flagArea: [],
    maxAge: "0 hr",
    minAge: "0 hr",
    packageCount: 0,
  },
};

export interface IEditPackageItemBody {
  id: string;
  [RequestKeys.SequenceId]?: number;
  [RequestKeys.ApplyDecision]?: number;
  [RequestKeys.AssignToQueue]?: number;
  [RequestKeys.AssignUnAssign]?: number;
}

export const INITIAL_STATE: IAdminPackageManagementState = {
  packages: [],
  tempPackages: "",
  queueSummary: DefaultQueueSummary,
  packageItemsCount: 0,
  selectedPackage: [],
  resetTableSelection: false,
  isSubmittedOnly: false,
  packageLoadState: {
    packages: LoadState.NotLoaded,
    queueSummary: LoadState.NotLoaded,
  },
};
