import { Reducer } from "redux";
import { IAddFlag } from "../../components/review-package/panels/AddFlag.types";
import { Decision } from "../../components/review-package/ReviewPackageTable.types";
import { AdType, LoadState, SubEntityTypeText } from "@trinity/enums";
import { dateUtils } from "@trinity/common";
import { isEmptyObject } from "../../../src/utils/common/common";
import {
  actionTypes,
  DecisionSource,
  DefaultReviewPackageDetails,
  DefaultReviewPackageState,
  IFlag,
  IImageTranslationSummary,
  IReviewPackageDetails,
  IReviewPackageFlagAreaSummary,
  IReviewPackageItem,
  IReviewPackageState,
  IReviewPackageSummary,
  ReviewPackagePanelType,
} from "./ReviewPackageState";
import history from "../../utils/common/history";

export const reviewPackageReducer: Reducer<IReviewPackageState> = (
  state: IReviewPackageState = DefaultReviewPackageState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.OPEN_PANEL:
      return {
        ...state,
        panelType: action.payload as ReviewPackagePanelType,
      };

    case actionTypes.CLOSE_PANEL:
      return {
        ...state,
        panelType: ReviewPackagePanelType.None,
        selectedItems: [],
        supportingInfo: {
          ...state.supportingInfo,
          translatedImagesText: resetImageTranslation(),
        },
      };

    case actionTypes.SET_REVIEW_PACKAGE_DETAILS:
      const reviewPackageDetails = action.payload as IReviewPackageDetails;
      return {
        ...state,
        reviewPackageDetails: reviewPackageDetails,
        isTranslationEnabled: reviewPackageDetails.queueIsTranslationEnabled,
        summary: {
          ...state.summary,
          loadState: LoadState.InProgress,
        },
        packageItems: {
          items: [],
          count: 0,
          loadState: LoadState.InProgress,
          translatedItems: {},
        },
      };

    case actionTypes.SET_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload.items as Array<IReviewPackageItem>,
        areAllItemsSelected: false,
        isFiltered: action.payload.isFilterApplied as boolean,
      };

    case actionTypes.SET_IS_TRANSLATION_ENABLED:
      return {
        ...state,
        isTranslationEnabled: action.payload as boolean,
      };

    case actionTypes.SET_ARE_ALL_ITEMS_SELECTED:
      return {
        ...state,
        areAllItemsSelected: action.payload as boolean,
      };

    case actionTypes.GET_PACKAGE_SUMMARY:
      return {
        ...state,
        summary: {
          ...state.summary,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.PACKAGE_SUMMARY_RECEIVED:
      return {
        ...state,
        summary: getPackageSummary(action.payload),
      };

    case actionTypes.PACKAGE_SUMMARY_FETCH_FAILED:
      return {
        ...state,
        summary: {
          ...state.summary,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.GET_PACKAGE_ITEMS:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          items: [],
          translatedItems: getTranslations(
            state.isTranslationEnabled,
            state.reviewPackageDetails.packageGuid
          ),
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.PACKAGE_ITEMS_RECEIVED:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          items: action.payload.value,
          count: action.payload["@odata.count"],
          translatedItems: getTranslations(
            state.isTranslationEnabled,
            state.reviewPackageDetails.packageGuid
          ),
          loadState: LoadState.Loaded,
        },
      };

    case actionTypes.PACKAGE_ITEMS_FETCH_FAILED:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.SUBMIT_PACKAGE:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          loadState: LoadState.InProgress,
        },
        summary: {
          ...state.summary,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.SUBMIT_PACKAGE_COMPLETE:
      return {
        ...state,
        reviewPackageDetails: DefaultReviewPackageDetails,
      };

    case actionTypes.SUBMIT_PACKAGE_FAILED:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.SUBMIT_PACKAGE_TO_NEW_QUEUE:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.SUBMIT_PACKAGE_TO_NEW_QUEUE_FAILED:
      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.REDIRECT_TO_OVERVIEW_SCREEN:
      redirectTo(action.payload);
      return {
        ...state,
      };

    case actionTypes.SUBMIT_FLAG_TERM_OVERRIDE:
      return {
        ...state,
        overrideFlagTermResponse: action.payload,
      };

    case actionTypes.SUBMIT_FLAG_TERM_OVERRIDE_FAILED:
      return {
        ...state,
      };

    case actionTypes.SUBMIT_FLAG_AREA_OVERRIDE:
      return {
        ...state,
      };

    case actionTypes.SUBMIT_FLAG_AREA_OVERRIDE_FAILED:
      return {
        ...state,
      };

    case actionTypes.UPDATE_OVERTURN_REJECTION_DECISIONS: {
      const selectedItems = action.payload as Array<IReviewPackageItem>;
      const areAllItemsSelected = state.areAllItemsSelected as boolean;
      const addFlagForceDecisionOverturned =
        state.reviewPackageDetails.addFlagForceDecisionOverturned;
      const isFilterApplied = state.isFiltered;
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;

      // Summary Logic
      // Get previous decisions of the selected items
      // Add the total no of selected items to completed & overturned
      // subtract the previous decisions taken from the selected items

      const previousDecisions = getPreviousSelectedItemsDecisions(
        selectedItems,
        addFlagForceDecisionOverturned,
        disabledFlagAreas
      );

      const previousFlagAreaDecisions = getPreviousFlagAreaDecisions(
        selectedItems,
        addFlagForceDecisionOverturned
      );

      const updateSelectedItems = selectedItems.map((x: IReviewPackageItem) => {
        const isDisabledFlagArea = disabledFlagAreas.includes(
          x.content.FlagAreaID
        );

        state.summary.flagAreas = getFlagAreasModifyAllFlags(
          x,
          state.summary.flagAreas,
          isDisabledFlagArea,
          Decision.OVERTURNREJECTION,
          addFlagForceDecisionOverturned
        );

        return {
          ...x,
          extraDecision: {
            ...x.extraDecision,
            newFlags:
              isDisabledFlagArea || addFlagForceDecisionOverturned
                ? []
                : (x.extraDecision && x.extraDecision.newFlags) || [],
            decisionSource:
              areAllItemsSelected && !isFilterApplied
                ? DecisionSource.PACKAGE
                : selectedItems.length > 1
                ? DecisionSource.MULTIPLE
                : DecisionSource.SINGLE,
          },
          decision: String(Decision.OVERTURNREJECTION),
        };
      });

      return {
        ...state,
        areAllItemsSelected: false,
        selectedItems: updateSelectedItems,
        panelType: ReviewPackagePanelType.None,
        deleteAllFlags: areAllItemsSelected
          ? disabledFlagAreas.includes(selectedItems[0].content.FlagAreaID) ||
            addFlagForceDecisionOverturned
          : false,
        packageItems: {
          ...state.packageItems,
          items: updateSelectedItems.reduce((acc, item) => {
            const index = acc.findIndex((x) => x.id === item.id);
            if (index > -1) {
              acc[index] = item;
            }

            return acc;
          }, state.packageItems.items),
        },
        summary: {
          ...state.summary,
          completed:
            selectedItems.length +
            (state.summary.completed - previousDecisions.completed),
          overturned:
            selectedItems.length +
            (state.summary.overturned - previousDecisions.overturned),
          rowsToReview:
            state.summary.rowsToReview - previousDecisions.rowsToReview,
          upheld: state.summary.upheld - previousDecisions.upheld,
          flagAreas: state.summary.flagAreas.map((x) => {
            const overturnedCount = previousFlagAreaDecisions[x.id]
              ? previousFlagAreaDecisions[x.id]["total"] +
                (x.overturned - previousFlagAreaDecisions[x.id]["overturned"])
              : x.overturned;

            const incompleteCount = previousFlagAreaDecisions[x.id]
              ? x.incomplete - previousFlagAreaDecisions[x.id]["incomplete"]
              : x.incomplete;

            const upheldCount = previousFlagAreaDecisions[x.id]
              ? x.upheld - previousFlagAreaDecisions[x.id]["upheld"]
              : x.upheld;

            return {
              ...x,
              overturned: overturnedCount,
              incomplete: incompleteCount,
              upheld: upheldCount,
            };
          }),
        },
      };
    }

    case actionTypes.UPDATE_OVERTURN_REJECTION_DECISIONS_COMPLETED:
      return {
        ...state,
        selectedItems: [],
      };

    case actionTypes.UPDATE_UPHOLD_REJECTION_DECISIONS: {
      const selectedItems = action.payload as Array<IReviewPackageItem>;
      const areAllItemsSelected = state.areAllItemsSelected as boolean;
      const addFlagForceDecisionOverturned =
        state.reviewPackageDetails.addFlagForceDecisionOverturned;
      const isFilterApplied = state.isFiltered;
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;

      // Summary Logic
      // Get previous decisions of the selected items
      // Add the total no of selected items to completed & upheld
      // subtract the previous decisions taken from the selected items

      const previousDecisions = getPreviousSelectedItemsDecisions(
        selectedItems,
        addFlagForceDecisionOverturned,
        disabledFlagAreas
      );

      const previousFlagAreaDecisions = getPreviousFlagAreaDecisions(
        selectedItems,
        addFlagForceDecisionOverturned
      );

      const updateSelectedItems = selectedItems.map((x: IReviewPackageItem) => {
        const isDisabledFlagArea = disabledFlagAreas.includes(
          x.content.FlagAreaID
        );

        state.summary.flagAreas = getFlagAreasModifyAllFlags(
          x,
          state.summary.flagAreas,
          isDisabledFlagArea,
          Decision.UPHOLDREJECTION,
          addFlagForceDecisionOverturned
        );

        return {
          ...x,
          extraDecision: {
            ...x.extraDecision,
            decisionSource:
              areAllItemsSelected && !isFilterApplied
                ? DecisionSource.PACKAGE
                : selectedItems.length > 1
                ? DecisionSource.MULTIPLE
                : DecisionSource.SINGLE,
          },
          decision:
            isDisabledFlagArea || addFlagForceDecisionOverturned
              ? String(Decision.OVERTURNREJECTION)
              : String(Decision.UPHOLDREJECTION),
        };
      });

      const hasReviewReqFlagArea = updateSelectedItems.some((x) => {
        return disabledFlagAreas.includes(x.content.FlagAreaID);
      });

      return {
        ...state,
        areAllItemsSelected:
          (hasReviewReqFlagArea || addFlagForceDecisionOverturned) &&
          areAllItemsSelected
            ? true
            : false,
        selectedItems: updateSelectedItems,
        deleteAllFlags: false,
        panelType:
          hasReviewReqFlagArea ||
          state.reviewPackageDetails.addFlagForceDecisionOverturned
            ? ReviewPackagePanelType.Flags
            : ReviewPackagePanelType.None,
        packageItems: {
          ...state.packageItems,
          items: updateSelectedItems.reduce((acc, item) => {
            const index = acc.findIndex((x) => x.id === item.id);
            if (index > -1) {
              acc[index] = item;
            }

            return acc;
          }, state.packageItems.items),
        },
        summary: {
          ...state.summary,
          completed:
            selectedItems.length +
            (state.summary.completed - previousDecisions.completed),
          upheld:
            selectedItems.length +
            (state.summary.upheld - previousDecisions.upheld),
          overturned: state.summary.overturned - previousDecisions.overturned,
          rowsToReview:
            state.summary.rowsToReview - previousDecisions.rowsToReview,
          flagAreas: state.summary.flagAreas.map((x) => {
            const upheldCount = previousFlagAreaDecisions[x.id]
              ? previousFlagAreaDecisions[x.id]["total"] +
                (x.upheld - previousFlagAreaDecisions[x.id]["upheld"])
              : x.upheld;

            const incompleteCount = previousFlagAreaDecisions[x.id]
              ? x.incomplete - previousFlagAreaDecisions[x.id]["incomplete"]
              : x.incomplete;

            const overturnedCount = previousFlagAreaDecisions[x.id]
              ? x.overturned - previousFlagAreaDecisions[x.id]["overturned"]
              : x.overturned;

            return {
              ...x,
              upheld: upheldCount,
              incomplete: incompleteCount,
              overturned: overturnedCount,
            };
          }),
        },
      };
    }

    case actionTypes.UPDATE_UPHOLD_REJECTION_DECISIONS_COMPLETED:
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;
      const hasReviewReqFlagArea = state.selectedItems.some((x) => {
        return disabledFlagAreas.includes(x.content.FlagAreaID);
      });

      return {
        ...state,
        selectedItems:
          hasReviewReqFlagArea ||
          state.reviewPackageDetails.addFlagForceDecisionOverturned
            ? state.selectedItems
            : [],
      };

    case actionTypes.UPDATE_TO_REVIEW_DECISIONS: {
      const selectedItems = action.payload
        .selectedItems as Array<IReviewPackageItem>;
      const isFromAddFlagDefaultDecision = action.payload
        .isFromAddFlagDefaultDecision as boolean;
      const areAllItemsSelected = state.areAllItemsSelected as boolean;
      const addFlagForceDecisionOverturned =
        state.reviewPackageDetails.addFlagForceDecisionOverturned;
      const isFilterApplied = state.isFiltered;
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;

      // Summary Logic
      // Get previous decisions of the selected items
      // subtract the previous decisions taken from the selected items
      // Add the total no of selected items to rowsToReview

      const previousDecisions = getPreviousSelectedItemsDecisions(
        selectedItems,
        addFlagForceDecisionOverturned,
        disabledFlagAreas,
        isFromAddFlagDefaultDecision
      );

      const previousFlagAreaDecisions = getPreviousFlagAreaDecisions(
        selectedItems,
        addFlagForceDecisionOverturned,
        isFromAddFlagDefaultDecision
      );

      const updateSelectedItems = selectedItems.map((x: IReviewPackageItem) => {
        const isDisabledFlagArea = disabledFlagAreas.includes(
          x.content.FlagAreaID
        );

        state.summary.flagAreas = getFlagAreasModifyAllFlags(
          x,
          state.summary.flagAreas,
          isDisabledFlagArea,
          Decision.TOREVIEW,
          addFlagForceDecisionOverturned
        );

        return {
          ...x,
          extraDecision: {
            ...x.extraDecision,
            newFlags:
              isDisabledFlagArea || addFlagForceDecisionOverturned
                ? []
                : (x.extraDecision && x.extraDecision.newFlags) || [],
            decisionSource:
              areAllItemsSelected && !isFilterApplied
                ? DecisionSource.PACKAGE
                : selectedItems.length > 1
                ? DecisionSource.MULTIPLE
                : DecisionSource.SINGLE,
          },
          decision: String(Decision.TOREVIEW),
        };
      });

      return {
        ...state,
        selectedItems: updateSelectedItems,
        deleteAllFlags:
          areAllItemsSelected && !isFilterApplied
            ? disabledFlagAreas.includes(selectedItems[0].content.FlagAreaID) ||
              addFlagForceDecisionOverturned
            : false,
        areAllItemsSelected: false,
        panelType: ReviewPackagePanelType.None,
        packageItems: {
          ...state.packageItems,
          items: updateSelectedItems.reduce((acc, item) => {
            const index = acc.findIndex((x) => x.id === item.id);
            if (index > -1) {
              acc[index] = item;
            }

            return acc;
          }, state.packageItems.items),
        },
        summary: {
          ...state.summary,
          completed: state.summary.completed - previousDecisions.completed,
          rowsToReview:
            selectedItems.length +
            (state.summary.rowsToReview - previousDecisions.rowsToReview),
          upheld: state.summary.upheld - previousDecisions.upheld,
          overturned: state.summary.overturned - previousDecisions.overturned,
          flagAreas: state.summary.flagAreas.map((x) => {
            const incompleteCount = previousFlagAreaDecisions[x.id]
              ? previousFlagAreaDecisions[x.id]["total"] +
                (x.incomplete - previousFlagAreaDecisions[x.id]["incomplete"])
              : x.incomplete;

            const upheldCount = previousFlagAreaDecisions[x.id]
              ? x.upheld - previousFlagAreaDecisions[x.id]["upheld"]
              : x.upheld;

            const overturnedCount = previousFlagAreaDecisions[x.id]
              ? x.overturned - previousFlagAreaDecisions[x.id]["overturned"]
              : x.overturned;

            return {
              ...x,
              incomplete: incompleteCount,
              upheld: upheldCount,
              overturned: overturnedCount,
            };
          }),
        },
      };
    }

    case actionTypes.UPDATE_TO_REVIEW_DECISIONS_COMPLETED:
      return {
        ...state,
        selectedItems: [],
      };

    case actionTypes.GET_SUPPORTING_INFO_START:
      return {
        ...state,
        panelType: ReviewPackagePanelType.SupportingInfo,
        packageItems: {
          ...state.packageItems,
          translatedItems: getTranslations(
            state.isTranslationEnabled,
            state.reviewPackageDetails.packageGuid
          ),
        },
        supportingInfo: {
          ...state.supportingInfo,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.GET_SUPPORTING_INFO_COMPLETE:
      const item = action.payload as IReviewPackageItem;

      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          translatedItems: getTranslations(
            state.isTranslationEnabled,
            state.reviewPackageDetails.packageGuid
          ),
        },
        supportingInfo: {
          ...state.supportingInfo,
          loadState: LoadState.Loaded,
          items: {
            content: JSON.parse(item.content.VNextSamplesJSON),
            entityText: JSON.parse(item.content.VNextEntityTextJSON),
            highlightTerm: item.content.FlagTerm,
            subEntityType: parseInt(item.content.SubEntityType) as AdType,
            type: parseInt(item.content.EntityType),
            ignoreVNextSamples: shouldIgnoreVNextSamples(
              item.content.SubEntityTypeText as SubEntityTypeText
            ),
          },
        },
      };

    case actionTypes.GET_SUPPORTING_INFO_FAILED:
      return {
        ...state,
        supportingInfo: {
          ...state.supportingInfo,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.SUBMIT_ADD_FLAG_ALL:
    case actionTypes.SUBMIT_ADD_FLAG: {
      const addFlag: IAddFlag = action.payload;
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;

      const updatedSelectedItems = state.selectedItems.map((item) => {
        const newFlags = addFlag.countries.map((country) => {
          return {
            component: addFlag.component,
            flagAreaID: addFlag.flagAreaID,
            flagLevel: addFlag.flagLevel,
            flagTerm: addFlag.flagTerm,
            country: country,
            detectionMode: addFlag.detectionMode,
          };
        });

        return {
          ...item,
          decision:
            disabledFlagAreas.includes(item.content.FlagAreaID) &&
            item.decision === String(Decision.UPHOLDREJECTION)
              ? String(Decision.OVERTURNREJECTION)
              : item.decision,
          extraDecision: {
            ...item.extraDecision,
            newFlags: [
              ...((item.extraDecision && item.extraDecision.newFlags) || []),
              ...newFlags,
            ],
          },
        };
      });

      const updatedPackageItems = updatedSelectedItems.reduce((acc, curr) => {
        const index = acc.findIndex((x) => x.id === curr.id);

        if (index !== -1) {
          acc[index] = curr;
        }

        return acc;
      }, state.packageItems.items);

      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          items: updatedPackageItems,
        },
        selectedItems: updatedSelectedItems,
        summary: {
          ...state.summary,
          flagAreas: getFlagAreasAddFlag(
            updatedSelectedItems,
            state.summary.flagAreas,
            addFlag,
            state.reviewPackageDetails.addFlagForceDecisionOverturned,
            disabledFlagAreas
          ),
        },
      };
    }

    case actionTypes.SUBMIT_ADD_FLAG_ALL_FAILED:
    case actionTypes.SUBMIT_ADD_FLAG_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_FLAG_INFO: {
      const disabledFlagAreas = state.reviewPackageDetails.disabledFlagAreas;

      const updatedSelectedItems = state.selectedItems.map((x) => {
        const addFlagIndex: number = action.payload.index;

        const newFlags = x.extraDecision.newFlags.filter(
          (_, index) => addFlagIndex !== index
        );

        return {
          ...x,
          extraDecision: {
            ...x.extraDecision,
            newFlags: newFlags,
          },
        };
      });

      const updatedPackageItems = updatedSelectedItems.reduce((acc, curr) => {
        const index = acc.findIndex((x) => x.id === curr.id);

        if (index !== -1) {
          acc[index] = curr;
        }

        return acc;
      }, state.packageItems.items);

      return {
        ...state,
        packageItems: {
          ...state.packageItems,
          items: updatedPackageItems,
        },
        selectedItems: updatedSelectedItems,
        summary: {
          ...state.summary,
          flagAreas: getFlagAreasDeleteFlag(
            action.payload.item,
            action.payload.index,
            state.summary.flagAreas,
            state.reviewPackageDetails.addFlagForceDecisionOverturned,
            disabledFlagAreas
          ),
        },
      };
    }

    case actionTypes.SYNC_ALL_PACKAGE_ITEMS_DECISIONS:
      return {
        ...state,
        isPackageDecisionsSyncInProgress: true,
        isPackageDecisionsSyncFailed: false,
      };

    case actionTypes.SYNC_ALL_PACKAGE_ITEMS_DECISIONS_COMPLETED:
      return {
        ...state,
        isPackageDecisionsSyncInProgress: false,
      };

    case actionTypes.SYNC_ALL_PACKAGE_ITEMS_DECISIONS_FAILED:
      return {
        ...state,
        isPackageDecisionsSyncInProgress: false,
        isPackageDecisionsSyncFailed: true,
      };

    case actionTypes.SYNC_PACKAGE_ITEMS:
      return {
        ...state,
        isPackageDecisionsSyncInProgress: true,
        isPackageDecisionsSyncFailed: false,
      };

    case actionTypes.SYNC_PACKAGE_ITEMS_COMPLETE:
      return {
        ...state,
        isPackageDecisionsSyncInProgress: false,
      };

    case actionTypes.SYNC_PACKAGE_ITEMS_DECISIONS_FAILED:
      return {
        ...state,
        isPackageDecisionsSyncFailed: true,
      };

    case actionTypes.DOWNLOAD_PACKAGE_DETAILS:
      return {
        ...state,
        csvData: action.payload,
      };

    case actionTypes.DOWNLOAD_PACKAGE_DETAILS_FAILED:
      return {
        ...state,
      };

    case actionTypes.DOWNLOAD_PACKAGE_DETAILS_COMPLETED:
      return {
        ...state,
        csvData: null,
      };

    case actionTypes.GET_IMAGE_TEXT_TRANSLATION:
      const printedText = action.payload.data.printedText;
      const translatedText =
        action.payload.data.translatedText || action.payload.data.printedText;

      return {
        ...state,
        supportingInfo: {
          ...state.supportingInfo,
          translatedImagesText: getImageTranslations(
            state.supportingInfo.translatedImagesText,
            printedText,
            translatedText,
            action.payload.index
          ),
        },
      };

    case actionTypes.SAVE_COMMENTS: {
      const id = state.selectedItems[0].id;

      const updatedPackageItems = state.packageItems.items.map((item) => {
        if (id === item.id) {
          item.content.Comments = action.payload;
        }
        return item;
      });
      return {
        ...state,
        packageItems: { ...state.packageItems, items: updatedPackageItems },
      };
    }

    case actionTypes.GET_PACKAGE_SUMMARY_TO_VALIDATE:
      return {
        ...state,
        summary: {
          ...state.summary,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.PACKAGE_SUMMARY_TO_VALIDATE_RECEIVED:
      const data = getPackageSummary(action.payload);
      return {
        ...state,
        summary: {
          ...state.summary,
          completed: data.overturned + data.upheld,
          overturned: data.overturned,
          flagAreas: data.flagAreas,
          rowsToReview: data.rowsToReview,
          upheld: data.upheld,
          hasAllRowsDecisionTaken: data.hasAllRowsDecisionTaken,
          loadState: LoadState.Loaded,
        },
      };

    case actionTypes.PACKAGE_SUMMARY_TO_VALIDATE_FETCH_FAILED:
      return {
        ...state,
        summary: {
          ...state.summary,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2: {
      const updatedSelectedItems = action.payload.map((x) => {
        const flags: Array<IFlag> = JSON.parse(x.content.Flags).map((x) => {
          return {
            ...x,
            FFL: "1",
          };
        });

        return {
          ...x,
          decision: String(Decision.UPHOLDREJECTION),
          content: {
            ...x.content,
            Flags: JSON.stringify(flags),
          },
        };
      });

      const updatedPackageItems = updatedSelectedItems.reduce((acc, curr) => {
        const index = acc.findIndex((x) => x.id === curr.id);

        if (index !== -1) {
          acc[index] = curr;
        }

        return acc;
      }, state.packageItems.items);

      const previousDecisions = getPreviousSelectedItemsDecisions(
        action.payload,
        false,
        []
      );

      return {
        ...state,
        selectedItems: updatedSelectedItems,
        packageItems: {
          ...state.packageItems,
          items: updatedPackageItems,
        },
        summary: {
          ...state.summary,
          completed:
            action.payload.length +
            (state.summary.completed - previousDecisions.completed),
          upheld:
            action.payload.length +
            (state.summary.upheld - previousDecisions.upheld),
          overturned: state.summary.overturned - previousDecisions.overturned,
          rowsToReview:
            state.summary.rowsToReview - previousDecisions.rowsToReview,
        },
      };
    }

    case actionTypes.UPDATE_UPHOLD_REJECTION_DECISIONS_APPEAL_V2_COMPLETED:
      return {
        ...state,
        selectedItems: [],
      };

    case actionTypes.UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2: {
      const updatedSelectedItems = action.payload.map((x) => {
        const flags: Array<IFlag> = JSON.parse(x.content.Flags).map((x) => {
          return {
            ...x,
            FFL: "5",
          };
        });

        return {
          ...x,
          decision: String(Decision.OVERTURNREJECTION),
          content: {
            ...x.content,
            Flags: JSON.stringify(flags),
          },
        };
      });

      const updatedPackageItems = updatedSelectedItems.reduce((acc, curr) => {
        const index = acc.findIndex((x) => x.id === curr.id);

        if (index !== -1) {
          acc[index] = curr;
        }

        return acc;
      }, state.packageItems.items);

      const previousDecisions = getPreviousSelectedItemsDecisions(
        action.payload,
        false,
        []
      );

      return {
        ...state,
        selectedItems: updatedSelectedItems,
        packageItems: {
          ...state.packageItems,
          items: updatedPackageItems,
        },
        summary: {
          ...state.summary,
          completed:
            action.payload.length +
            (state.summary.completed - previousDecisions.completed),
          overturned:
            action.payload.length +
            (state.summary.overturned - previousDecisions.overturned),
          rowsToReview:
            state.summary.rowsToReview - previousDecisions.rowsToReview,
          upheld: state.summary.upheld - previousDecisions.upheld,
        },
      };
    }

    case actionTypes.UPDATE_OVERTURN_REJECTION_DECISIONS_APPEAL_V2_COMPLETED:
      return {
        ...state,
        selectedItems: [],
      };

    case actionTypes.APPEAL_V2_SUBMIT:
      const updatedSelectedItem = state.selectedItems.map((x) => {
        return {
          ...x,
          decision:
            action.payload.selectedOtherOption === "" ||
            action.payload.selectedOtherOption === "0"
              ? String(Decision.APPEAL_V2)
              : action.payload.selectedOtherOption,
          content: {
            ...x.content,
            Flags: JSON.stringify(action.payload.flags),
          },
        };
      });

      return {
        ...state,
        selectedItems: updatedSelectedItem,
        packageItems: {
          ...state.packageItems,
          items: getApplealV2FlagsItems(
            state.packageItems.items,
            updatedSelectedItem
          ),
        },
      };

    case actionTypes.UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2: {
      const updatedSelectedItem = action.payload.map((x) => {
        const flags: Array<IFlag> = JSON.parse(x.content.Flags).map((x) => {
          return {
            ...x,
            FFL: "",
          };
        });

        return {
          ...x,
          decision: String(Decision.APPEAL_V2),
          content: {
            ...x.content,
            Flags: JSON.stringify(flags),
          },
        };
      });

      const previousDecisions = getPreviousSelectedItemsDecisions(
        action.payload,
        false,
        []
      );

      return {
        ...state,
        selectedItems: updatedSelectedItem,
        packageItems: {
          ...state.packageItems,
          items: getApplealV2FlagsItems(
            state.packageItems.items,
            updatedSelectedItem
          ),
        },
        summary: {
          ...state.summary,
          completed: state.summary.completed - previousDecisions.completed,
          rowsToReview:
            action.payload.length +
            (state.summary.rowsToReview - previousDecisions.rowsToReview),
          upheld: state.summary.upheld - previousDecisions.upheld,
          overturned: state.summary.overturned - previousDecisions.overturned,
        },
      };
    }

    case actionTypes.UPDATE_TO_REVIEW_DECISIONS_APPEAL_V2_COMPLETED:
      return {
        ...state,
        selectedItems: [],
      };

    default:
      return state;
  }
};

function getPackageSummary(payload: any): IReviewPackageSummary {
  const getElapsedTime = (): Date => {
    const value = payload.summary.startTime || payload.summary.publishedAt;
    if (value) {
      return dateUtils.getUTCDateTime(value);
    }

    return new Date();
  };

  const elapsedTime = getElapsedTime();

  const reviewTime = dateUtils.getElapsedDuration(
    dateUtils.getUTCDateTime(payload.summary.startTime)
  );

  return {
    id: payload.id,
    startTime: payload.summary.startTime,
    completed: payload.summary.overturned + payload.summary.upheld,
    publishedAt: payload.summary.publishedAt,
    overturned: payload.summary.overturned,
    flagAreas: payload.summary.flagAreas,
    rowsToReview: payload.summary.rowsToReview,
    upheld: payload.summary.upheld,
    reviewTime: reviewTime >= 0 ? reviewTime : 0,
    elapsedTime: elapsedTime,
    loadState: LoadState.Loaded,
    aggregatedColumns: {
      customerIds:
        (payload.summary.aggregatedColumns &&
          payload.summary.aggregatedColumns["CustomerId"]) ||
        [],
      entityIds:
        (payload.summary.aggregatedColumns &&
          payload.summary.aggregatedColumns["EntityId"]) ||
        [],
      entityTypes:
        (payload.summary.aggregatedColumns &&
          payload.summary.aggregatedColumns["SubEntityTypeText"]) ||
        [],
    },
    hasAllRowsDecisionTaken: payload.summary.hasAllRowsDecisionTaken,
  };
}

function redirectTo(payload: any) {
  setTimeout(() => {
    history.push(payload);
  }, 3000);
}

function getPreviousSelectedItemsDecisions(
  selectedItems: Array<IReviewPackageItem>,
  addFlagForceDecisionOverturned: boolean,
  disabledFlagAreas: Array<string>,
  isFromAddFlagDefaultDecision?: boolean
) {
  return selectedItems.reduce(
    (acc, item) => {
      const isDisabledFlagArea = disabledFlagAreas.includes(
        item.content.FlagAreaID
      );
      const flags = (item.extraDecision && item.extraDecision.newFlags) || [];

      // Since we are forcing the decision to be overturned when submitting a flag if flag area is review required,
      // this below condition is to sync back the decisions as to way it was before forcing the overturned decision.
      if (
        (isDisabledFlagArea || addFlagForceDecisionOverturned) &&
        flags.length > 0
      ) {
        switch (Number(item.decision)) {
          case Decision.OVERTURNREJECTION:
            ++acc.upheld;
            ++acc.completed;
            break;
        }
      } else {
        switch (Number(item.decision)) {
          case Decision.TOREVIEW:
          case Decision.APPEAL_V2:
            ++acc.rowsToReview;
            break;

          case Decision.OVERTURNREJECTION:
            isFromAddFlagDefaultDecision ? ++acc.upheld : ++acc.overturned;
            ++acc.completed;
            break;

          case Decision.UPHOLDREJECTION:
            ++acc.upheld;
            ++acc.completed;
            break;
        }
      }

      return acc;
    },
    { upheld: 0, overturned: 0, completed: 0, rowsToReview: 0 }
  );
}

function getPreviousFlagAreaDecisions(
  selectedItems: Array<IReviewPackageItem>,
  addFlagForceDecisionOverturned: boolean,
  isFromAddFlagDefaultDecision?: boolean
) {
  return selectedItems.reduce((acc, item) => {
    const values = acc[item.content.FlagAreaID] || {
      total: 0,
      upheld: 0,
      overturned: 0,
      incomplete: 0,
    };
    switch (Number(item.decision)) {
      case Decision.TOREVIEW:
        ++values["incomplete"];
        break;

      case Decision.OVERTURNREJECTION:
        isFromAddFlagDefaultDecision || addFlagForceDecisionOverturned
          ? ++values["upheld"]
          : ++values["overturned"];
        break;

      case Decision.UPHOLDREJECTION:
        ++values["upheld"];
        break;
    }

    ++values["total"];
    acc[item.content.FlagAreaID] = values;
    return acc;
  }, {});
}

function shouldIgnoreVNextSamples(
  subEntityTypeText: SubEntityTypeText
): boolean {
  switch (subEntityTypeText) {
    case SubEntityTypeText.ImageAsset:
    case SubEntityTypeText.VideoAsset:
      return true;

    default:
      return false;
  }
}

function getTranslations(
  isTranslationEnabled: boolean,
  packageGuid: string
): Record<string, string> {
  const currentLocalStorageTransKey = `${packageGuid}-translations`;

  Object.keys(localStorage).forEach((key) => {
    if (key !== currentLocalStorageTransKey && key.endsWith("translations")) {
      localStorage.removeItem(key);
    }
  });

  if (isTranslationEnabled) {
    const results = localStorage.getItem(currentLocalStorageTransKey);

    if (results && !isEmptyObject(results)) {
      return JSON.parse(results);
    }
  }

  return {};
}

function getFlagAreasAddFlag(
  updatedItems: IReviewPackageItem[],
  flagAreaSummary: IReviewPackageFlagAreaSummary[],
  flag: IAddFlag,
  addFlagForceDecisionOverturned: boolean,
  disabledFlagAreas: Array<string>
) {
  updatedItems.forEach((x) => {
    const flags = (x.extraDecision && x.extraDecision.newFlags) || [];

    const decision =
      (disabledFlagAreas.includes(x.content.FlagAreaID) ||
        addFlagForceDecisionOverturned) &&
      x.decision === String(Decision.OVERTURNREJECTION)
        ? String(Decision.UPHOLDREJECTION)
        : x.decision;

    if (flags.length === 1) {
      const index = flagAreaSummary.findIndex(
        (fa) => Number(fa.id) === Number(x.content.FlagAreaID)
      );

      if (index > -1) {
        --flagAreaSummary[index].total;

        switch (Number(decision)) {
          case Decision.OVERTURNREJECTION:
            --flagAreaSummary[index].overturned;
            break;

          case Decision.UPHOLDREJECTION:
            --flagAreaSummary[index].upheld;
            break;

          default:
            --flagAreaSummary[index].incomplete;
            break;
        }
      }
    }

    const sameFlagAreas = flags.filter(
      (x) => Number(x.flagAreaID) === Number(flag.flagAreaID)
    );

    if (sameFlagAreas.length === 1) {
      const value: IReviewPackageFlagAreaSummary = {
        id: flag.flagAreaID,
        incomplete: 0,
        overturned: 0,
        total: 1,
        upheld: 0,
      };

      switch (Number(decision)) {
        case Decision.OVERTURNREJECTION:
          ++value.overturned;
          break;

        case Decision.UPHOLDREJECTION:
          ++value.upheld;
          break;

        default:
          ++value.incomplete;
          break;
      }

      const index = flagAreaSummary.findIndex(
        (x) => Number(x.id) === Number(value.id)
      );

      if (index > -1) {
        ++flagAreaSummary[index].total;

        switch (Number(decision)) {
          case Decision.OVERTURNREJECTION:
            ++flagAreaSummary[index].overturned;
            break;

          case Decision.UPHOLDREJECTION:
            ++flagAreaSummary[index].upheld;
            break;

          default:
            ++flagAreaSummary[index].incomplete;
            break;
        }
      } else {
        flagAreaSummary.push(value);
      }
    }
  });

  return flagAreaSummary;
}

function getFlagAreasDeleteFlag(
  item: IReviewPackageItem,
  faIndex: number,
  flagAreaSummary: IReviewPackageFlagAreaSummary[],
  addFlagForceDecisionOverturned: boolean,
  disabledFlagAreas: Array<string>
) {
  const flags = (item.extraDecision && item.extraDecision.newFlags) || [];
  const removedFlags = flags.filter((_, index) => index === faIndex);

  removedFlags.forEach((x) => {
    const isDisabledFlagArea = disabledFlagAreas.includes(
      item.content.FlagAreaID
    );

    const decision =
      isDisabledFlagArea || addFlagForceDecisionOverturned
        ? String(Decision.UPHOLDREJECTION)
        : item.decision;

    if (flags.length === 1) {
      const index = flagAreaSummary.findIndex(
        (fa) => Number(fa.id) === Number(item.content.FlagAreaID)
      );

      if (index > -1) {
        ++flagAreaSummary[index].total;

        switch (Number(decision)) {
          case Decision.OVERTURNREJECTION:
            ++flagAreaSummary[index].overturned;
            break;

          case Decision.UPHOLDREJECTION:
            ++flagAreaSummary[index].upheld;
            break;

          default:
            ++flagAreaSummary[index].incomplete;
            break;
        }
      } else {
        const value: IReviewPackageFlagAreaSummary = {
          id: Number(item.content.FlagAreaID),
          incomplete: 0,
          overturned: 0,
          total: 1,
          upheld: 0,
        };

        switch (Number(decision)) {
          case Decision.OVERTURNREJECTION:
            ++value.overturned;
            break;

          case Decision.UPHOLDREJECTION:
            ++value.upheld;
            break;

          default:
            ++value.incomplete;
            break;
        }

        flagAreaSummary.push(value);
      }
    }

    const sameFlagAreas = flags.filter(
      (x) => Number(x.flagAreaID) === Number(x.flagAreaID)
    );

    if (sameFlagAreas.length === 1) {
      const faIndex = flagAreaSummary.findIndex(
        (fa) => Number(fa.id) === Number(x.flagAreaID)
      );

      if (faIndex > -1) {
        --flagAreaSummary[faIndex].total;

        if (flagAreaSummary[faIndex].total === 0) {
          flagAreaSummary = flagAreaSummary.filter(
            (fa) => Number(fa.id) !== flagAreaSummary[faIndex].id
          );
        } else {
          switch (Number(decision)) {
            case Decision.OVERTURNREJECTION:
              --flagAreaSummary[faIndex].overturned;
              break;

            case Decision.UPHOLDREJECTION:
              --flagAreaSummary[faIndex].upheld;
              break;

            default:
              --flagAreaSummary[faIndex].incomplete;
              break;
          }
        }
      }
    }
  });

  return flagAreaSummary;
}

function getFlagAreasModifyAllFlags(
  item: IReviewPackageItem,
  flagAreaSummary: IReviewPackageFlagAreaSummary[],
  isDisabledFlagArea: boolean,
  decision: Decision,
  addFlagForceDecisionOverturned: boolean
) {
  const flags = (item.extraDecision && item.extraDecision.newFlags) || [];

  if (flags.length > 0) {
    if (isDisabledFlagArea || addFlagForceDecisionOverturned) {
      if (decision === Decision.UPHOLDREJECTION) {
        return flagAreaSummary;
      }

      flags
        .map((x) => x.flagAreaID)
        .filter((value, index, self) => self.indexOf(value) === index)
        .forEach((flagAreaId) => {
          const decision = isDisabledFlagArea
            ? String(Decision.UPHOLDREJECTION)
            : item.decision;

          const faIndex = flagAreaSummary.findIndex(
            (fa) => Number(fa.id) === Number(flagAreaId)
          );

          if (faIndex > -1) {
            --flagAreaSummary[faIndex].total;

            if (flagAreaSummary[faIndex].total === 0) {
              flagAreaSummary = flagAreaSummary.filter(
                (fa) => Number(fa.id) !== Number(flagAreaSummary[faIndex].id)
              );
            } else {
              switch (Number(decision)) {
                case Decision.OVERTURNREJECTION:
                  --flagAreaSummary[faIndex].overturned;
                  break;

                case Decision.UPHOLDREJECTION:
                  --flagAreaSummary[faIndex].upheld;
                  break;

                default:
                  --flagAreaSummary[faIndex].incomplete;
                  break;
              }
            }
          }
        });

      const index = flagAreaSummary.findIndex(
        (fa) => Number(fa.id) === Number(item.content.FlagAreaID)
      );

      if (index > -1) {
        ++flagAreaSummary[index].total;

        switch (Number(item.decision)) {
          case Decision.OVERTURNREJECTION:
            ++flagAreaSummary[index].overturned;
            break;

          case Decision.UPHOLDREJECTION:
            ++flagAreaSummary[index].upheld;
            break;

          default:
            ++flagAreaSummary[index].incomplete;
            break;
        }
      } else {
        const value: IReviewPackageFlagAreaSummary = {
          id: Number(item.content.FlagAreaID),
          incomplete: 0,
          overturned: 0,
          total: 1,
          upheld: 0,
        };

        switch (Number(decision)) {
          case Decision.OVERTURNREJECTION:
            ++value.overturned;
            break;

          case Decision.UPHOLDREJECTION:
            ++value.upheld;
            break;

          default:
            ++value.incomplete;
            break;
        }

        flagAreaSummary.push(value);
      }
    } else {
      flags
        .map((x) => x.flagAreaID)
        .filter((value, index, self) => self.indexOf(value) === index)
        .forEach((flagAreaId) => {
          const index = flagAreaSummary.findIndex(
            (fa) => Number(fa.id) === Number(item.content.FlagAreaID)
          );

          if (index > -1) {
            switch (Number(item.decision)) {
              case Decision.OVERTURNREJECTION:
                ++flagAreaSummary[index].overturned;
                break;

              case Decision.UPHOLDREJECTION:
                ++flagAreaSummary[index].upheld;
                break;

              default:
                ++flagAreaSummary[index].incomplete;
                break;
            }

            switch (decision) {
              case Decision.OVERTURNREJECTION:
                --flagAreaSummary[index].overturned;
                break;

              case Decision.UPHOLDREJECTION:
                --flagAreaSummary[index].upheld;
                break;

              default:
                --flagAreaSummary[index].incomplete;
                break;
            }
          }

          const faIndex = flagAreaSummary.findIndex(
            (fa) => Number(fa.id) === Number(flagAreaId)
          );

          if (faIndex > -1) {
            switch (Number(item.decision)) {
              case Decision.OVERTURNREJECTION:
                --flagAreaSummary[faIndex].overturned;
                break;

              case Decision.UPHOLDREJECTION:
                --flagAreaSummary[faIndex].upheld;
                break;

              default:
                --flagAreaSummary[faIndex].incomplete;
                break;
            }

            switch (decision) {
              case Decision.OVERTURNREJECTION:
                ++flagAreaSummary[faIndex].overturned;
                break;

              case Decision.UPHOLDREJECTION:
                ++flagAreaSummary[faIndex].upheld;
                break;

              default:
                ++flagAreaSummary[faIndex].incomplete;
                break;
            }
          }
        });
    }
  }

  return flagAreaSummary;
}

function resetImageTranslation(): IImageTranslationSummary[] {
  const initValue: IImageTranslationSummary = {
    originalText: "",
    translatedText: "",
    id: -1,
  };
  return [initValue];
}

function getImageTranslations(
  oldTranslatedTextArr: IImageTranslationSummary[],
  originalText: string,
  translatedText: string,
  index: number
): IImageTranslationSummary[] {
  const translatedObj: IImageTranslationSummary = {
    originalText: originalText,
    translatedText: translatedText,
    id: index,
  };
  const initValue: IImageTranslationSummary = {
    originalText: "",
    translatedText: "",
    id: -1,
  };

  const newTranslateArr = oldTranslatedTextArr;
  for (let i = 0; i <= index; i++) {
    if (i != index && (!newTranslateArr || !newTranslateArr[i])) {
      initValue.id = i;
      newTranslateArr.push(initValue);
    } else {
      if (index < newTranslateArr.length)
        newTranslateArr[index] = translatedObj;
      else newTranslateArr.push(translatedObj);
    }
  }
  return newTranslateArr;
}

function getApplealV2FlagsItems(
  items: IReviewPackageItem[],
  selectedItems: IReviewPackageItem[]
) {
  const index = items.findIndex((x) => x.id === selectedItems[0].id);

  if (index > -1) {
    items[index] = selectedItems[0];
  }

  return items;
}
