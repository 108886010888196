import { LoadState, QueueTypes } from "@trinity/enums";

export interface IMisMatchPackage {
  PackageGuid: string;
  ContentType: number;
  CreatedDate: string;
  AssignedToUserId: number;
  AssignedToUserName: string;
  WorkflowStatusId: number;
  RowsCount: number;
}

export interface ICurrentPackage {
  id: string;
  queueId: number;
  queueName: string;
  queueIsTranslationEnabled: boolean;
  queueLanguage: number;
  queueType: QueueTypes;
  workflowStatusId: number;
  loadState: LoadState;
  assignedUserID: number | null;
  assignedToUserEmail: string;
}

export interface IPackageDetails {
  dSeqId: number;
  sequenceId: number;
  packageGuid: string;
  capabilityID: number;
  metaInfoJson: string;
  rowsCount: number;
  workflowStatusId: number;
  createdAt: Date;
  modifiedAt: Date | null;
  assignedAt: Date | null;
  completedAt: Date | null;
  assignedUserID: number | null;
  modifiedBy: string | null;
  capabilityName: string;
  reviewType: number;
  rowDateCreated: Date;
  queueType: number;
  entityType: number;
  segment: number;
  language: number;
  region: number;
  isSystemDefined: boolean;
  priority: number;
  packageCheckoutDuration: number;
  isTranslationEnabled: boolean;
  assignedToUserEmail: string;
}

export enum InputKeys {
  START_DATE = "startDate",
  END_DATE = "endDate",
}

export interface IDateRange {
  startDate?: string;
  endDate?: string;
}

export interface IAdminMismatchReportState {
  loadState: LoadState;
  packages: IMisMatchPackage[];
  isViewOnly: boolean;
  currentPackage: ICurrentPackage;
  dateRange: IDateRange;
}

export const DefaultCurrentPackage: ICurrentPackage = {
  id: "",
  queueId: 0,
  queueName: "",
  queueIsTranslationEnabled: false,
  queueLanguage: -1,
  queueType: QueueTypes.None,
  workflowStatusId: -1,
  loadState: LoadState.NotLoaded,
  assignedUserID: null,
  assignedToUserEmail: "",
};

export const DefaultMismatchReportState: IAdminMismatchReportState = {
  loadState: LoadState.NotLoaded,
  packages: [],
  currentPackage: DefaultCurrentPackage,
  isViewOnly: false,
  dateRange: { startDate: "", endDate: "" },
};

export enum actionTypes {
  SET_MISMATCH_PACKAGES_DATE_RANGE = "[ADMIN-MISMATCH-PACKAGE] - SET_MISMATCH_PACKAGES_DATE_RANGE",
  GET_MISMATCH_PACKAGES = "[ADMIN-MISMATCH-PACKAGE] - GET_MISMATCH_PACKAGES",
  GET_MISMATCH_PACKAGES_RECEIVED = "[ADMIN-MISMATCH-PACKAGE] - GET_MISMATCH_PACKAGES_RECEIVED",
  GET_MISMATCH_PACKAGES_FAILED = "[ADMIN-MISMATCH-PACKAGE] - GET_MISMATCH_PACKAGES_FAILED",

  UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE = "[ADMIN-MISMATCH-PACKAGE] - UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE",
  UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE_FAILED = "[ADMIN-MISMATCH-PACKAGE] - UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE_FAILED",

  GET_PACKAGE_DETAILS = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS",
  GET_PACKAGE_DETAILS_FAILED = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS_FAILED",
  GET_PACKAGE_DETAILS_COMPLETE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_PACKAGE_DETAILS_COMPLETE",
  GET_CURRENT_PACKAGE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE",
  GET_CURRENT_PACKAGE_COMPLETE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE_COMPLETE",
  GET_CURRENT_PACKAGE_FAILED = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_CURRENT_PACKAGE_FAILED",

  GET_ADMIN_MISMATCH_REVIEWING_PACKAGE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_ADMIN_MISMATCH_REVIEWING_PACKAGE",
  GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_COMPLETE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_COMPLETE",
  GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_FAILED = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_FAILED",
  ASSIGN_AND_REVIEW_PACKAGE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - ASSIGN_AND_REVIEW_PACKAGE",
  ASSIGN_AND_REVIEW_PACKAGE_FAILED = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - ASSIGN_AND_REVIEW_PACKAGE_FAILED",
  RESET_CURRENT_PACKAGE = "[ADMIN-MISMATCH-REVIEW-PACKAGE] - RESET_CURRENT_PACKAGE",
}
