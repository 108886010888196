import { configs } from "../../configs";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import { MessageBarType } from "@fluentui/react";
import { actionCreators as commonActions } from "../common/CommonActions";
import { IEvdRolesSummary, IUser } from "./AdminUserManagementState";
import { IApplicationState } from "..";
import { UserActionTypes, UserRole } from "@trinity/enums";
import { IUserState } from "../user/UserState";
import { v4 as uuidv4 } from "uuid";
import { IUserActionPayload } from "../common/CommonState";

export enum actionTypes {
  GET_USERS_SUMMARY = "GET_USERS_SUMMARY",
  GET_USERS_SUMMARY_FAILED = "GET_USERS_SUMMARY_FAILED",
  GET_USERS = "GET_USERS",
  GET_USERS_FAILED = "GET_USERS_FAILED",
  GET_ALL_QUEUES = "GET_ALL_QUEUES",
  GET_ALL_QUEUES_FAILED = "GET_ALL_QUEUES_FAILED",
  SUBMIT_USER = "SUBMIT_USER",
  SUBMIT_USER_FAILED = "SUBMIT_USER_FAILED",
  SELECTED_ITEM = "SELECTED_ITEM",
  GET_SEARCHED_USER = "GET_SEARCHED_USER",
  GET_SEARCHED_USER_FAILED = "GET_SEARCHED_USER_FAILED",
}

export const actionCreators = {
  getUsersSummary: () => async (dispatch: any) => {
    try {
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + "users/summary"
        )
      );

      const data = (await response.json()) as Array<IEvdRolesSummary>;
      dispatch({
        type: actionTypes.GET_USERS_SUMMARY,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_USERS_SUMMARY_FAILED,
      });
      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );
    }
  },

  getUsers: () => async (dispatch: any, getState: () => IApplicationState) => {
    try {
      dispatch(
        commonActions.showMessage({
          message: "Loading...",
          type: MessageBarType.info,
        })
      );

      const response = await dispatch(
        fetchCreator(`${configs.client.endpoint.appServiceEndpoint}users`)
      );

      const data = await response.json();

      dispatch(commonActions.hideMessage());
      dispatch({
        type: actionTypes.GET_USERS,
        payload: {
          items: data.value as Array<IUser>,
          count: data["@odata.count"],
        },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_USERS_FAILED,
      });

      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );
    }
  },

  searchUsers:
    (value: string) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      try {
        if (value.trim()) {
          const fetchAction = fetchCreator(
            `${configs.client.endpoint.appServiceEndpoint}users?$search=${value}`
          );
          const debounceAction = {
            ...fetchAction,
            meta: {
              debounce: {
                name: "searchUsers",
                time: 1000,
              },
            },
          };
          const response = await dispatch(debounceAction);
          const data = await response.json();
          dispatch({
            type: actionTypes.GET_SEARCHED_USER,
            payload: data.value as Array<IUser>,
          });

          return getState().adminUserManagement.searchedUsers;
        }

        return [];
      } catch (error: any) {
        dispatch({
          type: actionTypes.GET_SEARCHED_USER_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  getAllQueues: () => async (dispatch: any, getState) => {
    try {
      const roles = getState().user.userRoles;
      if (
        roles.includes(UserRole.EditorialAdmin) ||
        roles.includes(UserRole.EditorialChineseAdmin)
      ) {
        const response = await dispatch(
          fetchCreator(
            configs.client.endpoint.appServiceEndpoint + `queues/all`
          )
        );
        const data = await response.json();

        dispatch({
          type: actionTypes.GET_ALL_QUEUES,
          payload: data,
        });
      }
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_ALL_QUEUES_FAILED,
      });
      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );
    }
  },

  selectedItem: (item: IUser | undefined) => async (dispatch: any) => {
    dispatch({
      type: actionTypes.SELECTED_ITEM,
      payload: item,
    });
  },

  submitUser: (user: IUser, userId: number) => async (dispatch: any) => {
    try {
      dispatch(
        commonActions.showMessage({
          message: "Submitting user details...",
          type: MessageBarType.info,
        })
      );
      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + `users/${userId}`,
          {
            method: "PUT",
            body: JSON.stringify(user),
          }
        )
      );

      dispatch({
        type: actionTypes.SUBMIT_USER,
      });
      dispatch(actionCreators.getUsers());
      dispatch(actionCreators.getUsersSummary());
      dispatch(actionCreators.postUserRoleAssignmentAction(userId, user.roles));
    } catch (error: any) {
      dispatch({
        type: actionTypes.SUBMIT_USER_FAILED,
      });
      dispatch(
        commonActions.showMessage({
          message: error.message,
          type: MessageBarType.error,
        })
      );
    }
  },

  postUserRoleAssignmentAction:
    (selectedUser: number, roles: number[]) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const { userId } = getState().user as IUserState;
      const payload: IUserActionPayload = {
        id: uuidv4(),
        userId,
        type: UserActionTypes.UserRoleAssignment,
        dateTime: new Date().toISOString(),
        extraInfo: JSON.stringify({
          selectedUser,
          roles,
        }),
      };
      dispatch(commonActions.postUserAction([payload]));
    },
};
