import { authInstance } from "../../components/auth/AuthModule";
import { IFetchOptions } from "./fetch";

const FETCH = "utils/fetch";

interface IFetchAction {
  type: string;
  payload: {
    input: RequestInfo;
    init: RequestInit;
  };
}

export const authMiddleware = (store) => (next) => (action) => {
  if (action.type === FETCH) {
    const fetchAction = action as IFetchAction;
    const fetchOptions = fetchAction.payload.init as IFetchOptions;
    const scope =
      fetchOptions.customOptions && fetchOptions.customOptions.scope;

    return new Promise((resolve, reject) => {
      authInstance
        .acquireToken(scope)
        .then((response) => {
          if (!fetchAction.payload.init.headers) {
            fetchAction.payload.init.headers = {};
          }
          Object.assign(fetchAction.payload.init.headers, {
            Authorization: "Bearer " + response,
          });

          // Resolve or reject the promise with the results of subsequent middleware
          resolve(next(action));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return next(action);
};
