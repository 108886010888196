import { MessageBarType } from "@fluentui/react";
import { IApplicationState } from "..";
import { configs } from "../../configs";
import {
  Brackets,
  EnforcementStatus,
  JoinCondition,
  Operator,
  TNSEntityType,
  TNSTableView,
  UserActionTypes,
  UserRole,
} from "@trinity/enums";
import { ISharedValue } from "../../modules/tns-explore/TNSExplore.types";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import {
  IExtraPreferences,
  IUserModulePreferences,
} from "../user-preference/UserPreferenceState";
import {
  ICustomerEnforcePayload,
  IEnforcePayload,
  IRowDefinition,
  ITNSExploreState,
} from "./TNSExploreState";
import { actionCreators as commonActions } from "../common/CommonActions";
import { getRiskScope } from "../../utils/common/getRiskScope";
import { IUserActionPayload } from "../common/CommonState";
import { v4 as uuidv4 } from "uuid";

export enum actionTypes {
  SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS",
  SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS",
  SEARCH_CUSTOMERS_FAILED = "SEARCH_CUSTOMERS_FAILED",
  SEARCH_PIVOT = "SEARCH_PIVOT",
  SEARCH_PIVOT_SUCCESS = "SEARCH_PIVOT_SUCCESS",
  SEARCH_PIVOT_FAILED = "SEARCH_PIVOT_FAILED",
  CLEAR_ENFORCE = "CLEAR_ENFORCE",
  SEARCHEDITOR_SEARCH = "SEARCHEDITOR_SEARCH",
  SEARCHEDITOR_ADD = "SEARCHEDITOR_ADD",
  SEARCHEDITOR_DELETE = "SEARCHEDITOR_DELETE",
  SEARCHEDITOR_JOINCHANGE = "SEARCHEDITOR_JOINCHANGE",
  SEARCHEDITOR_BRACKETCHANGE = "SEARCHEDITOR_BRACKETCHANGE",
  SEARCHEDITOR_FIELDCHANGE = "SEARCHEDITOR_FIELDCHANGE",
  SEARCHEDITOR_VALUECHANGE = "SEARCHEDITOR_VALUECHANGE",
  SEARCHEDITOR_OPERATORCHANGE = "SEARCHEDITOR_OPERATORCHANGE",
  SEARCHEDITOR_CLEAR = "SEARCHEDITOR_CLEAR",
  SAVE_SEARCH = "SAVE_SEARCH",
  ADVANCED_SEARCH_CUSTOMERS = "ADVANCED_SEARCH_CUSTOMERS",
  LOAD_SAVED_SEARCH = "LOAD_SAVED_SEARCH",
  DELETE_SEARCH = "DELETE_SEARCH",
  INCREMENT_ROWID = "INCREMENT_ROWID",
  CHANGE_TABLE_VIEW = "CHANGE_TABLE_VIEW",
  SEARCH_PIVOT_CLEAR = "SEARCH_PIVOT_CLEAR",
  GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS",
  GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS",
  GET_CUSTOMER_DETAILS_FAILED = "GET_CUSTOMER_DETAILS_FAILED",
  GET_CUSTOMER_NETWORK = "GET_CUSTOMER_NETWORK",
  GET_CUSTOMER_NETWORK_SUCCESS = "GET_CUSTOMER_NETWORK_SUCCESS",
  GET_CUSTOMER_NETWORK_FAILED = "GET_CUSTOMER_NETWORK_FAILED",
  ENFORCE_DECISION = "ENFORCE_DECISION",
  ENFORCE_DECISION_SUCCESS = "ENFORCE_DECISION_SUCCESS",
  ENFORCE_DECISION_FAILED = "ENFORCE_DECISION_FAILED",
  SEARCHEDITOR_CLOSEBRACKETCHANGE = "SEARCHEDITOR_CLOSEBRACKETCHANGE",
  SEARCHEDITOR_OPENBRACKETCHANGE = "SEARCHEDITOR_OPENBRACKETCHANGE",
  UPDATE_SEARCH_TYPE = "UPDATE_SEARCH_TYPE",
  ENFORCEMENT_RESPONSE_LOAD = "ENFORCEMENT_RESPONSE_LOAD",
  CLEAR_ENFORCEMENT_RESPONSE = "CLEAR_ENFORCEMENT_RESPONSE",
}

interface INode {
  LogicalOperator: JoinCondition | null;
  FieldName: string;
  FieldValue: string;
  FieldOperator: string;
  Children: INode[];
}

function getInputList(allRows: IRowDefinition[]) {
  const input: any = [];

  if (allRows[0].isBracketOpen && !allRows[0].isBracketClose) input.push("(");
  input.push({
    FieldName: allRows[0].field,
    FieldOperator: allRows[0].operator,
    FieldValue: allRows[0].value,
  });

  for (let i = 1; i < allRows.length; i++) {
    input.push(allRows[i].joinCondition);
    if (allRows[i].isBracketOpen && !allRows[i].isBracketClose) input.push("(");

    input.push({
      FieldName: allRows[i].field,
      FieldOperator: allRows[i].operator,
      FieldValue: allRows[i].value,
    });

    if (!allRows[i].isBracketOpen && allRows[i].isBracketClose) input.push(")");

    if (!allRows[0].isBracketOpen && allRows[0].isBracketClose) input.push(")");
  }

  return input;
}

function newNode(
  LogicalOperator: JoinCondition | null,
  FieldName: string,
  FieldValue: string,
  FieldOperator: string
): INode {
  return {
    LogicalOperator,
    Children: [],
    FieldName,
    FieldValue,
    FieldOperator,
  };
}

let iterator = 0;
function recur(node: INode, input: any[]) {
  if (iterator == input.length) {
    return;
  }

  if (input[iterator] == "(") {
    iterator++;

    while (input[iterator] != ")" && iterator != input.length) {
      if (input[iterator] == "(") {
        const childNode = newNode(null, "", "", "");
        node.Children.push(childNode);
        recur(childNode, input);
      } else if (
        input[iterator] == JoinCondition.OR ||
        input[iterator] == JoinCondition.AND
      ) {
        node.LogicalOperator = input[iterator];
      } else {
        node.Children.push({
          LogicalOperator: null,
          Children: [],
          ...input[iterator],
        });
      }
      iterator++;
    }
  }
}

const generateAdvancedSearchJSONTree = (allRows: IRowDefinition[]) => {
  if (allRows.length == 1) {
    return newNode(
      null,
      allRows[0].field,
      allRows[0].value,
      allRows[0].operator.toUpperCase()
    );
  }

  const inputArray: any[] = getInputList(allRows);
  inputArray.unshift("(");
  inputArray.push(")");

  let root = newNode(null, "", "", "");
  iterator = 0;
  recur(root, inputArray);
  if (!root.LogicalOperator && root.Children.length === 1) {
    root = root.Children[0];
  }
  return root;
};

const generateBasicSearchJSONTree = (term: string, filter: string) => {
  return newNode(null, filter, term, Operator.EQUALS);
};

const populatePivotResponse = (
  pivotResult: any[],
  tempPivotResponse: {
    customerName: ISharedValue[];
    phone: ISharedValue[];
    email: ISharedValue[];
    paymentInstr: ISharedValue[];
    domain: ISharedValue[];
  }
) => {
  pivotResult.forEach((result) => {
    switch (result.connectionEntityAttribute) {
      case "Email":
        tempPivotResponse.email.push(result);
        break;
      case "PhoneNumber":
        tempPivotResponse.phone.push(result);
        break;
      case "CustomerName":
        tempPivotResponse.customerName.push(result);
        break;
      case "FullDomain":
        tempPivotResponse.domain.push(result);
        break;
      case "PaymentInstr":
        tempPivotResponse.paymentInstr.push(result);
        break;
    }
  });
};

export const actionCreators = {
  searchCustomers:
    (allRows?: IRowDefinition[], term?: string, filter?: string) =>
    async (dispatch, getState) => {
      try {
        iterator = 0;
        const jsonTree = allRows
          ? generateAdvancedSearchJSONTree(allRows)
          : term && filter
          ? generateBasicSearchJSONTree(term, filter)
          : null;

        dispatch({
          type: actionTypes.SEARCH_CUSTOMERS,
        });
        const roles = getState().user.userRoles;
        const response = await dispatch(
          fetchCreator(`${configs.client.endpoint.tnsEndpoint}api/search`, {
            method: "POST",
            body: JSON.stringify(jsonTree),
            customOptions: { scope: getRiskScope(roles) },
          })
        );

        if (response.status === 204) {
          dispatch({
            type: actionTypes.SEARCH_CUSTOMERS_FAILED,
          });
          dispatch(
            commonActions.showMessage({
              message: "Incorrect details given.",
              type: MessageBarType.error,
            })
          );
          return;
        }

        const searchResult = await response.json();

        dispatch({
          type: actionTypes.SEARCH_CUSTOMERS_SUCCESS,
          payload: {
            result: searchResult,
            searchedObject: jsonTree,
            userRoles: roles,
          },
        });
      } catch (error: any) {
        dispatch({
          type: actionTypes.SEARCH_CUSTOMERS_FAILED,
          message: error.message,
        });
      }
    },

  searchPivot: (searchTree: INode) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.SEARCH_PIVOT,
      });
      const roles = getState().user.userRoles;
      const response = await dispatch(
        fetchCreator(`${configs.client.endpoint.tnsEndpoint}api/search/pivot`, {
          method: "POST",
          body: JSON.stringify(searchTree),
          customOptions: { scope: getRiskScope(roles) },
        })
      );
      const pivotResult = await response.json();
      const tempPivotResponse = {
        customerName: [],
        phone: [],
        email: [],
        paymentInstr: [],
        domain: [],
      };
      populatePivotResponse(pivotResult, tempPivotResponse);

      dispatch({
        type: actionTypes.SEARCH_PIVOT_SUCCESS,
        payload: { tempPivotResponse },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.SEARCH_PIVOT_FAILED,
        message: error.message,
      });
    }
  },

  clearSearchPivot: () => (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_PIVOT_CLEAR,
    });
  },

  updateSearchType: (type: ITNSExploreState["searchType"]) => (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH_TYPE,
      payload: { searchType: type },
    });
  },

  getCustomerDetails: (customerId: string) => async (dispatch, getState) => {
    const roles = getState().user.userRoles;
    const userId = getState().user.userId;

    try {
      dispatch({
        type: actionTypes.GET_CUSTOMER_DETAILS,
      });

      dispatch(
        commonActions.showMessage({
          message: "Getting customer details from Server...",
          type: MessageBarType.info,
        })
      );

      const payload: IUserActionPayload = {
        id: uuidv4(),
        userId,
        type: UserActionTypes.FLAReqSent,
        dateTime: new Date().toISOString(),
        extraInfo: { customerId: customerId },
      };

      dispatch(commonActions.postUserAction([payload]));

      const response = await dispatch(
        fetchCreator(
          `${configs.client.endpoint.tnsEndpoint}/api/customer/view/detail/${customerId}`,
          {
            method: "GET",
            customOptions: { scope: getRiskScope(roles) },
          }
        )
      );

      if (response.status === 204) {
        const payloadFail: IUserActionPayload = {
          id: uuidv4(),
          userId,
          type: UserActionTypes.FLAReqFailed,
          dateTime: new Date().toISOString(),
          extraInfo: "",
        };

        dispatch(commonActions.postUserAction([payloadFail]));

        dispatch({
          type: actionTypes.GET_CUSTOMER_DETAILS_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: "Incorrect details given.",
            type: MessageBarType.error,
          })
        );
        return;
      }

      const searchResult = await response.json();

      const payloadSuccess: IUserActionPayload = {
        id: uuidv4(),
        userId,
        type: UserActionTypes.FLAReqSuccess,
        dateTime: new Date().toISOString(),
        extraInfo: "",
      };

      dispatch(commonActions.postUserAction([payloadSuccess]));

      dispatch({
        type: actionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
        payload: { result: searchResult },
      });
    } catch (error: any) {
      const payloadFail: IUserActionPayload = {
        id: uuidv4(),
        userId,
        type: UserActionTypes.FLAReqFailed,
        dateTime: new Date().toISOString(),
        extraInfo: "",
      };

      dispatch(commonActions.postUserAction([payloadFail]));
      dispatch({
        type: actionTypes.GET_CUSTOMER_DETAILS_FAILED,
        message: error.message,
      });
    } finally {
      dispatch(commonActions.hideMessage());
    }
  },

  getCustomerNetwork: (customerId: string) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.GET_CUSTOMER_NETWORK,
      });
      const roles = getState().user.userRoles;

      const networkResponse = await dispatch(
        fetchCreator(
          `${configs.client.endpoint.tnsEndpoint}/api/customer/view/${customerId}/network`,
          {
            method: "GET",
            customOptions: { scope: getRiskScope(roles) },
          }
        )
      );

      if (networkResponse.status === 204) {
        dispatch({
          type: actionTypes.GET_CUSTOMER_NETWORK_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            message: "Incorrect details given.",
            type: MessageBarType.error,
          })
        );
        return;
      }

      const networkRAW = await networkResponse.json();

      dispatch({
        type: actionTypes.GET_CUSTOMER_NETWORK_SUCCESS,
        payload: { networkResponse: networkRAW },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_CUSTOMER_NETWORK_FAILED,
        message: error.message,
      });
    }
  },

  enforceCustomerDecision:
    (payload: ICustomerEnforcePayload) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actionTypes.ENFORCE_DECISION,
        });

        const roles = getState().user.userRoles;
        const userId = getState().user.userId;
        const userName = getState().user.alias.split("@")[0];

        const enforcementResponse = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.tnsEndpoint}api/enforcement`,
            {
              method: "POST",
              body: JSON.stringify({
                ...payload,
                userName: userName,
                userId: userId,
              }),
              customOptions: { scope: getRiskScope(roles) },
            }
          )
        );

        const enforcementResult = await enforcementResponse.json();

        dispatch({
          type: actionTypes.ENFORCE_DECISION_SUCCESS,
        });

        dispatch({
          type: actionTypes.ENFORCEMENT_RESPONSE_LOAD,
          payload: {
            enforcementResponse: enforcementResult,
          },
        });
      } catch (error: any) {
        dispatch({
          type: actionTypes.ENFORCE_DECISION_FAILED,
        });
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.error,
            message: error.message,
          })
        );
      } finally {
        setTimeout(() => {
          dispatch(
            commonActions.showMessage({
              type: MessageBarType.success,
              message: "",
            })
          );
        }, 8000);
      }
    },

  enforceDecision: (payload: IEnforcePayload) => async (dispatch, getState) => {
    try {
      const { entityType, entityItems, decision, reasonCodes, notes, source } =
        payload;
      dispatch({
        type: actionTypes.ENFORCE_DECISION,
      });

      const { userRoles, userId, email } = getState().user;

      const finalPayload = {
        entityType: entityType,
        entities: entityItems.map((entityItem) => {
          return entityType === TNSEntityType.Account
            ? {
                customerId: entityItem.customerId,
                accountId: entityItem.accountId,
              }
            : {
                customerId: entityItem.customerId,
                storeId: entityItem.storeId,
              };
        }),
        decision,
        reasonCode: reasonCodes,
        notes,
        modifiedByUserId: userId,
        modifiedByUserEmail: email,
        source: source,
      };
      const enforcementResponse = await dispatch(
        fetchCreator(
          `${configs.client.endpoint.tnsEndpoint}api/entityenforcement/enforce`,
          {
            method: "POST",
            body: JSON.stringify(finalPayload),
            customOptions: { scope: getRiskScope(userRoles) },
          }
        )
      );
      const enforcementResult = await enforcementResponse.json();

      dispatch({
        type: actionTypes.ENFORCE_DECISION_SUCCESS,
      });

      dispatch({
        type: actionTypes.ENFORCEMENT_RESPONSE_LOAD,
        payload: {
          enforcementResponse: enforcementResult,
        },
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.ENFORCE_DECISION_FAILED,
      });
      dispatch(
        commonActions.showMessage({
          type: MessageBarType.error,
          message: error.message,
        })
      );
    } finally {
      setTimeout(() => {
        dispatch(
          commonActions.showMessage({
            type: MessageBarType.success,
            message: "",
          })
        );
      }, 8000);
    }
  },

  searchEditorAdd:
    (defaultField: string, index: number) => (dispatch, getState) =>
      dispatch({
        type: actionTypes.SEARCHEDITOR_ADD,
        payload: { defaultField, index },
      }),

  searchEditorDelete: (rowIndex) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.SEARCHEDITOR_DELETE,
      payload: { rowIndex },
    });
  },

  searchEditorJoinChange:
    (rowIndex: number, newValue: JoinCondition) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_JOINCHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorOpenBracketChange:
    (rowIndex: number, newValue: Brackets) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_OPENBRACKETCHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorCloseBracketChange:
    (rowIndex: number, newValue: Brackets) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_CLOSEBRACKETCHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorFieldChange:
    (rowIndex: number, newValue: string) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_FIELDCHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorOperatorChange:
    (rowIndex: number, newValue: Operator) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_OPERATORCHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorValueChange:
    (rowIndex: number, newValue: string) => (dispatch, getState) => {
      dispatch({
        type: actionTypes.SEARCHEDITOR_VALUECHANGE,
        payload: { rowIndex, newValue },
      });
    },

  searchEditorClear: () => (dispatch, getState) => {
    dispatch({
      type: actionTypes.SEARCHEDITOR_CLEAR,
    });
  },

  incrementRowId: () => (dispatch) => {
    dispatch({
      type: actionTypes.INCREMENT_ROWID,
    });
  },

  saveSearch: (extras: IExtraPreferences) => (dispatch) => {
    dispatch(userPreferenceActions.updateTNSExplorerPreferencesExtra(extras));
  },

  deleteSearch: (searchName: string) => (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SEARCH,
      payload: { searchName },
    });
  },

  loadSearch: (selectedSearch) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.LOAD_SAVED_SEARCH,
      payload: { selectedSearch },
    });
  },

  changeTableView: (newTableView: TNSTableView) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.CHANGE_TABLE_VIEW,
      payload: { newTableView },
    });
  },

  updatePresetsTNSNetworkViewExaminePanel:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updatePresetsTNSNetworkViewExaminePanelPreferences(
          presets
        )
      );
    },

  updatePresetsTNSInsightsExaminePanel:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updatePresetsTNSInsightsExaminePanelPreferences(
          presets
        )
      );
    },

  updatePresetsTNSInsightsCardPanel:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updatePresetsTNSInsightsCardsPanelPreferences(
          presets
        )
      );
    },

  updatePresetsTNSExploreCustomers:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreCustomersPreferences(presets)
      );
    },

  updatePresetsTNSExploreAccounts:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreAccountsPreferences(presets)
      );
    },

  updatePresetsTNSExploreStores:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreStoresPreferences(presets)
      );
    },

  updatePresetsTNSExploreUsers:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateTNSExploreUsersPreferences(presets));
    },

  updatePresetsTNSExploreDomains:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreDomainsPreferences(presets)
      );
    },

  updatePresetsCustomerAccounts:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerProfileAccounts(presets));
    },

  updatePresetsCustomerDomains:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerProfileDomains(presets));
    },

  updatePresetsCustomerUsers:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerProfileUsers(presets));
    },

  updatePresetsCustomerPayments:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerProfilePayments(presets));
    },

  updatePresetsCustomerStores:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerProfileStores(presets));
    },

  updatePresetsCustomerInvestigate:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreInvestigatePreferences(presets)
      );
    },

  updatePresetsRiskPanel:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreRiskExaminePreferences(presets)
      );
    },
  updatePresetsMCAPanel:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSExploreMCAExaminePreferences(presets)
      );
    },

  updatePresetsDecsionHistory:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateCustomerDecisionHistoryPreferences(presets)
      );
    },
  updatePresetsDetectionHistory:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateCustomerDetectionHistoryPreferences(presets)
      );
    },

  updatePresetsAIVData:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(userPreferenceActions.updateCustomerAIVDataPreferences(presets));
    },

  clearEnforce: () => async (dispatch: any) => {
    dispatch({
      type: actionTypes.CLEAR_ENFORCE,
    });
  },
};
