import { ITag, IDropdownOption } from "@fluentui/react";
import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { JoinCondition, LoadState, Operator } from "@trinity/enums";
import { IUser } from "../admin-user-management/AdminUserManagementState";

export enum actionTypes {
  ADD_NEW_ATTRIBUTE = "ADD_NEW_ATTRIBUTE",
  REMOVE_ATTRIBUTE = "REMOVE_ATTRIBUTE",
  REMOVE_ALL_ATTRIBUTE = "REMOVE_ALL_ATTRIBUTE",
  ON_FIELD_CHANGE = "ON_FIELD_CHANGE",
  ON_OPERATOR_CHANGE = "ON_OPERATOR_CHANGE",
  ON_VALUE_CHANGE = "ON_VALUE_CHANGE",
  ON_INCREAMENT = "ON_INCREAMENT",
  INSERT_NEW_TRADEMARK = "INSERT_NEW_TRADEMARK",
  INSERT_NEW_TRADEMARK_FAILED = "INSERT_NEW_TRADEMARK_FAILED",
  IMPORT_TRADEMARK = "IMPORT_TRADEMARK",
  IMPORT_TRADEMARK_FAILED = "IMPORT_TRADEMARK_FAILED",
  SEARCH_TRADEMARK = "SEARCH_TRADEMARK",
  SEARCH_TRADEMARK_FAILED = "SEARCH_TRADEMARK_FAILED",
  EXPORT_TRADEMARK = "EXPORT_TRADEMARK",
  EXPORT_TRADEMARK_FAILED = "EXPORT_TRADEMARK_FAILED",
  CLEAR_EXPORT_DATA = "CLEAR_EXPORT_DATA",
  FETCH_ADVERTISER_DETAIL = "FETCH_ADVERTISER_DETAIL",
  FETCH_ADVERTISER_DETAIL_FAILED = "FETCH_ADVERTISER_DETAIL_FAILED",
  FETCH_SELECTED_ADVERTISER_DETAIL = "FETCH_SELECTED_ADVERTISER_DETAIL",
  FETCH_SELECTED_ADVERTISER_DETAIL_FAILED = "FETCH_SELECTED_ADVERTISER_DETAIL_FAILED",
  FETCH_ACCOUNT_DETAIL = "FETCH_ACCOUNT_DETAIL",
  FETCH_ACCOUNT_DETAIL_FAILED = "FETCH_ACCOUNT_DETAIL_FAILED",
  FETCH_SELECTED_ACCOUNT_DETAIL = "FETCH_SELECTED_ACCOUNT_DETAIL",
  FETCH_SELECTED_ACCOUNT_DETAIL_FAILED = "FETCH_SELECTED_ACCOUNT_DETAIL_FAILED",
  SELECTED_TRADEMARK = "SELECTED_TRADEMARK",
  SELECTED_TRADEMARK_FAILED = "SELECTED_TRADEMARK_FAILED",
  UPDATE_TRADEMARK = "UPDATE_TRADEMARK",
  UPDATE_TRADEMARK_FAILED = "UPDATE_TRADEMARK_FAILED",
  DELETE_TRADEMARK = "DELETE_TRADEMARK",
  DELETE_TRADEMARK_FAILED = "DELETE_TRADEMARK_FAILED",
  BULK_DELETE_TRADEMARK = "BULK_DELETE_TRADEMARK",
  BULK_DELETE_TRADEMARK_FAILED = "BULK_DELETE_TRADEMARK_FAILED",
  ON_LOAD_TRADEMARK_DATA = "ON_LOAD_TRADEMARK_DATA",
  FETCH_REVENUE_ACCOUNT_DETAIL = "FETCH_REVENUE_ACCOUNT_DETAIL",
  FETCH_REVENUE_ACCOUNT_DETAIL_FAILED = "FETCH_REVENUE_ACCOUNT_DETAIL_FAILED",
  FETCH_TOTAL_REVENUE_DATA = "FETCH_TOTAL_REVENUE_DATA",
  FETCH_TOTAL_REVENUE_DATA_FAILED = "FETCH_TOTAL_REVENUE_DATA_FAILED",
  FETCH_TRADEMARK_COUNTRY_LANGUAGE = "FETCH_TRADEMARK_COUNTRY_LANGUAGE",
  FETCH_TRADEMARK_COUNTRY_LANGUAGE_FROM_LOCAL = "FETCH_TRADEMARK_COUNTRY_LANGUAGE_FROM_LOCAL",
  FETCH_TRADEMARK_COUNTRY_LANGUAGE_FAILED = "FETCH_TRADEMARK_COUNTRY_LANGUAGE_FAILED",
  FETCH_TOTAL_REVENUE_FOR_IMPORT = "FETCH_TOTAL_REVENUE_FOR_IMPORT",
  FETCH_TOTAL_REVENUE_FOR_IMPORT_FAILED = "FETCH_TOTAL_REVENUE_FOR_IMPORT_FAILED",
}

export interface ISearchRequest {
  fieldName: string;
  operator: string;
  value: string;
}

export interface ISearchTrademarkItem extends ITableRowItemProps {
  term: string;
  matchType: string;
  customerIds: string;
  accountIds: string;
  LanguageCode: string;
  CountryCode: string;
  FlagLevel: string;
  expiryDtae: Date;
  id: string;
  modifiedBy: string;
}

export interface ISearchTrademarkDetails {
  exportData: ISearchTrademarkDetail[];
  searchResults: ISearchTrademarkDetail[];
  allAdvertisers: string | ITag[];
  allAccounts: string | ITag[];
}

export interface ISearchTrademarkDetail {
  term: string;
  matchType: string;
  customerIds: string;
  accountIds: string;
  LanguageCode: string;
  CountryCode: string;
  FlagLevel: string;
  expiryDtae: Date;
  id: string;
  modifiedBy: string;
}

export interface IRevenueGrainDetails {
  revenueDetails: IRevenueGrainDetail[];
  totalRevenueData: number;
}

export interface IRevenueGrainDetail {
  QueryPhrase: string;
  AdvertiserAccountId: string;
  AccountName: string;
  Estimated30DayRevenue: string;
  Rank: string;
}

export interface ImportDataDetails {
  Action: string;
  Term: string;
  CustomerIds: string;
  AccountIds: string;
  CountryCode: string;
  LanguageCode: string;
  MatchType: string;
  Decision: string;
  ExpiryDate: Date;
  Operation: string;
  UserId: string;
}

export interface ImportDataWithRevenue
  extends ImportDataDetails,
    ITableRowItemProps {
  TotalRevenue: number;
}

export interface ImportDataWithRevenues {
  data: ImportDataWithRevenue[];
}

export interface IAdvertiserDetails {
  advertiserList: ITag[];
  selectedAdvertiserList: ITag[];
  allAdvertisers: ITag[];
}

export interface IAccountDetails {
  accountList: ITag[];
  selectedAccountList: ITag[];
  allAccounts: ITag[];
}

export interface ITrademarkLanguageCountry {
  contriesForLanguage: Record<string, CodeNameMapping>;
  languagesForCountry: Record<string, CodeNameMapping>;
}

export interface CodeNameMapping {
  code: string;
  name: string;
  mappings: IMappings[];
}

export interface IMappings {
  code: string;
  name: string;
  market: string;
}
export const DefaultAdvertiserState: IAdvertiserDetails = {
  advertiserList: [],
  selectedAdvertiserList: [],
  allAdvertisers: [],
};

export const DefaultAccountState: IAccountDetails = {
  accountList: [],
  selectedAccountList: [],
  allAccounts: [],
};

export const DefaultTrademarkLanguageCountry: ITrademarkLanguageCountry = {
  contriesForLanguage: {},
  languagesForCountry: {},
};
export const DefaultSearchTrademarkState: ISearchTrademarkDetails = {
  exportData: [],
  searchResults: [],
  allAccounts: "",
  allAdvertisers: "",
};

export const DefaultRevenueGrainDetailsState: IRevenueGrainDetails = {
  revenueDetails: [],
  totalRevenueData: 0.0,
};

export interface IUpdateTrademarkState {
  term: string;
  matchType: string | IDropdownOption;
  customerIds: string | ITag[];
  accountIds: string | ITag[];
  languageCode: string | IDropdownOption;
  countryCode: string | IDropdownOption;
  flagLevel: string | IDropdownOption;
  expiryDate: Date;
  id: string;
  modifiedBy: string;
}

export interface IDeleteTrademarkState {
  term: string;
  matchType: string | IDropdownOption;
  customerIds: string | ITag[];
  accountIds: string | ITag[];
  languageCode: string | IDropdownOption;
  countryCode: string | IDropdownOption;
  flagLevel: string | IDropdownOption;
  expiryDate: Date;
  id: string;
  modifiedBy: string;
}

export const DefaultUpdateTrademarkState: IUpdateTrademarkState = {
  countryCode: "",
  flagLevel: "",
  languageCode: "",
  customerIds: "",
  accountIds: "",
  expiryDate: new Date(),
  id: "",
  matchType: "",
  modifiedBy: "",
  term: "",
};

export const DefaultImportDataDetails: ImportDataDetails = {
  Action: "",
  Term: "",
  CustomerIds: "",
  AccountIds: "",
  CountryCode: "",
  LanguageCode: "",
  MatchType: "",
  Decision: "",
  ExpiryDate: new Date("2079-01-01"),
  UserId: "",
  Operation: "",
};

export const DefaultImportDataWithRevenue: ImportDataWithRevenue = {
  Action: "",
  Term: "",
  CustomerIds: "",
  AccountIds: "",
  CountryCode: "",
  LanguageCode: "",
  MatchType: "",
  Decision: "",
  ExpiryDate: new Date("2079-01-01"),
  UserId: "",
  Operation: "",
  TotalRevenue: 0,
};

export const DefaultImportDataWithRevenues: ImportDataWithRevenues = {
  data: [],
};

export const OperatorMap = {
  checkbox: Operator.EQUALS,
  contains: Operator.CONTAINS,
};
