import { AIVSource, LoadState, TNSEntityType } from "@trinity/enums";
// import {
//   IEntity,
//   ITNSEnforcementPayload,
// } from "../tns-enforcement/TNSEnforcementState";

export interface ITNSAIVDataState {
  loadState: LoadState;
  aivDataList: IAIVData[];
}

interface IAIVData {
  accountId: string;
  customerId: string;
  issuingCountry: string;
  businessCountryId: string;
  legalName: string;
  verificationType: string;
  oneVetGuid: string;
  onfidoGuid: string;
  addressId: string;
  requestDate: string;
  startDate: string;
  finishDate: string;
  verificationStatus: string;
  failureReason: string;
  modifiedDate: string;
  modifiedByUserId: string;
}

export const DefaultAIVDataState: ITNSAIVDataState = {
  loadState: LoadState.NotLoaded,
  aivDataList: [],
};
