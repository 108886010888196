import { AnyAction, Reducer } from "redux";
import { LoadState, WorkflowStatus } from "@trinity/enums";
import {
  actionTypes,
  DefaultCurrentPackage,
  DefaultMismatchReportState,
  IAdminMismatchReportState,
  ICurrentPackage,
  IMisMatchPackage,
  IPackageDetails,
} from "./MismatchReportState";
import history from "../../utils/common/history";

export const adminMismatchReportReducer: Reducer<IAdminMismatchReportState> = (
  state: IAdminMismatchReportState = DefaultMismatchReportState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.SET_MISMATCH_PACKAGES_DATE_RANGE:
      return { ...state, dateRange: { ...state.dateRange, ...action.payload } };

    case actionTypes.GET_MISMATCH_PACKAGES:
      return { ...state, loadState: LoadState.InProgress };

    case actionTypes.GET_MISMATCH_PACKAGES_RECEIVED:
      return {
        ...state,
        loadState: LoadState.Loaded,
        packages: action.payload || [],
      };

    case actionTypes.GET_MISMATCH_PACKAGES_FAILED:
      return {
        ...state,
        loadState: LoadState.Failed,
      };

    case actionTypes.UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE: {
      const result = updateAssignedToPackages(state.packages, action.payload);
      return {
        ...state,
        packages: result,
      };
    }

    case actionTypes.UPDATE_MISMATCH_ASSIGNED_TO_PACKAGE_INLINE_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.GET_CURRENT_PACKAGE_COMPLETE:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.Loaded,
        },
      };

    case actionTypes.GET_CURRENT_PACKAGE_FAILED:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.GET_PACKAGE_DETAILS:
      return {
        ...state,
      };

    case actionTypes.GET_PACKAGE_DETAILS_COMPLETE: {
      const data = action.payload as IPackageDetails;

      redirectIfPackageIdIsEmpty(data.packageGuid);

      return {
        ...state,
        isViewOnly: true,
        currentPackage: {
          ...state.currentPackage,
          id: data.packageGuid,
          queueId: data.capabilityID,
          queueIsTranslationEnabled: data.isTranslationEnabled,
          queueLanguage: data.language,
          queueName: data.capabilityName,
          queueType: data.queueType,
          workflowStatusId: data.workflowStatusId,
          assignedUserID: data.assignedUserID,
          assignedToUserEmail: data.assignedToUserEmail,
        },
      };
    }

    case actionTypes.GET_PACKAGE_DETAILS_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_ADMIN_MISMATCH_REVIEWING_PACKAGE:
      return {
        ...state,
      };

    case actionTypes.GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_COMPLETE: {
      const data = action.payload as ICurrentPackage;
      redirectIfPackageIdIsEmpty(data.id as string);

      return {
        ...state,
        isViewOnly: false,
        currentPackage: data,
      };
    }

    case actionTypes.GET_ADMIN_MISMATCH_REVIEWING_PACKAGE_FAILED:
      return {
        ...state,
      };

    case actionTypes.ASSIGN_AND_REVIEW_PACKAGE:
      return {
        ...state,
      };

    case actionTypes.ASSIGN_AND_REVIEW_PACKAGE_FAILED:
      return {
        ...state,
      };

    case actionTypes.RESET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: DefaultCurrentPackage,
      };

    default:
      return state;
  }
};

function redirectIfPackageIdIsEmpty(packageGuid: string) {
  if (!packageGuid) {
    //3 secs delay to display messages
    setTimeout(() => {
      history.push("/admin/mismatch");
    }, 3000);
  }
}

const updateAssignedToPackages = (
  packages: IMisMatchPackage[],
  data: IMisMatchPackage
) => {
  const toUpdateFieldValue = {
    AssignedToUserName: "",
    WorkflowStatusId: WorkflowStatus.Published,
    AssignedToUserId: "",
  };
  packages.forEach((packageItem) => {
    if (packageItem.PackageGuid === data.PackageGuid) {
      const updateKeys = Object.keys(toUpdateFieldValue);
      updateKeys.forEach((key) => {
        packageItem[key] = toUpdateFieldValue[key];
      });
    }
  });

  return packages;
};
