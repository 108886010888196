import {
  IDropdownOption,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../store";
import { StatusTypeOption } from "./PulisherRule.service";
import { Renderer } from "./renderers/Renderer";
import { RendererType } from "./renderers/Renderer.types";
import {
  ITableProps,
  ITableColumn,
  ITableStyleProps,
  ITableStyles,
} from "../../components/common/table/Table.types";
import { ISearchPublisherRuleItem } from "../../store/publisher-rule/PublisherRuleState";

export interface ISearchPublisherRuleProps {
  styles?: IStyleFunctionOrObject<
    ISearchPublisherRuleProps,
    ISearchPublisherRuleStyles
  >;
  theme?: ITheme;
  className?: string;
}

export enum SearchCellValue {
  Empty = "--",
}

export interface IParams {
  queueId: string;
}

export interface ISearchPublisherRuleStyles {
  root: IStyle;
  main: IStyle;
  heading: IStyle;
  username: IStyle;
  messageBox: IStyle;
  shimmerHeading: IStyle;
  toggleButtonStyles: IStyle;
  disableEvents: IStyle;
  content?: IStyle;
  contentMain?: IStyle;
  category?: IStyle;
  hidden?: IStyle;
  horizontalLine?: IStyle;
  isActive?: IStyle;
  status?: IStyle;
  error?: IStyle;
  inline?: IStyle;
  floatLeft?: IStyle;
  capabilityId?: IStyle;
  columnGap?: IStyle;
  inlineReadOnly?: IStyle;
  check?: IStyle;
  footerButton: IStyle;
  updateButton: IStyle;
  deleteButton: IStyle;
  spinnerContainer: IStyle;
  spinner: IStyle;
  scheduledStartDate?: IStyle;
  token?: IStyle;
}

export interface ISearchPublisherRuleStyleProps {
  theme?: ITheme;
  className?: string;
}
export interface ICreatePublisherRuleProps {
  location: any;
  theme?: ITheme;
  className?: string;
  styles?: IStyleFunctionOrObject<
    ICreatePublisherRuleProps,
    ICreatePublisherRuleStyles
  >;
}

export interface ISearchPublisherRuleFilterProps {
  styles?: IStyleFunctionOrObject<
    ISearchPublisherRuleProps,
    ISearchPublisherRuleStyles
  >;
  theme?: ITheme;
  className?: string;
}

export enum WorkflowStatus {
  Published = 1,
  Assigned = 2,
  Submitted = 3,
  Synced = 4,
  Deleted = 7,
}

export enum SearchPublisherRuleValidations {
  SequenceMaxLength = 19,
}

export interface ISearchPublisherRuleTableProps extends ITableProps {
  columns: ISearchPublisherRuleColumn[];
}

export interface ISearchPublisherRuleColumn extends ITableColumn {
  rendererType?: RendererType;
  additionalInfo?: Record<string, any>;
  onOpenIconClick?: (item: ISearchPublisherRuleItem) => void;
}

export interface ICreatePublisherRuleStyles {
  root: IStyle;
  main: IStyle;
  heading: IStyle;
  username: IStyle;
  messageBox: IStyle;
  shimmerHeading: IStyle;
  toggleButtonStyles: IStyle;
  disableEvents: IStyle;
  content?: IStyle;
  contentMain?: IStyle;
  listName?: IStyle;
  category?: IStyle;
  hidden?: IStyle;
  horizontalLine?: IStyle;
  isActive?: IStyle;
  status?: IStyle;
  error?: IStyle;
  inline?: IStyle;
  floatLeft?: IStyle;
  columnGap?: IStyle;
  inlineReadOnly?: IStyle;
  check?: IStyle;
  footerButton: IStyle;
  updateButton: IStyle;
  deleteButton: IStyle;
  spinnerContainer: IStyle;
  spinner: IStyle;
  token?: IStyle;
}

export interface IPublisherRuleData {
  category: IDropdownOption;
  status: IDropdownOption;
  tokens: string[];
  modifiedBy: string;
}

export const searchFieldColumn = (): ISearchPublisherRuleColumn[] => {
  const { categories } = useSelector((state: IApplicationState) => ({
    ...state.publisherCategories,
  }));
  return [
    {
      key: "tokens", //response
      name: "Token(s)",
      fieldName: "tkn", //request
      minWidth: 200,
      maxWidth: 200,
      mandatory: true,
      isFilterable: true,
      isResizable: true,
      validations: {
        type: "string",
        maxLength: 100,
        errorMessage: validationMsg(100),
      },
      isHidden: false,
      onRender: Renderer,
      rendererType: RendererType.TOKENS,
    },

    {
      key: "status",
      name: "Status",
      fieldName: "sts",
      minWidth: 200,
      maxWidth: 200,
      mandatory: true,
      isResizable: true,
      isFilterable: true,
      validations: {
        type: "dropdown",
        options: StatusTypeOption,
      },
      onRender: Renderer,
      rendererType: RendererType.STATUS,
    },

    {
      key: "category",
      name: "Category",
      fieldName: "cty",
      minWidth: 100,
      maxWidth: 250,
      mandatory: true,
      isFilterable: true,
      isResizable: true,
      validations: {
        type: "dropdown",
        options: categories,
      },
      onRender: Renderer,
      rendererType: RendererType.CATEGORY,
    },
    {
      key: "modifiedBy",
      name: "Modified By",
      fieldName: "modifiedBy",
      minWidth: 100,
      maxWidth: 250,
      mandatory: true,
      isFilterable: true,
      isResizable: true,
      validations: {
        type: "string",
        maxLength: 100,
        errorMessage: validationMsg(100),
      },
      isHidden: true,
      onRender: Renderer,
      rendererType: RendererType.CATEGORY,
    },
  ];
};

export interface IUpdatePublisherRuleProps {
  location: any;
  theme?: ITheme;
  className?: string;
  styles?: IStyleFunctionOrObject<
    IUpdatePublisherRuleProps,
    IUpdatePublisherRuleStyles
  >;
}

export interface IUpdatePublisherRuleStyles {
  root: IStyle;
  main: IStyle;
  heading: IStyle;
  username: IStyle;
  messageBox: IStyle;
  shimmerHeading: IStyle;
  toggleButtonStyles: IStyle;
  disableEvents: IStyle;
  content?: IStyle;
  contentMain?: IStyle;
  category?: IStyle;
  hidden?: IStyle;
  horizontalLine?: IStyle;
  isActive?: IStyle;
  status?: IStyle;
  error?: IStyle;
  inline?: IStyle;
  floatLeft?: IStyle;
  columnGap?: IStyle;
  inlineReadOnly?: IStyle;
  check?: IStyle;
  footerButton: IStyle;
  updateButton: IStyle;
  deleteButton: IStyle;
  spinnerContainer: IStyle;
  spinner: IStyle;
  token?: IStyle;
}

export interface IUpdatePublisherRuleData {
  category: string | IDropdownOption;
  status: number | IDropdownOption;
  modifiedBy: string;
  tokens: string[];
}

export const DefaultUpdatePublisherRuleData: IUpdatePublisherRuleData = {
  category: "",
  status: -1,
  modifiedBy: "",
  tokens: [],
};

export interface IDeletePublisherRuleProps {
  location: any;
  theme?: ITheme;
  className?: string;
  styles?: IStyleFunctionOrObject<
    IDeletePublisherRuleProps,
    IDeletePublisherRuleStyles
  >;
}

export interface IDeletePublisherRuleStyles {
  root: IStyle;
  main: IStyle;
  heading: IStyle;
  username: IStyle;
  messageBox: IStyle;
  shimmerHeading: IStyle;
  toggleButtonStyles: IStyle;
  disableEvents: IStyle;
  content?: IStyle;
  contentMain?: IStyle;
  category?: IStyle;
  hidden?: IStyle;
  horizontalLine?: IStyle;
  isActive?: IStyle;
  status?: IStyle;
  error?: IStyle;
  inline?: IStyle;
  floatLeft?: IStyle;
  columnGap?: IStyle;
  inlineReadOnly?: IStyle;
  check?: IStyle;
  footerButton: IStyle;
  updateButton: IStyle;
  deleteButton: IStyle;
  spinnerContainer: IStyle;
  spinner: IStyle;
  token?: IStyle;
}

export interface IDeletePublisherRuleData {
  category: string | IDropdownOption;
  status: string | IDropdownOption;
  isActive: string;
  modifiedBy: string;
}

function validationMsg(length: number): string {
  return "Max length can't exceed more than " + length;
}

export const searchTableStyles: IStyleFunctionOrObject<
  ITableStyleProps,
  ITableStyles
> = {
  scrollPane: [],
};
