import { ISearchPublisherRuleItem } from "../../../store/publisher-rule/PublisherRuleState";
import { ISearchPublisherRuleColumn } from "../PublisherRule.types";

export interface IRendererProps {
  item?: ISearchPublisherRuleItem;
  index?: number;
  column?: ISearchPublisherRuleColumn;
  disabled?: boolean;
  icon?: {
    name: string;
    color?: string;
    size?: string;
  };
}

export enum RendererType {
  CATEGORY,
  STATUS,
  TOKENS,
}
