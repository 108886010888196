export enum QueueTypes {
  IA = 0, //Inline Appeals
  PMA = 1, // Pending Manual Review
  None = -1,
  PAAppeals = 2,
  PAPMA = 3,
  Escalation = 4,
  MisMatchReport = 5,
  QRE = 6,
  QREPA = 7,
  RejectionReport = 8,
  QREReviewsReport = 9,
  QREDefectRate = 10,
}
