import { Reducer } from "redux";
import {
  actionTypes,
  defaultApprovalState,
  IApprovalState,
} from "./ApprovalState";
import { FeatureType, OperationType } from "../rule/constants";

const FlagLevelMap = {
  1: "Confirm",
  2: "Probable",
  3: "Possible",
  99: "Monitor",
  4: "Override",
  5: "Clear",
};

const BypassTypeMap = {
  0: "Unknown",
  1: "False Positive",
  2: "Allowed Business",
};

function transformData(input, featureType) {
  const resp: any = [];
  input?.forEach((item) => {
    if (item.featureId !== featureType) return [];
    const requestData = JSON.parse(item.requestData);
    const impactAssessment = JSON.parse(item.requestImpactJson);

    // Temp remove for testing
    // if (
    //   item.operationType === OperationType.Create &&
    //   impactAssessment == null
    // ) {
    //   return;
    // }

    const parsedVNext = impactAssessment?.vNextSamplesData
      ? JSON.parse(impactAssessment.vNextSamplesData)
      : null;

    const supportingInfo =
      Array.isArray(parsedVNext) || parsedVNext === null
        ? { AdEntities: [], AIMAdEntities: [] }
        : parsedVNext;

    requestData.rules?.forEach((rule) => {
      resp.push({
        requestId: item.requestId || "N/A",
        operationType: item.operationType,
        term: rule.term || "N/A",
        flagarea: rule.defaultFlagAreaId || "N/A",
        overrideFlagArea: rule.overrideFlagAreaId || "N/A",
        flagLevel: FlagLevelMap[rule?.defaultFlagLevel] || "N/A",
        overrideFlagLevel: FlagLevelMap[rule?.overrideFlagLevel] || "N/A",
        bypassType: BypassTypeMap[rule?.BypassType] || "N/A",
        country: rule.country,
        language: rule.language,
        requestor: item.requestorName || "N/A",
        demandScanned: impactAssessment?.DemandScanned || "N/A",
        additionalDemand: impactAssessment?.DemandImpacted || "N/A",
        impactPercentage: impactAssessment?.ImpactPercentage || "N/A",
        estRevenue: impactAssessment?.EstRevenue || "N/A",
        estImpressions:
          impactAssessment?.EstImpressions ||
          impactAssessment?.EstImpressions == 0
            ? impactAssessment?.EstImpressions
            : "N/A",
        sampleDemand: item.sampleDemand || "N/A",
        overrideType: item.overrideType || "N/A",
        CID: rule.advertiserId || "N/A",
        XIDs: item.XIDs || "N/A",
        comments: rule.comments || "N/A",
        supportingInfo,
        modifiedAt: item.modifiedAt,
      });
    });
  });
  return resp;
}

export const ApprovalReducer: Reducer<IApprovalState> = (
  state: IApprovalState = defaultApprovalState,
  action: any
) => {
  const fetchedData = action?.payload || [];

  switch (action.type) {
    case actionTypes.GET_APPROVAL_ROWS:
      return {
        ...state,
        defaultRules: transformData(fetchedData, FeatureType.DefaultTerm),
        flagOverrideRules: transformData(fetchedData, FeatureType.FlagOverride),
        termOverrideRules: transformData(fetchedData, FeatureType.TermOverride),
      };

    case actionTypes.GET_APPROVAL_ROWS_FAILED:
      return defaultApprovalState;

    default:
      return state || {};
  }
};
