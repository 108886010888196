import { AnyAction, Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import {
  DefaulEnforcementHistoryState,
  ITNSEnforcementHistoryState,
} from "./TNSEnforcementHistoryState";
import { actionTypes } from "./TnsEnforcementHistoryActions";

export const tnsEnforcmentHistoryReducer: Reducer<
  ITNSEnforcementHistoryState
> = (
  state: ITNSEnforcementHistoryState = DefaulEnforcementHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.GET_ENFORCEMENT_HISTORY:
      return { ...state, loadState: LoadState.InProgress };

    case actionTypes.GET_ENFORCEMENT_HISTORY_RECEIVED:
      return {
        ...state,
        loadState: LoadState.Loaded,
        enforcementHistroyList: action.payload,
      };

    case actionTypes.GET_ENFORCEMENT_HISTORY_FAILED:
      return {
        ...state,
        loadState: LoadState.Failed,
      };

    case actionTypes.CLEAR_ENFORCEMENT_HISTORY:
      return DefaulEnforcementHistoryState;

    default:
      return state;
  }
};
