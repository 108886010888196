import { FontSizes, getTheme, Icon, Stack, Text } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

function NoAccess() {
  const theme = getTheme();
  const { t } = useTranslation();

  const contentStyles: any = {
    root: [
      {
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100% - 83px)",
      },
    ],
  };
  const iconStyles: any = {
    root: [
      {
        fontSize: FontSizes.mega,
        color: theme.palette.neutralTertiaryAlt,
      },
    ],
  };
  const textStyles: any = {
    root: [
      {
        fontSize: FontSizes.xLarge,
        color: theme.palette.neutralSecondary,
      },
    ],
  };

  return (
    <Stack styles={contentStyles}>
      <Icon data-test="icon" iconName="Sad" styles={iconStyles}></Icon>
      <h1>
        <Text data-test="text" styles={textStyles}>
          {t("error.noaccess-text")}
        </Text>
      </h1>
    </Stack>
  );
}

export default NoAccess;
