import {
  IStyleFunction,
  FontWeights,
  getTheme,
  FontSizes,
} from "@fluentui/react";
import { IMyAccountStyleProps, IMyAccountStyles } from "./MyAccount.types";

export const getStyles: IStyleFunction<
  IMyAccountStyleProps,
  IMyAccountStyles
> = (props) => {
  const theme = getTheme();

  return {
    root: ["trinity-myaccount", {}],
    personawrapper: [
      "trinity-myaccount-persona-wrapper",
      {
        background: theme.palette.white,
        width: "100%",
        height: "98px",
        display: "flex",
        padding: theme.spacing.m,
        alignItems: "center",
        boxShadow: theme.effects.elevation4,
      },
    ],
  };
};

export const getPersonaStyles = () => {
  return {
    primaryText: [
      {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
      },
    ],
    secondaryText: [
      {
        fontWeight: FontWeights.semibold,
      },
    ],
  };
};
