import { UserRole } from "@trinity/enums";
import AuthorizationWrapper from "./AuthorizationWrapper";
import LazyComponentWrapper from "./LazyComponentWrapper";
import { ReactNode } from "react";
import Layout from "../components/layout/Layout";

interface Props {
  allowedRoles: UserRole[];
  children: ReactNode;
}

const RouteWrapper = ({ children, allowedRoles }: Props) => {
  return (
    <AuthorizationWrapper allowedRoles={allowedRoles}>
      <Layout>
        <LazyComponentWrapper>{children}</LazyComponentWrapper>
      </Layout>
    </AuthorizationWrapper>
  );
};

export default RouteWrapper;
