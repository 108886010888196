import { Reducer } from "redux";
import { actionTypes } from "./EntityTrackerAction";
import {
  IEntityTrackerResponse,
  DefaultEntityTrackerResponse,
} from "./EntityTrackerState";

export const entityTrackerReducer: Reducer<IEntityTrackerResponse> = (
  state: IEntityTrackerResponse = DefaultEntityTrackerResponse,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_ENTITY_TRACKER:
      return {
        ...state,
        entityStatus: action.payload.data.entityStatus,
        entityHistory: action.payload.data.entityHistory,
        entityBypassData: action.payload.data.entityBypassData,
        entityUserData: action.payload.data.entityUserData,
        escalationInfo: action.payload.data.escalationInfo,
      };
    default:
      return state;
  }
};
