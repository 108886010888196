import { Reducer } from "redux";
import * as UserActions from "./UserActions";
import { DefaultUserState, IUserState } from "./UserState";
import { Permission, LoadState, UserRole } from "@trinity/enums";

export const userReducer: Reducer<IUserState> = (
  state: IUserState = DefaultUserState,

  action: any
) => {
  switch (action.type) {
    case UserActions.USER_DETAILS_RECEIVED:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };

    case UserActions.USER_IMAGE_RECEIVED:
      return {
        ...state,
        imageUrl: action.payload.imageUrl,
      };

    case UserActions.USER_ROLE_RECEIVED:
      return {
        ...state,
        userRoles: action.payload.userRoles,
        userId: action.payload.userId,
        permissions: getPermissions(action.payload.userRoles),
        loadState: LoadState.Loaded,
      };

    case UserActions.USER_DETAILS_FAILED:
      return {
        ...state,
        userRoles: DefaultUserState.userRoles,
        userId: DefaultUserState.userId,
        permissions: getPermissions(DefaultUserState.userRoles),
        loadState: LoadState.Failed,
        error: action.payload,
      };

    case UserActions.USER_ROLE_FAILED:
      return {
        ...state,
        userRoles: DefaultUserState.userRoles,
        userId: DefaultUserState.userId,
        permissions: getPermissions(DefaultUserState.userRoles),
        loadState: LoadState.Failed,
      };

    case UserActions.UPDATE_USER_ROLE:
      return {
        ...state,
        userRoles: action.payload.roles,
      };

    default:
      return state || DefaultUserState;
  }
};

export function getPermissions(userRoles: UserRole[]): Record<string, boolean> {
  const permissions = {};

  for (const roleIdx in userRoles) {
    const rolePermissions = getPermissionsForRole(userRoles[roleIdx]);

    for (const permissionIdx in rolePermissions) {
      permissions[rolePermissions[permissionIdx]] = true;
    }
  }

  return permissions;
}

export function getPermissionsForRole(userRole: UserRole): Permission[] {
  let permissions: Permission[] = [];

  switch (userRole) {
    case UserRole.EditorialChineseAdmin:
    case UserRole.EditorialAdmin:
      permissions = [
        Permission.WriteQueue,
        Permission.ConfigurePackageCheckoutDuration,
        Permission.ChangeQueueStatus,
        Permission.ChangeQueuePriority,
        Permission.AssignReviewerToQueue,
        Permission.ManageUserRole,
        Permission.ConfigureBulkReviewPermission,
        Permission.MovePackageToDifferentQueue,
        Permission.DeletePackageFromQueue,
        Permission.ReviewPackage,
        Permission.TakeBulkDecision,
        Permission.AccessAllStats,
        Permission.AccessAuditQueue,
        Permission.ManageLists,
        Permission.AddRulesFromPackageReview,
        Permission.FraudPremiumAdvertisers,
      ];

      break;

    case UserRole.EditorialManager:
      permissions = [
        Permission.MovePackageToDifferentQueue,
        Permission.DeletePackageFromQueue,
        Permission.ReviewPackage,
        Permission.TakeBulkDecision,
        Permission.AccessAllStats,
        Permission.AccessAuditQueue,
        Permission.AddRulesFromPackageReview,
        Permission.FraudPremiumAdvertisers,
      ];

      break;

    case UserRole.EditorialAdvancedReviewer:
      permissions = [
        Permission.MovePackageToDifferentQueue,
        Permission.ReviewPackage,
        Permission.TakeBulkDecision,
        Permission.AddRulesFromPackageReview,
        Permission.FraudPremiumAdvertisers,
      ];

      break;

    case UserRole.EditorialReviewer:
      permissions = [
        Permission.MovePackageToDifferentQueue,
        Permission.ReviewPackage,
        Permission.AddRulesFromPackageReview,
      ];

      break;

    //   TODO: "To uncomment when ListManager is included"
    //   case UserRole.ListManager:
    //   permissions = [Permission.AccessAllStats, Permission.ManageLists];
    //   break;
  }

  return permissions;
}
