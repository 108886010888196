import { MessageBarType } from "@fluentui/react";
import { configs } from "../../configs";
import { ReviewPackageWorkFlow } from "@trinity/enums";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import { actionCreators as commonActions } from "../common/CommonActions";
import { IUserPackage } from "./UserPackageState";

export enum actionTypes {
  GET_USER_PACKAGE = "GET_USER_PACKAGE",
  UPDATE_USER_PACKAGE = "UPDATE_USER_PACKAGE",
  RESET_USER_PACKAGE = "RESET_USER_PACKAGE",
}

export const actionCreators = {
  getUserPackage:
    (isMismatchPackage?: boolean, IsRejectionPackage?: boolean) =>
    async (dispatch: any) => {
      try {
        const workFlowParams = isMismatchPackage
          ? ReviewPackageWorkFlow.MISMATCH
          : "";

        const response = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.appServiceEndpoint}me/package?workflow=${workFlowParams}`
          )
        );

        const data: IUserPackage = await response.json();

        dispatch({
          type: actionTypes.GET_USER_PACKAGE,
          payload: {
            data,
          },
        });

        return data;
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },

  updateUserPackage: (details) => async (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_USER_PACKAGE,
      payload: details,
    });
  },

  resetUserPackage: () => async (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_USER_PACKAGE,
    });
  },
};
