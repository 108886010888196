export enum Operator {
  IN = "IN",
  CONTAINS = "CONTAINS",
  EQUALS = "EQUALS",
  UNDEFINED = "undefined",
  GREATER_THAN = "GT",
  GREATER_THAN_OR_EQUALS = "GE",
  LESSER_THAN = "LT",
  LESSER_THAN_OR_EQUALS = "LE",
}
