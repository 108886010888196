import { Conditions } from "../../../src/components/common/table/table-command-bar/plugins/filters/Filters.types";
import { IFilter } from "../../../src/components/common/table/Table.types";
import {
  IUserModulePreferences,
  UserPreferencesModule,
} from "../../../src/store/user-preference/UserPreferenceState";
import { IQueryParams } from "./types";

export function getParamsFromPreferences(
  modules: Record<UserPreferencesModule, IUserModulePreferences>,
  moduleName: UserPreferencesModule | null
): string {
  if (!moduleName) return "";

  const preferences = modules[moduleName];
  const queryParameters = {} as IQueryParams;
  let queryString = "";
  const tablePresets = preferences.tablePresets;

  queryParameters.count = true;
  queryParameters.top = 200;

  if (tablePresets) {
    if (tablePresets.search) {
      queryParameters.search = encodeURIComponent(tablePresets.search.term);
    }

    if (
      tablePresets.columns &&
      tablePresets.columns.sorted &&
      tablePresets.columns.sorted.length > 0
    ) {
      const columns = tablePresets.columns.sorted;
      queryParameters.orderBy = "";

      columns.forEach((column, index) => {
        if (column.sortedBy) {
          queryParameters.orderBy += `${column.title} ${column.sortedBy}`;

          if (index < columns.length - 1) {
            queryParameters.orderBy += ",";
          }
        }
      });
    }

    if (tablePresets.filters && tablePresets.filters.applied.length > 0) {
      const filters = tablePresets.filters.applied;
      queryParameters.filter = "";

      filters.forEach((item, index, items) => {
        const hasCustomFilterQuery =
          (item.customQueries && item.customQueries.length > 0) || false;

        if (hasCustomFilterQuery) {
          queryParameters.filter += generateCustomFilterQuery(item);
        } else {
          queryParameters.filter += generateFilterQuery(item);
        }

        if (index !== items.length - 1) {
          queryParameters.filter += " and ";
        }
      });

      queryParameters.filter = encodeURIComponent(queryParameters.filter);
    }

    if (tablePresets.paging) {
      queryParameters.top = tablePresets.paging.rowsPerPage;

      if (tablePresets.paging.currentPage) {
        const skip =
          tablePresets.paging.currentPage * tablePresets.paging.rowsPerPage -
          tablePresets.paging.rowsPerPage;
        if (skip > 0) {
          queryParameters.skip = skip;
        }
      }
    }
  }

  const paramKeys = Object.keys(queryParameters);

  paramKeys.forEach((key, index) => {
    queryString += `$${key}=${queryParameters[key]}`;

    if (index < paramKeys.length - 1) {
      queryString += "&";
    }
  });

  return queryString;
}

function generateQuery(values: []): string {
  let result = "";
  values.forEach((x, index, arr) => {
    if (index === arr.length - 1) {
      result += `'${x}'`;
    } else {
      result += `'${x}', `;
    }
  });

  return result;
}

function generateCustomFilterQuery(item: IFilter): string {
  let result: string[] = [];

  if (item.customQueries && item.customQueries.length > 0) {
    result = result.concat(item.customQueries);

    if (item.key && item.key.length > 0) {
      result.push(generateFilterQuery(item));
    }
  }

  switch (item.condition) {
    case Conditions.EQUALS:
      return `(${result.join(" or ")})`;

    case Conditions.DOES_NOT_EQUAL:
      return `(${result.join(" and ")})`;

    default:
      return "";
  }
}

function generateFilterQuery(item: IFilter) {
  switch (item.condition) {
    case Conditions.EQUALS:
      switch (item.type) {
        case "checkbox":
        case "dropdown":
        case "combobox":
          return `${item.field} in (${generateQuery(item.key)})`;

        default:
          return `${item.field} eq '${item.value}'`;
      }

    case Conditions.DOES_NOT_EQUAL:
      switch (item.type) {
        case "checkbox":
        case "dropdown":
        case "combobox":
          return `${item.field} not in (${generateQuery(item.key)})`;

        default:
          return `${item.field} neq '${item.value}'`;
      }

    case Conditions.CONTAINS:
      return `contains(${item.field},'${item.value}')`;

    case Conditions.DOES_NOT_CONTAIN:
      return `not contains(${item.field},'${item.value}')`;

    case Conditions.BEGINS_WITH:
      return `startswith(${item.field},'${item.value}')`;

    case Conditions.ENDS_WITH:
      return `endswith(${item.field},'${item.value}')`;
  }
}
