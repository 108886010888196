export enum WorkflowStatus {
  Published = 1,
  Assigned = 2,
  Submitted = 3,
  Synced = 4,
  Deleted = 7,
}

export enum EscalationParentStatus {
  Created = 1,
  Published = 2,
  ErrorRetrying = 3,
  Error = 4,
  NoEntities = 5,
  Deleted = 7,
  Queued = 9,
}

export enum EscalationChildStatus {
  "Available for review" = 1,
  "Review in progress" = 2,
  Submitted = 3,
  Completed = 4,
  Deleted = 7,
  Queued = 9,
}
