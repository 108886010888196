import { UserRole } from "@trinity/enums";

export function getRiskScope(roles: UserRole[]) {
  if (roles.includes(UserRole.RiskAdmin)) {
    return "RISKAdmin";
  } else if (roles.includes(UserRole.RiskPremiumReviewer)) {
    return "RISKPremiumWrite";
  } else if (roles.includes(UserRole.RiskReviewer)) {
    return "RISKWrite";
  } else if (roles.includes(UserRole.RiskViewer)) {
    return "RISKRead";
  } else {
    return "";
  }
}
