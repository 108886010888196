import {
  IEntityTrackerRequest,
  IEntityTrackerResponse,
} from "./EntityTrackerState";
import { MessageBarType } from "@fluentui/react";
import { configs } from "../../configs";
import { fetchCreator } from "../../utils/middleware/fetch";
import { actionCreators as commonActions } from "../common/CommonActions";

export enum actionTypes {
  GET_ENTITY_TRACKER = "GET_ENTITY_TRACKER_RESPONSE",
}

export const actionCreators = {
  getEntityTracker:
    (
      entityID: string,
      entityType: string,
      assetType: string,
      rSAAttributeType: string,
      orderID: string,
      startDate: string,
      endDate: string
    ) =>
    async (dispatch: any) => {
      try {
        const params: IEntityTrackerRequest = {
          entityID: entityID,
          entityType: entityType,
          assetType: assetType,
          rSAAttributeType: rSAAttributeType,
          orderID: orderID,
          startDate: startDate,
          endDate: endDate,
        };

        const response = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.trinityEditorialEndpoint}api/entityTracker`,
            {
              method: "POST",
              body: JSON.stringify(params),
            }
          )
        );

        const data: IEntityTrackerResponse = await response.json();

        dispatch({
          type: actionTypes.GET_ENTITY_TRACKER,
          payload: {
            data,
          },
        });

        return data;
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
      }
    },
};
