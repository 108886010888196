import { CardItemType } from "@trinity/common";
import { LoadState } from "@trinity/enums";

export interface IAdminQueueItem {
  id: number;
  name: string;
  packageCount: number;
  maxAge: string;
  packagesOOSLA: string;
  status: string;
  priority: string;
  usersCount: number;
}

export interface IAdminSummaryItem {
  key: string;
  name: string;
  count: number;
}

export interface IAdminOverviewState {
  queues: IAdminQueueItem[];
  nextPackageGuid: string;
  adminSummary: IAdminSummaryItem[];
  loadState: IAdminOverviewLoadState;
}

const DefaultAdminSummaryState = [
  {
    key: "averageTime",
    name: "admin-overview.summary-card-average-time-label",
    type: CardItemType.Duration,
    count: 0,
  },
  {
    key: "averageThroughput",
    name: "admin-overview.summary-card-average-throughput-label",
    type: CardItemType.Rows,
    count: 0,
  },
  {
    key: "accuracy",
    name: "admin-overview.summary-card-accuracy-label",
    type: CardItemType.Percentage,
    count: 0,
  },
  {
    key: "rowsReviewed",
    name: "admin-overview.summary-card-rows-reviewed-label",
    type: CardItemType.Rows,
    count: 0,
  },
  {
    key: "packagesReviewed",
    name: "admin-overview.summary-card-packages-reviewed-label",
    type: CardItemType.Number,
    count: 0,
  },
  {
    key: "averageRows",
    name: "admin-overview.summary-card-average-rows-label",
    type: CardItemType.Number,
    count: 0,
  },
];

export const DefaultAdminOverviewState: IAdminOverviewState = {
  queues: [],
  nextPackageGuid: "",
  adminSummary: DefaultAdminSummaryState,
  loadState: {
    queues: LoadState.NotLoaded,
    queuesSummary: LoadState.NotLoaded,
  },
};

export interface IAdminOverviewLoadState {
  queues: LoadState;
  queuesSummary: LoadState;
}
