import { Reducer } from "redux";
import { LoadState, WorkflowStatus } from "@trinity/enums";
import { actionTypes } from "./AdminPackageManagementActions";
import {
  IAdminPackageManagementState,
  INITIAL_STATE,
  IPackageItem,
} from "./AdminPackageManagementState";

export const adminPackageManagementReducer: Reducer<
  IAdminPackageManagementState
> = (state: IAdminPackageManagementState = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.LOAD_QUEUE_SUMMARY:
      return {
        ...state,
        resetTableSelection: false,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.InProgress,
        },
      };

    case actionTypes.GET_QUEUE_SUMMARY:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.Loaded,
        },
        queueSummary: {
          id: action.payload.data.id,
          name: action.payload.data.name,
          language: action.payload.data.language,
          relatedQueues: action.payload.data.relatedQueues.filter(
            (queue) => queue.id !== action.payload.data.id
          ),
          isTranslationEnabled: action.payload.data.isTranslationEnabled,
          queueType: action.payload.data.queueType,
          summary: {
            ...action.payload.data.summary,
          },
        },
      };

    case actionTypes.GET_QUEUE_SUMMARY_FAILED:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          queueSummary: LoadState.Loaded,
        },
      };

    case actionTypes.LOAD_PACKAGE_MANAGEMENT_PACKAGES:
      return {
        ...state,
        resetTableSelection: false,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.InProgress,
        },
      };

    case actionTypes.GET_PACKAGE_MANAGEMENT_PACKAGES:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.Loaded,
        },
        packages: action.payload.value.map((item) => ({
          ...item,
          disabled: item.WorkflowStatusId
            ? !state.isSubmittedOnly &&
              [WorkflowStatus.Submitted, WorkflowStatus.Synced].includes(
                item.WorkflowStatusId
              )
            : false,
        })),
        tempPackages: JSON.stringify(action.payload.value),
        packageItemsCount: action.payload["@odata.count"],
      };

    case actionTypes.GET_PACKAGE_MANAGEMENT_PACKAGES_FAILED:
      return {
        ...state,
        packageLoadState: {
          ...state.packageLoadState,
          packages: LoadState.NotLoaded,
        },
      };

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE: {
      const result = updateAssignedToPackages(state.packages, action.payload);
      return {
        ...state,
        packages: result,
        tempPackages: JSON.stringify(result),
      };
    }

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_ASSIGNED_TO_PACKAGE_INLINE_FAILED:
      return {
        ...state,
        packages: JSON.parse(state.tempPackages),
      };

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE: {
      const result = updatedPackagesSequence(state.packages, action.payload);
      return {
        ...state,
        packages: result,
        tempPackages: JSON.stringify(result),
      };
    }

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_INLINE_SEQUENCE_FAILED:
      return {
        ...state,
        packages: JSON.parse(state.tempPackages),
      };

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA: {
      return {
        ...state,
        resetTableSelection: false,
      };
    }

    case actionTypes.UPDATE_PACKAGE_MANAGEMENT_PACKAGE_DATA_FAILED: {
      return {
        ...state,
        resetTableSelection: false,
      };
    }

    case actionTypes.PACKAGE_MANAGEMENT_SELECTED_PACKAGE: {
      return {
        ...state,
        selectedPackage: action.payload,
      };
    }

    case actionTypes.RESET_TABLE_SELECTION:
      return {
        ...state,
        resetTableSelection: true,
        packageLoadState: {
          packages: LoadState.InProgress,
          queueSummary: LoadState.InProgress,
        },
      };

    case actionTypes.RESET_TABLE_SELECTION_COMPLETE:
      return {
        ...state,
        resetTableSelection: false,
        packageLoadState: {
          packages: LoadState.Loaded,
          queueSummary: LoadState.Loaded,
        },
      };

    case actionTypes.FILTER_BY_FLAG_AREA_ON_SUMMARY:
      return {
        ...state,
      };

    case actionTypes.SET_IS_SUBMITTED_PACKAGES_ONLY:
      return {
        ...state,
        isSubmittedOnly: action.payload,
      };
    default:
      return state;
  }
};

const updatedPackagesSequence = (
  packages: IPackageItem[],
  data: IPackageItem
) => {
  for (let index = 0; index < packages.length; index++) {
    const element = packages[index].PackageGuid;
    if (element === data.PackageGuid) {
      packages[index] = data;
      break;
    }
  }
  return packages;
};

const updateAssignedToPackages = (
  packages: IPackageItem[],
  data: IPackageItem
) => {
  const toUpdateFieldValue = {
    AssignedToUserName: "",
    WorkflowStatusId: 1,
    AssignedToUserId: "",
  };
  packages.forEach((packageItem) => {
    if (packageItem.PackageGuid === data.PackageGuid) {
      const updateKeys = Object.keys(toUpdateFieldValue);
      updateKeys.forEach((key) => {
        packageItem[key] = toUpdateFieldValue[key];
      });
    }
  });

  return packages;
};
