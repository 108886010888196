import { Reducer } from "redux";
import { actionTypes } from "./AdminOverviewActions";
import {
  DefaultAdminOverviewState,
  IAdminOverviewState,
} from "./AdminOverviewState";
import { LoadState } from "@trinity/enums";

export const adminOverviewReducer: Reducer<IAdminOverviewState> = (
  state: IAdminOverviewState = DefaultAdminOverviewState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_ADMIN_QUEUES:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          queues: LoadState.InProgress,
        },
      };

    case actionTypes.ADMINQUEUES_RECEIVED:
      return {
        ...state,
        queues: action.payload.queues,
        nextPackageGuid: "",
        loadState: {
          ...state.loadState,
          queues: LoadState.Loaded,
        },
      };

    case actionTypes.GET_PACKAGE_DETAILS:
      return {
        ...state,
        nextPackageGuid: action.payload.packageGuid,
      };

    case actionTypes.GET_ADMINQUEUES_SUMMARY:
      return {
        ...state,
        adminQueuesSummary: action.payload.adminSummary,
        loadState: {
          ...state.loadState,
          queuesSummary: LoadState.Loaded,
        },
      };

    default:
      return state || DefaultAdminOverviewState;
  }
};
