import { Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import { getApplicationConfig } from "../../../src/utils/common/common";
import { actionTypes } from "./CommonActions";
import {
  Country,
  DistributionChannel,
  Flag,
  ICommonState,
  IConstantsState,
  INITIAL_STATE,
  LMFlag,
  Language,
  MatchTypeAndComponentMask,
} from "./CommonState";

export const commonReducer: Reducer<ICommonState> = (
  state: ICommonState = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.DISPLAY_MESSAGE:
      return {
        ...state,
        messageBox: action.payload,
      };

    case actionTypes.HIDE_MESSAGE:
      return {
        ...state,
        messageBox: {
          message: "",
          type: "",
        },
      };

    case actionTypes.GET_CONSTANTS_LOADING:
      return {
        ...state,
        loadState: LoadState.InProgress,
      };

    case actionTypes.GET_CONSTANTS:
      const flagAreas = getFlagAreasDictionary(
        action.payload.flagAreaDictionary
      );
      const lmFlagAreas = getLMFlagAreasDictionary(action.payload.flag);
      const countries = getCountries(action.payload.country);
      const languages = getLanguages(action.payload.language);
      const lmCountries = getCountries(action.payload.LMCountryList);
      const matchTypeAndComponentMask = getMatchTypeAndComponentMask(
        action.payload.MatchTypeAndComponentMask
      );
      const lmLanguages = getLanguages(action.payload.LMLanguageList);
      const distributionChannels = getDistributionChannel(
        action.payload.distributionChannel
      );
      const globalizationEnabledCountries = getCountries(
        action.payload.globalizationEnabledCountries
      );

      const constants: IConstantsState = {
        country: countries,
        language: languages,
        distributionChannel: distributionChannels,
        languageCountryMappings: action.payload.languageCountryMappings,
        globalizationEnabledCountries: globalizationEnabledCountries,
        flagAreaCategories: action.payload.flagAreaCategories,
        flagAreaDictionary: flagAreas,
        LMCountryList: lmCountries,
        LMLanguageList: lmLanguages,
        flag: lmFlagAreas,
        MatchTypeAndComponentMask: matchTypeAndComponentMask,
      };

      localStorage.setItem("constants", JSON.stringify(constants));

      return {
        ...state,
        constants: constants,
        loadState: LoadState.Loaded,
      };

    case actionTypes.GET_FLA_CONSTANTS:
      const data = action.payload;
      localStorage.setItem("flaConstants", JSON.stringify(data));
      return {
        ...state,
        flaConstants: data,
        flaConstantsLoadState: LoadState.Loaded,
      };

    case actionTypes.CACHE_BUSTING:
      cacheBust();
      return { ...state };

    case actionTypes.SHORTCUT:
      return {
        ...state,
        shortcutType: action.payload,
      };

    case actionTypes.SHORTCUT_RESET:
      return {
        ...state,
        shortcutType: undefined,
      };

    case actionTypes.INIT_BROADCAST_CHANNEL:
      initBroadcastChannel(action.payload.channel, action.payload.fn);
      return {
        ...state,
      };

    case actionTypes.BROADCAST_MESSAGE_TO_CHANNEL:
      broadcastMessageToChannel(action.payload.channel, action.payload.message);
      return {
        ...state,
      };

    default:
      return state;
  }
};

function cacheBust() {
  const APP_VERSION_ITEM_NAME = "appVersion";
  const currentAppVersion = Number(localStorage.getItem(APP_VERSION_ITEM_NAME));
  const latestAppVersion = getApplicationConfig().appVersion;
  if (
    !currentAppVersion ||
    (currentAppVersion && currentAppVersion !== latestAppVersion)
  ) {
    localStorage.clear();
  }

  // Set the appVersion irrespective of any version change
  localStorage.setItem(APP_VERSION_ITEM_NAME, JSON.stringify(latestAppVersion));
}

function getCountries(payload: any): Record<number, Country> {
  const countries: Record<number, Country> = {};
  (Object.values(payload) as Country[]).forEach((x: Country) => {
    countries[x.value] = x;
  });

  return countries;
}

function getDistributionChannel(
  payload: any
): Record<number, DistributionChannel> {
  const distributionChannels: Record<number, DistributionChannel> = {};
  (Object.values(payload) as DistributionChannel[]).forEach(
    (x: DistributionChannel) => {
      distributionChannels[x.distChanCode] = x;
    }
  );

  return distributionChannels;
}

function getLanguages(payload: any): Record<number, Language> {
  const languages: Record<number, Language> = {};
  (Object.values(payload) as Language[]).forEach((x: Language) => {
    languages[x.value] = x;
  });

  return languages;
}

function initBroadcastChannel(channel: string, fn: () => void) {
  const broadcastChannel = new BroadcastChannel(channel);
  broadcastChannel.onmessage = fn;
}

function broadcastMessageToChannel(channel: string, message: any) {
  const broadcastChannel = new BroadcastChannel(channel);
  broadcastChannel.postMessage(message);
}

const getFlagAreas = (value, result = {}): Record<string, Flag> => {
  if (!value) return {};

  if (typeof value === "object") {
    Object.entries(value).forEach(([key, value]: any) => {
      if (value) {
        if (key === "flagData") {
          result[value.id] = value;
        } else {
          getFlagAreas(value, result);
        }
      }
    });
  }

  return result;
};

function getFlagAreasDictionary(payload: any): Record<number, Flag> {
  const flagAreas: Record<number, Flag> = {};
  if (payload) {
    (Object.values(payload) as Flag[]).forEach((x: Flag) => {
      flagAreas[x.id] = x;
    });
  }

  return flagAreas;
}

function getLMFlagAreasDictionary(payload: any): Record<number, LMFlag> {
  const lmFlagAreas: Record<number, LMFlag> = {};
  if (payload) {
    (Object.values(payload) as LMFlag[]).forEach((x: LMFlag) => {
      lmFlagAreas[x.id] = x;
    });
  }

  return lmFlagAreas;
}

function getMatchTypeAndComponentMask(
  payload: any
): Record<number, MatchTypeAndComponentMask> {
  const matchTypeAndComponentMask: Record<number, MatchTypeAndComponentMask> =
    {};
  if (payload) {
    (Object.values(payload) as MatchTypeAndComponentMask[]).forEach(
      (x: MatchTypeAndComponentMask) => {
        matchTypeAndComponentMask[x.matchTypeId] = x;
      }
    );
  }
  return matchTypeAndComponentMask;
}
