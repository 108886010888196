import { Reducer } from "redux";
import { actionTypes } from "./UserPackageActions";
import { INITIAL_STATE, IUserPackage } from "./UserPackageState";

export const userPackageReducers: Reducer<IUserPackage> = (
  state: IUserPackage = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_USER_PACKAGE:
      return {
        ...state,
        ...action.payload.data,
      };

    case actionTypes.UPDATE_USER_PACKAGE:
      return {
        ...state,
        id: action.payload.id,
        queueId: action.payload.queueId,
        queueName: action.payload.queueName,
      };

    case actionTypes.RESET_USER_PACKAGE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
