import { HeaderItems } from "@trinity/enums";

export interface IHeaderState {
  showWorkspaces: boolean;
  selectedWorkspaceKey: string;
  selectedItemKey: HeaderItems;
  title: string;
}

export const DefaultHeaderState: IHeaderState = {
  selectedItemKey: HeaderItems.Default,
  showWorkspaces: false,
  selectedWorkspaceKey: "",
  title: "",
};
