import { IDropdownOption } from "@fluentui/react";

export const StatusTypeOption: IDropdownOption[] = [
  { key: "1", text: "Active" },
  { key: "0", text: "Inactive" },
];

export enum InputKeys {
  LIST_NAME = "listName",
  MARKET = "marketId",
  SUB_CATEGORY = "subCategoryId",
  STATUS = "status",
  REGION = "region",
  SCHEDULED_END_DATE = "scheduledEndDate",
  SCHEDULED_START_DATE = "scheduledStartDate",
  CAPABILITY = "capabilityId",
  KEYWORDS = "keywords",
}

export enum FieldName {
  listName = "List Name",
  marketId = "Market",
  subCategoryId = "SubCategory Id",
  scheduledEndDate = "Scheduled End Date",
  scheduledStartDate = "Scheduled Start Date",
  keywords = "Keywords",
  capabilityId = "Capability Id",
  region = "Region",
  status = "Status",
}
export enum QREConstants {
  QRE_CAPABILITY_DETAIL = "qreCapabilityDetail",
}
// const dispatch = useDispatch<any>();
// dispatch(actionCreators.fetchCountries);
