import { ReactNode } from "react";
import NoAccess from "../components/error/NoAccess";
import { LoadState, UserRole } from "@trinity/enums";
import { ISpinnerStyles, Spinner, SpinnerSize } from "@fluentui/react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../store";

interface Props {
  allowedRoles: UserRole[];
  children: ReactNode;
}

const AuthorizationWrapper = ({ allowedRoles, children }: Props) => {
  // TODO: remember to switch this with useAppSelector post merge of that PR
  const { userRoles, isAuthenticated, loadState } = useSelector(
    (state: IApplicationState) => state.user
  );

  if (loadState === LoadState.NotLoaded || loadState === LoadState.InProgress) {
    return <LoadingComponent />;
  }

  if (isAuthenticated) {
    for (const roleIdx in userRoles) {
      if (allowedRoles.includes(userRoles[roleIdx])) {
        return <>{children}</>;
      }
    }

    return <NoAccess />;
  }

  return <NoAccess />;
};

export default AuthorizationWrapper;

function LoadingComponent({ title = "Setting things up..." }) {
  const spinnerStyles: ISpinnerStyles = {
    root: [
      {
        height: "100vh",
      },
    ],
  };
  return (
    <Spinner label={title} styles={spinnerStyles} size={SpinnerSize.large} />
  );
}
