import { IDropdownOption } from "@fluentui/react";
import { ADD_FLAG_ENABLED_QUEUES } from "@trinity/constants";
import {
  IModuleColumns,
  IModuleFilters,
  IModulePaging,
  ITablePresets,
} from "../../../src/components/common/table/Table.types";
import { Decision } from "../../../src/components/review-package/ReviewPackageTable.types";
import * as applicationConfig from "../../../src/configs/applicationConfig.json";
import { PACKAGE_GUID_PATTERN } from "@trinity/constants";
import {
  Component,
  EntityType,
  FlagAreaType,
  QueueTypes,
  SubEntityType,
  UserRole,
} from "@trinity/enums";
import { ICustomerNode } from "../../../src/modules/tns-explore/TNSExplore.types";
import { Flag, IConstantsState } from "../../../src/store/common/CommonState";
import { IExtraDecision } from "../../../src/store/review-package/ReviewPackageState";
import { IUserModulePreferences } from "../../../src/store/user-preference/UserPreferenceState";
import { IApplicationConfig } from "./types";
import * as marketsConfig from "../../../src/configs/markets.json";

export const hasAnyProperty = (obj: any, properties: string[]): boolean => {
  if (obj) {
    for (let index = 0; index < properties.length; index++) {
      const propertyExists = obj.hasOwnProperty(properties[index]);
      if (propertyExists) {
        return true;
      }
    }
  }

  return false;
};

export const getObjectPropertyValue = (obj: any, property: string) => {
  const propertyParts = property.split(".");
  let value = obj;

  for (const property of propertyParts) {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(value, property) &&
      value[property] !== "NaN"
    ) {
      value = value[property];
    } else {
      value = "";
      break;
    }
  }

  return value;
};

export const compare = (isSortedDescending: boolean, x: any, y: any) => {
  if (typeof x === "string") {
    x = String(x).trim().toLowerCase();
    y = String(y).trim().toLowerCase();
  }

  if (isSortedDescending) {
    return x > y ? -1 : x < y ? 1 : 0;
  } else {
    return x < y ? -1 : x > y ? 1 : 0;
  }
};

export function getApplicationConfig(): IApplicationConfig {
  return applicationConfig as IApplicationConfig;
}

export const isJson = (value) => {
  try {
    const result = JSON.parse(value);

    if (typeof result === "object") {
      return true;
    }
  } catch (error: any) {
    return false;
  }

  return false;
};

/**
 * @description fn to truncate the string with ellipsis
 * @param string string to truncate
 * @param maxLength truncate length
 * @returns if str length > maxLength return truncate string else whole string
 */
export const stringTruncate = (string: string, maxLength: number) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}...` : string;

/**
 * Will reset user preferences but only the required ones.
 * If the user has preferences for rowsPerPage and columns, it won't reset
 * @param presets ITablePresets
 * @returns IUserModulePreferences
 */
export function resetPresets(presets?: ITablePresets): IUserModulePreferences {
  const paging: IModulePaging = {
    currentPage: 1,
    rowsPerPage: presets && presets.paging ? presets.paging.rowsPerPage : 200,
  };

  const filters: IModuleFilters = {
    applied: [],
    recent: [],
    saved: [],
  };

  const columns: IModuleColumns = (presets && presets.columns) || {
    applied: [],
    saved: [],
    sorted: [],
    modified: [],
  };

  return {
    tablePresets: {
      paging,
      filters,
      columns,
    },
  };
}

export const populateDropDown = (
  arg,
  key,
  text,
  isAlreadySorted?: boolean,
  sortByKey?: boolean,
  isKeyRequiredInDisplayText?: boolean
): IDropdownOption[] => {
  if (!arg) return [{ key: "", text: "" }];

  const result: IDropdownOption[] = [];
  if (isKeyRequiredInDisplayText) {
    for (const element in arg) {
      result.push({
        key: arg[element][key],
        text: arg[element][key] + "-" + arg[element][text],
      });
    }
  } else {
    for (const element in arg) {
      result.push({
        key: arg[element][key],
        text: arg[element][text],
      });
    }
  }

  if (isAlreadySorted) return result;

  if (sortByKey) {
    return result.sort((a, b) => compare(false, a.key, b.key));
  } else {
    return result.sort((a, b) => compare(false, a.text, b.text));
  }
};

export const populateEnumDropDown = (
  arg,
  renderKey: boolean,
  omitKeys?: string[]
): IDropdownOption[] => {
  return Object.keys(arg)
    .filter((e) => isNaN(+e) !== renderKey)
    .map((i) => {
      return { key: i, text: arg[i] };
    })
    .filter(({ text }) => (omitKeys && omitKeys.includes(text) ? false : true))
    .sort((a, b) => compare(false, a.text, b.text));
};

export const hasExceededGivenWordsLimit = (
  limit: number,
  separatedBy: string,
  str: string
): boolean => {
  return str.split(separatedBy).length > limit ? true : false;
};

export const isAddFlagEnabled = (queueType: QueueTypes): boolean => {
  return ADD_FLAG_ENABLED_QUEUES.includes(queueType);
};

export const getFlagAreaId = (
  flagAreaID: number,
  subEntityTypeText: string,
  flagAreas: Record<string, Flag>
): number => {
  switch (subEntityTypeText) {
    case "ImageAsset":
      return 300;

    case "VideoAsset":
      return 323;

    default:
      return flagAreas[flagAreaID] &&
        flagAreas[flagAreaID].type !== FlagAreaType.DEPRECATED
        ? flagAreaID
        : -1;
  }
};

export function validateURL(targetString: string): boolean {
  const regex = new RegExp(
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  );

  return regex.test(targetString);
}

export function isEmptyObject(obj: any): boolean {
  for (const name in obj) {
    return false;
  }

  return true;
}

export function roundTo(
  num: string | number | undefined,
  fractionDigits
): number {
  return +Number(num).toFixed(fractionDigits);
}

export function formatCurrency(
  value: string | number | undefined,
  fractionDigits = 2,
  prefixSymbol = "$"
): string {
  return `${prefixSymbol} ${new Intl.NumberFormat("en-US").format(
    roundTo(value, fractionDigits)
  )}`;
}

export function DownloadFile(data: string, fileName: string) {
  if (data) {
    const dataURI = "data:application/text; charset=utf-8,";

    const link = document.createElement("a");

    link.href = `${dataURI}${encodeURIComponent(JSON.stringify(data))}`;
    link.download = `${fileName}.txt`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const calcFraudRate = (
  currentCustomer: ICustomerNode,
  customerNodes?: ICustomerNode[]
) => {
  if (customerNodes) {
    const finalNodes = [...customerNodes, currentCustomer];
    const fraudCustomers = finalNodes.filter(
      (x) => x.fraudStatusId === "8"
    ).length;
    return Math.round((fraudCustomers / finalNodes.length) * 100) + "%";
  }
  return "0%";
};

/**
 * @description Filter fn to remove duplicates from an array of objects.
 * @param property in the item based on which duplicates are identified.
 * @returns boolean
 */
export const removeDuplicatesFilter =
  (property: string) =>
  (item: any, index: number, array: any[]): boolean => {
    return (
      array.findIndex((_item) => _item[property] === item[property]) === index
    );
  };

export const isPremimum = (isPremimumItem: boolean, userRoles: UserRole[]) => {
  const isRiskPremiumReviewer = userRoles.includes(
    UserRole.RiskPremiumReviewer
  );
  const isRiskAdmin = userRoles.includes(UserRole.RiskAdmin);

  if (isPremimumItem) {
    if (isRiskPremiumReviewer || isRiskAdmin) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};
export function getViewOnlyModeDetails(): {
  isViewOnly: boolean;
  packageGuid: string;
} {
  const values = window.location.pathname.split("/");
  const viewOnlyIndex = values.indexOf("view");
  if (viewOnlyIndex !== -1) {
    const packageGuid = values[viewOnlyIndex + 1];
    const isValidPackageGuid = PACKAGE_GUID_PATTERN.test(packageGuid);
    if (isValidPackageGuid) {
      return { packageGuid, isViewOnly: true };
    }
  }

  return { packageGuid: "", isViewOnly: false };
}

export function getNewFlagsString(
  newFlags: Array<{ flagAreaID: number }>,
  constants: IConstantsState
) {
  return newFlags
    ? newFlags
        .map(({ flagAreaID }) => constants.flagAreaDictionary[flagAreaID].name)
        .join(", ")
    : "";
}

export function getComponentMaskForAddFlag(
  entityType: EntityType,
  subEntityType: SubEntityType
) {
  const values: string[] = [];
  switch (entityType) {
    case EntityType.TextAsset:
      values.push(Component.AdDescription);
      break;

    case EntityType.OrderItem:
      values.push(Component.Keyword);
      values.push(Component.KeywordParam1);
      values.push(Component.KeywordParam2);
      values.push(Component.KeywordParam3);
      values.push(Component.SiteDomain);
      values.push(Component.DestinationURL);
      values.push(Component.LandingUrl);
      values.push(Component.FinalUrl);
      values.push(Component.MobileFinalUrl);
      values.push(Component.AppFinalUrl);
      break;

    case EntityType.Ad:
      values.push(Component.AdTitle);
      values.push(Component.AdTitleDescription);
      values.push(Component.AdDescription);
      values.push(Component.DisplayUrl);
      values.push(Component.BusinessName);
      values.push(Component.PhoneNumber);
      values.push(Component.SiteDomain);
      values.push(Component.DestinationURL);
      values.push(Component.LandingUrl);
      values.push(Component.FinalUrl);
      values.push(Component.MobileFinalUrl);
      values.push(Component.AppFinalUrl);
      values.push(Component.MediaAssetId);
      values.push(Component.Video);
      break;

    case EntityType.Listing:
      values.push(Component.AdTitle);
      values.push(Component.AdTitleDescription);
      values.push(Component.AdDescription);
      values.push(Component.DisplayUrl);
      values.push(Component.BusinessName);
      values.push(Component.PhoneNumber);
      values.push(Component.DestinationURL);
      values.push(Component.FinalUrl);
      values.push(Component.MobileFinalUrl);
      values.push(Component.AppFinalUrl);
      values.push(Component.Keyword);
      values.push(Component.KeywordParam1);
      values.push(Component.KeywordParam2);
      values.push(Component.KeywordParam3);
      values.push(Component.SiteDomain);
      break;

    case EntityType.FeedItem:
      values.push(Component.DataFeedItem);
      break;

    case EntityType.AdExtension:
      values.push(Component.AdDescription);
      values.push(Component.DestinationURL);
      values.push(Component.MapIcon);
      values.push(Component.BusinessImage);
      values.push(Component.AddressLine1);
      values.push(Component.AddressLine2);
      values.push(Component.LocationExtnBusinessName);
      values.push(Component.PhoneNumber);
      values.push(Component.Description1);
      values.push(Component.Description2);
      values.push(Component.AltText);
      values.push(Component.MediaAssetId);
      values.push(Component.Video);
      values.push(Component.MediaURL);
      values.push(Component.OriginalMediaHeight);
      values.push(Component.OriginalMediaWidth);
      values.push(Component.LandingUrl);
      values.push(Component.FinalUrl);
      values.push(Component.MobileFinalUrl);
      values.push(Component.AppFinalUrl);
      values.push(Component.ReviewSource);
      values.push(Component.ReviewSourceUrl);
      values.push(Component.ReviewText);
      values.push(Component.CallOutText);
      values.push(Component.StructuredSnippetText);
      values.push(Component.BusinessName);
      break;

    case EntityType.VideoAsset:
      values.push(Component.Video);
      break;

    case EntityType.ImageAsset:
      values.push(Component.Image);
      break;

    case EntityType.PA:
    case EntityType.QREPA:
      values.push(Component.AdTitle);
      values.push(Component.SellerName);
      break;

    case EntityType.SmartListing:
      values.push(Component.Keyword);
      break;

    case EntityType.AssetGroupAsset:
      switch (subEntityType) {
        case SubEntityType.Headline:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.LongHeadline:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.Description:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.Image:
        case SubEntityType.ImageSPOTLIGHT:
        case SubEntityType.ImagePRONG:
        case SubEntityType.TransparentLogo:
          values.push(Component.Image);
          break;

        case SubEntityType.SubTitle:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.Video:
          values.push(Component.Video);
          break;

        case SubEntityType.BusinessName:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.FinalUrl:
          values.push(Component.DisplayUrl);
          break;

        case SubEntityType.MobileFinalUrl:
          values.push(Component.DisplayUrl);
          break;

        case SubEntityType.Path1:
          values.push(Component.AdDescription);
          break;

        case SubEntityType.Path2:
          values.push(Component.AdDescription);
          break;
      }

      break;
  }

  return values;
}

export function getDecision(
  decision: string,
  extraDecision: IExtraDecision,
  isFlagReviewReq = false,
  addFlagForceDecisionOverturned = false,
  enableFlagsForceDecisionOverturned = false
) {
  const flags = (extraDecision && extraDecision.newFlags) || [];
  if (
    decision &&
    (isFlagReviewReq ||
      addFlagForceDecisionOverturned ||
      enableFlagsForceDecisionOverturned) &&
    flags.length > 0
  ) {
    return String(Decision.UPHOLDREJECTION);
  }
  return String(decision || Decision.TOREVIEW);
}

export const isValidGuid = (guid: string) => {
  const guidRegex =
    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;
  return guidRegex.test(guid);
};

export function getMarketsConfig() {
  return marketsConfig["default"];
}
