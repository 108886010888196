import { AnyAction, Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import {
  DefaultDetectionHistoryState,
  ITNSDetectionHistoryState,
} from "./TNSDetectionHistoryState";
import { actionTypes } from "./TNSDetectionHistoryActions";

export const tnsDetectionHistoryReducer: Reducer<ITNSDetectionHistoryState> = (
  state: ITNSDetectionHistoryState = DefaultDetectionHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.GET_DETECTION_HISTORY:
      return { ...state, loadState: LoadState.InProgress };

    case actionTypes.GET_DETECTION_HISTORY_RECEIVED:
      return {
        ...state,
        loadState: LoadState.Loaded,
        detectionHistoryListHistoryList: action.payload,
      };

    case actionTypes.GET_DETECTION_HISTORY_FAILED:
      return {
        ...state,
        loadState: LoadState.Failed,
      };

    case actionTypes.CLEAR_DETECTION_HISTORY:
      return DefaultDetectionHistoryState;

    default:
      return state;
  }
};
