export enum Permission {
  WriteQueue = "WriteQueue",
  ConfigurePackageCheckoutDuration = "ConfigurePackageCheckoutDuration",
  ChangeQueueStatus = "ChangeQueueStatus",
  ChangeQueuePriority = "ChangeQueuePriority",
  AssignReviewerToQueue = "AssignReviewerToQueue",
  ManageUserRole = "ManageUserRole",
  ConfigureBulkReviewPermission = "ConfigureBulkReviewPermission",
  MovePackageToDifferentQueue = "MovePackageToDifferentQueue",
  DeletePackageFromQueue = "DeletePackageFromQueue",
  ReviewPackage = "ReviewPackage",
  TakeBulkDecision = "TakeBulkDecision",
  AccessAllStats = "AccessAllStats",
  AccessAuditQueue = "AccessAuditQueue",
  ManageLists = "ManageLists",
  AddRulesFromPackageReview = "AddRulesFromPackageReview",
  FraudPremiumAdvertisers = "FraudPremiumAdvertisers",
}
