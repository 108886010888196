import { LoadState, UserRole } from "@trinity/enums";

export interface IUserState {
  userId: number;
  isAuthenticated: boolean;
  loadState: LoadState;
  displayName: string;
  email: string;
  alias: string;
  imageInitials: string;
  imageUrl: string;
  userRoles: UserRole[];
  permissions: Record<string, boolean>;
  error: string;
}

export const DefaultUserState: IUserState = {
  userId: 0,
  isAuthenticated: false,
  loadState: LoadState.NotLoaded,
  displayName: "",
  email: "",
  alias: "",
  imageInitials: "",
  imageUrl: "",
  userRoles: [],
  permissions: {},
  error: "",
};
