import { ResponseError } from "../common/types";

export const FETCH = "utils/fetch";

export interface IFetchOptions extends RequestInit {
  customOptions?: any;
}

export interface IFetchAction {
  type: string;
  payload: {
    input: RequestInfo;
    init: IFetchOptions;
  };
}

export const NoCacheHeader = {
  pragma: "no-cache",
  "cache-control": "no-cache",
};

export const fetchCreator = function (
  input: RequestInfo,
  init: IFetchOptions = {}
): IFetchAction {
  return {
    type: FETCH,
    payload: {
      input: input,
      init: init,
    },
  };
};

export const fetchMiddleware = (store) => (next) => (action) => {
  if (action.type === FETCH) {
    action.payload.init.headers = {
      ...action.payload.init.headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return new Promise((resolve, reject) => {
      fetch(action.payload.input, action.payload.init)
        .then((response: Response) => {
          if (response.ok) resolve(response);
          else
            throw new ResponseError(
              response.status,
              response.statusText,
              response
            );
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    next(action);
  }
};
