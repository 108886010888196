import { CardItemType } from "@trinity/common";
import { LoadState } from "@trinity/enums";

export interface IQueueItem {
  id: number;
  name: string;
  packageCount: number;
  maxAge: string;
  packagesOOSLA: number;
  priority: number;
}

export interface IReviewerSummaryItem {
  key: string;
  name: string;
  count: number;
}

export interface IReviewerOverviewState {
  queues: IQueueItem[];
  nextPackageGuid: string;
  reviewerSummary: IReviewerSummaryItem[];
  loadState: IReviewerOverviewLoadState;
}

const DefaultSummaryState = [
  {
    key: "averageTime",
    name: "reviewer-overview.summary-card-average-time-label",
    type: CardItemType.Duration,
    count: 0,
  },
  {
    key: "averageThroughput",
    name: "reviewer-overview.summary-card-average-throughput-label",
    type: CardItemType.Rows,
    count: 0,
  },
  {
    key: "accuracy",
    name: "reviewer-overview.summary-card-accuracy-label",
    type: CardItemType.Percentage,
    count: 0,
  },
  {
    key: "rowsReviewed",
    name: "reviewer-overview.summary-card-rows-reviewed-label",
    type: CardItemType.Rows,
    count: 0,
  },
  {
    key: "packagesReviewed",
    name: "reviewer-overview.summary-card-packages-reviewed-label",
    type: CardItemType.Number,
    count: 0,
  },
  {
    key: "averageRows",
    name: "reviewer-overview.summary-card-average-rows-label",
    type: CardItemType.Number,
    count: 0,
  },
];

export const DefaultReviewerOverviewState: IReviewerOverviewState = {
  queues: [],
  nextPackageGuid: "",
  reviewerSummary: DefaultSummaryState,
  loadState: {
    queues: LoadState.NotLoaded,
    queuesSummary: LoadState.NotLoaded,
  },
};

export interface IReviewerOverviewLoadState {
  queues: LoadState;
  queuesSummary: LoadState;
}
