export enum Component {
  Keyword = "Keyword",
  KeywordParam1 = "KeywordParam1",
  KeywordParam2 = "KeywordParam2",
  KeywordParam3 = "KeywordParam3",
  AdTitleDescription = "AdTitleDescription",
  AdTitle = "AdTitle",
  AdDescription = "AdDescription",
  DisplayUrl = "DisplayUrl",
  DestinationURL = "DestinationURL",
  LandingUrl = "LandingUrl",
  SiteDomain = "SiteDomain",
  BusinessName = "BusinessName",
  PhoneNumber = "PhoneNumber",
  CashbackTextParam = "CashbackTextParam",
  AltText = "AltText",
  Audio = "Audio",
  Video = "Video",
  Flash = "Flash",
  CAsset = "CAsset",
  Image = "Image",
  Destination = "Destination",
  Asset = "Asset",
  Ad = "Ad",
  Order = "Order",
  BiddingKeyword = "BiddingKeyword",
  Association = "Association",
  Script = "Script",
  LocationExtnBusinessName = "LocationExtnBusinessName",
  AddressLine1 = "AddressLine1",
  AddressLine2 = "AddressLine2",
  MapIcon = "MapIcon",
  BusinessImage = "BusinessImage",
  MediaAssetId = "MediaAssetId",
  SellerName = "SellerName",
  DefaultParam = "DefaultParam",
  MatchType = "MatchType",
  IfSearch = "IfSearch",
  IfContent = "IfContent",
  Description1 = "Description1",
  Description2 = "Description2",
  MediaURL = "MediaURL",
  DestintationUrl = "DestintationUrl",
  OriginalMediaHeight = "OriginalMediaHeight",
  OriginalMediaWidth = "OriginalMediaWidth",
  FinalUrl = "FinalUrl",
  AppFinalUrl = "AppFinalUrl",
  MobileFinalUrl = "MobileFinalUrl",
  MediaAssetId2 = "MediaAssetId2",
  MediaAssetId3 = "MediaAssetId3",
  MediaAssetId4 = "MediaAssetId4",
  ReviewText = "ReviewText",
  ReviewSourceUrl = "ReviewSourceUrl",
  CallOutText = "CallOutText",
  ReviewSource = "ReviewSource",
  StructuredSnippetText = "StructuredSnippetText",
  DataFeedItem = "DataFeedItem",
}

export enum ComponentMask {
  All = "All",
  TX = "AdDescription",
  TI = "AdTitle",
  AN = "AdTitleDescription",
  AT = "AltText",
  BN = "BusinessText",
  CB = "CashbackTextParam",
  DE = "DestinationUrl",
  DI = "DisplayUrl",
  IM = "Image",
  KW = "Keyword",
  P1 = "KeywordParam1",
  P2 = "KeywordParam2",
  P3 = "KeywordParam3",
  BK = "BiddingKeyword",
  LP = "LandingUrl",
  PN = "PhoneNumber",
  CA = "CAsset",
  FL = "Flash",
  SC = "Script",
  AU = "Audio",
  VI = "Video",
  FI = "DataFeedItem",
}
