import { Reducer } from "redux";
import { IAdminUserManagementState, IUser } from "./AdminUserManagementState";
import { actionTypes } from "./AdminUserManagementActions";

const INITIAL_STATE: IAdminUserManagementState = {
  evdRolesSummary: [],
  users: [],
  itemsCount: 0,
  queues: [],
  selectedUser: {} as IUser,
  searchedUsers: [],
  resetSelection: false,
};

export const adminUserManagementReducer: Reducer<IAdminUserManagementState> = (
  state: IAdminUserManagementState = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_USERS_SUMMARY:
      return {
        ...state,
        evdRolesSummary: action.payload,
      };

    case actionTypes.GET_USERS_SUMMARY_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_USERS:
      return {
        ...state,
        users: action.payload.items.filter((x) => x.roles.length > 0),
        itemsCount: action.payload.count,
        resetSelection: false,
      };

    case actionTypes.GET_USERS_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_ALL_QUEUES:
      return {
        ...state,
        queues: action.payload,
      };

    case actionTypes.GET_ALL_QUEUES_FAILED:
      return {
        ...state,
      };

    case actionTypes.SELECTED_ITEM:
      return {
        ...state,
        selectedUser: action.payload,
        resetSelection: false,
      };

    case actionTypes.SUBMIT_USER:
      return {
        ...state,
        resetSelection: true,
      };

    case actionTypes.SUBMIT_USER_FAILED:
      return {
        ...state,
        resetSelection: true,
      };

    case actionTypes.GET_SEARCHED_USER:
      return {
        ...state,
        searchedUsers: action.payload,
      };

    case actionTypes.GET_SEARCHED_USER_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
