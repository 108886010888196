import { Reducer } from "redux";
import {
  actionTypes,
  IWordBreakerState,
  INITIAL_STATE,
} from "./WordBreakerState";

export const wordBreakerReducer: Reducer<IWordBreakerState> = (
  state: IWordBreakerState = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_WordBreaker_OUTPUT:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_WordBreaker_OUTPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        normalizedOutput: action.payload.outputToken
          ? action.payload.outputToken.join("\n")
          : "",
      };

    case actionTypes.GET_WordBreaker_OUTPUT_FAILED:
      return {
        ...state,
        normalizedOutput: "",
        loading: false,
      };

    case actionTypes.CLEAR_WordBreaker_OUTPUT:
      return {
        ...state,
        normalizedOutput: "",
      };

    default:
      return state;
  }
};
