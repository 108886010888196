import { QueueTypes } from "@trinity/enums";

export interface IUserPackage {
  id: string;
  queueId: number | undefined;
  queueName: string | undefined;
  queueIsTranslationEnabled: boolean;
  queueLanguage: number;
  queueType: QueueTypes;
}

export const INITIAL_STATE: IUserPackage = {
  id: "",
  queueId: 0,
  queueName: "",
  queueIsTranslationEnabled: false,
  queueLanguage: -1,
  queueType: QueueTypes.None,
};
