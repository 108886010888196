import { Reducer } from "redux";
import {
  actionTypes,
  defaultPublisherCommonData,
  DefaultSearchPublisherRuleDetails,
  IPublisherCommonData,
  ISearchPublisherRuleDetails,
} from "./PublisherRuleState";
import {
  DefaultUpdatePublisherRuleData,
  IUpdatePublisherRuleData,
} from "../../modules/publisher-rule/PublisherRule.types";
import { PublisherRuleConstants } from "../../modules/publisher-rule/PulisherRule.service";

export const SearchPublisherRuleReducer: Reducer<
  ISearchPublisherRuleDetails
> = (
  state: ISearchPublisherRuleDetails = DefaultSearchPublisherRuleDetails,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SEARCH_PUBLISHER_RULE:
      const fetchedData = action.payload.data;
      return {
        ...state,
        searchResults: fetchedData,
      };
    case actionTypes.SELECTED_PUBLISHER_RULE: {
      return {
        ...state,
        selectedRule: action.payload,
      };
    }
    default:
      return state || {};
  }
};

export const PublisherCategoryReducer: Reducer<IPublisherCommonData> = (
  state: IPublisherCommonData = defaultPublisherCommonData,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORY_DETAILS:
      const fetchedData = action.payload.data;
      const transformedData = fetchedData.map((item) => ({
        key: item.id.toString(),
        text: `${item.val}`,
      }));
      const publisherCommonDatas: IPublisherCommonData = {
        categories: transformedData,
      };
      localStorage.setItem(
        PublisherRuleConstants.PUBLISHER_CATEGORY_DETAILS,
        JSON.stringify(publisherCommonDatas)
      );
      return {
        ...state,
        categories: transformedData,
      };
    case actionTypes.FETCH_PUBLISHER_CATEGORY_FROM_LOCAL_STORAGE:
      const commonData: IPublisherCommonData = action.payload;
      return {
        ...state,
        categories: commonData.categories,
      };

    default:
      return state || {};
  }
};

export const UpdatePublisherRuleReducer: Reducer<IUpdatePublisherRuleData> = (
  state: IUpdatePublisherRuleData = DefaultUpdatePublisherRuleData,
  action: any
) => {
  switch (action.type) {
    case actionTypes.ON_LOAD_PUBLISHER_RULE_DATA:
      //const data: IUpdatePublisherRuleData = [...action.payload.data];
      return {
        ...state,
        category: action.payload.data.category,
        tokens: action.payload.data.tokens,
        status: action.payload.data.status,
        modifiedBy: action.payload.data.modifiedBy,
      };
    default:
      return state || {};
  }
};
