/**
 * List of differences between translations supported by the API and default rules DB language.
 * SUPPORTED TRANSLATION LANGUAGES REFERENCE: https://docs.microsoft.com/en-us/azure/cognitive-services/translator/language-support
 */
export const TRANSLATIONS_SUPPORTED_LANGUAGES = {
  iw: { supportedCode: "he", name: "Hebrew" },
  in: { supportedCode: "id", name: "Indonesian" },
  mn: { supportedCode: "mn-Mong", name: "Mongolian" },
  no: { supportedCode: "nb", name: "Norwegian" },
  sr: { supportedCode: "sr-Cyrl", name: "Serbian" },
  sh: { supportedCode: "hr", name: "Serbo-Croatian" },
  sc: { supportedCode: "zh-hans", name: "Simplified Chinese" },
};
