import { Reducer } from "redux";
import {
  DefaultTNSEnforcementState,
  ITNSEnforcementState,
} from "./TNSEnforcementState";
import { actionTypes } from "./TNSEnforcementActions";
import { LoadState } from "@trinity/enums";

export const tnsEnforcementReducer: Reducer<ITNSEnforcementState> = (
  state: ITNSEnforcementState = DefaultTNSEnforcementState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.ENFORCEMENT_VALIDATE_RESPONSE: {
      const { overallStatus, entityDecisions } = action.payload;
      return {
        ...state,
        validate: { overallStatus, entityDecisions },
      };
    }
    case actionTypes.ENFORCEMENT_CONFIRMED_PROGRESS: {
      return {
        ...state,
        isConfirmed: LoadState.InProgress,
      };
    }
    case actionTypes.ENFORCEMENT_CONFIRMED: {
      return {
        ...state,
        isConfirmed: LoadState.Loaded,
      };
    }
    case actionTypes.CLEAR_ENFORCEMENT: {
      return {
        ...state,
        validate: DefaultTNSEnforcementState["validate"],
        isConfirmed: LoadState.NotLoaded,
      };
    }

    case actionTypes.GET_AUTO_SUGGESTION_VALUES_RESPONSE:
      return {
        ...state,
        autoSuggest: action.payload.map((x) => {
          return {
            key: x.transactionType,
            value: x.trackingId,
            notificationTimeUtc: x.notificationTimeUtc,
          };
        }),
      };

    case actionTypes.CLEAR_AUTO_SUGGESTION_VALUES: {
      return {
        ...state,
        autoSuggest: [],
      };
    }

    default:
      return state;
  }
};
