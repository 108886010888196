import { Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import { actionTypes } from "./AdminQueueManagementActions";
import {
  IAdminQueueManagementState,
  INITIAL_STATE,
  IQueueItem,
} from "./AdminQueueManagementState";

export const adminQueueManagementReducer: Reducer<
  IAdminQueueManagementState
> = (state: IAdminQueueManagementState = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.GET_QUEUE_MANAGEMENT_QUEUES:
      return {
        ...state,
        allQueues: action.payload.allQueues,
        isLoaded: LoadState.Loaded,
      };

    case actionTypes.GET_QUEUE_MANAGEMENT_QUEUES_FAILED:
      return {
        ...state,
        isLoaded: LoadState.Failed,
      };

    case actionTypes.UPDATE_TRANSLATION:
      return {
        ...state,
        allQueues: updateQueues(state.allQueues, action.payload),
      };

    default:
      return state;
  }
};

const updateQueues = (
  currentQueues: IQueueItem[],
  payload: { queueId: number; checked: boolean }
) => {
  const { checked, queueId } = payload;

  for (let index = 0; index < currentQueues.length; index++) {
    const element = currentQueues[index];
    if (element.id === queueId) {
      currentQueues[index].isTranslationEnabled = checked;
      break;
    }
  }

  return currentQueues;
};
