import { IRendererProps } from "./Renderer.types";

export const TextRenderer = (props: IRendererProps) => {
  const { item, column } = props;

  if (!item || !column || !column.key) return null;

  if (!item[column.key] || item[column.key].length === 0) {
    return <></>;
  }

  return <>{item[column.key]}</>;
};
