export const actionTypes = {
  GET_APPROVAL_ROWS: "GET_APPROVAL_ROWS",
  GET_APPROVAL_ROWS_FAILED: "GET_APPROVAL_ROWS_FAILED",
};

export interface PendingRuleItem {
  term: string;
  flagarea: number;
  country: string;
  language: string;
  requestor: string;
  demandScanned: number;
  additionalDemand: number;
  impactPercentage: number;
  estRevenue: number;
  estImpressions: number;
  sampleDemand: string;
  overrideType: string;
  CID: string;
  XIDs: string;
  comments: string;
}

export interface IApprovalState {
  defaultRules: PendingRuleItem[];
  flagOverrideRules: PendingRuleItem[];
  termOverrideRules: PendingRuleItem[];
}

export const defaultApprovalState = {
  defaultRules: [],
  flagOverrideRules: [],
  termOverrideRules: [],
};
