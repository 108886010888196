import { ITableRowItemProps } from "../../components/common/table/Table.types";
import { LoadState, QueueTypes } from "@trinity/enums";

export interface IReviewerSubmittedPackagesState {
  packages: IPackageItem[];
  packageItemsCount: number;
  packageLoadState: ISubmittedPackagesLoadState;
  queueSummary: IQueueSummary;
}

export interface ISubmittedPackagesLoadState {
  packages: LoadState;
  queueSummary: LoadState;
}

export interface IPackageItem extends ITableRowItemProps {
  AccountId: string;
  Age: number;
  AssignedToUserId: number;
  AssignedToUserName: string;
  CapabilityId: number;
  CustomerId: string;
  CustomerName: string;
  FlagArea: string;
  PackageGuid: string;
  RowsCount: number;
  SequenceId: number;
  WorkflowStatusId: number;
  StoreId: string;
  UsedInEntityTypes?: string;
}

export interface IQueueSummary {
  id: number;
  name: string;
  language: string;
  queueType: QueueTypes;
}

export const DefaultQueueSummary: IQueueSummary = {
  id: 0,
  language: "",
  name: "",
  queueType: QueueTypes.None,
};

export const INITIAL_STATE: IReviewerSubmittedPackagesState = {
  packages: [],
  packageItemsCount: 0,
  packageLoadState: {
    packages: LoadState.NotLoaded,
    queueSummary: LoadState.NotLoaded,
  },
  queueSummary: DefaultQueueSummary,
};
