import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import {
  ITableColumn,
  ITableProps,
} from "../../components/common/table/Table.types";
import { IPackageItem } from "../../store/admin-package-management/AdminPackageManagementState";
import { RendererType } from "./renderers/Renderer.types";

export interface IAdminPackageManagementProps {
  styles?: IStyleFunctionOrObject<
    IAdminPackageManagementProps,
    IAdminPackageManagementStyles
  >;
  theme?: ITheme;
  className?: string;
}

export interface IParams {
  queueId: string;
}

export interface IAdminPackageManagementStyles {
  root: IStyle;
  main: IStyle;
  heading: IStyle;
  username: IStyle;
  messageBox: IStyle;
  shimmerHeading: IStyle;
  toggleButtonStyles: IStyle;
  disableEvents: IStyle;
}

export interface IAdminPackageManagementStyleProps {
  theme?: ITheme;
  className?: string;
}

export enum PackageTableColumn {
  Priority = "priority",
  AssignedTo = "AssignedToUserName",
}

export enum PackageCellValue {
  Empty = "--",
}

export enum RequestKeys {
  AssignToQueue = "queueId",
  ApplyDecision = "decision",
  SequenceId = "sequence",
  AssignUnAssign = "assignedTo",
}

export enum PackageManagementValidations {
  SequenceMaxLength = 19,
}

export interface IPackageManagementTableProps extends ITableProps {
  columns: IPackageManagementColumn[];
}

export interface IPackageManagementColumn extends ITableColumn {
  rendererType?: RendererType;
  additionalInfo?: Record<string, any>;
  onOpenIconClick?: (item: IPackageItem) => void;
}

export enum PackageUnAssign {
  value = -1,
}
