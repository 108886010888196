export enum ReviewType {
  Normal = 0,
  Escalation = 1,
  Audit = 2,
}

export enum ReviewPackageWorkFlow {
  NONE = "none",
  BULK = "bulk",
  ESCALATION = "escalation",
  MISMATCH = "MisMatchReport",
  REJECTION = "RejectionReport",
  QREREVIEWS = "QREReviewsReport",
}
