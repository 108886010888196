import { Reducer } from "redux";
import { LoadState } from "@trinity/enums";
import {
  actionTypes,
  DefaultCurrentPackage,
  DefaultReviewerReviewPackageState,
  ICurrentPackage,
  IPackageDetails,
  IReviewerReviewPackageState,
} from "./ReviewerReviewPackageState";
import history from "../../utils/common/history";

export const reviewerReviewPackageReducer: Reducer<
  IReviewerReviewPackageState
> = (
  state: IReviewerReviewPackageState = DefaultReviewerReviewPackageState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.InProgress,
        },
      };

    case actionTypes.GET_CURRENT_PACKAGE_COMPLETE:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.Loaded,
        },
      };

    case actionTypes.GET_CURRENT_PACKAGE_FAILED:
      return {
        ...state,
        currentPackage: {
          ...state.currentPackage,
          loadState: LoadState.Failed,
        },
      };

    case actionTypes.GET_PACKAGE_DETAILS:
      return {
        ...state,
      };

    case actionTypes.GET_PACKAGE_DETAILS_COMPLETE: {
      const data = action.payload as IPackageDetails;

      redirectIfPackageIdIsEmpty(data.packageGuid);

      return {
        ...state,
        isViewOnly: true,
        currentPackage: {
          ...state.currentPackage,
          id: data.packageGuid,
          queueId: data.capabilityID,
          queueIsTranslationEnabled: data.isTranslationEnabled,
          queueLanguage: data.language,
          queueName: data.capabilityName,
          queueType: data.queueType,
          workflowStatusId: data.workflowStatusId,
          assignedUserID: data.assignedUserID,
        },
      };
    }

    case actionTypes.GET_PACKAGE_DETAILS_FAILED:
      return {
        ...state,
      };

    case actionTypes.GET_REVIEWER_REVIEWING_PACKAGE:
      return {
        ...state,
      };

    case actionTypes.GET_REVIEWER_REVIEWING_PACKAGE_COMPLETE: {
      const data = action.payload as ICurrentPackage;
      redirectIfPackageIdIsEmpty(data.id as string);

      return {
        ...state,
        isViewOnly: false,
        currentPackage: data,
      };
    }

    case actionTypes.GET_REVIEWER_REVIEWING_PACKAGE_FAILED:
      return {
        ...state,
      };

    case actionTypes.ASSIGN_AND_REVIEW_PACKAGE:
      return {
        ...state,
      };

    case actionTypes.ASSIGN_AND_REVIEW_PACKAGE_FAILED:
      return {
        ...state,
      };

    case actionTypes.RESET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: DefaultCurrentPackage,
      };

    default:
      return state;
  }
};

function redirectIfPackageIdIsEmpty(packageGuid: string) {
  if (!packageGuid) {
    const redirectUrl =
      localStorage.getItem("trinity-overview-origin") || "/reviewer/overview";

    //3 secs delay to display messages
    setTimeout(() => {
      history.push(redirectUrl);
    }, 3000);
  }
}
