import { ITag } from "@fluentui/react/lib/components/pickers/TagPicker/TagPicker.types";
import { Reducer } from "redux";
import {
  actionTypes,
  CodeNameMapping,
  DefaultAccountState,
  DefaultAdvertiserState,
  DefaultImportDataWithRevenues,
  DefaultRevenueGrainDetailsState,
  DefaultSearchTrademarkState,
  DefaultTrademarkLanguageCountry,
  DefaultUpdateTrademarkState,
  IAccountDetails,
  IAdvertiserDetails,
  IMappings,
  ImportDataWithRevenues,
  IRevenueGrainDetail,
  IRevenueGrainDetails,
  ISearchTrademarkDetails,
  ITrademarkLanguageCountry,
  IUpdateTrademarkState,
} from "./TrademarkState";

export const AdvertiserReducer: Reducer<IAdvertiserDetails> = (
  state: IAdvertiserDetails = DefaultAdvertiserState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_ADVERTISER_DETAIL:
      return {
        ...state,
        advertiserList: action.payload.data,
      };
    case actionTypes.FETCH_SELECTED_ADVERTISER_DETAIL:
      let allAdvertiserIds: ITag[] = [];
      if (action.payload.hasSearchRecords) {
        allAdvertiserIds = action.payload.data;
        return {
          ...state,
          allAdvertisers: action.payload.data,
        };
      }
      return {
        ...state,
        selectedAdvertiserList: action.payload.data,
      };
    default:
      return state || {};
  }
};

export const AccountReducer: Reducer<IAccountDetails> = (
  state: IAccountDetails = DefaultAccountState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_DETAIL:
      return {
        ...state,
        accountList: action.payload.data,
      };
    case actionTypes.FETCH_SELECTED_ACCOUNT_DETAIL:
      let allAccountIds: ITag[] = [];
      if (action.payload.hasSearchRecords) {
        allAccountIds = action.payload.data;
        return {
          ...state,
          allAccounts: action.payload.data,
        };
      }
      return {
        ...state,
        selectedAccountList: action.payload.data,
      };
    default:
      return state || {};
  }
};

export const RevenueGrainReducer: Reducer<IRevenueGrainDetails> = (
  state: IRevenueGrainDetails = DefaultRevenueGrainDetailsState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_REVENUE_ACCOUNT_DETAIL:
      return {
        ...state,
        revenueDetails: action.payload.data,
      };
    case actionTypes.FETCH_TOTAL_REVENUE_DATA:
      return {
        ...state,
        totalRevenueData: action.payload.data,
      };
    default:
      return state || {};
  }
};

export const ImportTrademarkReducer: Reducer<ImportDataWithRevenues> = (
  state: ImportDataWithRevenues = DefaultImportDataWithRevenues,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_TOTAL_REVENUE_FOR_IMPORT:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state || [];
  }
};

export const SearchTrademarkReducer: Reducer<ISearchTrademarkDetails> = (
  state: ISearchTrademarkDetails = DefaultSearchTrademarkState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SEARCH_TRADEMARK:
      const fetchedData = action.payload.data;
      const toDisplayData: any = [];
      let allAccountIds = "0,";
      let allAdvertiserIds = "0,";
      if (fetchedData && fetchedData.length > 0) {
        fetchedData.forEach((element) => {
          if (element.customerIds && element.customerIds.trim() !== "0") {
            element.flagLevel = element.flagLevel.trim() == "99" ? "1" : "99";
          }
          const accountIds = element.accountIds
            ? element.accountIds.trim()
            : "";
          const customerIds = element.customerIds
            ? element.customerIds.trim()
            : "";
          if (accountIds.length > 0 && allAccountIds.indexOf(accountIds) == -1)
            allAccountIds = allAccountIds.concat(accountIds, ",");
          if (
            customerIds.length > 0 &&
            allAdvertiserIds.indexOf(customerIds) == -1
          )
            allAdvertiserIds = allAdvertiserIds.concat(customerIds, ",");
          toDisplayData.push(element);
        });
      }
      allAccountIds = allAccountIds.slice(0, allAccountIds.length - 1);
      allAdvertiserIds = allAdvertiserIds.slice(0, allAdvertiserIds.length - 1);
      return {
        ...state,
        searchResults: toDisplayData,
        allAccounts: allAccountIds,
        allAdvertisers: allAdvertiserIds,
      };
    case actionTypes.EXPORT_TRADEMARK:
      const exportData = action.payload.data;
      const toExportedData: any = [];

      if (exportData && exportData.length > 0) {
        exportData.forEach((element) => {
          if (element.customerIds && element.customerIds.trim() !== "0") {
            element.flagLevel = element.flagLevel.trim() == "99" ? "1" : "99";
          }
          toExportedData.push(element);
        });
      }
      return {
        ...state,
        exportData: toExportedData,
      };
    case actionTypes.CLEAR_EXPORT_DATA:
      return {
        ...state,
        exportData: [],
      };
    case actionTypes.SELECTED_TRADEMARK: {
      return {
        ...state,
        selectedRule: action.payload,
      };
    }
    default:
      return state || {};
  }
};

export const UpdateTrademarkReducer: Reducer<IUpdateTrademarkState> = (
  state: IUpdateTrademarkState = DefaultUpdateTrademarkState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.ON_LOAD_TRADEMARK_DATA:
      const data: IUpdateTrademarkState = action.payload.data;
      return {
        ...state,
        customerIds: data.customerIds,
        term: data.term,
        expiryDate: data.expiryDate,
        matchType: data.matchType,
        flagLevel: data.flagLevel,
        countryCode: data.countryCode,
        languageCode: data.languageCode,
        id: data.id,
        modifiedBy: data.modifiedBy,
      };
    default:
      return state || {};
  }
};

export const FetchCountryLanguageReducer: Reducer<ITrademarkLanguageCountry> = (
  state: ITrademarkLanguageCountry = DefaultTrademarkLanguageCountry,
  action: any
) => {
  switch (action.type) {
    case actionTypes.FETCH_TRADEMARK_COUNTRY_LANGUAGE:
      const country: Record<number, CodeNameMapping> = action.payload.country;
      const language: Record<number, CodeNameMapping> = action.payload.language;
      const mappings: ITrademarkLanguageCountry = {
        languagesForCountry: country,
        contriesForLanguage: language,
      };
      localStorage.setItem(
        "trademarkCountryLanguage",
        JSON.stringify(mappings)
      );
      return {
        ...state,
        languagesForCountry: country,
        contriesForLanguage: language,
      };
    case actionTypes.FETCH_TRADEMARK_COUNTRY_LANGUAGE_FROM_LOCAL:
      const lsCountry: Record<number, CodeNameMapping> = action.payload.country;
      const lsLanguage: Record<number, CodeNameMapping> =
        action.payload.language;
      return {
        ...state,
        languagesForCountry: lsCountry,
        contriesForLanguage: lsLanguage,
      };
    default:
      return state || {};
  }
};
