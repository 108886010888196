export interface IWordBreakerState {
  loading: boolean;
  normalizedOutput: string;
}

export enum actionTypes {
  GET_WordBreaker_OUTPUT = "[WordBreaker] - GET_WordBreaker_OUTPUT",
  GET_WordBreaker_OUTPUT_SUCCESS = "[WordBreaker] - GET_WordBreaker_OUTPUT_SUCCESS",
  GET_WordBreaker_OUTPUT_FAILED = "[WordBreaker] - GET_WordBreaker_OUTPUT_FAILED",
  CLEAR_WordBreaker_OUTPUT = "[WordBreaker] - CLEAR_WordBreaker_OUTPUT",
}

export const INITIAL_STATE: IWordBreakerState = {
  loading: false,
  normalizedOutput: "",
};
