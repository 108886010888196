import { ISeedTokenJobStatusItem } from "../../modules/list-manager-seed-token-job-status/SeedTokenJobStatus.types";

export interface IListManagerSeedTokenState {
  loading: boolean;
  jobStatus: IJobStatus;
}

export interface IJobStatus {
  items: Array<ISeedTokenJobStatusItem> | null;
  selectedItems: Array<ISeedTokenJobStatusItem> | null;
}

export enum actionTypes {
  UPDATE_PRESETS = "[LISTMANAGERSEEDTOKEN] - UPDATE_PRESETS",
  CREATE_JOB = "[LISTMANAGERSEEDTOKEN] - CREATE_JOB",
  CREATE_JOB_FAILED = "[LISTMANAGERSEEDTOKEN] - CREATE_JOB_FAILED",
  GET_JOB_STATUS_FAILED = "[LISTMANAGERSEEDTOKEN] - GET_JOB_STATUS_FAILED",
  GET_JOB_STATUS = "[LISTMANAGERSEEDTOKEN] - GET_JOB_STATUS",
  DELETE_JOBS = "[LISTMANAGERSEEDTOKEN] - DELETE_JOBS",
  DELETE_JOBS_FAILED = "[LISTMANAGERSEEDTOKEN] - DELETE_JOBS_FAILED",
  SELECTED_ITEMS = "[LISTMANAGERSEEDTOKEN] - SELECTED_ITEMS",
}

export const INITIAL_STATE: IListManagerSeedTokenState = {
  loading: false,
  jobStatus: {
    items: null,
    selectedItems: null,
  },
};
