import {
  FontWeights,
  getScreenSelector,
  getTheme,
  IPanelStyles,
  FontSizes,
} from "@fluentui/react";
import { Sizes } from "@trinity/common";

export const getStyles = () => {
  const theme = getTheme();
  const MinimumScreenSelector = getScreenSelector(0, Number.MAX_VALUE);

  const panelStyles: Partial<IPanelStyles> = {
    root: [
      {
        top: 0,
        position: "fixed",
      },
    ],
    main: [
      {
        left: 0,
      },
    ],
    contentInner: [
      {
        background: theme.palette.neutralLighterAlt,
      },
    ],
    content: [
      {
        selectors: {
          [MinimumScreenSelector]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    ],
    header: [
      {
        selectors: {
          [MinimumScreenSelector]: {
            margin: 0,
            padding: Sizes.paddingDefault,
          },
        },
      },
    ],
    commands: [
      {
        background: theme.palette.neutralLighterAlt,
        selectors: {
          ".iconSwitch": {
            left: 0,
            position: "absolute",
            width: 48,
            height: 48,
            padding: 0,

            selectors: {
              ":hover, :active": {
                backgroundColor: theme.palette.neutralLight,
              },
              "i, :hover i,:active i": {
                color: theme.palette.neutralPrimary,
                fontSize: 24,
              },
            },
          },
        },
      },
    ],
    navigation: [
      {
        height: 48,
      },
    ],
    scrollableContent: [
      {
        padding: `0 ${Sizes.paddingSmall}`,
        overflowX: "hidden",

        selectors: {
          ".selected": {
            fontWeight: FontWeights.semibold,
          },
        },
      },
    ],
  };

  const titleStyles = {
    root: [
      {
        fontSize: FontSizes.size20,
        fontWeight: FontWeights.semibold,
      },
    ],
  };

  return {
    panel: panelStyles,
    title: titleStyles,
  };
};
