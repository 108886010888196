import { CustomerStatus } from "./customer-status";

export const fraudStatusCodes = {
  [CustomerStatus.Active]: {
    color: "#107C10",
    iconName: "Shield",
  },
  [CustomerStatus.Pending]: {
    color: "#A19F9D",
    iconName: "Clock",
  },
  [CustomerStatus.Fraud]: {
    color: "#D83B01",
    iconName: "ShieldAlert",
  },
  [CustomerStatus["Strike 2"]]: {
    color: "#FFA500",
    iconName: "ShieldAlert",
  },
  [CustomerStatus["Strike 3"]]: {
    color: "#FF6666",
    iconName: "ShieldAlert",
  },
};
