import { SubEntityTypeText } from "@trinity/enums";
import { ITableRowItemProps } from "../../components/common/table/Table.types";

export interface IEntityTrackerRequest {
  entityID: string;
  entityType: string;
  assetType: string;
  rSAAttributeType: string;
  orderID: string;
  startDate: string;
  endDate: string;
}

export interface IEntityTrackerResponse {
  entityStatus: IEntityStatusData;
  entityHistory: IEntityHistoryData;
  entityBypassData: IEntityBypassData;
  entityUserData: IEntityUserData;
  escalationInfo: IEscalationInfo;
}

export interface IEntityStatusData {
  customerID: string;
  accountID: string;
  entityID: string;
  orderId: string;
  entityType: string;
  entityCreatedDateCampaign: string;
  entityCreatedDateEditorial: string;
  entityModifiedDateEditorial: string;
  entityLastUpdateFlow: string;
  languageId: string;
  editorialStatusOutput: Record<string, string[]>;
  editorialCategories: Record<string, string[]>;
  editorialDetectedErrors: IFlagData[];
  assetData: IAssetData | null;
  adExtentionData: IAdExtensionData | null;
  rsaAttributeData: IRSAAttributeData | null;
  productAdData: IProductAdData | null;
}

export interface IFlagData extends ITableRowItemProps {
  countryCode: string;
  flagId: string | null;
  flagAreaId: string;
  flagLevel: string;
  details: string;
}

export interface ICategoryData extends ITableRowItemProps {
  category: string;
  countryCode: string[];
}

export interface IStatusdData extends ITableRowItemProps {
  status: string;
  countryCode: string[];
}

export interface IAssetData {
  assetType: string;
}

export interface IAdExtensionData {
  adExtensionTypeId: string;
}

export interface IProductAdData {
  sellerName: string;
  storeId: string;
  evSourceMask: string;
  languageCode: string;
  feedLabel: string;
  optinCountires: string;
  offerOrigin: string;
  adTitle: string;
  adDescription: string;
}

export interface IRSAAttributeData {
  attributeType: string;
}

export interface IEntityHistoryData {
  isOutputTruncated: boolean;
  entityHistoryDataList: IEntityData[];
}

export interface IEntityData extends ITableRowItemProps {
  timestamp: string;
  entityID: string;
  parentEntityID: string;
  version: string;
  modifiedByUserId: IModifiedByUserData;
  eventType: string;
  requestID: string;
  message: string;
  exception: string;
  editorialStatusOutput: Record<string, string[]>;
  editorialCategories: Record<string, string[]>;
  editorialDetectedErrors: IFlagData[];
}

export interface IModifiedByUserData {
  id: string;
  name: string;
  email: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface IEntityBypassData {
  flagLevelOverrideData: IFALevelOverrideData[];
  ruleLevelOverrideData: IRulesLevelOverrideData[];
  isCustomerUberAllowed: boolean;
}

export interface IEntityUserData {
  originUrl: string;
  entityAccountInfo: IEntityAccountInfo | null;
  entityCustomerInfo: IEntityCustomerInfo | null;
  entityEditorialCustomerPilotFeatureList: IEntityCustomerPilotFeatureInfo[];
  entityCustomerPilotFeatureList: IEntityCustomerPilotFeatureInfo[];
}

export interface IEscalationInfo {
  entityId: string;
  entityType: string;
  rowDateSource: string;
  subEntityTypeText: string;
  editorialStatus: string;
  editorialCategoryInfoDict: Record<string, string>;
  customerId: string;
  isAdultCustomer: string;
}

export interface IEntityAccountInfo {
  accountID: string;
  accountNumber: string;
  accountName: string;
  countryCode: string;
}

export interface IEntityCustomerInfo {
  customerID: string;
  customerName: string;
  customerNumber: string;
  customerFraudStatusId: string;
}

export interface IEntityCustomerPilotFeatureInfo {
  customerID: string;
  featureId: string;
  modifiedDateTime: string;
  modifiedByUserId: string;
}

export interface IFALevelOverrideData extends ITableRowItemProps {
  listID: string;
  listItemTypeID: string;
  listItemID: string;
  countryCode: string;
  customerID: string;
  componentMask: string;
  modifiedByUserID: string;
  descr: string;
  rowStatus: string;
  rowDateCreated: string;
  rowDateModified: string;
  rowGUID: string;
  rowAction: string;
  dateStart: string;
  dateStop: string;
  flagAreaID: string;
  flagLevel: string;
  overrideFlagAreaID: string;
  overrideFlagLevel: string;
  bypassTypeId: string;
}

export interface IRulesLevelOverrideData extends ITableRowItemProps {
  listID: string;
  listItemID: string;
  customerID: string;
  termphrase: string;
  modifiedByUserID: string;
  rowStatus: string;
  rowDateCreated: string;
  rowDateModified: string;
  rowGUID: string;
  rowAction: string;
  dateStart: string;
  dateStop: string;
  overrideFlagAreaID: string;
  overrideFlagLevel: string;
  source: string;
  bypassTypeId: string;
}

export const DefaultEntityTrackerResponse: IEntityTrackerResponse = {
  entityStatus: {
    customerID: "",
    accountID: "",
    entityID: "",
    orderId: "",
    entityType: "",
    entityCreatedDateCampaign: "",
    entityCreatedDateEditorial: "",
    entityModifiedDateEditorial: "",
    entityLastUpdateFlow: "",
    languageId: "",
    editorialStatusOutput: {},
    editorialCategories: {},
    editorialDetectedErrors: [],
    adExtentionData: null,
    assetData: null,
    productAdData: null,
    rsaAttributeData: null,
  },
  entityHistory: {
    isOutputTruncated: false,
    entityHistoryDataList: [],
  },
  entityBypassData: {
    flagLevelOverrideData: [],
    ruleLevelOverrideData: [],
    isCustomerUberAllowed: false,
  },
  entityUserData: {
    originUrl: "",
    entityAccountInfo: {
      accountID: "",
      accountNumber: "",
      accountName: "",
      countryCode: "",
    },
    entityCustomerInfo: {
      customerID: "",
      customerName: "",
      customerNumber: "",
      customerFraudStatusId: "",
    },
    entityEditorialCustomerPilotFeatureList: [],
    entityCustomerPilotFeatureList: [],
  },
  escalationInfo: {
    entityId: "",
    entityType: "",
    rowDateSource: "",
    subEntityTypeText: "",
    editorialStatus: "",
    editorialCategoryInfoDict: {},
    customerId: "",
    isAdultCustomer: "",
  },
};
