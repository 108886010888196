import { IFetchAction } from "./fetch";

const timers = {};
const mergedData = {};

export interface IDebounceAction extends IFetchAction {
  meta: {
    debounce: {
      name: string;
      time: number;
      merge: boolean;
    };
  };
}

export const debounceMiddleware = (store) => (next) => (action) => {
  if (action.meta && action.meta.debounce) {
    const { time = 1000, merge = false } = action.meta.debounce;

    if (timers[action.meta.debounce.name]) {
      clearTimeout(timers[action.meta.debounce.name]);
      timers[action.meta.debounce.name] = null;
    }

    const later = (resolve) => () => {
      resolve(next(action));
      timers[action.meta.debounce.name] = null;
      mergedData[action.meta.debounce.name] = null;
    };

    const mergeBody = () => {
      const body =
        action.payload.init.body && JSON.parse(action.payload.init.body);

      if (!Array.isArray(body)) return;

      if (
        !(action.meta.debounce.name in mergedData) ||
        mergedData[action.meta.debounce.name] === null
      )
        mergedData[action.meta.debounce.name] = {};

      body.forEach((element) => {
        mergedData[action.meta.debounce.name][element.id] = element;
      });

      action.payload.init.body = JSON.stringify(
        Object.values(mergedData[action.meta.debounce.name])
      );
    };

    return new Promise((resolve) => {
      if (merge) mergeBody();

      timers[action.meta.debounce.name] = setTimeout(later(resolve), time);
    });
  } else {
    return next(action);
  }
};
