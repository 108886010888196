import { Reducer } from "redux";
import { actionTypes } from "./ReviewerOverviewActions";
import {
  DefaultReviewerOverviewState,
  IReviewerOverviewState,
} from "./ReviewerOverviewState";
import { LoadState } from "@trinity/enums";

export const reviewerOverviewReducer: Reducer<IReviewerOverviewState> = (
  state: IReviewerOverviewState = DefaultReviewerOverviewState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_QUEUES:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          queues: LoadState.InProgress,
        },
      };

    case actionTypes.QUEUES_RECEIVED:
      return {
        ...state,
        queues: action.payload.queues,
        nextPackageGuid: "",
        loadState: {
          ...state.loadState,
          queues: LoadState.Loaded,
        },
      };

    case actionTypes.GET_PACKAGE_DETAILS:
      return {
        ...state,
        nextPackageGuid: action.payload.packageGuid,
      };

    case actionTypes.GET_REVIEWERQUEUES_SUMMARY:
      return {
        ...state,
        reviewerQueuesSummary: action.payload.reviewerSummary,
        loadState: {
          ...state.loadState,
          queuesSummary: LoadState.Loaded,
        },
      };

    default:
      return state || DefaultReviewerOverviewState;
  }
};
