import { MessageBarType } from "@fluentui/react";
import { AnyAction, Dispatch } from "redux";
import { configs } from "../../configs";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import {
  IConstantsState,
  IMessageBoxState,
  ShortcutTypes,
  IUserActionPayload,
  IFLAConstantsState,
} from "./CommonState";
import { getRiskScope } from "../../utils/common/getRiskScope";
import * as staticConstants from "./constants.json";

export enum actionTypes {
  DISPLAY_MESSAGE = "DISPLAY_MESSAGE",
  GET_CONSTANTS = "GET_CONSTANTS",
  GET_CONSTANTS_FAILED = "GET_CONSTANTS_FAILED",
  GET_FLA_CONSTANTS = "GET_FLA_CONSTANTS",
  GET_FLA_CONSTANTS_FAILED = "GET_FLA_CONSTANTS_FAILED",
  HIDE_MESSAGE = "HIDE_MESSAGE",
  CACHE_BUSTING = "CACHE_BUSTING",
  SHORTCUT = "SHORTCUT",
  SHORTCUT_RESET = "SHORTCUT_RESET",
  INIT_BROADCAST_CHANNEL = "INIT_BROADCAST_CHANNEL",
  BROADCAST_MESSAGE_TO_CHANNEL = "BROADCAST_MESSAGE_TO_CHANNEL",
  GET_CONSTANTS_LOADING = "GET_CONSTANTS_LOADING",
}

export const actionCreators = {
  showMessage: (messageBox: IMessageBoxState) => (dispatch: any) => {
    dispatch({
      type: actionTypes.DISPLAY_MESSAGE,
      payload: messageBox,
    });
  },

  hideMessage: () => (dispatch: any) => {
    dispatch({
      type: actionTypes.HIDE_MESSAGE,
    });
  },

  getConstants: () => async (dispatch: any) => {
    try {
      if (!localStorage.constants) {
        const response = await dispatch(
          fetchCreator(
            configs.client.endpoint.appServiceEndpoint + "constants",
            { customOptions: { scope: "ads" } }
          )
        );

        const data = await response.json();
        dispatch({
          type: actionTypes.GET_CONSTANTS,
          payload: data,
        });
      } else {
        dispatch({
          type: actionTypes.GET_CONSTANTS,
          payload: JSON.parse(localStorage.constants) as IConstantsState,
        });
      }
    } catch (error: any) {
      const data = staticConstants;
      dispatch({
        type: actionTypes.GET_CONSTANTS,
        payload: data,
      });
      dispatch({
        type: actionTypes.GET_CONSTANTS_FAILED,
        error: error,
      });
    }
  },

  getFLAConstants: () => async (dispatch, getState) => {
    try {
      const roles = getState().user.userRoles;

      if (!localStorage.flaConstants) {
        const response = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.tnsEndpoint}api/cache/reference`,
            {
              method: "GET",
              customOptions: { scope: getRiskScope(roles) },
            }
          )
        );

        const data = await response.json();

        dispatch({
          type: actionTypes.GET_FLA_CONSTANTS,
          payload: data,
        });
      } else {
        dispatch({
          type: actionTypes.GET_FLA_CONSTANTS,
          payload: JSON.parse(localStorage.flaConstants) as IFLAConstantsState,
        });
      }
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_FLA_CONSTANTS_FAILED,
        error: error,
      });
    }
  },

  cacheBust: () => (dispatch: any) => {
    dispatch({ type: actionTypes.CACHE_BUSTING });
  },

  keyboardShortcut: (type: ShortcutTypes | undefined) => (dispatch: any) => {
    dispatch({ type: actionTypes.SHORTCUT, payload: type });
  },

  resetKeyboardShortcuts: () => (dispatch: any) => {
    dispatch({ type: actionTypes.SHORTCUT_RESET });
  },

  initBroadcastChannel:
    (channel: string, fn: () => void) => (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: actionTypes.INIT_BROADCAST_CHANNEL,
        payload: { channel, fn },
      });
    },

  broadcastMessageToChannel:
    (channel: string, message: any) => (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: actionTypes.BROADCAST_MESSAGE_TO_CHANNEL,
        payload: { channel, message },
      });
    },

  //Makes the api call for the captured actions across the app.
  //Debounce to accumulate actions for every 10s.
  postUserAction:
    (body: Array<IUserActionPayload>) => async (dispatch: any) => {
      try {
        const fetchAction = fetchCreator(
          configs.client.endpoint.appServiceEndpoint + "UserActions",
          {
            method: "POST",
            body: JSON.stringify(body),
          }
        );
        const debounceAction = {
          ...fetchAction,
          meta: {
            debounce: {
              name: "postUserAction",
              time: 10000,
              merge: true,
            },
          },
        };

        dispatch(debounceAction);
      } catch (error: any) {}
    },

  viewPackage: (packageGuid: string) => async () => {
    window.open(`/reviewer/review-package/view/${packageGuid}`, "_blank");
  },
};
