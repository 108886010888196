import { IApplicationState } from "..";
import { configs } from "../../configs";
import { fetchCreator } from "../../utils/middleware/fetch";
import { IUserModulePreferences } from "../user-preference/UserPreferenceState";
import { actionCreators as commonActions } from "../common/CommonActions";
import { MessageBarType } from "@fluentui/react";
import { actionCreators as userPreferenceActions } from "../user-preference/UserPreferenceActions";
import { DetectionSource } from "@trinity/enums";
import { getRiskScope } from "../../utils/common/getRiskScope";
import { compare } from "../../utils/common/common";

export enum actionTypes {
  GET_DETECTION_HISTORY = "GET_DETECTION_HISTORY",
  GET_DETECTION_HISTORY_RECEIVED = "GET_DETECTION_HISTORY_RECEIVED",
  GET_DETECTION_HISTORY_FAILED = "GET_DETECTION_HISTORY_FAILED",
  CLEAR_DETECTION_HISTORY = "CLEAR_DETECTION_HISTORY",
}

export const actionCreators = {
  getDetectionHistory:
    (
      payload: {
        entityId: string;
        entityType: string;
        startDate: string;
        endDate: string;
        algoId: string | null;
        maxDetectionHistoryCount: number;
        source: DetectionSource;
      },
      hideLoader?: boolean
    ) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      const {
        entityId,
        entityType,
        startDate,
        endDate,
        algoId,
        maxDetectionHistoryCount,
        source,
      } = payload;
      try {
        const { userRoles } = getState().user;
        dispatch({ type: actionTypes.GET_DETECTION_HISTORY });
        if (!hideLoader) {
          dispatch(
            commonActions.showMessage({
              message: "loading...",
              type: MessageBarType.info,
            })
          );
        }
        const response = await dispatch(
          fetchCreator(
            `${configs.client.endpoint.tnsEndpoint}api/detection/history`,
            {
              method: "POST",
              body: JSON.stringify({
                EntityId: entityId,
                EntityType: entityType,
                StartDate: startDate,
                EndDate: endDate,
                AlgoId: algoId,
                MaxDetectionHistoryCount: maxDetectionHistoryCount,
                Source: source,
              }),
              customOptions: { scope: getRiskScope(userRoles) },
            }
          )
        );

        if (response.status === 204) {
          dispatch({
            type: actionTypes.GET_DETECTION_HISTORY_FAILED,
          });
          dispatch(
            commonActions.showMessage({
              message: "Incorrect details given.",
              type: MessageBarType.error,
            })
          );
          return;
        }

        const data = await response.json();

        dispatch(commonActions.hideMessage());
        dispatch({
          type: actionTypes.GET_DETECTION_HISTORY_RECEIVED,
          payload: data.sort((a, b) =>
            compare(
              true,
              new Date(a.notificationDateTimeUtc),
              new Date(b.notificationDateTimeUtc)
            )
          ),
        });
      } catch (error: any) {
        dispatch(
          commonActions.showMessage({
            message: error.message,
            type: MessageBarType.error,
          })
        );
        dispatch({ type: actionTypes.GET_DETECTION_HISTORY_FAILED });
      }
    },

  updateDetectionHistoryPreferences:
    (presets: IUserModulePreferences) =>
    async (dispatch: any, getState: () => IApplicationState) => {
      dispatch(
        userPreferenceActions.updateTNSDetectionHistoryPreferences(presets)
      );
    },

  clearDetectionHistory: () => async (dispatch: any) => {
    dispatch({
      type: actionTypes.CLEAR_DETECTION_HISTORY,
    });
  },
};
