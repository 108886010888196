// Middleware for defering execution of an action until another action completes (useful if you need to trigger an action to wait until another action completes)
export const DEFER = "utils/defer";

export interface IDeferAction {
  type: string;
  payload: {
    condition: (state: any) => boolean;
    actionCreator: () => Record<string, unknown>;
  };
}

export function defer(
  condition: (state: any) => boolean,
  actionCreator: () => Record<string, unknown>
): IDeferAction {
  return {
    type: DEFER,
    payload: {
      condition,
      actionCreator,
    },
  };
}

export const deferMiddleware = (store: any) => {
  const pending: IDeferAction[] = [];

  return (next: any) => (action: any) => {
    let res = null;

    // Register if a deferred action
    if (action.type === DEFER) {
      pending.push(action);
    } else {
      res = next(action);
    }

    // Get the potentially updated state
    const state = store.getState();

    // Test all pending deferred actions
    let idx = pending.length;
    while (idx--) {
      const item = pending[idx];
      // if the condition is met, dispatch the deferred action and remove it from pending
      if (item.payload.condition(state)) {
        pending.splice(idx, 1);
        store.dispatch(item.payload.actionCreator());
      }
    }

    return res;
  };
};
