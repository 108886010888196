import { IDropdownOption } from "@fluentui/react";

export const StatusTypeOption: IDropdownOption[] = [
  { key: 1, text: "Confirm" },
  { key: 99, text: "Pending" },
];

export enum InputKeys {
  TOKENS = "tokens",
  CATEGORY = "category",
  STATUS = "status",
}

export enum FieldName {
  tokens = "Tokens",
  category = "Category",
  status = "Status",
}
export enum PublisherRuleConstants {
  PUBLISHER_CATEGORY_DETAILS = "publisherRuleCategories",
}
