import { Reducer } from "redux";
import { actionTypes } from "./HeaderActions";
import { DefaultHeaderState, IHeaderState } from "./HeaderState";
import { HeaderItems } from "@trinity/enums";

export const headerReducer: Reducer<IHeaderState> = (
  state: IHeaderState = DefaultHeaderState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.HEADER_ITEM_OPENED:
      return {
        ...state,
        selectedItemKey:
          state.selectedItemKey === action.payload.selectedItemKey
            ? HeaderItems.Default
            : action.payload.selectedItemKey,
        title: action.payload.title,
      };

    case actionTypes.HEADER_ITEM_CLOSED:
      return {
        ...state,
        selectedItemKey: HeaderItems.Default,
        title: "",
      };

    case actionTypes.OPEN_WORKSPACES:
      return {
        ...state,
        showWorkspaces: true,
      };

    case actionTypes.CLOSE_WORKSPACES:
      return {
        ...state,
        showWorkspaces: false,
      };

    case actionTypes.SET_WORKSPACE:
      return {
        ...state,
        selectedWorkspaceKey: action.payload,
      };

    default:
      return state || DefaultHeaderState;
  }
};
